import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { sessionKeys, encryptStorage } from "../../configs/constant";
import { Loader } from "../loader";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import moment from "moment";
import Graph from "./graphupcoming";
import InvoiceData from "./invoiceData";
import { customerSessionData } from "../../interface";
import Displaycard from "./card";

export default function Dashboard() {
  let location = useLocation();
  let navigate = useNavigate();

  const [UpcomingRenewal, setUpcomingRenewal] = useState<any>([]);
  const [top10Customer, settop10Customer] = useState([]);
  const [revenueGraph, setrevenueGraph] = useState([]);
  const [loader, setloader] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [hoveredLicenseIndex, setHoveredLicenseIndex] = React.useState(null);

  const open = Boolean(anchorEl);

  // const[]
  useEffect(() => {
    getCardData();
  }, [location]);

  async function getCardData() {
  }

  const handlePopoverOpen = (event: any, index: any) => {
    setAnchorEl(event.currentTarget);
    setHoveredLicenseIndex(index);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setHoveredLicenseIndex(null);
  };


  return (
    <>
      <>
        {/* header starts */}
        {/* header ends */}
        {/* Admin starts */}
       

        <div className="container-fluid px-4">
          <div className="row mt-3">
            {Displaycard()}
            {Graph()}
            {InvoiceData()}
          </div>
        </div>

        {loader ? <Loader /> : null}
      </>
    </>
  );
}
