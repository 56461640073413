import React, { useRef, ChangeEvent, useState } from 'react';
import uploadFileToBlob from '../configs/blobContainer';
import { uploadProductLogo } from '../services/loginService';

interface FileInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  webkitdirectory?: string;
  directory?: string;
}
//** PS_SH_19, PS_SH_20 to perform the image upload */
const ImageUpload: React.FC = () => {
  const inputRef = useRef<HTMLInputElement>(null);



  const [filesUploaded, setFilesUploaded] = useState<any>();
  const [totalFiles, setTotalFiles] = useState<any>();
 //** PS_SH_21 handle folder selection operation */
  const handleFolderSelect = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };
//** PS_SH_22, PS_SH_23, PS_SH_24, PS_SH_25, PS_SH_26, PS_SH_27 handle the file */
  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    
    setTotalFiles(files?.length);
    if (files && files.length > 0) {
      const uploadedLogoData = [];

      for (let i = 0; i < files.length; i++) {
        let fileData = await uploadFileToBlob(files[i], `AVAHUB/productLogos`);
        uploadedLogoData.push({
            Url: fileData,
            product:files[i]?.name?.replace(/\.[^/.]+$/, '')
            } )

            
    setFilesUploaded(uploadedLogoData.length);

        

        
      }

      let pay:any ={
        "filesUlrs":uploadedLogoData
    }

      let DBUpdate = await uploadProductLogo(pay);

      // Perform the upload logic using formData, e.g., with axios or fetch
      
    }
  };
//** PS_SH_28 render the values into the html  */
  return (
    <div   style={{marginTop:"226px"}}>
      <button onClick={handleFolderSelect}>Select Folder</button>
      <input
        ref={inputRef}
        type="file"
        style={{ display: 'none' }}
        accept="image/*"
        multiple
        directory=""
        webkitdirectory=""
        onChange={handleFileUpload}
        // Using the custom interface for file input props
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...{ directory: '', webkitdirectory: '' } as FileInputProps}
      />
      <p>{filesUploaded? filesUploaded : 0 } - {totalFiles? totalFiles: 0} uploaded</p>
    </div>
  );
};

export default ImageUpload;
