import { EncryptStorage } from 'encrypt-storage';

const REACT_APP_SECRET_NAME: any = process.env.REACT_APP_SECRET_NAME;
export const encryptStorage = new EncryptStorage(REACT_APP_SECRET_NAME, {
    stateManagementUse: true,
    storageType: 'sessionStorage',
});

export const defaultLimit = 10

// LOCAL HOST URL
//export const backendurl = 'http://localhost:8080'
// NON PROD BACKEND URL
// export const backendurl = 'https://ava-eus-avahub-np-ca-be.delightfulplant-c0ce7a26.eastus.azurecontainerapps.io'

export const backendurl = 'https://ava-eus-avahub-appsvc-be-dev.azurewebsites.net';

export const configs = process.env.REACT_APP_SECRET_NAME;


export const subscriptionUrl = {
    getData: "/subscription/getsubscriptions",
    getdropdown: "/subscription/getfilterdropdowns",
    editsubscription: "/subscription/editsubscription",
    history: "/subscription/history",
    historyDropdown: "/subscription/historydropdown",
    updateSubscription: "/subscription/updatesubscription",
    cancelSubscription: "/subscription/cancelsusbcription"
}

// ned to change this while deploying
export const sessionKeys = {
    customerId: "eydvgjdsbcdbscjfknd4208182dsjhmbdhjcb-58cdhbsdjcmcsdbhd-4b08-8755ndcjdjhbks-3f18b0e46740 cdsnmcd",
    userDetails: "ebhdsmdncbdhcjjddbskcnmchdfbcff71bb056dcbhsnmccskdnjkjcd-ca10bskjnc-4729cdn-8386dnjk-f29765fff8cddnjk",
    customerDetails: "ejdhbfknjkmz0d78d601vfjfbjhknjcz-8f18vjndcm-4682bjfncjmd-afafvbcnjdmk-2a7f54588668hbknjcdmxnckjvj",
    customerCart: "eysdbjhnjsmcdbhnj2459c793gsbchjknjlmxk-1cc6bfhjsnkdcj-4787bfhjcdsnzkmlxs-b1f8bjhcnkjd-70db2da22732jbf",
    profileImage: "ebhjdnsmf01c9a2djfhbsdnk-060ddjhbfskndj-4284vjfhbnkjdczkmx-ad51jbhfxnkjcd-41de7c8fddeasbjdfhnkfbhnjn",
    sessiontokens:"eyvgcajzxbcdsjbcdf01c9a2dchbdhbcdsbchkbfd-060dvhhfbhisbcfdbvfdk-4284vcbsdibdshcd-ad51kc-41cks7c8fddea"
}

export const adminUrl = {//PS_AD_19
    userDetails: "/admin/userDetails",
    getUser: "/admin/getUser",
    getUserById: "/admin/getUserById",
    updateUserRole: "/admin/updateUserRole",
    fileNameUpload: "/admin/fileNameUpload"
}

export const loginUrl = {
    getRole: "/login/getRole",
    isRpaUser: "/login/isRpaUser"
}
//PC_NO_131, PC_NO_132, PC_NO_133, PC_NO_134, PC_NO_135, PC_NO_136, PC_NO_137, PC_NO_138, PC_NO_139, PC_NO_140, PC_NO_141, PC_NO_142, PC_NO_143,
export const customerUrl = {
    getCustomerDetails: "/customer/getCustomerDetails",
    getCustomerDetailsForFilter: "/customer/getCustomerDetailsForFilter",
    getCustomerBasicDetailsByid: "/customer/getCustomerBasicDetailsByid",
    getCustomerBrandDetails: "/customer/getCustomerBrandDetails",
    getCustomerContactDetailsById: "/customer/getCustomerContactDetailsById",
    getCustomerLegalDetailsById: "/customer/getCustomerLegalDetailsById",
    getCustomerLicensePriceListById: "/customer/getCustomerLicensePriceListById",
    getCustomerNotificationDetailsById: "/customer/getCustomerNotificationDetailsById",
    updateCustomerBasicDetailsByid: "/customer/updateCustomerBasicDetailsByid",
    updateCustomerBrandDetails: "/customer/updateCustomerBrandDetails",
    updateCustomerContactDetails: "/customer/updateCustomerContactDetails",
    updateCustomerNotificationDetails: "/customer/updateCustomerNotificationDetails",
    deleteCustomerLicensePriceList: "/customer/deleteCustomerLicensePriceList",
    updateCustomerLegalDetailsById:"/customer/updateCustomerLegalDetailsById",
    manualSync:"/customer/manualSync"
}


export const orderUrl = {
    getActivityLog: "/order/getActivityLog",
    getOrderDetailsByCustomer: "/order/getOrderDetailsByCustomer",
    getOrderFilterDropdownDetails: "/order/getOrderFilterDropdownDetails",
    getOrderDetailsByCustomerId: '/order/getOrderDetailsByCustomerId',
    getOrderDetailsByOrderId: '/order/getOrderDetailsByOrderId',
    insertActivityLog: "/order/insertActivityLog"
}
export const invetoryUrl = {
    getProducts: "/inventory/GetProducts",
    getCategories: "/inventory/categories",
    removeCategories: "/inventory/removecategory",
    addcategory: "/inventory/addcategory",
    newCategory: "/inventory/newcategory",
    cancelledcategory: "/inventory/removecancelledcategory",
    getcustomer: "/inventory/getcustomers",
    getavailablity: "/inventory/getavailablity",
    getTerms: "/inventory/getterms",
    addcart: "/inventory/addcart",
    addQuotes: "/inventory/createquotes",
    addLicense: "/inventory/addlicense",
    addons: "/inventory/addon",
    promo: "/inventory/getpromo",
}


export const quotesUrl = {
    getquotes: "/quotes/getCustomerQuotesDetails",
    editquotes: "/quotes/getCustomerQuotesLicenseDetailsById",
    getfromDetails: "/quotes/fromdetails",
    updatelicense: "/quotes/updateCustomerQuotesLicenseDetailsById",
    sendQuotes: "/quotes/sendquotes",
    deleteQuotes: "/quotes/deletequotes",
    previewQuotes:"/quotes/previewquotes"
}

export const errorUrl = {
    createErrorLog: "/error/createErrorLog"
}

export const InvoiceUrl = {
    getInvoiceDetails: "/invoice/getInvoiceDetails",
    getCustomerInvoiceDetailsById: "/invoice/getCustomerInvoiceDetailsById",
    getCustomerInvoiceDownloadAndPreview: "/invoice/getCustomerInvoiceDownloadAndPreview",
    updateCustomerInvoicelicenseDetails: "/invoice/updateCustomerInvoicelicenseDetails",
    createCustomerInvoiceDetails: "/invoice/createCustomerInvoiceDetails",
    createCustomerInvoicelicenseDetails: "/invoice/createCustomerInvoicelicenseDetails",
    getCustomerInvoiceGridFilterDetails: "/invoice/getCustomerInvoiceGridFilterDetails",
    deleteCustomerInvoiceDetailsById: "/invoice/deleteCustomerInvoiceDetailsById",
    saveCustomerInvoicelicenseDetails: "/invoice/saveCustomerInvoicelicenseDetails",
    getCustomerInvoiceFilterStatus: "/invoice/getCustomerInvoiceFilterStatus",
    getInvoicePrefixDetails: "/invoice/getInvoicePrefixDetails",
    getCustomerFilterDetails: "/invoice/getCustomerFilterDetails",
    invoiceCalculator: "/invoice/invoiceCalculator",
    getCustomerInvoiceLicenseDetailsById: "/invoice/getCustomerInvoiceLicenseDetailsById",
    getprieview:'/invoice/getpreview',
    triggerInvoice:'/invoice/triggerInvoice',
    validateCustomerEmail:'/invoice/validateCustomerEmail',
    sendinvoice:'/invoice/sendinvoice',
    downloadinvoice:'/invoice/downloadinvoice'
}

export const dashboardUrl = {
    getCardCount: "/dashboard/getCardCount",
    getRevenueCostGraph: "/dashboard/getRevenueCostGraph",
    getTopTenCustomerProfit: "/dashboard/getTopTenCustomerProfit",
    getupcomingrenewal: "/dashboard/getUpcomingRenewal"
}



export const OnlyNumberSpecialChar = (value: any) => {

    let regexItem = new RegExp('^[[\'"|.,=+?{}<>!0-9 @#$%^&*()_-]+$');
    let checkValid = regexItem.test(value);
    return checkValid
}



export const allowOnlyNumberAndCharacter = (value: any) => {
    let regexItem = new RegExp('^[[\'"|.,=+?{}<>@#$%^&*()_-]+$');
    let checkValid = regexItem.test(value);
    return checkValid

}



export const NotOnlySpecialChar = (value: any) => {
    let regexItem = new RegExp('^[[\'"|.,=+?{}<>! @#$%^&*()_-]+$');
    let checkValid = regexItem.test(value);
    return checkValid

}



export const OnlyEmail = (value: any) => {
    let emailValid = value.match(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/);
    let checkValid = emailValid ? true : false
    return !checkValid

}

export const OnlyNumber = (value: any) => {
    let regexItem = new RegExp("^[0-9]*$");
    let checkValid = regexItem.test(value);
    return !checkValid

}

export const OnlyIntegers = (value: any) => {
    // Allow integers only
    let regexItem = new RegExp("^[0-9]+$");
    return !regexItem.test(value);
}


export const OnlyIntegersInclidesDot = (value: any) => {
    // Allow integers only
    let regexItem = new RegExp("^[0-9.]*$");
    return !regexItem.test(value);
}

export const removeHtmlTags = (input: any) => {
    return input?.replace(/<[^>]*>/g, '');
};

export const OnlyText = (value: any) => {
    let regexItem = new RegExp("^[a-zA-Z]*$");
    let checkValid = regexItem.test(value);
    return !checkValid;
};

export function validatePhoneNumber(value: any) {
    const phoneNumberPattern = /^\d{3}[-\s]?\d{3}[-\s]?\d{4}$/;
    return phoneNumberPattern.test(value);
}

export function validateAddress(value: any) {
    const addressPattern = /^[a-zA-Z0-9\s,'-]*$/;
    return addressPattern.test(value);
}
export const onlytextandnumber = (value: any) => {
    let regexItem = new RegExp('^[a-zA-Z0-9]+$');
    let checkValid = regexItem.test(value);
    return !checkValid;

}



export const formatAmount = (amount:any) => {
    
    let options = { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 };
    let formattedNumber = amount?.toLocaleString('en-US', options);
    return formattedNumber;
}


