import { link } from "fs";
import React from "react";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addCart,
  addQuotesApi,
  insertLicenseApi,
} from "../../services/inventoryservice";
import { OnlyNumber, encryptStorage } from "../../configs/constant";
import { sessionKeys } from "../../configs/constant";
import { customerSessionData } from "../../interface";
import { log } from "console";
import { CheckPromoEligibility } from "../../services/inventoryservice";
import { userSessionData } from "../../interface";
import moment from "moment";
import { OnlyIntegersInclidesDot } from "../../configs/constant";
import ErrorPopup  from "../popup/errorPopup";
//import CartLoader from "./loadercart";
//** ps_I_55.2, ps_I_55.3, ps_I_55.4, ps_I_55.5, ps_I_55.6, ps_I_55.7, ps_I_55.8, ps_I_55.9, ps_I_55.10, ps_I_55.11, ps_I_55.12 */
export default function Cart() {
  const location = useLocation();
  const navigate = useNavigate();

  const [cartData, setcartData] = useState<any>([]);
  const [selectedDurations, setSelectedDurations] = useState<any>({});
  const [selectedBillingCycle, setSelectedBillingCycle] = useState<any>({});
  const [quantityValues, setQuantityValues] = useState<any>({});
  const [marginValues, setMarginValues] = useState<any>({});
  const [durationValues, setDurationValues] = useState<any>({});
  const [billingCycleValues, setBillingCycleValues] = useState<any>({});
  const [price, setprice] = useState<any>({});
  // const [margin, setmargin] = useState<any>({});
  const [cartErrordata, setcartErrorData] = useState<any>({});
  const [loader, setloader] = useState(false);
  const [productId, setproductID] = useState<any>({});
  const [productName, setproductName] = useState<any>({});
  const [showProductError, setshowProductError] = useState<boolean>(false);
  const [orderError, setorderError] = useState([]);
  const [checkedProductId, setcheckedproductId] = useState<any>([]);
  const [provisionPopup, setprovisionPopup] = useState(false);
  const [NotQuotesError, setNotQuotesError] = useState(false);
  const [NotQuotedProductedNames, setNotQuotedProductedNames] = useState([]);
  const [NewQuote, setNewQuote] = useState([]);
  const [checkIndex, setcheckIndex] = useState<any>({});
  const [checkQunatity, setCheckQuantity] = useState<any>({});
  const [promo, setpromo] = useState(false);
  const [loadingIndex, setLoadingIndex] = useState<any>({});
  const [showMoveDetails, setshowMoveDetails] = useState(false);
  const [moveId, setmoveId] = useState<any>(0);
  const [promotionsdetails, setpromotionsdetails] = useState(false);

  const [PromotionsPopupArray, setPromotionsPopupArray] = useState([]);
  const [showErrorPopup, setShowErrorPopup] = useState<boolean>(false);

  let customerDetails: customerSessionData | null = JSON.parse(
    encryptStorage.getItem(sessionKeys.customerDetails) || "null"
  );

  const value = new URL(window.location.toString()).searchParams.get(
    "editquote"
  );

  let sessionUserDetails: userSessionData | null = JSON.parse(
    encryptStorage.getItem(sessionKeys.userDetails) || "null"
  );


  useEffect(() => {
    
    setcartData(location.state.cart);
  }, []);

  useEffect(() => {
    const initialQuantityValues: any = {};
    const billingCycle: any = {};
    const duration: any = {};
    const productId: any = {};
    const prodcutName: any = {};
    const price: any = {};
    const margin: any = {};
    // cartData?.forEach((cart: any, index: any) => {
    //   initialQuantityValues[index] = 1;
    //   billingCycle[index] = cart?.MyTerms[0]?.billingCycle[0];
    //   duration[index] = cart?.MyTerms[0]?.duration;
    //   productId[index] = cart?.product?.id;
    //   prodcutName[index] = cart.product.title;
    //   price[index] = cart.price;
    //   margin[index] = cart.margin;
    // });

    cartData?.forEach((cart: any, index: any) => {
      initialQuantityValues[index] = 1;
      billingCycle[index] = cart?.MyTerms[0]?.billingCycle[0];
      duration[index] = cart?.MyTerms[0]?.duration;
      productId[index] = cart?.product?.id;
      prodcutName[index] = cart.product.title;
      let calculateprice = calculatePrice(
        cartData[index],
        duration[index],
        cart?.MyTerms[0]?.billingCycle[0]
      );
      price[index] = calculateprice;
      // margin[index] = customerDetails?.customer_margin
      //   ? customerDetails?.customer_margin
      //   : 0;
      margin[index] = !(location?.state?.customerId?.slice(0, 3) === "new")
        ? customerDetails?.customer_margin
        : 0;
    });
    if (!promo) {
      setQuantityValues(initialQuantityValues);
      setBillingCycleValues(billingCycle);
      setDurationValues(duration);
      setproductID(productId);
      setproductName(prodcutName);
      setprice(price);
      setMarginValues(margin);
      // setpromo(true);
      !(location.state.customerId.slice(0, 3) === "new") &&
        GetPromotions(billingCycle, duration);
    }
  }, [cartData]);

  // useEffect(() => {
  //   GetPromotions();
  // }, [promo]);
  //** ps_IC_4, ps_IC_5, ps_IC_6, ps_IC_7, ps_IC_8, ps_IC_9, ps_IC_10, ps_IC_11, ps_IC_12
  //Get the Promotion details of the Product */
  async function GetPromotions(billingCycles: any, Durations: any) {
    setloader(true);
    let lineItems: any = [];

    if (location?.state?.customerId) {
      cartData?.forEach((data: any, index: any) => {
        if (data.is_promo) {
          let promotionPayload = {
            id: index,
            catalogItemId: data.catalogItemId,
            quantity: 1,
            billingCycle: billingCycles[index],
            termDuration: Durations[index],
          };
          lineItems.push(promotionPayload);
          let promotion = data.promotions.filter((pr: any) => {
            return (
              billingCycles[index] == pr.billing_cyle &&
              Durations[index] == pr.duration
            );
          });
          data.EligiblitypromoDetails = promotion;
        }

        // if (daa.length > 0) {
        //   array.push(daa);
        // }
      });
    }

    if (lineItems.length > 0) {
      let res = await InvokeCheckElegibility(lineItems);

      res?.forEach((data: any) => {
        
        let promo = cartData?.filter((catId: any) => {
          return catId?.catalogItemId == data?.catalogItemId;
        });

        if (promo.length > 0) {
        if (data?.eligibilities[0]?.isEligible) {
          
          setpromo(true);
          // setcartData((data: any) => ({
          //   ...data,
          //   [data.promoEligibility]: true,
          //   [data.prmotionOfferCust]: true,
          // }));
          //need to work
          let datum = cartData.filter((datum: any) => {
            return data?.catalogItemId == datum.catalogItemId;
          });
          let valuespercent = datum[0].promotions.filter((pr: any) => {
            return (
              pr.duration.toLowerCase() ==
                data.termDuration.toLowerCase() &&
              pr.billing_cycle.toLowerCase() ==
                data.billingCycle.toLowerCase()
            );
          });

            setcartData((prevData: any) => {
              const updatedData = prevData.map((item: any) =>
                item.catalogItemId === data?.catalogItemId
                  ? {
                      ...item,
                      promoEligibility: true,
                      prmotionOfferCust: true,
                      value: valuespercent[0].value,
                    }
                  : item
              );

              return updatedData;
            });
          }
          setloader(false);
        }
      });


    }
    setloader(false);
  }
  //** ps_IC_4, ps_IC_5, ps_IC_6, ps_IC_7, ps_IC_8, ps_IC_9, ps_IC_10, ps_IC_11, ps_IC_12, ps_IC_13, ps_IC_14, ps_IC_15, ps_IC_16, ps_IC_17, ps_IC_18, ps_IC_19, ps_IC_20, ps_IC_21
  //to validate the Eligibility of Customer */
  async function InvokeCheckElegibility(lineItems: any) {
    // setloader(true);
    let payload = {
      items: lineItems,
      customer_id: location?.state?.customerId,
    };

    let data: any = await CheckPromoEligibility(payload);

    // setloader(false);
    return data.data.responseData;
  }
  //** ps_IC_4, ps_IC_5, ps_IC_6, ps_IC_7, ps_IC_8, ps_IC_9, ps_IC_10, ps_IC_11, ps_IC_12, ps_IC_13, ps_IC_14, ps_IC_15, ps_IC_16, ps_IC_17, ps_IC_18, ps_IC_19, ps_IC_20, ps_IC_21, ps_IC_22, ps_IC_23, ps_IC_24, ps_IC_25, ps_IC_26
  // to calculate the Price of the product*/
  const calculatePrice = (
    cart: any,
    selectedDuration: any,
    billingCycle: any
  ) => {
    const priceDetails = cart?.priceDetails?.find(
      (data: any) =>
        data?.term_duration === selectedDuration &&
        data?.billing_cycle == billingCycle
    );

    return priceDetails ? priceDetails.unit_price : 0;
  };

  
  //** ps_IC_22, ps_IC_23, ps_IC_24, ps_IC_25, ps_IC_26, ps_IC_27, ps_IC_28, ps_IC_29, ps_IC_30
  //Handle the quantity Changes in the Cart */
  const handleQuantityChange = async (
    index: any,
    value: any,
    cartItemId: any,
    allowpromoApicall: boolean
  ) => {
    setpromo(true);
    if (value == "" || value == 0) {
      setCheckQuantity({ ...checkQunatity, [index]: index });
    } else if (value != "" && Object.keys(checkQunatity).length && value != 0) {
      const updatedCheckIndex = { ...checkQunatity };
      delete updatedCheckIndex[index];
      setCheckQuantity(updatedCheckIndex);
    }
    if (!OnlyNumber(value)) {
      // if (value.length >= 0) {
      setQuantityValues({ ...quantityValues, [index]: value });

      // }
      // else {
      //   // setQuantityValues({ ...quantityValues, [index]: 1 });

      // }

      if (allowpromoApicall) {
        if (!(location.state.customerId.slice(0, 3) === "new")) {
          if (value !== "" && value.length > 0 && value != 0) {
            let data: any;
            let promotionPayload = [
              {
                id: index,
                catalogItemId: cartItemId,
                quantity: value != 0 ? value : 1,
                billingCycle: billingCycleValues[index],
                termDuration: durationValues[index],
              },
            ];

            // Wrap the setTimeout in a Promise

            const delay = (ms: any) =>
              new Promise((resolve) => setTimeout(resolve, ms));
            // setLoadingIndex({ ...loadingIndex, index: index });

            // Introduce a delay of 10 seconds before making the API call
            await delay(1000);
            setLoadingIndex((data: any) => ({
              ...data,
              [index]: true,
            }));

            data = await InvokeCheckElegibility(promotionPayload);

            if (data[0]?.eligibilities[0]?.isEligible) {
              setpromo(true);
              let datum = cartData.filter((datum: any) => {
                return data[0]?.catalogItemId == datum.catalogItemId;
              });
              let valuespercent = datum[0].promotions.filter((pr: any) => {
                return (
                  pr.duration.toLowerCase() ==
                    data[0].termDuration.toLowerCase() &&
                  pr.billing_cycle.toLowerCase() ==
                    data[0].billingCycle.toLowerCase()
                );
              });

              setcartData((prevData: any) => {
                const updatedData = prevData.map((item: any) =>
                  item.catalogItemId === data[0]?.catalogItemId
                    ? {
                        ...item,
                        promoEligibility: true,
                        prmotionOfferCust: true,
                        value: valuespercent[0].value,
                      }
                    : item
                );

                return updatedData;
              });
            } else {
              setcartData((prevData: any) => {
                const updatedData = prevData.map((item: any) =>
                  item.catalogItemId === data[0]?.catalogItemId
                    ? {
                        ...item,
                        promoEligibility: false,
                        prmotionOfferCust: false,
                      }
                    : item
                );

                return updatedData;
              });

              // setLoadingIndex({ ...loadingIndex, index: "" });
            }
            setLoadingIndex((data: any) => ({
              ...data,
              [index]: false,
            }));
          }
        }
      }
    }
  };

  
  ///** ps_IC_22, ps_IC_23, ps_IC_24, ps_IC_25, ps_IC_26, ps_IC_27, ps_IC_28, ps_IC_29, ps_IC_30, ps_IC_31, ps_IC_32, ps_IC_33, ps_IC_34
  //to handle the margin Changes into the Cart */
  const handleMarginChange = (index: any, value: any) => {
    const invalidMarginCombinations = [
      "0.0000",
      "00.000",
      "000.00",
      "0000.0",
      "00000",
      "0.0",
      "0.00",
      "0.000",
      ".00000",
      "000000",
      "00000.",
      "0.000",
      "00.00",
      "000.0",
      "00",
      "000",
      "0000",
      "000000",
      "00000.",
      "000.0",
      "0.",
      "00.",
      "00.0",
      "000.",
      "0000.",
    ];

    if (!OnlyIntegersInclidesDot(value)) {
      if (value === "") {
        setcheckIndex({ ...checkIndex, [index]: "Enter margin" });
      } 
      else if (Object.keys(checkIndex).length && checkIndex[index]) {
        const updatedCheckIndex = { ...checkIndex };
        delete updatedCheckIndex[index];
        setcheckIndex(updatedCheckIndex);
      }

      const dots = value.match(/\./g);
      const dotCount = dots ? dots.length : 0;

      if (dotCount > 1 || invalidMarginCombinations.includes(value) || value > 100) {
        setcheckIndex({ ...checkIndex, [index]: "Enter valid margin" });
      } else if (parseFloat(value) >= 0) {
        const updatedCheckIndex = { ...checkIndex };
        delete updatedCheckIndex[index];
        setcheckIndex(updatedCheckIndex);
      }

      setMarginValues({ ...marginValues, [index]: value });
    }
  };
  //** ps_IC_35 ps_IC_36 ps_IC_37 ps_IC_38 Handle the Duration changes in the cart */
  const handleDurationChange = async (
    cartItemId: string,
    duration: string,
    index: any,
    allowpromoApicall: number
  ) => {
    setpromo(true);

    const filteredDurations = cartData[index]?.MyTerms?.filter((item: any) => {
      // cartData[0]?.MyTerms[index]?.billingCycle[0]
      return item?.duration == duration;
    });
    const updatedPrice = calculatePrice(
      cartData[index],
      duration,
      filteredDurations[0].billingCycle[0]
    );
    setprice({ ...price, [index]: updatedPrice });
    setDurationValues({ ...durationValues, [index]: duration });
    setBillingCycleValues({
      ...billingCycleValues,
      [index]: filteredDurations[0].billingCycle[0],
    });

    if (allowpromoApicall) {
      if (!(location.state.customerId.slice(0, 3) === "new")) {
        let promotionPayload = [
          {
            id: index,
            catalogItemId: cartItemId,
            quantity: quantityValues[index],
            billingCycle: billingCycleValues[index],
            termDuration: duration,
          },
        ];
        setLoadingIndex((data: any) => ({
          ...data,
          [index]: true,
        }));

        let data = await InvokeCheckElegibility(promotionPayload);

        // data.foreach((id: any) => {

        // });

        if (data[0]?.eligibilities[0]?.isEligible) {
          let datum = cartData.filter((datum: any) => {
            return data[0]?.catalogItemId == datum.catalogItemId;
          });

          let valuespercent = datum[0].promotions.filter((pr: any) => {
            return (
              pr.duration.toLowerCase() == data[0].termDuration.toLowerCase() &&
              pr.billing_cycle.toLowerCase() ==
                data[0].billingCycle.toLowerCase()
            );
          });

          setcartData((prevData: any) => {
            const updatedData = prevData.map((item: any) =>
              item.catalogItemId === data[0]?.catalogItemId
                ? {
                    ...item,
                    promoEligibility: true,
                    prmotionOfferCust: true,
                    value: valuespercent[0].value,
                  }
                : item
            );

            return updatedData;
          });
        } else {
          setcartData((prevData: any) => {
            const updatedData = prevData.map((item: any) =>
              item.catalogItemId === data[0]?.catalogItemId
                ? { ...item, promoEligibility: false, prmotionOfferCust: false }
                : item
            );

            return updatedData;
          });
        }
      }
    }
    setLoadingIndex((data: any) => ({
      ...data,
      [index]: false,
    }));
  };
  //** ps_IC_39 ps_IC_40 ps_IC_41 ps_IC_42 Handle BillingCycle Change */
  const handleBillingCycleChange = async (
    cartItemId: string,
    cycle: string,
    index: any,
    allowpromoApicall: boolean
  ) => {
    setLoadingIndex((data: any) => ({
      ...data,
      [index]: true,
    }));
    // setSelectedBillingCycle({
    //   ...selectedBillingCycle,
    //   [cartItemId]: cycle,
    // });

    setpromo(true);

    const filteredDurations = cartData[index]?.MyTerms?.filter((item: any) => {
      return item.billingCycle && item.billingCycle.includes(cycle);
    });

    // filteredDurations[0].duration

    let data = filteredDurations.filter((dats: any) => {
      return dats.duration == durationValues[index];
    });

    setBillingCycleValues({ ...billingCycleValues, [index]: cycle });

    const updatedPrice = calculatePrice(
      cartData[index],
      data[0].duration,
      cycle
    );

    setprice({ ...price, [index]: updatedPrice });

    if (allowpromoApicall) {
      if (!(location.state.customerId.slice(0, 3) === "new")) {
        let promotionPayload = [
          {
            id: index,
            catalogItemId: cartItemId,
            quantity: quantityValues[index],
            billingCycle: cycle,
            termDuration: durationValues[index],
          },
        ];
        let checkeligi = await InvokeCheckElegibility(promotionPayload);

        // setpromo(!promo);
        if (checkeligi[0]?.eligibilities[0]?.isEligible) {
          let datum = cartData.filter((datum: any) => {
            return checkeligi[0]?.catalogItemId == datum.catalogItemId;
          });
          let valuespercent = datum[0].promotions.filter((pr: any) => {
            return (
              pr.duration.toLowerCase() ==
                checkeligi[0].termDuration.toLowerCase() &&
              pr.billing_cycle.toLowerCase() ==
                checkeligi[0].billingCycle.toLowerCase()
            );
          });
          setpromo(true);
          setcartData((prevData: any) => {
            const updatedData = prevData.map((item: any) =>
              item.catalogItemId === checkeligi[0]?.catalogItemId
                ? {
                    ...item,
                    promoEligibility: true,
                    prmotionOfferCust: true,
                    value: valuespercent[0].value,
                  }
                : item
            );

            return updatedData;
          });
        } else {
          setcartData((prevData: any) => {
            const updatedData = prevData.map((item: any) =>
              item.catalogItemId === checkeligi[0]?.catalogItemId
                ? { ...item, promoEligibility: false, prmotionOfferCust: false }
                : item
            );

            return updatedData;
          });
        }
      }
    }
    setLoadingIndex((data: any) => ({
      ...data,
      [index]: false,
    }));
    // setDurationValues({
    //   ...durationValues,
    //   [index]: filteredDurations[0].duration,
    // });
  };
  //** ps_IC_43, ps_IC_44, ps_IC_45 to handle the provison of the product */
  async function provision() {
    if (
      Object.keys(checkIndex).length <= 0 &&
      Object.keys(checkQunatity).length <= 0
    ) {
      setloader(true);
      let lineitems: any = [];
      let productIdWithItems: any = [];
      let Quotes: any = [];
      cartData?.forEach((cart: any, index: number) => {
        let data = {
          Id: index,
          CatalogItemId: cart?.catalogItemId,
          Quantity: quantityValues[index],
          TermDuration: durationValues[index],
          BillingCycle: billingCycleValues[index],
        };
        let dats = {
          Id: index,
          CatalogItemId: cart?.catalogItemId,
          Quantity: quantityValues[index],
          TermDuration: durationValues[index],
          BillingCycle: billingCycleValues[index],
          productId: productId[index],
          title: productName[index],
          //added segment
          segment: cart.segment,
        };
        let partnerPrice = cartData[index]?.priceDetails.filter((dats: any) => {
          return (
            dats.billing_cycle == billingCycleValues[index] &&
            dats.term_duration == durationValues[index]
          );
        });

        let QuoteDetails = {
          productId: productId[index],
          skuId: cart.sku.id,
          title: productName[index],
          Quantity: quantityValues[index] ? quantityValues[index] : 0,
          TermDuration: durationValues[index],
          BillingCycle: billingCycleValues[index],
          Microsoft_price: partnerPrice[0]?.erp_price
            ? partnerPrice[0]?.erp_price
            : 0,
          partner_price: partnerPrice[0]?.unit_price
            ? partnerPrice[0]?.unit_price
            : 0,
          margin: marginValues[index] ? marginValues[index] : 0,
          is_promo: cart.prmotionOfferCust ? 1 : 0,
          is_cust_promo: cart.prmotionOfferCust ? 1 : 0,
          is_ms_promo: cart.promoEligibility ? 1 : 0,
          promo_percent: cart?.value ? String(cart?.value) : String(0),
          is_tax_certificate_present: customerDetails?.tax
            ? customerDetails?.tax
            : 0,
          //added segment
          segment: cart.segment,
        };
        productIdWithItems.push(dats);
        lineitems.push(data);
        Quotes.push(QuoteDetails);
      });
      let payload = {
        LineItems: lineitems,
        customerId: location?.state?.customerId,
        Mylineitems: productIdWithItems,
        customerName: location?.state?.customerName,
        Quotes: Quotes,
        createdBy: sessionUserDetails?.user_id,
        CreatedName: sessionUserDetails?.user_name,
      };

      let cart = await addCart(payload);

      if (cart.data.statuscode == 200) {
        setprovisionPopup(true);
      } else if (cart.data.statuscode == 100) {
        setNotQuotedProductedNames(cart.data.productName);
        setNewQuote(cart.data.responseData);
        setNotQuotesError(true);
      } else if (cart.data.statuscode == 400) {
        // carterror
        const updatedCartErrorData: any = {};
        cart?.data?.errorData?.forEach((item: any, index: any) => {
          updatedCartErrorData[index] = {
            productId: item.productId,
            skuId: item.skuId,
            error: item.error,
          };
        });
        setcartErrorData(updatedCartErrorData);
        setloader(false);
      } else if (cart.data.statuscode == 250) {
        setorderError(cart.data.err);
        setshowProductError(true);
      }
    }
  }
  //** ps_IC_46, ps_IC_47, ps_IC_48, ps_IC_49 remove the cart data of the product */
  async function removeCartData(id = checkedProductId) {
    setpromo(false);
    // setLoadingIndex(index);
    const updatedCartData = cartData.filter(
      (item: any) => !id.includes(item.product.id)
    );
    let removeCart = checkedProductId.filter((item: any) => !id.includes(item));
    setcheckedproductId(removeCart);
    setcartData(updatedCartData);
  }
  //** ps_IC_50, ps_IC_51, ps_IC_52, ps_IC_53 handle increase the quanity */
  const handleIncrease = (index: any) => {
    const updatedValues = { ...quantityValues };
    updatedValues[index]++;
    setQuantityValues(updatedValues);
  };
  /** ps_IC_54," "ps_IC_55," "ps_IC_56," and "ps_IC_57 to handle the decrese opretion in product */
  const handleDecrease = (index: any) => {
    const updatedValues = { ...quantityValues };
    updatedValues[index]--;
    if (updatedValues[index] <= 0) {
      updatedValues[index] = 1;
    }
    setQuantityValues(updatedValues);
  };
  //** ps_IC_58," "ps_IC_59," and "ps_IC_60 to perform the add quotes or provision  */
  async function addQuoteAndProvision() {

    try{

      if (
        Object.keys(checkIndex).length <= 0 &&
        Object.keys(checkQunatity).length <= 0
      ) {
        setloader(true);
        let Quotes: any = [];
        cartData?.forEach((cart: any, index: number) => {
          let partnerPrice = cartData[index]?.priceDetails.filter((dats: any) => {
            return (
              dats.billing_cycle == billingCycleValues[index] &&
              dats.term_duration == durationValues[index]
            );
          });
          let QuoteDetails = {
            productId: productId[index],
            skuId: cart.sku.id,
            title: productName[index],
            Quantity: quantityValues[index],
            TermDuration: durationValues[index],
            BillingCycle: billingCycleValues[index],
            //need to add the unit price
            Microsoft_price: partnerPrice[0]?.erp_price
              ? partnerPrice[0]?.erp_price
              : 0,
            partner_price: partnerPrice[0]?.unit_price
              ? partnerPrice[0]?.unit_price
              : 0,
            margin: marginValues[index] ? Number(marginValues[index]) : 0,
            is_promo: cart.prmotionOfferCust ? 1 : 0,
            is_ms_promo: cart.promoEligibility ? 1 : 0,
            is_cust_promo: cart.prmotionOfferCust ? 1 : 0,
            promo_percent: cart?.value ? String(cart?.value) : String(0),
            is_tax_certificate_present: customerDetails?.tax
              ? customerDetails?.tax
              : 0,
            //added segment
            segment: cart.segment,
          };
          Quotes.push(QuoteDetails);
        });

        if ("quote_id" in location.state) {
          let payload = {
            customer_id: location?.state?.customerId,
            created_by: sessionUserDetails?.user_id,
            customerName: location?.state?.customerName,
            quote_id: location.state.quote_id,
            QuoteProduct: Quotes,
          };

          let insertLicense = await insertLicenseApi(payload);
          if (insertLicense.data.statusCode == 200) {
            setloader(false);
            navigate("/quote");
          }
        } else {
          let payload = {
            customer_id: location?.state?.customerId,
            created_by: sessionUserDetails?.user_id,
            customerName: location?.state?.customerName,
            QuoteProduct: Quotes,
          };

          let quotes = await addQuotesApi(payload);

          if (quotes.data.statusCode == 200) {
            setloader(false);
            setmoveId(quotes.data.responseData);
            setshowMoveDetails(true);
            // navigate("/quote");
          }
        }
      }

  }catch(e:any){


    if(e?.response.data?.statusCode == 429){
      setloader(false);
      setShowErrorPopup(true);
    }
      
  }
  }
  //** ps_IC_61 ps_IC_62 ps_IC_63 Create a quotes or proviosned the product for the customer */
  async function CreateQuoteAndProvision() {
    setNotQuotesError(false);
    setloader(true);
    let payload = {
      customer_id: location?.state?.customerId,
      customerName: location?.state?.customerName,
      created_by: sessionUserDetails?.user_id,
      QuoteProduct: NewQuote,
    };
    let quotes = await addQuotesApi(payload);

    if (quotes?.data?.statusCode == 200) {
      provision();
    }
  }



  const values = Object.keys(quantityValues).map((index) => {
    let calculateIspromo = cartData[index];
    if (calculateIspromo?.prmotionOfferCust) {
      const val = Number(price[index]) * Number(quantityValues[index]);
      const data = (val * Number(marginValues[index])) / 100;
      const removepercentage = data - cartData[index].value;
      const total = val + removepercentage;
      return total;
    } else {
      const val = Number(price[index]) * Number(quantityValues[index]);
      const data = (val * Number(marginValues[index])) / 100;
      const total = val + data;
      return total;
    }
  });

  // const values = Object.keys(quantityValues).map((index) => {

  //   const val = Number(price[index]) * Number(quantityValues[index]);
  //   const data = (val * Number(marginValues[index])) / 100;
  //   const total = val + data;
  //   return total;
  // });

  // Sum the values
  const totalValue = values.reduce((acc, val) => acc + val, 0);
  //** "ps_IC_64," "ps_IC_65," "ps_IC_66," and "ps_IC_67."  to show the promotion product details into the cart */
  function showPromotions(promotions: any, duration: any, billingCycle: any) {
    let data = promotions.filter((data: any) => {
      return data.billing_cycle == billingCycle && data.duration == duration;
    });
    setPromotionsPopupArray(data);
    setpromotionsdetails(true);
  }

  return (
    <>
      <>
        <div className="container-fluid px-4">
          <div className="px-5">
            <p className="font-20 font-semibold table-title-color mt-4">
              <img
                src="Images/back-arrow.svg"
                className="me-2"
                alt="back-arrow"
                onClick={() => {
                  navigate("/inventory", {
                    state: {
                      cart: cartData,
                      customerName: location?.state?.customerName,
                      customerId: location?.state?.customerId,
                      customerType: location?.state?.customerType,
                      mode: location?.state?.mode,
                      FromChoosedCustomer: location.state.FromChoosedCustomer
                        ? true
                        : false,
                    },
                  });
                }}
              />
              New Quote/Provision
            </p>
          </div>
          <div className="row mt-2">
            {/* Wizard starts here */}
            <div className="d-flex justify-content-center w-100">
              <div className="col-12 col-sm-12 col-md-11 col-lg-9 col-xl-5">
                <div className="wizard-conatiner mt-2 mb-4 d-flex justify-content-between position-relative">
                  <div className="wiz-step wizard-after active completed">
                    <div className="d-flex justify-content-center">
                      <span className="wiz-count font-regular font-14">1</span>
                    </div>
                    <span className="font-semibold d-none d-sm-inline-block wiz-text pt-2">
                      Choose Customer
                    </span>
                  </div>
                  <div className="wiz-step wizard-after active completed">
                    <div className="d-flex justify-content-center">
                      <span className="wiz-count font-regular font-14">2</span>
                    </div>
                    <span className="font-semibold d-none d-sm-inline-block wiz-text pt-2">
                      Add License
                    </span>
                  </div>
                  <div className="wiz-step wizard-after active">
                    <div className="d-flex justify-content-center">
                      <span className="wiz-count font-regular font-14">3</span>
                    </div>
                    <span className="font-semibold d-none d-sm-inline-block wiz-text pt-2">
                      Quote/Provision
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* Wizard ends here */}
          </div>
          <p className="ms-4 font-18">
            <span className=" font-semibold tertiary-color">
              Customer Name :{" "}
            </span>
            <span className="color-black-v2 font-semibold">
              {location?.state?.customerName}
            </span>
          </p>
          <div className="row mx-4 color-white-bg mb-5 rounded-3">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-9 bg-white rounded-3">
              <div className="row m-2 mt-4">
                <div className="d-flex justify-content-between p-0">
                  <h3 className="font-18 font-semibold tertiary-color mb-4 ">
                    Cart{" "}
                  </h3>
                  <span
                    className="color-red cursor-pointer"
                    onClick={() => {
                      checkedProductId?.length > 0 &&
                        removeCartData(checkedProductId);
                    }}
                  >
                    <img
                      src="Images/sm-delete-icon.svg"
                      className="me-2"
                      alt="delete-icon"
                    />
                    Remove
                  </span>
                </div>
                {/* first cart starts here */}
                {cartData?.map((cart: any, index: any) => {
                  let errorMessage = null;
                  let errorProductId = null;
                  Object?.keys(cartErrordata)?.forEach((key) => {
                    if (cart?.skuId === cartErrordata[key]?.skuId) {
                      errorProductId = cartErrordata[key]?.skuId;
                      errorMessage = cartErrordata[key]?.error;
                    }
                  });

                  return (
                    <>
                      {/* <div className="mb-4 px-0"> */}
                      <div
                        className={
                          cart.skuId == errorProductId
                            ? "d-flex cart-red-border  align-items-center px-2"
                            : "d-flex cart-border  align-items-center mb-4"
                        }
                      >
                        <div className="width-3">
                          <div className="form-check">
                            <input
                              className="form-check-input custom-check-box"
                              type="checkbox"
                              id={`checked${index}`}
                              // value={checkedProductId[index]}
                              checked={
                                checkedProductId?.includes(cart.product.id) ||
                                ""
                              }
                              onChange={(e: any) => {
                                if (e.target.checked) {
                                  setcheckedproductId((product: any) => [
                                    ...product,
                                    cart?.product?.id,
                                  ]);
                                } else {
                                  setcheckedproductId((sku: any) =>
                                    sku.filter(
                                      (id: any) => id !== cart?.product?.id
                                    )
                                  );
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div className="full-width">
                          <div className="row mt-3">
                            <div className="col-md-9">
                              <div className=" p-0 mb-2">
                                <div className="d-flex">
                                  <div className="d-flex">
                                    <img
                                      src={
                                        cart?.logoUrl
                                          ? cart?.logoUrl
                                          : "Images/image-not-available.svg"
                                      }
                                      alt="excel"
                                    />
                                  </div>
                                  <div className="my-3 ms-2">
                                    <p className="font-semibold font-14 color-black-v2 mb-0 ">
                                      {cart?.sku?.title}
                                    </p>
                                    <p className="tertiary-color font-16 font-semibold mb-0">
                                      ${price[index]}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {cart?.promoEligibility ? (
                              <div className="col-md-3">
                                <div className=" p-0">
                                  <div className="d-flex align-items-center">
                                    <label className="form-check-label font-14 color-black-v2 me-2">
                                      Promotions
                                    </label>
                                    <div className="form-check form-switch  theme-switch">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        id="check3"
                                        checked={cart?.prmotionOfferCust}
                                        onChange={(e) => {
                                          setcartData((data: any) => {
                                            const updatedPrmotionOfferCust = [
                                              ...data,
                                            ];
                                            updatedPrmotionOfferCust[index] = {
                                              ...updatedPrmotionOfferCust[
                                                index
                                              ],
                                              prmotionOfferCust: e.target
                                                .checked
                                                ? true
                                                : false,
                                            };

                                            return updatedPrmotionOfferCust;
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <p className="font-14 color-black-v2">
                                    {cart?.value}% discount
                                    <span
                                      className="link-blue font-14 ms-2"
                                      onClick={() => {
                                        showPromotions(
                                          cart.promotions,
                                          durationValues[index],
                                          billingCycleValues[index]
                                        );
                                      }}
                                    >
                                      Details
                                    </span>
                                  </p>
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <div className="row">
                            <div className="col-md-3">
                              <label className="font-12">
                                Qty<span className="color-red">*</span>
                              </label>
                              <div className="input-group mb-3 w-75">
                                <span
                                  className="custom-input-group input-group-text tertiary-color font-semibold"
                                  onClick={() => {
                                    handleDecrease(index);
                                  }}
                                >
                                  -
                                </span>
                                <input
                                  type="text"
                                  //   defaultValue={0}
                                  className="custom-input-group form-control custom-input"
                                  aria-label="Amount (to the nearest dollar)"
                                  value={quantityValues[index]}
                                  onChange={(e) =>
                                    handleQuantityChange(
                                      index,
                                      e.target.value,
                                      cart.catalogItemId,
                                      cart.is_promo
                                    )
                                  }
                                />
                                <span
                                  className="custom-input-group input-group-text tertiary-color font-semibold"
                                  onClick={() => {
                                    handleIncrease(index);
                                  }}
                                >
                                  +
                                </span>
                              </div>
                              {index == checkQunatity[index] ? (
                                <>
                                  <span className="color-red font-12 font-regular p-0">
                                    Enter Quantity
                                  </span>
                                </>
                              ) : null}
                            </div>
                            <div className="col-md-3">
                              <label className="font-12">
                                Margin<span className="color-red">*</span>
                              </label>
                              <div className="input-group mb-3 w-75">
                                <span
                                  className="custom-input-group-bg input-group-text ps-2 w-30"
                                  id="margin1"
                                >
                                  %
                                </span>
                                <input
                                  type="text"
                                  className="custom-input-group-bg  form-control custom-input"
                                  placeholder=""
                                  value={marginValues[index]}
                                  maxLength={5}
                                  onChange={(e) => {
                                    handleMarginChange(index, e.target.value);
                                  }}
                                  aria-label="Username"
                                />
                              </div>

                              <>
                                <span className="color-red font-12 font-regular p-0">
                                  {checkIndex[index]}
                                </span>
                              </>
                            </div>
                            {Object.keys(durationValues).length > 0 ? (
                              <div className="col-md-3 pe-5">
                                <label className="font-12">
                                  Term Duration
                                  <span className="color-red">*</span>
                                </label>
                                <select
                                  className="form-select custom-select custom-input"
                                  aria-label="Default select example"
                                  value={durationValues[index]}
                                  onChange={(e) =>
                                    handleDurationChange(
                                      cart.catalogItemId,
                                      e.target.value,
                                      index,
                                      cart.is_promo
                                    )
                                  }
                                >
                                  {selectedBillingCycle &&
                                    cart?.MyTerms?.map((term: any) => (
                                      <option
                                        key={term.duration}
                                        value={term.duration}
                                      >
                                        {term.duration}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            ) : null}

                            {Object.keys(durationValues).length > 0 ? (
                              <div className="col-md-3 pe-5">
                                <label className="font-12">
                                  Billing Cycle
                                  <span className="color-red">*</span>
                                </label>
                                <select
                                  className="form-select custom-select custom-input"
                                  aria-label="Default select example"
                                  value={billingCycleValues[index]}
                                  onChange={(e) =>
                                    handleBillingCycleChange(
                                      cart.catalogItemId,
                                      e.target.value,
                                      index,
                                      cart.is_promo
                                    )
                                  }
                                  // disabled={!durationValues[index]}
                                >
                                  {/* {cart?.MyTerms?.filter(
                                  (term: any) =>
                                    term.duration ===
                                    selectedDurations[cart.catalogItemId]
                                ).map((term: any) =>
                                  term.billingCycle.map((cycle: any) => (
                                    <option key={cycle} value={cycle}>
                                      {cycle}
                                    </option>
                                  ))
                                )} */}
                                  {cart?.MyTerms?.filter(
                                    (term: any) =>
                                      term.duration === durationValues[index]
                                  ).map((term: any) =>
                                    term.billingCycle.map((cycle: any) => (
                                      <option key={cycle} value={cycle}>
                                        {cycle}
                                      </option>
                                    ))
                                  )}
                                </select>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className=" d-flex justify-content-center width-1 align-items-center p-0 ms-4 me-2">
                          <div className="cursor-pointer">
                            <img
                              src="Images/sm-delete-icon.svg"
                              alt="delete-icon"
                              onClick={() => {
                                removeCartData(cart?.product.id);
                              }}
                            />
                          </div>
                        </div>
                        {loadingIndex[index] && (
                          <div className="overlay position-absolute rounded-3">
                            <div className="position-absolute top-50 start-50 translate-middle">
                              <div
                                className="spinner-border Loader text-light align-center"
                                role="status"
                              >
                                <span className="visually-hidden"></span>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* <CartLoader /> */}
                      </div>
                      <span className="color-red font-12 font-regular p-0">
                        {/* {cartErrordata[index]?.skuId == cart?.skuId
                          ? cartErrordata[index]?.error
                          : null} */}
                        {errorMessage}
                      </span>
                      {/* </div> */}
                    </>
                  );
                })}
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 mt-5 pt-4 ps-0">
              <div className="cart-border p-3 color-white-v2">
                <p className="color-black font-16 font-semibold mb-2">
                  {`Items(${cartData?.length})`}
                </p>
                {cartData?.map((cart: any, index: any) => {
                  return (
                    <div className="border-bottom py-1">
                      <div className="d-flex justify-content-between color-black-v2">
                        <span className="tertiary-color mb-2">
                          {cart?.sku?.title.length >= 28
                            ? cart?.sku?.title.substring(0, 27) + "..."
                            : cart?.sku?.title}
                        </span>
                        <span className="mb-2 color-black font-semibold">
                          ${values[index]?.toFixed(2)}
                        </span>
                      </div>
                    </div>
                  );
                })}
                <div className="border-bottom py-1">
                  <div className="d-flex justify-content-between color-black-v2 pt-2">
                    <span className="tertiary-color mb-2">Subtotal</span>
                    <span className="mb-2 color-black font-semibold">
                      ${totalValue.toFixed(2)}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between color-black-v2">
                    <span className="tertiary-color mb-2">Tax</span>
                    {customerDetails?.tax == 0 ||
                    customerDetails?.tax == null ? (
                      <span className="mb-2 color-black font-semibold">
                        ${((totalValue * 6) / 100).toFixed(2)}
                      </span>
                    ) : (
                      <span className="mb-2 color-black font-semibold">
                        $0.00
                      </span>
                    )}
                  </div>
                </div>
                {
                  <div className="d-flex justify-content-between color-black-v2 pt-2">
                    <span className="tertiary-color mb-2">Total </span>
                    <span className="color-black font-semibold">
                      ${(totalValue + (totalValue * 6) / 100).toFixed(2)}
                    </span>
                  </div>
                }
              </div>
            </div>
            <div className="d-flex justify-content-between mb-5 mt-5 px-4 ">
              <div>
                <button
                  type="button"
                  className="btn outline-btn text border-0 font-14 font-semibold me-3 shadow-none"
                  onClick={() => {
                    navigate("/inventory", {
                      state: {
                        cart: cartData,
                        customerName: location?.state?.customerName,
                        customerId: location?.state?.customerId,
                        customerType: location?.state?.customerType,
                        mode: location?.state?.mode,
                        FromChoosedCustomer: location.state.FromChoosedCustomer
                          ? true
                          : false,
                        NewQuote: location?.state?.newQuote
                          ? location?.state?.newQuote
                          : false,
                      },
                    });
                  }}
                >
                  <img
                    src="Images/previous-arrow.svg"
                    className="me-2"
                    alt="previous-arrow"
                  />
                  Previous
                </button>
              </div>
              <div className="">
                <button
                  type="button"
                  className="btn  text-btn text border-0 font-14 font-semibold me-3 shadow-none"
                  onClick={() => {
                    // if (value != null || location?.state?.newQuote) {
                    //   navigate("/quote");
                    // }
                    if (value != null) {
                      navigate("/editquote", {
                        state: { quote_id: location?.state?.quote_id },
                      });
                    } else if (location?.state?.newQuote) {
                      navigate("/quote");
                    } else if (location?.state?.FromChoosedCustomer) {
                      navigate("/inventory", { state: {} });
                    } else if (customerDetails?.customer_id) {
                      navigate("/inventory", {
                        state: {
                          mode: "customer",
                          customerId: customerDetails?.customer_id,
                          customerName: customerDetails?.customer_Name,
                          customerType: "Existing Customer",
                        },
                      });
                    }
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn outline-btn text border-0 font-14 font-semibold me-3 shadow-none"
                  disabled={cartData.length <= 0}
                  onClick={() => {
                    addQuoteAndProvision();
                  }}
                >
                  Quote
                </button>
                {!location?.state?.newQuote ? (
                  <button
                    type="button"
                    className="btn primary-btn border-0 font-14 font-semibold "
                    disabled={
                      cartData.length <= 0 ||
                      location.state.customerId?.slice(0, 3).includes("new")
                    }
                    onClick={() => {
                      provision();
                    }}
                  >
                    Provision
                  </button>
                ) : null}
              </div>
            </div>
          </div>

       { showErrorPopup? <ErrorPopup closePopup = {setShowErrorPopup} message={"You've reached the maximum limits.Try after a minute"}/> : <></>}
          {loader ? (
            <div className="overlay">
              <div className="position-absolute top-50 start-50 translate-middle">
                <div
                  className="spinner-border Loader text-light align-center "
                  role="status"
                >
                  <span className="visually-hidden" />
                </div>
                <p className="loading-text font-14 color-white mt-2 font-semibold">
                  Loading..
                </p>
              </div>
            </div>
          ) : null}
        </div>
        {showProductError ? (
          <div
            className="modal fade show"
            id="deletepopup"
            style={{ display: "block" }}
            // data-bs-backdrop="static"
            // data-bs-keyboard="false"
            tabIndex={-1}
            aria-hidden="true"
          >
            <div className="modal-dialog  modal-dialog-centered">
              <div className="modal-content px-3 border-0">
                <div className="modal-header border-0 justify-content-center">
                  <span className="popup-icon-pos">
                    <img
                      src="Images/exclamatory-icon.svg"
                      alt="delete-popup-icon"
                      className="popup-icon"
                    />
                  </span>
                </div>
                <div className="modal-body ">
                  <p className=" font-14 font-semibold secondary-text-color mb-3 mt-3">
                    These “licenses” are not able to proceed with provisions.
                  </p>
                  <p className="font-14 font-semibold secondary-text-color mb-2 ps-1">
                    License
                  </p>

                  {orderError?.map((err: any) => {
                    return (
                      <>
                        <div className="border font-14 font-regular p-2 rounded-2 mb-2">
                          {err?.title}
                        </div>
                      </>
                    );
                  })}
                </div>
                <div className="modal-footer border-0 justify-content-center px-2 mb-2">
                  <button
                    type="button"
                    className="btn danger-btn font-14 font-medium"
                    onClick={() => {
                      setshowProductError(false);
                      if (customerDetails?.customer_id) {
                        navigate("/inventory", {
                          state: {
                            mode: "customer",
                            customerId: customerDetails?.customer_id,
                            customerName: customerDetails?.customer_Name,
                            customerType: "Existing Customer",
                          },
                        });
                      } else {
                        navigate("/inventory", { state: {} });
                      }
                    }}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {provisionPopup ? (
          <div
            className="modal fade show"
            style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
            id="successpopup"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-hidden="true"
          >
            <div className="modal-dialog popup-w-400 modal-dialog-centered">
              <div className="modal-content px-3">
                <div className="modal-header border-0 justify-content-center">
                  <span className="popup-icon-pos">
                    <img
                      src="Images/success-icon.svg"
                      alt="success-icon"
                      className="popup-icon"
                    />
                  </span>
                </div>
                <div className="modal-body pt-5 px-3">
                  <p className="text-center font-14 font-medium secondary-text-color">
                    Provision done successfully!{" "}
                  </p>
                </div>
                <div className="modal-footer border-0 justify-content-center px-3">
                  <button
                    type="button"
                    className="btn text-btn green-btn shadow-none px-3 font-14 font-medium"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      navigate("/inventory", { state: {} });
                    }}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {NotQuotesError ? (
          <div
            className="modal fade show"
            style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
            id="deletepopup"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content px-3 border-0">
                <div className="modal-header border-0 justify-content-center">
                  <span className="popup-icon-pos">
                    <img
                      src="Images/exclamatory-icon.svg"
                      alt="delete-popup-icon"
                      className="popup-icon"
                    />
                  </span>
                </div>
                <div className="modal-body">
                  <p className="font-14 font-semibold secondary-text-color mb-3 mt-3">
                    These “licenses” are not in customer's quoted list.Would you
                    like to generate a quote and proceed with provision ?
                  </p>
                  <p className="font-14 font-semibold secondary-text-color mb-2 ps-1">
                    License
                  </p>
                  {NotQuotedProductedNames?.map((productNam: any) => {
                    return (
                      <>
                        <div className="border font-14 font-regular p-2 rounded-2 mb-2">
                          {productNam}
                        </div>
                      </>
                    );
                  })}
                </div>
                <div className="modal-footer border-0 justify-content-center px-2 mb-2">
                  <button
                    type="button"
                    className="btn danger-btn font-14 font-medium"
                    onClick={() => {
                      setNotQuotesError(false);
                      setloader(false);
                    }}
                  >
                    No
                  </button>
                  <button
                    type="button"
                    className="btn danger-btn font-14 font-medium"
                    onClick={() => {
                      CreateQuoteAndProvision();
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {showMoveDetails ? (
          <div
            className="modal fade show"
            style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
            id="successpopup"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-hidden="true"
          >
            <div className="modal-dialog popup-w-400 modal-dialog-centered">
              <div className="modal-content px-3">
                <div className="modal-header border-0 justify-content-center">
                  <span className="popup-icon-pos">
                    <img
                      src="Images/success-icon.svg"
                      alt="success-icon"
                      className="popup-icon"
                    />
                  </span>
                </div>
                <div className="modal-body pt-5 px-3">
                  <p className="text-center font-14 font-medium secondary-text-color">
                    Quote created successfully! Fill customer details to send
                    the quote.{" "}
                  </p>
                </div>
                <div className="modal-footer border-0 justify-content-center px-3">
                  <button
                    type="button"
                    className="btn text-btn shadow-none px-3 font-14 font-medium"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      navigate("/quote");
                    }}
                  >
                    Not Now
                  </button>
                  <button
                    type="button"
                    className="btn primary-btn font-14 font-medium"
                    onClick={() => {
                      navigate("/editquote", {
                        state: { quote_id: moveId },
                      });
                    }}
                  >
                    Move to Details
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {
          <>
            {promotionsdetails ? (
              <div
                className="modal fade show"
                style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
                id="inventory-prom"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content p-3">
                    <div className="modal-header px-1 pt-0 border-0 d-flex align-items-center">
                      <h3 className="font-16 font-semibold tertiary-color mb-0">
                        Promotion
                      </h3>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          setPromotionsPopupArray([]);
                          setpromotionsdetails(false);
                        }}
                      />
                    </div>
                    <div className="modal-body px-1 pt-0 pb-2 custom-promotion-popup-scroll">
                      <h5 className="font-14 font-semibold tertiary-color">
                        Available Promotions
                      </h5>
                      <p className="font-14 font-regular secondary-text-color">
                        Customer eligibility will be validated when reviewing
                        the cart.
                      </p>
                      {PromotionsPopupArray?.map((promo: any) => {
                        return (
                          <>
                            <h5 className="font-14 font-semibold tertiary-color">
                              {promo.product_name}
                            </h5>
                            <p className="font-14 font-regular secondary-text-color">
                              ID : {promo.promotion_id}
                            </p>
                            <div className="table-responsive mb-4 rounded-3">
                              <table className="table  themeTable ">
                                <thead className="font-bold">
                                  <tr>
                                    <th className="text-start">
                                      Term Duration
                                    </th>
                                    <th className="text-start">
                                      Billing Cycle
                                    </th>
                                    <th className="text-start">Discount</th>
                                    <th className="text-start">End Date</th>
                                  </tr>
                                </thead>
                                <tbody className="font-medium">
                                  <tr className="border-bottom-0 ">
                                    <td className="text-start font-regular">
                                      {promo.duration}
                                    </td>
                                    <td className="text-start font-regular">
                                      {promo.billing_cycle}
                                    </td>
                                    <td className="text-start font-regular">
                                      {promo.value}%
                                    </td>
                                    <td className="text-start font-regular">
                                      {moment(promo.end_date).format(
                                        "MM/DD/YYYY"
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </>
        }
      </>
    </>
  );
}
