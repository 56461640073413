import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { sessionKeys, encryptStorage } from "../../configs/constant";
import { useMsal } from "@azure/msal-react";
import { getcustomerApi } from "../../services/inventoryservice";
import { customerSessionData, userSessionData } from "../../interface";
import { insertActivityLogDetails } from "../../services/orderService";
import IdleTimer from '../../configs/idealTimer';

export default function Header() {
  const { instance } = useMsal();

  let customerDetails: customerSessionData | null = JSON.parse(
    encryptStorage.getItem(sessionKeys.customerDetails) || "null"
  );

  let sessionUserDetails: userSessionData | null = JSON.parse(
    encryptStorage.getItem(sessionKeys.userDetails) || "null"
  );

  const [logo, setLogo] = useState<any>(
    encryptStorage.getItem(sessionKeys.profileImage)
  );
  const [userDetails, setUserDetails] = useState<userSessionData | null>(
    sessionUserDetails
  );
  
  const [customerLogo, setCustomerLogo] = useState("Images/default-logo.svg");

  const [customerId, setCustomerId] = useState<any>(
    customerDetails?.customer_id
  );
  const navigate = useNavigate();
  const [searchvalue, setsearchvalue] = useState("");
  const [oldcustomerData, setoldCustomerData] = useState([]);

  const [getCustomerData, setGetcustomerData] = useState([]);
  const [customerName, setcustomerName] = useState(
    customerDetails?.customer_Name
  );
  const [showTimeOutPopupShow, setShowTimeOutPopupShow] = useState(false);
  const value = new URL(window.location.toString()).searchParams.get(
    "editquote"
  );
  useEffect(() => {
    getcustomerDatas();
  }, []);



  // useEffect(() => {
  //   /**
  //    * PS_HD_1.3
  //    * useEffect gets triggered and invoke loginValidation
  //    */


  //   const timer = new IdleTimer({
  //     timeout: 1800, //expire after 30 minutes
  //     onTimeout: () => {
  //       //setTimeoutPopup(false);
        
  //       sessionStorage.clear();
  //       encryptStorage.clear();
  //       console.log("EXPIRED");
  //       setShowTimeOutPopupShow(true);

  //     },
  //     onExpired: () => {
  //       sessionStorage.clear();
  //       encryptStorage.clear();
  //       //   try {
  //       //     // your logic for expiration
  //       //     throw new Error("Expired");
  //       // } catch (error) {
  //       //     console.error('Error in onExpired callback:', error);
  //       // }
  //       //console.log("EXPIRED");
  //     },
  //   } as any);
  //   return () => {
  //     timer.cleanUp();
  //   };
  // }, []);

  useEffect(() => {
    const thirtyMinutesInMillis = 30 * 60 * 1000;
    let timeoutId:any;

    const resetTimeout = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(handleExpiration, thirtyMinutesInMillis);
    };

    const handleExpiration = () => {
      try {
        // Your logic for expiration
        sessionStorage.clear();
        encryptStorage.clear();
        // console.log("EXPIRED");
        setShowTimeOutPopupShow(true);
      } catch (error) {
        console.error('Error in expiration logic:', error);
      }
    };

    const handleUserInteraction = () => {
      resetTimeout();
      // Additional logic for user interaction, if needed
    };

    // Set up initial timeout
    resetTimeout();

    // Add event listeners for user interaction
    document.addEventListener('mousemove', handleUserInteraction);
    document.addEventListener('keydown', handleUserInteraction);

    // Cleanup: remove event listeners and clear timeout on component unmount
    return () => {
      document.removeEventListener('mousemove', handleUserInteraction);
      document.removeEventListener('keydown', handleUserInteraction);
      clearTimeout(timeoutId);
    };
  }, []);
  
  
  
  const handleSignOut = async () => {

    const baseUrl = new URL(window.location.href).origin;
    encryptStorage.clear();


    let logoutresp: any = await instance.logoutPopup().then(async () => {


      const activityPayload: any = {
        user_id: sessionUserDetails?.user_id,
        user_name: sessionUserDetails?.user_name,
        activity: "Logged Out",
        comments: "Logged Out",
        created_by: sessionUserDetails?.user_id
      }
      const postActivityResponse: any = await insertActivityLogDetails(activityPayload);
      sessionStorage.clear();
      encryptStorage.clear();
    }).catch((err: any) => {
      console.log(err);
    });
   window.location.href='/'

  };

  async function getcustomerDatas() {
    let customer = await getcustomerApi();

    if (customer.data.statusCode == 200) {
      setGetcustomerData(customer.data.responseData);
      setoldCustomerData(customer.data.responseData);
    }
  }
  function onchangeCustomerData(data: any) {


    let customerDetails: customerSessionData | null = JSON.parse(
      encryptStorage.getItem(sessionKeys.customerDetails) || "null"
    );


    let cartdetails = JSON.parse(
      encryptStorage.getItem(sessionKeys.customerCart) || "null"
    );
    if (customerDetails) {
      customerDetails.customer_id = data.customer_id;
      customerDetails.customer_Name = data.company_name;
      customerDetails.customer_margin = Number(data.margin);
      customerDetails.logo_url = data.logo_url;
      customerDetails.tax = data.tax_certificate
    }
    encryptStorage.setItem(
      sessionKeys.customerDetails,
      JSON.stringify(customerDetails)
    );


    if (window.location.pathname == "/inventory") {
      navigate("/inventory", {
        state: {
          mode: "customer",
          customerId: customerDetails?.customer_id,
          customerName: customerDetails?.customer_Name,
          customerType: customerDetails?.customer_id
            ? "Existing customer"
            : "new customer",
        },
      });
    } else if (window.location.pathname == "/cart") {
      navigate("/dashboard");
    }

    window.location.href = `${window.location.pathname}`;
  }

  function removeId() {
    let customerDetails: customerSessionData | null = JSON.parse(
      encryptStorage.getItem(sessionKeys.customerDetails) || "null"
    );
    if (customerDetails) {
      customerDetails.customer_id = null;
      customerDetails.customer_Name = null;
      customerDetails.customer_margin = null;
      customerDetails.customer_theme = null;
      customerDetails.tax = null
    }
    encryptStorage.setItem(
      sessionKeys.customerDetails,
      JSON.stringify(customerDetails)
    );

    // encryptStorage.setItem("customername", data.company_name);
    // encryptStorage.setItem(sessionKeys.customerId, data.customer_id);
    window.location.href = `/dashboard`;
  }

  function searchCompany(search = searchvalue) {
    setGetcustomerData((customerData: any) => {
      return customerData.filter((customer: any) => {
        // Check if customer and customer.name are defined before calling toLowerCase()
        return (
          customer.company_name &&
          customer.company_name.toLowerCase().includes(search.toLowerCase())
        );
      });
    });
    if (search == "") {
      setGetcustomerData(oldcustomerData);
    }
  }

  return (
    <>
      <nav className="navbar fixed-top navbar-expand-lg bg-light shadow-sm py-0 navbar-light">
        <div className="container-fluid">
          <a
            className="navbar-brand d-flex cursor-pointer"
          >
            <span className="d-flex">
              <img src="Images/CSP Logo.svg" alt="logo" onClick={() => {
                removeId();
              }} />
            </span>
            {customerDetails?.customer_id ? <span className="org-brd d-flex">
              <img
                src={customerDetails?.logo_url ? customerDetails?.logo_url : "Images/default-logo.svg"}
                alt="organization-logo"
                onClick={() => {
                  navigate('/dashboard')
                }}
                className="org-logo-sty "
              />
            </span> : <></>}
            {/* <span class="org-brd d-flex">
        <span class="org-logo-batch "> <img src="Images/dummy-logo.svg" alt="dummy-logo"></span>
      </span> */}
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mb-2 mb-lg-0 ps-0 ps-lg-4 me-auto">
              <li className="nav-item position-relative nav-item position-relative ">
                <a
                  className={`nav-link custom-nav-link font-semibold font-14 nav-item position-relative px-0 mx-3 cursor-pointer ${window.location.pathname.includes("dashboard") || window.location.pathname.includes("upcomingrenewal")
                    ? "active"
                    : ""
                    }`}
                  aria-current="page"
                  onClick={() => {
                    navigate("/dashboard");
                  }}
                >
                  Dashboard
                </a>
              </li>
              {!customerId ? (
                <li className="nav-item position-relative nav-item position-relative ">
                  <a
                    className={`nav-link custom-nav-link font-semibold font-14 nav-item position-relative  px-0 mx-3 cursor-pointer ${window.location.pathname.includes("/customer")
                      ? "active"
                      : ""
                      }`}
                    aria-current="page"
                    onClick={() => {
                      navigate("/customer");
                    }}
                  >
                    Customers
                  </a>
                </li>
              ) : null}
              <li className="nav-item position-relative nav-item position-relative ">
                <a
                  className={`nav-link custom-nav-link font-semibold font-14 nav-item position-relative  px-0 mx-3 cursor-pointer ${window.location.pathname.includes("/inventory") ||  window.location.pathname.includes("/newquoteprovision") || window.location.pathname.includes("/cart")
                    ? "active"
                    : ""
                    }`}
                  aria-current="page"
                  onClick={() => {
                    navigate("/inventory", {
                      state: {
                        mode: "customer",
                        customerId: customerDetails?.customer_id,
                        customerName: customerDetails?.customer_Name,
                        customerType: customerDetails?.customer_id
                          ? "Existing customer"
                          : "new customer",
                      },
                    });
                  }}
                >
                  Inventory
                </a>
              </li>
              <li className="nav-item position-relative nav-item position-relative ">
                <a
                  className={`nav-link custom-nav-link font-semibold font-14 nav-item position-relative  px-0 mx-3 cursor-pointer ${
                    window.location.pathname.includes("/quote") || window.location.pathname.includes("/editquote")  ? "active" : ""
                    }`}
                  aria-current="page"
                  onClick={() => {
                    navigate("/quote");
                  }}
                >
                  Quotes
                </a>
              </li>
              <li className="nav-item position-relative nav-item position-relative ">
                <a
                  className={`nav-link custom-nav-link font-semibold font-14 nav-item position-relative  px-0 mx-3 cursor-pointer ${window.location.pathname.includes("/order") ? "active" : ""
                    }`}
                  aria-current="page"
                  onClick={() => {
                    navigate("/order");
                  }}
                >
                  Activity
                </a>
              </li>
              <li className="nav-item position-relative nav-item position-relative ">
                <a
                  className={`nav-link custom-nav-link font-semibold font-14 nav-item position-relative  px-0 mx-3 cursor-pointer ${
                    window.location.pathname.includes("/subscription")   || window.location.pathname.includes("/editsubscription")
                    ? "active"
                    : ""
                    }`}
                  aria-current="page"
                  onClick={() => {
                    navigate("/subscription");
                  }}
                >
                  Subscription
                </a>
              </li>
              <li className="nav-item position-relative nav-item position-relative ">
                <a
                  className={`nav-link custom-nav-link font-semibold font-14 nav-item position-relative  px-0 mx-3 cursor-pointer ${window.location.pathname.includes("/invoice")
                    ? "active"
                    : ""
                    }`}
                  aria-current="page"
                  onClick={() => {
                    navigate("/invoice");
                  }}
                >
                  Invoice
                </a>
              </li>
              {!customerId && sessionUserDetails?.role?.toLocaleLowerCase() == "admin" ? (
                <li className="nav-item position-relative nav-item position-relative ">
                  <a
                    className={`nav-link custom-nav-link font-semibold font-14 nav-item position-relative  px-0 mx-3 cursor-pointer ${window.location.pathname.includes("/admin")
                      ? "active"
                      : ""
                      }`}
                    aria-current="page"
                    onClick={() => {
                      navigate("/admin");
                    }}
                  >
                    Admin
                  </a>
                </li>
              ) : null}
            </ul>

            {customerId ? (
              <div className="btn-group d-flex align-items-center mb-lg-0 mb-3 ">
                <label className=" font-semibold tertiary-color nav-item font-14 me-2"> Customers</label>
                <div className="dropdown me-3">
                  <div
                    className="form-select custom-select customer-drop-header d-flex align-items-center"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    {...(window.location.pathname === "/editquote" ||
                      value != null
                      ? { disabled: true }
                      : {})}
                  >
                    <div className="circle-logo-sty d-flex align-items-center">
                      <img
                        src={customerDetails?.logo_url ? customerDetails?.logo_url : "Images/default-logo.svg"}
                        alt="header-profile-img"
                        className="circle-logo"
                      />
                    </div>
                    <span className="ps-2">{customerName?.substring(0, 8)}</span>
                  </div>

                  <ul
                    className="dropdown-menu border-0 shadow-sm p-1"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <span className="dropdown-item primary-text-color font-13 font-regular mb-1 p-1">
                        <span className="position-relative d-flex align-items-center w-100">
                          <input
                            type="search"
                            className="border-0 font-regular cus-z-ind font-14 top-0 bottom-0 py-2 px-3 custom-input"
                            id="search"
                            placeholder="Search"
                            value={searchvalue}
                            onKeyDown={(e: any) => {
                              if (e.keyCode == 13) {
                                searchCompany(e.target.value);
                              }
                            }}
                            onChange={(e: any) => {
                              setsearchvalue(e.target.value);
                            }}
                          />
                          <span className="position-absolute search-icon">
                            <img
                              src="Images/search.svg"
                              alt="search-icon"
                              onClick={() => {
                                searchCompany();
                              }}
                            />
                          </span>
                        </span>
                      </span>
                    </li>

                    {getCustomerData?.map((data: any, index: any) => (


                      <li key={index}>
                        <a
                          className="dropdown-item primary-text-color font-13 font-regular mb-1 p-1 label-fix"
                          onClick={() => {
                            onchangeCustomerData(data);
                          }}
                        >
                          <div className="circle-logo-sty me-2 d-flex align-items-center">
                            <img
                              //src="Images/default-logo.svg"
                              src={data?.logo_url ? data?.logo_url : "Images/default-logo.svg"}
                              alt="header-profile-img"
                              className="circle-logo"
                            />
                          </div>
                          {data.company_name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ) : null}

            <div className="btn-group">
              <button
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                className="btn border-0 shadow-none p-0 "
              >
                <img
                  src={logo ? logo : "Images/default-profile-icon.svg"}
                  //src="Images/Profile-img.svg"
                  alt="header-profile-img"
                  className="header-profile-img profile-img-border-radius"
                />
              </button>
              <div className="dropdown-menu dropdown-menu-end text-center logout-container">
                <span>
                  <img
                    src={logo ? logo : "Images/default-profile-icon.svg"}
                    alt="logout-profile-img"
                    className="logout-profile-img mt-3 mb-3"
                  />
                </span>
                <p className="font-16 font-semibold tertiary-color mb-1">
                  {userDetails?.user_name ? userDetails?.user_name : "-"}
                </p>
                <p className="font-12 font-medium primary-text-color">
                  {userDetails?.department ? userDetails?.department : " -"}
                </p>
                <button
                  type="button"
                  className="primary-btn mt-1 mb-2"
                  data-bs-toggle="modal"
                  data-bs-target="#deletepopup">
                  Logout
                </button>
              </div>
            </div>
          </div>

          {showTimeOutPopupShow ? (
            <div
              className="modal fade show"
              style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
              id="successpopup"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex={-1}
              aria-hidden="true"
            >
              <div className="modal-dialog popup-w-400 modal-dialog-centered">
                <div className="modal-content px-3">
                  <div className="modal-header border-0 justify-content-center">
                    <span className="popup-icon-pos">
                      <img
                        src="Images/exclamatory-icon.svg"
                        alt="success-icon"
                        className="popup-icon"
                      />
                    </span>
                  </div>
                  <div className="modal-body pt-5 px-3">
                    <p className="text-center font-14 font-medium secondary-text-color">
                      Your session has been expired
                    </p>
                  </div>
                  <div className="modal-footer border-0 justify-content-center px-3">
                    <button
                      type="button"
                      className="btn text-btn danger-btn shadow-none px-3 font-14 font-medium"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        window.location.href = "/";
                      }}
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}



        </div>
      </nav>

      <div

        className="modal fade" id="deletepopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog role-popup-width  modal-dialog-centered">
          <div className="modal-content px-3 border-0">
            <div className="modal-header border-0 justify-content-center">
              <span className="popup-icon-pos">
                <img
                  src="Images/logout-icon.svg"
                  alt="delete-popup-icon"
                  className="popup-icon"
                />
              </span>
            </div>
            <div className="modal-body pt-5 px-3">
              <p className="text-center font-14 font-medium secondary-text-color mb-0">
                Are you sure, you want to logout ?
              </p>
            </div>
            <div className="modal-footer border-0 justify-content-center px-3 mb-2">
              <button
                type="button"
                className="btn text-btns shadow-none px-3 font-14 font-medium"
                data-bs-dismiss="modal"
              >
                No
              </button>
              <button type="button"
                className="btn danger-btn font-14 font-medium"
                onClick={() => { handleSignOut(); }}>
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}
