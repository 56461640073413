import React, { useState, useEffect, useRef } from "react";

import "./App.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./components/login/Login";
import { useIsAuthenticated } from "@azure/msal-react";
import Dashboard from "./components/dashboard/dashboard";
import Subscription from "./components/subscriptions/subscription";
import EditSubscripiton from "./components/subscriptions/editsubscription";
import Inventory from "./components/Inventory/inventory";
import Quoteprovision from "./components/Inventory/quoteProvision";
import Cart from "./components/Inventory/cart";
import Footer from "./components/footer/footer";
import Header from "./components/header/header";
import ImageList from "./components/Inventory/bay";
import QuotesGrid from "./components/quote/quote";
import EditQuotes from "./components/quote/editquote";
import { GetCustomerBrandDetails } from "./services/customerService";

// import UsersGrid from "./components/admin/userGrid";
import UsersGrid from "./components/admin/userGrid";
import CustomerGrid from "./components/customer/customerGrid";
import CustomerDetails from "./components/customer/customerDetails";
import OrderGrid from "./components/order/orderGrid";
import ActivityGrid from "./components/order/activityGrid";
import OrderDetails from "./components/order/orderDetails";
import InvoiceGrid from "./components/invoice/invoiceGrid";
import InvoiceForm from "./components/invoice/invoiceForm";
import UpcomingRenewal from "./components/dashboard/upcomingrenewal";
import CreateInvoice from "./components/invoice/createInvoice";
import RpaToken from "./components/login/rpaToken";

import LoginAuth from "./components/login/loginAuth";
import { customerSessionData, userSessionData } from "./interface";
import { sessionKeys, encryptStorage } from "./configs/constant";
import Error from "./components/error/error";
import ImageUpload from "./components/file_upload";


function App() {
  let refs: any = useRef();
  const isAuthenticated = useIsAuthenticated();
  let customerDetails: customerSessionData | null = JSON.parse(
    encryptStorage.getItem(sessionKeys.customerDetails) || "null"
  );

  let userDetails: userSessionData | null = JSON.parse(
    encryptStorage.getItem(sessionKeys.userDetails) || "null"
  );
  const [customerId, setCustomerId] = useState(customerDetails?.customer_id);

  useEffect(() => {
    getCustomerTheme();
  }, [customerId]);

  async function getCustomerTheme() {

    if (customerId != null) {
      let payload = {
        customer_id: customerId,
      };
      let gettheme = await GetCustomerBrandDetails(payload);
      refs?.current?.style.setProperty(
        "--primary-color",
        gettheme.responseData.theme_color ? gettheme.responseData.theme_color : '#6100ee'
      );

    }
  }


  return (
    <>
      <div ref={refs}>
        <BrowserRouter>
          <React.Fragment>
            <Routes>
              <Route path="/" element={<Login />}></Route>
              <Route path="/loginAuth" element={<LoginAuth />}></Route>
            </Routes>

            {sessionStorage.getItem("msal.account.keys") &&
              window.location.pathname != "/" &&
              window.location.pathname != "/loginAuth" &&
              window.location.pathname != "/oops" ? (
              <Header />
            ) : (
              <></>
            )}

            {sessionStorage.getItem("msal.account.keys") ? (
              <Routes>
                <Route path="/dashboard" element={<Dashboard />}></Route>
                <Route path="/ImageUpload" element={<ImageUpload />}></Route>
                <Route path="/oops" element={<Error />}></Route>
                <Route
                  path="/upcomingrenewal"
                  element={<UpcomingRenewal />}
                ></Route>
                <Route path="/order" element={<OrderGrid />}></Route>
                <Route path="/orderdetails" element={<OrderDetails />}></Route>
                <Route path="/activitylog" element={<ActivityGrid />}></Route>
                <Route path="/rpatoken" element={<RpaToken />}></Route>

                {userDetails?.role?.toLocaleLowerCase() != "admin" && window.location.pathname != "/loginAuth" && window.location.pathname != "/"?
                  <Route path="*" element={<Error />}></Route> : <Route path="/admin" element={<UsersGrid />}></Route>  }

                <Route path="/customer" element={<CustomerGrid />}></Route>

                <Route path="/customerdetails" element={<CustomerDetails />}></Route>
                <Route path="/subscription" element={<Subscription />}></Route>
                <Route
                  path="/editsubscription"
                  element={<EditSubscripiton />}
                ></Route>
                <Route path="/inventory" element={<Inventory />}></Route>
                <Route
                  path="/newquoteprovision"
                  element={<Quoteprovision />}
                ></Route>
                <Route path="/cart" element={<Cart />}></Route>
                <Route path="/csploginSPA" element={<ImageList />}></Route>
                <Route path="/quote" element={<QuotesGrid />}></Route>
                <Route path="/editquote" element={<EditQuotes />}></Route>
                <Route path="/invoice" element={<InvoiceGrid />}></Route>
                <Route path="/invoiceform" element={<InvoiceForm />}></Route>
                {/* <Route path="/create-invoice" element={<CreateInvoice />} ></Route> */}
              </Routes>
            ) :

              window.location.pathname != "/" ? (
                <Routes>
                  <Route path="*" element={<Login />}></Route>
                </Routes>
              ) : <></>}

            {sessionStorage.getItem("msal.account.keys") &&
              window.location.pathname != "/" &&
              window.location.pathname != "/loginAuth" &&
              window.location.pathname != "/oops" ? (
              <Footer/>
            ) : (
              <></>
            )}

          </React.Fragment>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
