import React, { useEffect, useState } from 'react';
import { GetCustomerContactDetailsById, UpdateCustomerContactDetails } from '../../services/customerService';
import { useLocation, useNavigate } from 'react-router-dom';
import { OnlyEmail, OnlyNumber, validatePhoneNumber,OnlyNumberSpecialChar, encryptStorage, sessionKeys } from '../../configs/constant';
import { contactData, accountDetail, serviceDetail, billingDetail, userSessionData } from '../../interface';
import { ErrorLog } from '../../services/errorService';
import { Loader } from '../loader';
//** PC_NO_1, PC_NO_2, PC_NO_3, PC_NO_4, PC_NO_5, Fetch the customer Contact Details */

function CustomerContactDetails() {


  
  const location = useLocation();
  let billingDetails: billingDetail = {
    customer_id: "",
    first_name: "",
    last_name: "",
    role: "",
    email_address: "",
    phone_number: ""

  }
  let accManagerDetail: accountDetail = {
    customer_id: "",
    first_name: "",
    last_name: "",
    role: "",
    email_address: "",
    phone_number: "",
    same_as_billing_details: ""

  }
  let serviceDeskDetail: serviceDetail = {
    customer_id: "",
    first_name: "",
    last_name: "",
    role: "",
    email_address: "",
    phone_number: "",
    same_as_billing_details: ""
  }


  let billingDetailsErrorMessage: billingDetail = {
    customer_id: "",
    first_name: "",
    last_name: "",
    role: "",
    email_address: "",
    phone_number: ""

  }
  let accManagerDetailErrorMessage: accountDetail = {
    customer_id: "",
    first_name: "",
    last_name: "",
    role: "",
    email_address: "",
    phone_number: "",
    same_as_billing_details: ""

  }
  let serviceDeskDetailErrorMessage: serviceDetail = {
    customer_id: "",
    first_name: "",
    last_name: "",
    role: "",
    email_address: "",
    phone_number: "",
    same_as_billing_details: ""
  }



  const billingErrorMessage = {
    first_name: "Enter first name",
    last_name: "Enter last name",
    role: "Enter Role",
    email_address: "Enter email address",
    phone_number: "Enter phone number"

  }

  let regexErrorMessage:any={
    first_name: "Enter valid first name",
    last_name: "Enter valid last name",
    role: "Enter valid role",
    email_address: "Enter valid email address",
    phone_number: "Enter valid phone number",
    empty:"",
  }

const navigate=useNavigate();
  const [custBillingDetails, setCustBillingDetails] = useState<billingDetail>(billingDetails)
  const [custAccManagerDetail, setCustAccManagerDetail] = useState<accountDetail>(accManagerDetail)
  const [custServiceDeskDetail, setCustServiceDeskDetail] = useState<serviceDetail>(serviceDeskDetail)

  const [custBillingDetailsErrorMessage, setCustBillingDetailsErrorMessage] = useState<billingDetail>(billingDetailsErrorMessage)
  const [custAccManagerDetailErrorMessage, setCustAccManagerDetailErrorMessage] = useState<accountDetail>(accManagerDetailErrorMessage)
  const [custServiceDeskDetailErrorMessage, setCustServiceDeskDetailErrorMessage] = useState<serviceDetail>(serviceDeskDetailErrorMessage)

  const [customerId, setCustomerId] = useState(location?.state?.customer_id);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [disableSave, setDisableSave] = useState(true);

  useEffect(() => {
    getContactDetailsbyId();
  }, []);

  async function getContactDetailsbyId() {

    try {
      const customerRequest = {
        customer_id: customerId,
      };
      setShowLoader(true);
      const response = await GetCustomerContactDetailsById(customerRequest);

      if (response.data.statusCode === 200) {

        setCustBillingDetails(response?.data?.responseData?.billing);
        setCustAccManagerDetail(response?.data?.responseData?.account);
        setCustServiceDeskDetail(response?.data?.responseData?.service);
        setShowLoader(false);

      }
      setShowLoader(false);
    } catch (error: any) {
      console.error('Error fetching data:', Error);
      
      const errorObject = {
        stack_name: "frontend_react",
        module_name: "customer_module",
        function_name: "GetCustomerBasicDetailsByid",
        error_message: error?.message
      }
      const request: any = await ErrorLog(errorObject);
    }


  }

//** PC_NO_82.2, PC_NO_82.3, PC_NO_82.4, PC_NO_82.5, PC_NO_82.6, PC_NO_82.7, PC_NO_82.8, PC_NO_82.9, PC_NO_82.10, PC_NO_82.11, PC_NO_82.12
// to validate the details dueing the Onchange and throw errorvalidation */
  const onBillingDetailsChange = (e: any) => {

    setDisableSave(false);
      setCustBillingDetails({ ...custBillingDetails, [e.target.name]: e.target.value })
      setCustBillingDetailsErrorMessage({...custBillingDetailsErrorMessage, [e.target.name]: "" })

      if(e.target.name == "phone_number" && !validatePhoneNumber(e?.target?.value) && e.target.value.length != 0){
        setCustBillingDetailsErrorMessage({...custBillingDetailsErrorMessage, [e.target.name]: regexErrorMessage[e.target.name] })
      }
      else if(e.target.name == "email_address" && OnlyEmail(e?.target?.value) && e.target.value.length != 0){
        setCustBillingDetailsErrorMessage({...custBillingDetailsErrorMessage, [e.target.name]: regexErrorMessage[e.target.name] })
      }
      else if(e.target.name != "phone_number" && e.target.name != "email_address" && OnlyNumberSpecialChar(e.target.value)){
        setCustBillingDetailsErrorMessage({...custBillingDetailsErrorMessage, [e.target.name]: regexErrorMessage[e.target.name] })
      }


  }
//** PC_NO_82.13, PC_NO_82.14, PC_NO_82.15, PC_NO_82.16, PC_NO_82.17, PC_NO_82.18, PC_NO_82.19, PC_NO_82.20, PC_NO_82.21, PC_NO_82.22, PC_NO_82.23, PC_NO_82.24, PC_NO_82.25
// to perform the validate for manager details */
  const onAccManagerDetailChange = (e: any) => {

    setDisableSave(false);
    if (e.target.name == "same_as_billing_details") {

      if (e.target.checked) {

        const accountDetail = {
          customer_id: custBillingDetails.customer_id,
          first_name: custBillingDetails.first_name,
          last_name: custBillingDetails.last_name,
          role: custBillingDetails.role,
          email_address: custBillingDetails.email_address,
          phone_number: custBillingDetails.phone_number,
          same_as_billing_details: "1"
        }
        setCustAccManagerDetail(accountDetail)

      } else {

        const accountDetail = {
          customer_id: custBillingDetails.customer_id,
          first_name: "",
          last_name: "",
          role: "",
          email_address: "",
          phone_number: "",
          same_as_billing_details: "0"
        }
        setCustAccManagerDetail(accountDetail)
      }
    } else {
      setCustAccManagerDetailErrorMessage({...custAccManagerDetailErrorMessage, [e.target.name]: "" })
      setCustAccManagerDetail({ ...custAccManagerDetail, [e.target.name]: e.target.value })

      if(e.target.name == "phone_number" && !validatePhoneNumber(e?.target?.value)){
        setCustAccManagerDetailErrorMessage({...custAccManagerDetailErrorMessage, [e.target.name]: regexErrorMessage[e.target.name] })
      }
      else if(e.target.name == "email_address" && OnlyEmail(e?.target?.value)){
        setCustAccManagerDetailErrorMessage({...custAccManagerDetailErrorMessage, [e.target.name]: regexErrorMessage[e.target.name] })
      }
      else if(e.target.name != "phone_number" && e.target.name != "email_address" && OnlyNumberSpecialChar(e.target.value)){
        setCustAccManagerDetailErrorMessage({...custAccManagerDetailErrorMessage, [e.target.name]: regexErrorMessage[e.target.name] })
      }
    }

  }
//**PC_NO_82.26, PC_NO_82.27, PC_NO_82.28, PC_NO_82.29, PC_NO_82.30, PC_NO_82.31, PC_NO_82.32, PC_NO_82.33, PC_NO_82.34, PC_NO_82.35, PC_NO_82.36, PC_NO_82.37, PC_NO_82.38, PC_NO_82.39, PC_NO_82.40
// to perform the validation for the Service details*/
  const onServiceDeskDetailChange = (e: any) => {
    setDisableSave(false);
    if (e.target.name == "same_as_billing_details") {


      if (e.target.checked) {

        const serviceDetail = {
          customer_id: custBillingDetails.customer_id,
          first_name: custBillingDetails.first_name,
          last_name: custBillingDetails.last_name,
          role: custBillingDetails.role,
          email_address: custBillingDetails.email_address,
          phone_number: custBillingDetails.phone_number,
          same_as_billing_details: "1"
        }
        setCustServiceDeskDetail(serviceDetail)

      } else {

        const serviceDetail = {
          customer_id: custBillingDetails.customer_id,
          first_name: "",
          last_name: "",
          role: "",
          email_address: "",
          phone_number: "",
          same_as_billing_details: "0"
        }
        setCustServiceDeskDetail(serviceDetail)
      }

    } else {

      setCustServiceDeskDetailErrorMessage({...custServiceDeskDetailErrorMessage, [e.target.name]: "" })
      setCustServiceDeskDetail({ ...custServiceDeskDetail, [e.target.name]: e.target.value })

      if(e.target.name == "phone_number" && !validatePhoneNumber(e?.target?.value)){
        setCustServiceDeskDetailErrorMessage({...custServiceDeskDetailErrorMessage, [e.target.name]: regexErrorMessage[e.target.name] })
      }
      else if(e.target.name == "email_address" && OnlyEmail(e?.target?.value)){
        setCustServiceDeskDetailErrorMessage({...custServiceDeskDetailErrorMessage, [e.target.name]: regexErrorMessage[e.target.name] })
      }
      else if(e.target.name != "phone_number" && e.target.name != "email_address" && OnlyNumberSpecialChar(e.target.value)){
        setCustServiceDeskDetailErrorMessage({...custServiceDeskDetailErrorMessage, [e.target.name]: regexErrorMessage[e.target.name] })
      }

    }


  }

//** PC_NO_63, PC_NO_64, PC_NO_65, PC_NO_66, PC_NO_67, PC_NO_68
// to update the Contact details */
  async function UpdateContactDetails() {
    if (validate()) {      

      let sessionUserDetails: userSessionData | null = JSON.parse(
        encryptStorage.getItem(sessionKeys.userDetails) || "null"
      );
      setShowLoader(true);
      const payload = {
        //modifiedBy: sessio
        billing: custBillingDetails,
        service: custServiceDeskDetail,
        account: custAccManagerDetail,
        user_id: sessionUserDetails?.user_id
      }
      const response = await UpdateCustomerContactDetails(payload);
      if (response.statusCode === 200) {
        setShowLoader(false);
        getContactDetailsbyId();
        setDisableSave(true);
      }
    }

  }

//** PC_NO_82.41, PC_NO_82.42, PC_NO_82.43, PC_NO_82.44, PC_NO_82.45, PC_NO_82.46
// to validate the Contact details */
  const validate = () => {

    if (custBillingDetails?.first_name?.trim() == "" || custBillingDetails?.first_name?.trim() == null || custBillingDetails?.first_name?.trim() == undefined ) {

      custBillingDetailsErrorMessage.first_name = billingErrorMessage.first_name;
      setCustBillingDetailsErrorMessage({...custBillingDetailsErrorMessage});
      

    }
    if (custBillingDetails?.last_name?.trim() == "" || custBillingDetails?.last_name?.trim() == null || custBillingDetails?.last_name?.trim() == undefined) {
      custBillingDetailsErrorMessage.last_name = billingErrorMessage.last_name;
      setCustBillingDetailsErrorMessage({...custBillingDetailsErrorMessage});

    }
    if (custBillingDetails?.phone_number?.trim() == "" || custBillingDetails?.phone_number?.trim() == null || custBillingDetails?.phone_number?.trim() == undefined) {
      custBillingDetailsErrorMessage.phone_number = billingErrorMessage.phone_number;
      setCustBillingDetailsErrorMessage({...custBillingDetailsErrorMessage});

    }
    if (custBillingDetails?.role?.trim() == "" || custBillingDetails?.phone_number?.trim() == null || custBillingDetails?.phone_number?.trim() == undefined) {
      custBillingDetailsErrorMessage.role = billingErrorMessage.role;
      setCustBillingDetailsErrorMessage({...custBillingDetailsErrorMessage});

    }
    if (custBillingDetails?.email_address?.trim() == "" || custBillingDetails?.email_address?.trim() == null || custBillingDetails?.email_address?.trim() == undefined) {
      custBillingDetailsErrorMessage.email_address = billingErrorMessage.email_address;
      setCustBillingDetailsErrorMessage({...custBillingDetailsErrorMessage});

    }


    if ((Object.values(custBillingDetailsErrorMessage).every((eachValue: any) => eachValue?.trim() == "")) &&
        (Object.values(custAccManagerDetailErrorMessage).every((eachValue: any) => eachValue?.trim() == "")) &&
        (Object.values(custServiceDeskDetailErrorMessage).every((eachValue: any) => eachValue?.trim() == ""))

    ) {
      return true;
    } else {
      return false;
      
    }

  }

  
  return (
    <>
      <div
        className="tab-pane fade"
        id="contact-detail"
        role="tabpanel"
        aria-labelledby="contactdetail-tab"
      >
        {showLoader ? <Loader /> : null}

        {location.state.mode == "view" ?
      
        (<>
          <p className="font-18 font-semibold tertiary-color  py-lg-3 mb-0 px-3">
          Contact Details
        </p>
        <p className="font-16 font-semibold tertiary-color py-lg-3 mb-0 px-3">
          Billing Details
        </p>
        <div className="row">
          <div className="col-md-4">
            <div className="mb-5 px-3">
              <label
                htmlFor="first_name"
                className="font-14 font-semibold primary-text-color mb-2"
              >
                First Name <span className="color-red"></span>
              </label>

              <p className='font-13 font-medium color-black text-inputwrap'>{custBillingDetails.first_name ? custBillingDetails.first_name : "-"}</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-5 px-3">
              <div className="mb-5">
                <label
                  htmlFor="lastname"
                  className="font-14 font-semibold primary-text-color mb-2"
                >

                  Last Name<span className="color-red"></span>
                </label>
                <p className='font-13 font-medium color-black text-inputwrap'>{custBillingDetails.last_name ? custBillingDetails.last_name: "-"}</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-5 px-3">
              <div className="mb-5">
                <label
                  htmlFor="role"
                  className="font-14 font-semibold primary-text-color mb-2"
                >
                  Role <span className="color-red"></span>
                </label>
                <p className='font-13 font-medium color-black text-inputwrap' >{custBillingDetails.role ? custBillingDetails.role : "-"}</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-5 px-3">
              <div className="mb-5">
                <label
                  htmlFor="emailaddrs"
                  className="font-14 font-semibold primary-text-color mb-2"
                >
                  Email Address <span className="color-red"></span>
                </label>
               <p className='font-13 font-medium text-black mb-1'>{custBillingDetails.email_address ? custBillingDetails.email_address : "-"}</p>
                {/* <span className="color-red font-14">
                  Enter Email address
                </span> */}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-5 px-3">
              <label
                htmlFor="Phonenum"
                className="font-14 font-semibold primary-text-color mb-2"
              >
                Phone Number <span className="color-red"></span>
              </label>
               <p className='font-13 font-medium color-black '>{custBillingDetails.phone_number ? custBillingDetails.phone_number : "-"}</p>

            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-5">
             
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3 px-3">
              <label
                htmlFor="accmanage"
                className="form-label color-black-v2 font-14 font-bold"
              >
                Is the account manager details are same as billing details?
              </label>
              <div className="form-check form-switch theme-switch">
                
              <input
                      className="form-check-input"
                      disabled={true}
                      type="checkbox"
                      role="switch"
                      name='same_as_billing_details'
                      checked={custAccManagerDetail.same_as_billing_details == "1" ? true : false}
                      value={custAccManagerDetail.same_as_billing_details}
                      onChange={(e: any) => onAccManagerDetailChange(e)}
                    />
               <p className='font-13 font-medium color-black '>{custAccManagerDetail.same_as_billing_details == "1" ? "Yes" : "No"}</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3 px-3">
              <label
                htmlFor="servicedesk"
                className="form-label color-black-v2 font-14 font-bold"
              >
                Is the service desk details are same as billing details?
              </label>
              <div className="form-check form-switch theme-switch">
              <input
                      className="form-check-input"
                      type="checkbox"
                      disabled={true}
                      role="switch"
                      name='same_as_billing_details'
                      checked={custServiceDeskDetail.same_as_billing_details == "1" ? true : false}
                      value={custServiceDeskDetail.same_as_billing_details}
                      onChange={(e: any) => onServiceDeskDetailChange(e)}
                    /> 
                <p className='font-13 font-medium color-black '>{custServiceDeskDetail?.same_as_billing_details == "1" ? "Yes" : "No"}</p>


                
              </div>
            </div>
          </div>
          <p className="font-16 font-semibold tertiary-color py-lg-3 mb-0 px-4">
            Account Manager
          </p>
          <div className="col-md-4">
            <div className="mb-5 px-3">
              <label
                htmlFor="accfrstname"
                className="font-14 font-semibold primary-text-color mb-2"
              >
                First Name
              </label>

             <p className='font-13 font-medium color-black text-inputwrap '>{custAccManagerDetail.first_name ? custAccManagerDetail.first_name : "-"}</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-5 px-3">
              <label
                htmlFor="acclastname"
                className="font-14 font-semibold primary-text-color mb-2"
              >
                Last Name
              </label>
              <p className='font-13 font-medium color-black text-inputwrap'>{custAccManagerDetail.last_name ? custAccManagerDetail.last_name : "-"}</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3 px-3">
              <label
                htmlFor="accrole"
                className="font-14 font-semibold primary-text-color mb-2"
              >
                Role
              </label>
             <p className='font-13 font-medium color-black text-inputwrap '>{custAccManagerDetail.role ? custAccManagerDetail.role : "-"}</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-5 px-3">
              <label
                htmlFor="accemail"
                className="font-14 font-semibold primary-text-color mb-2"
              >
                Email Address
              </label>
             <p className='font-13 font-medium color-black  '>{custAccManagerDetail.email_address ? custAccManagerDetail.email_address : "-"}</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3 px-3">
              <label
                htmlFor="phone@account"
                className="font-14 font-semibold primary-text-color mb-2"

              >
                Phone Number
              </label>
              <p className='font-13 font-medium color-black '>{custAccManagerDetail.phone_number ? custAccManagerDetail.phone_number : "-"}</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3 px-3"></div>
          </div>
          <p className="font-16 font-semibold tertiary-color py-lg-3 mb-0 px-4">
            Service Desk
          </p>
          <div className="col-md-4">
            <div className="mb-5 px-3">
              <label
                htmlFor="sdfrstname"
                className="font-14 font-semibold primary-text-color mb-2"
              >
                First Name
              </label>
             <p className='font-13 font-medium color-black text-inputwrap'>{custServiceDeskDetail.first_name ? custServiceDeskDetail.first_name : "-"}</p>

            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-5 px-3">
              <label
                htmlFor="sdlastname"
                className="font-14 font-semibold primary-text-color mb-2"
              >
                Last Name
              </label>
              <p className='font-13 font-medium color-black text-inputwrap'>{custServiceDeskDetail.last_name ? custServiceDeskDetail.last_name : "-"}</p>

            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-5 px-3">
              <label
                htmlFor="sdrole"
                className="font-14 font-semibold primary-text-color mb-2"
              >
                Role
              </label>
             <p className='font-13 font-medium color-black text-inputwrap'>{custServiceDeskDetail.role ? custServiceDeskDetail.role : "-"}</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-5 px-3">
              <label
                htmlFor="sdemail"
                className="font-14 font-semibold primary-text-color mb-2"
              >
                Email Address
              </label>
             <p className='font-13 font-medium color-black '>{custServiceDeskDetail.email_address ? custServiceDeskDetail.email_address : "-"}</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-5 px-3">
              <label
                htmlFor="sdphnnum"
                className="font-14 font-semibold primary-text-color mb-2"
              >
                Phone Number
              </label>
              <p className='font-13 font-medium color-black '>{custServiceDeskDetail.phone_number ? custServiceDeskDetail.phone_number : "-"}</p>

            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-5 px-3">
              {/* <span class="upload-btn-wrapper me-2 d-block ">
                          <button type="button" class="font-14  font-medium primary-color upload-btn border-0 shadow-none cursor-pointer"><img src="images/uploads.svg" alt="browse" class="me-2">Upload</button><span class="link-blue">Tax certificate.pdf<img src="Images/red-cross.svg"></span>
                          <input type="file" name="myfile" class="cursor-pointer">
                      </span> */}
            </div>
          </div>
        </div>
        
        </>
        ):(<>

<p className="font-18 font-semibold tertiary-color  py-lg-3 mb-0 px-3">
          Contact Details
        </p>
        <p className="font-16 font-semibold tertiary-color py-lg-3 mb-0 px-3">
          Billing Details
        </p>
        <div className="row">
          <div className="col-md-4">
            <div className="mb-5 px-3">
              <label
                htmlFor="first_name"
                className="font-14 font-semibold primary-text-color mb-2"
              >
                First Name <span className="color-red">*</span>
              </label>

            
                  <input
                    type="text"
                    className="form-control custom-input"
                    placeholder="Enter First Name"
                    maxLength={255}
                    value={custBillingDetails.first_name}
                    name='first_name'
                    onChange={(e: any) => onBillingDetailsChange(e)}

                  />
                  {custBillingDetailsErrorMessage.first_name && <span className="color-red font-14">{custBillingDetailsErrorMessage.first_name}</span>}
                  {/* <span className="color-red font-14" defaultValue={contactDetailsError.billing.first_name_error}  >
                    {contactDetailsError.billing.first_name_error}
                  </span> */}
               
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-5 px-3">
              <div className="mb-5">
                <label
                  htmlFor="lastname"
                  className="font-14 font-semibold primary-text-color mb-2"
                >

                  Last Name<span className="color-red">*</span>
                </label>
                
                    <input
                      type="text"
                      className="form-control custom-input"
                      maxLength={255}
                      placeholder="Enter Last Name"
                      name='last_name'
                      value={custBillingDetails.last_name}
                      onChange={(e: any) => onBillingDetailsChange(e)}
                    />
                    {custBillingDetailsErrorMessage.last_name && <span className="color-red font-14">{custBillingDetailsErrorMessage.last_name}</span>}
                  
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-5 px-3">
              <div className="mb-5">
                <label
                  htmlFor="role"
                  
                  className="font-14 font-semibold primary-text-color mb-2"
                >
                  Role <span className="color-red">*</span>
                </label>
               
                    <input
                      type="text"
                      className="form-control custom-input"
                      placeholder="Enter Role"
                      maxLength={50}
                      name='role'
                      value={custBillingDetails.role}
                      onChange={(e: any) => onBillingDetailsChange(e)}
                    />
                    {custBillingDetailsErrorMessage.role && <span className="color-red font-14">{custBillingDetailsErrorMessage.role ? custBillingDetailsErrorMessage.role : "-"}</span>}
                  
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-5 px-3">
              <div className="mb-5">
                <label
                  htmlFor="emailaddrs"
                  className="font-14 font-semibold primary-text-color mb-2"
                >
                  Email Address <span className="color-red">*</span>
                </label>
                
                    <input
                      type="text"
                      className="form-control custom-input"
                      placeholder="Enter Email Address"
                      name='email_address'
                      value={custBillingDetails.email_address}
                      onChange={(e: any) => onBillingDetailsChange(e)}
                    />
                    {custBillingDetailsErrorMessage.email_address && <span className="color-red font-14">{custBillingDetailsErrorMessage.email_address}</span>}
                  
                
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-5 px-3">
              <label
                htmlFor="Phonenum"
                className="font-14 font-semibold primary-text-color mb-2"
              >
                Phone Number <span className="color-red">*</span>
              </label>
              
                  <input
                    type="text"
                    className="form-control custom-input"
                    placeholder="Enter Phone Number"
                    name='phone_number'
                    maxLength={10}
                    value={custBillingDetails.phone_number}
                    onChange={(e: any) => onBillingDetailsChange(e)}
                  />
                  {custBillingDetailsErrorMessage.phone_number && <span className="color-red font-14">{custBillingDetailsErrorMessage.phone_number}</span>}
                

              {/* <span className="color-red font-14">Enter Phone Number</span> */}
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-5">
              {/* <label for="exampleFormControlInput1" class="form-label color-black-v2 font-14 font-bold">Customer Name</label>
                      <p class="font-13 font-regular color-black-v3">Lenovo</p> */}
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3 px-3">
              <label
                htmlFor="accmanage"
                className="form-label color-black-v2 font-14 font-bold"
              >
                Is the account manager details are same as billing details?
              </label>
              <div className="form-check form-switch theme-switch">
                
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      name='same_as_billing_details'
                      checked={custAccManagerDetail.same_as_billing_details == "1" ? true : false}
                      value={custAccManagerDetail.same_as_billing_details}
                      onChange={(e: any) => onAccManagerDetailChange(e)}
                    />
                    
                    <label className="form-check-label" htmlFor="accmanage">
                  {custAccManagerDetail.same_as_billing_details == "1" ? "Yes" : "No"}
                </label>
                 
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3 px-3">
              <label
                htmlFor="servicedesk"
                className="form-label color-black-v2 font-14 font-bold"
              >
                Is the service desk details are same as billing details?
              </label>
              <div className="form-check form-switch theme-switch">
                
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      name='same_as_billing_details'
                      checked={custServiceDeskDetail.same_as_billing_details == "1" ? true : false}
                      value={custServiceDeskDetail.same_as_billing_details}
                      onChange={(e: any) => onServiceDeskDetailChange(e)}
                    /> 
                    <label className="form-check-label" htmlFor="servicedesk">
                    {custServiceDeskDetail?.same_as_billing_details == "1" ? "Yes" : "No"}
                  </label>
                


                
              </div>
            </div>
          </div>
          <p className="font-16 font-semibold tertiary-color py-lg-3 mb-0 px-4">
            Account Manager
          </p>
          <div className="col-md-4">
            <div className="mb-5 px-3">
              <label
                htmlFor="accfrstname"
                className="font-14 font-semibold primary-text-color mb-2"
              >
                First Name
              </label>

              
                  <input
                    type="text"
                    className="form-control custom-input"
                    placeholder="Enter First Name"
                    name='first_name'
                    value={custAccManagerDetail.first_name}
                    disabled={custAccManagerDetail.same_as_billing_details == "1" ? true : false}
                    onChange={(e: any) => onAccManagerDetailChange(e)}
                  />
                  {custAccManagerDetailErrorMessage.first_name && <span className="color-red font-14">{custAccManagerDetailErrorMessage.first_name}</span>}
                
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-5 px-3">
              <label
                htmlFor="acclastname"
                className="font-14 font-semibold primary-text-color mb-2"
              >
                Last Name
              </label>
             
                  <input
                    type="text"
                    className="form-control custom-input"
                    placeholder="Enter Last Name"
                    name='last_name'
                    value={custAccManagerDetail.last_name}
                    disabled={custAccManagerDetail.same_as_billing_details == "1" ? true : false}
                    onChange={(e: any) => onAccManagerDetailChange(e)}
                  />
                  {custAccManagerDetailErrorMessage.last_name && <span className="color-red font-14">{custAccManagerDetailErrorMessage.last_name}</span>}
                
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3 px-3">
              <label
                htmlFor="accrole"
                className="font-14 font-semibold primary-text-color mb-2"
              >
                Role
              </label>
             
                  <input
                    type="text"
                    className="form-control custom-input"
                    placeholder="Enter Role"
                    maxLength={255}
                    name='role'
                    value={custAccManagerDetail.role}
                    disabled={custAccManagerDetail.same_as_billing_details == "1" ? true : false}
                    onChange={(e: any) => onAccManagerDetailChange(e)}
                  />
                  {custAccManagerDetailErrorMessage.role && <span className="color-red font-14">{custAccManagerDetailErrorMessage.role}</span>}
                
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-5 px-3">
              <label
                htmlFor="accemail"
                className="font-14 font-semibold primary-text-color mb-2"
              >
                Email Address
              </label>
              
                  <input
                    type="text"
                    className="form-control custom-input"
                    placeholder="Enter Email Address"
                    name='email_address'
                    value={custAccManagerDetail.email_address}
                    disabled={custAccManagerDetail.same_as_billing_details == "1" ? true : false}
                    onChange={(e: any) => onAccManagerDetailChange(e)}
                  />
                  {custAccManagerDetailErrorMessage.email_address && <span className="color-red font-14">{custAccManagerDetailErrorMessage.email_address}</span>}
               
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3 px-3">
              <label
                htmlFor="phone@account"
                className="font-14 font-semibold primary-text-color mb-2"

              >
                Phone Number
              </label>
             
                  <input
                    type="text"
                    className="form-control custom-input"
                    placeholder="Enter Phone Number"
                    name='phone_number'
                    maxLength={10}
                    value={custAccManagerDetail.phone_number}
                    disabled={custAccManagerDetail.same_as_billing_details == "1" ? true : false}
                    onChange={(e: any) => onAccManagerDetailChange(e)}
                  />
                  {custAccManagerDetailErrorMessage.phone_number && <span className="color-red font-14">{custAccManagerDetailErrorMessage.phone_number}</span>}
                
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3 px-3"></div>
          </div>
          <p className="font-16 font-semibold tertiary-color py-lg-3 mb-0 px-4">
            Service Desk
          </p>
          <div className="col-md-4">
            <div className="mb-5 px-3">
              <label
                htmlFor="sdfrstname"
                className="font-14 font-semibold primary-text-color mb-2"
              >
                First Name
              </label>
                          <input
                  type="text"
                  className="form-control custom-input"
                  placeholder="Enter First Name"
                  name='first_name'
                  value={custServiceDeskDetail.first_name}
                  disabled={custServiceDeskDetail.same_as_billing_details == "1" ? true : false}
                  onChange={(e: any) => onServiceDeskDetailChange(e)}

                />
                  {custServiceDeskDetailErrorMessage.first_name && <span className="color-red font-14">{custServiceDeskDetailErrorMessage.first_name}</span>}
              

            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-5 px-3">
              <label
                htmlFor="sdlastname"
                className="font-14 font-semibold primary-text-color mb-2"
              >
                Last Name
              </label>
              
                  <input
                    type="text"
                    className="form-control custom-input"
                    placeholder="Enter Last Name"
                    name='last_name'
                    value={custServiceDeskDetail.last_name}
                    disabled={custServiceDeskDetail.same_as_billing_details == "1" ? true : false}
                    onChange={(e: any) => onServiceDeskDetailChange(e)}
                  />
                  {custServiceDeskDetailErrorMessage.last_name && <span className="color-red font-14">{custServiceDeskDetailErrorMessage.last_name}</span>}
                

            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-5 px-3">
              <label
                htmlFor="sdrole"
                className="font-14 font-semibold primary-text-color mb-2"
              >
                Role
              </label>
             
                  <input
                    type="text"
                    className="form-control custom-input"
                    placeholder="Enter Role"
                    name='role'
                    value={custServiceDeskDetail.role}
                    disabled={custServiceDeskDetail.same_as_billing_details == "1" ? true : false}
                    onChange={(e: any) => onServiceDeskDetailChange(e)}
                  />
                  {custServiceDeskDetailErrorMessage.role && <span className="color-red font-14">{custServiceDeskDetailErrorMessage.role}</span>}
                
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-5 px-3">
              <label
                htmlFor="sdemail"
                className="font-14 font-semibold primary-text-color mb-2"
              >
                Email Address
              </label>
              
                  <input
                    type="text"
                    className="form-control custom-input"
                    placeholder="Enter Email Address"
                    name='email_address'
                    value={custServiceDeskDetail.email_address}
                    disabled={custServiceDeskDetail.same_as_billing_details == "1" ? true : false}
                    onChange={(e: any) => onServiceDeskDetailChange(e)}
                  />
                  {custServiceDeskDetailErrorMessage.email_address && <span className="color-red font-14">{custServiceDeskDetailErrorMessage.email_address}</span>}
                
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-5 px-3">
              <label
                htmlFor="sdphnnum"
                className="font-14 font-semibold primary-text-color mb-2"
              >
                Phone Number
              </label>
              
                  <input
                    type="text"
                    className="form-control custom-input"
                    placeholder="Enter Phone Number"
                    name='phone_number'
                    maxLength={10}
                    value={custServiceDeskDetail.phone_number}
                    disabled={custServiceDeskDetail.same_as_billing_details == "1" ? true : false}
                    onChange={(e: any) => onServiceDeskDetailChange(e)}
                  />
                  {custServiceDeskDetailErrorMessage.phone_number && <span className="color-red font-14">{custServiceDeskDetailErrorMessage.phone_number}</span>}
                

            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-5 px-3">
              
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end mb-5">
          <button
            type="button"
            className="btn text border-0 font-14 font-semibold me-3 shadow-none text-btn"
            onClick={() => navigate('/customer')}

          >
            Cancel
          </button>
          <button
            type="button"
            className="btn primary-btn border-0 font-14 font-semibold "
            //onClick={() => { handleClick() }}
            onClick={() => UpdateContactDetails()}
            disabled={disableSave}
          >
            Save
          </button>
        </div>
        </>)
      }
        
      </div>
    </>
  )
}

export default CustomerContactDetails