import React, { useEffect, useState } from "react";
import {
  getInvoiceDetails,
  getInvoiceGridFilterDetails,
  getpreview,
  triggerInvoice,
  validateCustomerEmail,
  sendInvoiceApi,
  downloadInvoiceApi
} from "../../services/invoiceService";
import { getInvoiceGridData, customerSessionData } from "../../interface";
import { useLocation, useNavigate } from "react-router-dom";
import * as filesaver from "file-saver";
import moment from "moment";
import {
  defaultLimit,
  encryptStorage,
  sessionKeys,
  formatAmount,
} from "../../configs/constant";
//** PC_NO_IN_1 ,PC_NO_IN_2
//to render the invoice details into the Grid Component */
function InvoiceGrid() {
  
  //** PC_NO_IN_4 state and object variable */
  const navigate = useNavigate();

  let customerSessionDetails: customerSessionData | null = JSON.parse(
    encryptStorage.getItem(sessionKeys.customerDetails) || "null"
  );
  let filterData = {
    customer_id: customerSessionDetails?.customer_id,
    status_type: "",
    start_date: "",
    end_date: "",
  };

  const [invoiceId, setInvoiceId] = useState<string>("");
  const [sendMail, setSendMail] = useState<boolean>(false);
  const [isPreview, setIspreview] = useState<boolean>(false);
  const [invoiceDetails, setinvoiceDetails] = useState<any>();
  const [invoiceLicenseDetails, setinvoiceLicenseDetails] = useState<any[]>([]);
  const [invoiceTotalPrice, setinvoiceTotalPrice] = useState<any>();
  const [invoiceFromDetails, setinvoiceFromDetails] = useState<any>();
  const [invoiceBankDetail, setinvoiceBankDetail] = useState<any>();
  const [invoiceLicenseCount, setinvoiceLicenseCount] = useState<any>();
  const [invoiceCustomerTO, setinvoiceCustomerTO] = useState<any>();
  const [count, setcount] = useState<number>(defaultLimit);
  const [hidden, setHidden] = useState<boolean>(false);

  const [totalCount, setTotalCount] = useState<any>(0);
  const [recordCount, setRecordCount] = useState<any>(0);
  const [loader, setloader] = useState<boolean>(false);
  const [sortType, setSortType] = useState<string>("DESC");
  const [sortColumn, setSortColumn] = useState<string>("modified_date");
  const [sortHide, setSortHide] = useState<string>("");
  const [search_value, setSearch_value] = useState<string>("");
  const [filterDetails, setFilterDetails] = useState<any>(filterData);
  const [filterCustomerDropDown, setFilterCustomerDropDown] = useState<any>([]);
  const [filterStatusDropDown, setFilterStatusDropDown] = useState<any>([]);
  const [view, setView] = useState(
    customerSessionDetails?.customer_id ? "customerView" : "overallview"
  );
  const [issueDate, setissueDate] = useState<any>("");
  const [fromData, setfromData] = useState<any>();
  const [showEmailSuccess, setShowEmailSuccess] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState(
    "Email address not found in customer bill details"
  );
  const [showfilterpopup, setshowfilterpopup] = useState(false);
  const [GetInvoiceDetails, setgetInvoiceDetails] = useState<any[]>([
    {
      customer_id: "",
      invoice_id: "",
      created_date: "",
      company_name: "",
      status_type: "",
      reseller_price: "",
      count: "",
    },
  ]);
  //** PC_NO_IN_85 //PC_NO_IN_86 ,PC_NO_IN_87, PC_NO_IN_88
  // send the invoice to the customer */
  async function sendInvoice(
    invoice_id: any,
    customer_id: any,
    sendmail: boolean,
    ispreview: boolean
  ) {

    try{

    
      setloader(true);

      let payload = {
        invoice_id: invoice_id,
        customer_id: customer_id,
        sendMail: sendmail,
        ispreview: ispreview,
      };

      if (sendmail) {
        let validateEmail = await validateCustomerEmail(payload);

        if (
          validateEmail?.data?.statusCode === 200 &&
          validateEmail?.data?.responseData?.isCustomerHasEmail == false
        ) {
          setShowEmailError(true);
          setloader(false);
          return true;
        }
      }

      // let send = await getInvoiceLicenseDetailsById(payload);

      if (!sendmail) {
        let send = await downloadInvoiceApi(payload);
        const arrayBuffer = new Uint8Array(
          send?.data?.responseData?.buffer?.data
        );

        const blob = new Blob([arrayBuffer], {
          type: "application/octet-stream",
        });

        filesaver.saveAs(blob, `Invoice_${invoice_id}.pdf`);

        setloader(false);
      } 
      else if (sendmail) {
        let send = await sendInvoiceApi(payload);
        if (send.data.statusCode === 200) {
          setShowEmailSuccess(true);
        } else if (send.data.statusCode === 400) {
          setEmailErrorMessage(send?.data?.responseData);
          setShowEmailError(true);
        }

        setloader(false);
      }
      

    }catch(e:any){

      if(e?.response.data?.statusCode == 429){
        setloader(false);
        setEmailErrorMessage("You've reached the maximum limits.Try after a minute");
        setShowEmailError(true);
      }

    }
  }



  //** PC_NO_IN_3.2 UseEffect to call function during the rendering */
  useEffect(() => {
    getInvoiceGridDetails();
  }, [sortColumn, sortType, sortHide, count]);
  useEffect(() => {
    getFilterDetails();
  }, []);

  //** PC_NO_IN_74.8, PC_NO_IN_74.9, PC_NO_IN_74.10, PC_NO_IN_74.11, PC_NO_IN_74.12
  // to Fetch the filter option value of the filter dropdown */
  async function getFilterDetails() {
    const request = {};
    const response = await getInvoiceGridFilterDetails(request);
    const FilterCustomerDetails = response.data.responseData;

    setFilterCustomerDropDown(response.data.responseData[0]);
    setFilterStatusDropDown(response.data.responseData[1]);
  }

  // const inputDate: any = new Date(invoiceLicenseDetails[0].created_date);

  const optionss: any = { month: "numeric", day: "numeric", year: "numeric" };
  // const formattedDate = inputDate.toLocaleDateString('en-US', optionss);
  //** PC_NO_IN_5, PC_NO_IN_6, PC_NO_IN_7, PC_NO_IN_8, PC_NO_IN_9, PC_NO_IN_10, PC_NO_IN_11
  // to get the invoice details  */
  async function getInvoiceGridDetails(filter = filterDetails) {
    const StorePayLoad = {
      start_date: filter.start_date,
      end_date: filter.end_date,
      status_type: filter.status_type,
      customer_id: filter.customer_id,
      search_value: search_value,
      sortColumn: sortColumn,
      sortType: sortType,
      count: count,
      view: view,
    };
    try {
      setloader(true);

      const response = await getInvoiceDetails(StorePayLoad);

      if (response.data.statusCode === 200) {
        setgetInvoiceDetails(response?.data?.responseData?.invoiceData);
        setRecordCount(response?.data?.responseData?.recordCount);
        setTotalCount(response?.data?.responseData?.totalRecordCount);
      }
      setloader(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  //*** PS_AD_14 to perform the sort operation */
  const onSort = (e: any) => {
    try {
      let sortID = e.target.id;
      let sortval = sortID.split("@");
      setSortColumn(sortval[0]);
      setSortType(sortval[1]);
      setSortHide(sortID);
      setSearch_value("");
    } catch (e: any) {
      console.log(e.message);
    }
  };
  //PS_AD_13 perform the search operation
  const onSearch = (e: any) => {
    setSearch_value(e.target?.value);
    // handleClear();
    setloader(loader);
    if (e.key === "Enter") {
      getInvoiceGridDetails();
    }
  };
  //** PC_NO_IN_38.80, PC_NO_IN_38.81, PC_NO_IN_38.82, PC_NO_IN_38.83, PC_NO_IN_38.84, PC_NO_IN_38.85, PC_NO_IN_38.86, PC_NO_IN_38.87, PC_NO_IN_38.88, PC_NO_IN_38.89
  // to show the preview of the invoice pdf details */
  async function showPreview(
    invoice_id: any,
    customer_id: any,
    sendmail: any,
    ispreview: any
  ) {
    setloader(true);
    let payload = {
      invoice_id: invoice_id,
      customer_id: customer_id,
      sendMail: sendmail,
      ispreview: ispreview,
    };

    let send: any = await getpreview(payload);

    if (send.data.statusCode == 200) {
      setissueDate(send.data.responseData[6][0].created_date);
      setinvoiceLicenseDetails(send.data.responseData[3]);
      setinvoiceFromDetails(send.data.responseData[3][0]);
      setinvoiceBankDetail(send.data.responseData[2][0]);
      setinvoiceLicenseCount(send.data.responseData[5][0]);
      setinvoiceCustomerTO(send.data.responseData[1][0]);
      setIspreview(true);
      setIsPreviewOpen(true);
      setfromData(send.data.responseData[5][0]);
    }

    setloader(false);
  }
  //** PC_NO_IN_38.90,PC_NO_IN_38.91,PC_NO_IN_38.92 to render the invoice deatil into the html */
  function renderTableRows() {
    return GetInvoiceDetails.map((item, index) => (
      <tr className="border-bottom-0" key={index}>
        <td className="ps-5 text-start font-regular">{item?.company_name}</td>
        <td className="text-start">
          <a
            className="link-blue text-decoration-none cursor-pointer"
            onClick={() => {
              showPreview(item?.invoice_id, item?.customer_id, false, true);
            }}
          >
            {item?.invoice_id}
          </a>
        </td>
        <td className="text-start font-regular">{item?.status_type}</td>
        <td className="text-start font-regular">
          {item?.created_date
            ? moment(item?.created_date).format("MM/DD/YYYY")
            : ""}
        </td>
        <td className="text-end font-regular">
          $
          {formatAmount(
            Number(
              item?.reseller_price
                ? Number(item?.reseller_price).toFixed(2)
                : "0.00"
            )
          )}
        </td>
        <td className="text-center font-regular">
          <span className="cursor-pointer">
            <img
              src="Images/edit-icon.svg"
              alt="editIcon"
              title="Edit"
              onClick={() =>
                navigate("/invoiceform", {
                  state: {
                    mode: "edit",
                    invoice_id: item?.invoice_id,
                    company_name: item?.company_name,
                  },
                })
              }
            />
          </span>
          <span className="ms-3 cursor-pointer">
            <img
              src="Images/download-icons.svg"
              alt="downloadIcon"
              title="Download"
              onClick={() => {
                sendInvoice(item?.invoice_id, item?.customer_id, false, false);
              }}
            />
          </span>
          <span className="ms-3 cursor-pointer">
            <img
              src="Images/send-icon.svg"
              alt="sendIcon"
              title="Send"
              onClick={() => {
                sendInvoice(item?.invoice_id, item?.customer_id, true, false);
              }}
            />
          </span>
        </td>
      </tr>
    ));
  }

  const [isPreviewOpen, setIsPreviewOpen] = useState(false); // State for modal visibility

  const togglePreview = () => {
    setIsPreviewOpen(!isPreviewOpen);
  };

  const clearFilter = () => {
    let filterClearData = {
      customer_id: "",
      status_type: "",
      start_date: "",
      end_date: "",
    };

    setFilterDetails(filterClearData);
    getInvoiceGridDetails(filterData);
  };

  //**  PC_NO_IN_74.13,PC_NO_IN_74.14,PC_NO_IN_74.15,PC_NO_IN_74.16


  function calculateDateDifference(date1: any, date2: any) {
    // Remove the milliseconds part
    const removeMilliseconds = (dateString: any) => dateString.split(".")[0];

    const startDate = new Date(removeMilliseconds(date1));
    const endDate = new Date(removeMilliseconds(date2));

    // Check if the dates are exactly the same
    if (startDate.getTime() === endDate.getTime()) {
      return 0;
    }

    const differenceInMilliseconds = endDate.getTime() - startDate.getTime();
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

    return Math.round(differenceInDays);
  }
  let microsoftPriceSum = 0;
  let resel_price = 0;
  return (
    <>
      {/* header starts */}
      {/* header ends */}
      {/* Admin starts */}

      {!hidden ? (
        <div className="container-fluid px-4 pt-3">
          <div className="row justify-content-center">
            <div className="px-4">
              <div className="d-block d-md-flex flex-wrap justify-content-between align-items-center mb-4">
                {/* <div class> */}
                <h2 className="font-20 font-bold dark-blue-color d-flex mb-0">
                  Invoice
                  <span className="custom-admin-batch font-12 d-flex align-items-center justify-content-center ms-2 px-2">
                    {/* {totalCount} */}
                    {recordCount}
                  </span>
                  {/* </div> */}
                </h2>
                <div className="d-block d-md-flex">
                  <div className="w-md-100 d-flex mb-3 mb-md-0">
                    <span className="position-relative d-flex align-items-center me-3 w-100">
                      <input
                        type="search"
                        className="border-0 font-regular w-230 cus-z-ind  rounded-2 font-14 top-0 bottom-0 py-2 px-3 custom-input"
                        id="search"
                        placeholder="Search"
                        value={search_value}
                        onKeyPress={(e) => {
                          onSearch(e);
                        }}
                        onChange={(e) => {
                          onSearch(e);
                        }}
                      />
                      <span className="position-absolute search-icon">
                        <img
                          src="Images/search.svg"
                          alt="search-icon"
                          onClick={() => {
                            getInvoiceGridDetails();
                          }}
                        />
                      </span>
                    </span>
                    <div className="btn-group">
                      <button
                        type="button"
                        className="btn secondary-btn custom-filter rounded-3 height-37"
                        onClick={() => {
                          setshowfilterpopup(true);
                        }}
                      >
                        <img
                          src="Images/filter-active-icon.svg"
                          alt="Filter"
                          className="custom-filter-active-img"
                        />
                        <img
                          src="Images/Filter.svg"
                          alt="Filter"
                          className="custom-filter-img"
                        />
                      </button>
                      {showfilterpopup ? (
                        <div
                          className="dropdown-menu dropdown-menu-end adv-dropdown p-3 mt-1 border-0 end-0 position-absolute"
                          style={{ display: "block" }}
                        >
                          <div className="row">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                              <h6 className="font-16 font-semibold tertiary-color mb-0 ">
                                Filter
                              </h6>
                              <button
                                type="button"
                                className="btn-close"
                                onClick={() => {
                                  setshowfilterpopup(false);
                                }}
                              />
                            </div>
                            {customerSessionDetails?.customer_id ? (
                              <></>
                            ) : (
                              <div className="col-sm-12 col-md-6 mb-4">
                                <label className="form-label font-14 font-semibold color-black">
                                  Customer Name
                                </label>

                                <select
                                  className=" form-select custom-select custom-input font-regular font-14"
                                  value={filterDetails.customer_id}
                                  onChange={(e) => {
                                    setFilterDetails({
                                      ...filterDetails,
                                      customer_id: e.target.value,
                                    });
                                  }}
                                >
                                  <option value="">Select</option>
                                  {filterCustomerDropDown.map((val: any) => {
                                    return (
                                      <option value={val.customer_id}>
                                        {val.company_name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            )}

                            <div className="col-sm-12 col-md-6 mb-4">
                              <label className="form-label font-14 font-semibold color-black">
                                Invoice Type
                              </label>
                              <select
                                className=" form-select custom-select custom-input font-regular font-14"
                                value={filterDetails.status_type}
                                onChange={(e) => {
                                  setFilterDetails({
                                    ...filterDetails,
                                    status_type: e.target.value,
                                  });
                                }}
                              >
                                <option selected value="">
                                  Select
                                </option>
                                {filterStatusDropDown.map((val: any) => (
                                  <option value={val.status_type}>
                                    {val.status_type}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <h6 className="font-16 font-semibold tertiary-color mb-3 ">
                              Invoice Date
                            </h6>
                            <div className="col-sm-12 col-md-6 mb-5">
                              <label
                                htmlFor="from1"
                                className="form-label font-14 font-semibold color-black"
                              >
                                From
                              </label>
                              <input
                                type="date"
                                className="form-control custom-input"
                                id="from1"
                                value={filterDetails.start_date}
                                max={filterDetails.end_date}
                                onChange={(e) => {
                                  setFilterDetails({
                                    ...filterDetails,
                                    start_date: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-sm-12 col-md-6 mb-5">
                              <label
                                htmlFor="to1"
                                className="form-label font-14 font-semibold color-black"
                              >
                                To
                              </label>
                              <input
                                type="date"
                                className="form-control custom-input"
                                id="to1"
                                value={filterDetails.end_date}
                                min={filterDetails.start_date}
                                onChange={(e) => {
                                  setFilterDetails({
                                    ...filterDetails,
                                    end_date: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="d-flex justify-content-end">
                              <button
                                type="button"
                                className="btn text border-0 font-14 font-semibold me-3 shadow-none text-btn"
                                onClick={() => {
                                  clearFilter();
                                }}
                              >
                                Clear
                              </button>
                              <button
                                type="button"
                                className="btn primary-btn border-0 font-14 font-semibold "
                                onClick={() => {
                                  setshowfilterpopup(false);
                                  getInvoiceGridDetails();
                                }}
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn outline-btn border-0 font-14 font-semibold ms-md-3 ms-0 table-btn w-auto text-nowrap w-md-100"
                    onClick={() => {
                      triggerInvoice();
                    }}
                  >
                    Trigger Invoice
                  </button>
                  <button
                    type="button"
                    className="btn primary-btn border-0 font-14 font-semibold ms-md-3 ms-0 table-btn w-auto text-nowrap w-md-100"
                    onClick={() =>
                      navigate("/invoiceform", {
                        state: {
                          mode: "create",
                          customer_id: customerSessionDetails?.customer_id
                            ? customerSessionDetails?.customer_id
                            : "",
                          customer_name: customerSessionDetails?.customer_Name
                            ? customerSessionDetails?.customer_Name
                            : "",
                        },
                      })
                    }
                  >
                    Create Invoice
                  </button>
                </div>
              </div>

              {/* first tab starts here */}
             
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="table-responsive rounded-2">
                  <table className="table  themeTable mb-0 ">
                    <thead className="font-bold">
                      <tr>
                        <th className="ps-5 text-start">
                          Customer Name
                          <span className="ms-2 cursor-pointer">
                            <img
                              src="Images/sort-up-arrow.svg"
                              alt="sortarrow"
                              title="Sort"
                              id="company_name@DESC"
                              onClick={(e) => onSort(e)}
                              hidden={
                                sortHide === "company_name@ASC" ? false : true
                              }
                            />
                            <img
                              src="Images/sort-arrow.svg"
                              alt="sortarrow"
                              title="Sort"
                              id="company_name@ASC"
                              onClick={(e) => onSort(e)}
                              hidden={
                                sortHide === "company_name@ASC" ? true : false
                              }
                            />
                          </span>
                        </th>
                        <th className="text-start">
                          Invoice #
                          <span className="ms-2 cursor-pointer">
                            <img
                              src="Images/sort-up-arrow.svg"
                              alt="sortarrow"
                              title="Sort"
                              id="invoice_id@DESC"
                              onClick={(e) => {
                                onSort(e);
                              }}
                              hidden={
                                sortHide === "invoice_id@ASC" ? false : true
                              }
                            />
                            <img
                              src="Images/sort-arrow.svg"
                              alt="sortarrow"
                              title="Sort"
                              id="invoice_id@ASC"
                              onClick={(e) => onSort(e)}
                              hidden={
                                sortHide === "invoice_id@ASC" ? true : false
                              }
                            />
                          </span>
                        </th>
                        <th className="text-start">
                          Status
                          <span className="ms-2 cursor-pointer">
                            <img
                              src="Images/sort-up-arrow.svg"
                              alt="sortarrow"
                              title="Sort"
                              id="status_type@DESC"
                              onClick={(e) => onSort(e)}
                              hidden={
                                sortHide === "status_type@ASC" ? false : true
                              }
                            />
                            <img
                              src="Images/sort-arrow.svg"
                              alt="sortarrow"
                              title="Sort"
                              id="status_type@ASC"
                              onClick={(e) => onSort(e)}
                              hidden={
                                sortHide === "status_type@ASC" ? true : false
                              }
                            />
                          </span>
                        </th>
                        <th className="text-start">
                          Invoice Date
                          <span className="ms-2 cursor-pointer">
                            <img
                              src="Images/sort-up-arrow.svg"
                              alt="sortarrow"
                              title="Sort"
                              id="created_date@DESC"
                              onClick={(e) => onSort(e)}
                              hidden={
                                sortHide === "created_date@ASC" ? false : true
                              }
                            />
                            <img
                              src="Images/sort-arrow.svg"
                              alt="sortarrow"
                              title="Sort"
                              id="created_date@ASC"
                              onClick={(e) => onSort(e)}
                              hidden={
                                sortHide === "created_date@ASC" ? true : false
                              }
                            />
                          </span>
                        </th>
                        <th className="text-end">
                          Invoice Amount
                          <span className="ms-2 cursor-pointer">
                            <img
                              src="Images/sort-up-arrow.svg"
                              alt="sortarrow"
                              title="Sort"
                              id="reseller_price@DESC"
                              onClick={(e) => onSort(e)}
                              hidden={
                                sortHide === "reseller_price@ASC" ? false : true
                              }
                            />
                            <img
                              src="Images/sort-arrow.svg"
                              alt="sortarrow"
                              title="Sort"
                              id="reseller_price@ASC"
                              onClick={(e) => onSort(e)}
                              hidden={
                                sortHide === "reseller_price@ASC" ? true : false
                              }
                            />
                          </span>
                        </th>
                        <th className="text-center">Actions</th>
                      </tr>
                    </thead>

                    <tbody className="font-medium">{renderTableRows()}</tbody>
                  </table>
                </div>
                {recordCount > count ? (
                  <div className="d-flex justify-content-center mb-5 mt-3">
                    <button
                      type="button"
                      className="btn tertiary-btn border-0 font-14 font-semibold"
                      name="count"
                      value={count}
                      onClick={() => {
                        setcount(count + defaultLimit);
                      }}
                    >
                      Load More
                    </button>
                  </div>
                ) : null}
                {GetInvoiceDetails?.length === 0 ? (
                  <>
                    <div className="d-flex justify-content-center color-white-bg rounded-bottom pt-100 pb-400 mb-5">
                      <div>
                        <img
                          src="Images/no-records-found.svg"
                          alt="No-Records-Found"
                          className="d-block"
                        />
                        <div>
                          <p className="d-flex font-20 font-regular tertiary-color mt-3 justify-content-center">
                            No records found
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
          
          {/* tabs ends here */}
        </div>
      ) : null}
      {/* Admin Ends */}

      {isPreview ? (
        <>
          <>
            <>
              {/* success popup starts here */}
              <div>
                {/* Button trigger modal */}

                {/* Modal */}
                <div className="container-fluid">
                  <div
                    className="modal fade show"
                    id="invoicepreview"
                    style={{
                      display: "block",
                      backgroundColor: "rgba(0,0,0,0.6)",
                    }}
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex={-1}
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog modal-xl modal-dialog-scrollable ">
                      <div className="modal-content px-3">
                        <div className="modal-header border-0 justify-content-center">
                          <h2
                            className="modal-title tertiary-color font-40 font-bold"
                            id="exampleModalToggleLabel"
                          >
                            Invoice
                          </h2>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                              setIspreview(false);
                            }}
                          />
                        </div>
                        <div className="modal-body pt-2 px-3">
                         
                          <div className="row g-3 align-items-center ">
                            <div className="col-auto">
                              <label
                                htmlFor="inputPassword6"
                                className="col-form-label color-black-v2 font-16 font-semibold"
                              >
                                Invoice number :
                              </label>
                            </div>
                            <div className="col-auto invoice-preno">
                              <span className="color-black-v3 font-medium">
                                {invoiceLicenseDetails[0]?.invoice_id}
                              </span>
                            </div>
                          </div>
                          <div className="row g-3 align-items-center mb-5">
                            <div className="col-auto">
                              <label
                                htmlFor="inputPassword6"
                                className="col-form-label color-black-v2 font-16 font-semibold"
                              >
                                Issue Date :
                              </label>
                            </div>
                            <div className="col-auto invoice-predate">
                              <span className="color-black-v3 font-medium">
                                {moment.utc(issueDate).format("MM/DD/YYYY")}
                              </span>
                            </div>
                          </div>
                          <div className="row mb-5">
                            <div className="col-md-4">
                              <ul className="custom-list-style p-0">
                                <li className="color-black-v2 font-20 font-bold mb-3 ">
                                  From :
                                </li>
                                <li className="color-black-v2 font-16 font-semibold">
                                  {fromData.name}
                                </li>
                                <li className="color-black-v3 font-16 font-medium">
                                  {fromData?.address},{fromData?.state},
                                  {fromData?.city},
                                  <li className="color-black-v3 font-16 font-medium">
                                    {" "}
                                    {fromData?.country} {fromData?.zip_code}
                                  </li>
                                </li>
                                <li className="color-black-v3 font-16 font-medium">
                                  {fromData?.phone_number}
                                </li>
                                <li className="color-black-v3 font-16 font-medium">
                                  {fromData.email_address}
                                </li>
                              </ul>
                            </div>
                            <div className="col-md-4">
                              <ul className="custom-list-style p-0">
                                <li className="color-black-v2 font-20 font-bold mb-3 ">
                                  To :
                                </li>
                                <li className="color-black-v2 font-16 font-semibold">
                                  {invoiceCustomerTO?.company_name}
                                </li>
                                <li className="color-black-v2 font-16 font-regular">
                                  {invoiceCustomerTO?.address}
                                  {invoiceCustomerTO?.state}
                                  {invoiceCustomerTO?.city}
                                </li>
                                <li className="color-black-v2 font-16 font-regular">
                                  {" "}
                                  {invoiceCustomerTO?.country}
                                  {invoiceCustomerTO?.zip_code}
                                </li>
                                <li className="color-black-v2 font-16 font-regular">
                                  {invoiceCustomerTO?.phone_number}
                                </li>
                                <li className="color-black-v2 font-16 font-regular">
                                  {invoiceCustomerTO?.email}
                                </li>
                              </ul>
                            </div>
                            <div className="col-md-4">
                              <p className="color-black-v2 font-20 font-bold mb-0">
                                To remit by Wire/ACH
                              </p>
                              <p className="color-black-v2 font-16 font-semibold mb-2">
                                {invoiceBankDetail.bank_name}
                              </p>
                              <div className="row g-3 align-items-center">
                                <div className="col-auto mt-2">
                                  <label
                                    htmlFor="inputPassword6"
                                    className="col-form-label color-black-v2 font-16 font-semibold"
                                  >
                                    Invoice Number
                                  </label>
                                </div>
                                <div className="col-auto mt-2">
                                  <span className="color-black-v3 font-16 font-medium">
                                    {invoiceLicenseDetails[0]?.invoice_id}
                                  </span>
                                </div>
                              </div>
                              <div className="row g-3 align-items-center">
                                <div className="col-auto mt-2">
                                  <label
                                    htmlFor="inputPassword6"
                                    className="col-form-label color-black-v2 font-16 font-semibold"
                                  >
                                    Name of Account
                                  </label>
                                </div>
                                <div className="col-auto mt-2">
                                  <span className="color-black-v3 font-16 font-medium">
                                    {invoiceBankDetail.company_name}
                                  </span>
                                </div>
                              </div>

                              <div className="row g-3 align-items-center">
                                <div className="col-auto mt-2">
                                  <label
                                    htmlFor="inputPassword6"
                                    className="col-form-label color-black-v2 font-16 font-semibold"
                                  >
                                    Account #
                                  </label>
                                </div>
                                <div className="col-auto mt-2">
                                  <span className="color-black-v3 font-16 font-medium">
                                    {invoiceBankDetail.account_number}
                                  </span>
                                </div>
                              </div>

                              <div className="row g-3 align-items-center">
                                <div className="col-auto mt-2">
                                  <label
                                    htmlFor="inputPassword6"
                                    className="col-form-label color-black-v2 font-16 font-semibold"
                                  >
                                    ACH ABA #
                                  </label>
                                </div>
                                <div className="col-auto mt-2">
                                  <span className="color-black-v3 font-16 font-medium">
                                    {invoiceBankDetail.ach_aba_number}
                                  </span>
                                </div>
                              </div>
                              <div className="row g-3 align-items-center">
                                <div className="col-auto mt-2">
                                  <label
                                    htmlFor="inputPassword6"
                                    className="col-form-label color-black-v2 font-16 font-semibold"
                                  >
                                    Wire ABA #
                                  </label>
                                </div>
                                <div className="col-auto mt-2">
                                  <span className="color-black-v3 font-16 font-medium">
                                    {invoiceBankDetail.wire_aba_number}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <h2 className="font-18 font-semibold tertiary-color d-flex mb-3">
                            License List
                            <span className="custom-admin-batch font-12 d-flex align-items-center justify-content-center ms-2 px-2">
                              {invoiceLicenseDetails?.length}
                            </span>
                          </h2>
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="table-responsive themetable-border rounded-2">
                              <table className="table  themeTable mb-0 ">
                                <thead className="font-semibold">
                                  <tr>
                                    <th className="ps-4 text-start">License</th>
                                    <th className="text-end pe-5">Quantity</th>
                                    <th className="text-start">
                                      Term Duration
                                    </th>
                                    <th className="text-start">Start Date </th>
                                    <th className="text-start">End Date </th>
                                    <th className="text-start">Days </th>
                                    <th className="text-end">
                                      Microsoft Price
                                    </th>
                                    <th className="text-end">Avasoft Price</th>
                                  </tr>
                                </thead>
                                <tbody className="font-medium">
                                  {invoiceLicenseDetails.map((preview: any) => {
                                    const microsoftPrice =
                                      preview?.microsoft_price
                                        ? parseFloat(preview.microsoft_price)
                                        : 0;
                                    const reseller_price =
                                      preview?.microsoft_price
                                        ? parseFloat(preview.reseller_price)
                                        : 0;
                                    microsoftPriceSum += microsoftPrice;
                                    resel_price += reseller_price;

                                    return (
                                      <>
                                        <tr className="border-bottom-0 ">
                                          <td className="ps-4 text-start font-regular text-wrap">
                                            {preview.license_name
                                              ? preview.license_name
                                              : "-"}
                                          </td>
                                          <td className="text-end font-regular pe-5">
                                            {preview.quantity
                                              ? preview.quantity
                                              : "-"}
                                          </td>
                                          <td className="text-start font-regular">
                                            {preview.term_duration
                                              ? preview.term_duration
                                              : "-"}
                                          </td>
                                          <td className="text-start font-regular">
                                            {preview.start_date
                                              ? moment
                                                  .utc(preview.start_date)
                                                  .format("DD/MM/YYYY")
                                              : "-"}
                                          </td>
                                          <td className="text-start font-regular">
                                            {preview.end_date
                                              ? moment
                                                  .utc(preview.end_date)
                                                  .format("DD/MM/YYYY")
                                              : "-"}
                                          </td>

                                          <td className="text-start font-regular">
                                            {preview.end_date
                                              ? calculateDateDifference(
                                                  preview.start_date,
                                                  preview.end_date
                                                )
                                              : "-"}
                                          </td>
                                          <td className="text-end font-regular">
                                            $
                                            {preview?.microsoft_price
                                              ? Number(
                                                  preview.microsoft_price
                                                ).toFixed(2)
                                              : "0.00"}
                                          </td>
                                          <td className="text-end font-regular">
                                            $
                                            {preview?.reseller_price
                                              ? Number(
                                                  preview.reseller_price
                                                ).toFixed(2)
                                              : "0.00"}
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                            <div className="row">
                              <div className="d-flex  justify-content-end">
                                <ul>
                                  <li className="d-flex custom-invoice-popup-width justify-content-between p-3 border-bottom">
                                    <label className="font-medium tertiary-color font-14">
                                      Microsoft Price{" "}
                                    </label>
                                    <label className="font-semibold font-14 color-black">
                                      ${formatAmount(microsoftPriceSum)}
                                    </label>
                                  </li>
                                  <li className="d-flex custom-invoice-popup-width justify-content-between p-3 border-bottom">
                                    <label className="font-medium tertiary-color font-14">
                                      Discount
                                    </label>
                                    <label className="font-semibold font-14 color-black">
                                      $
                                      {formatAmount(
                                        microsoftPriceSum - resel_price
                                      )}
                                    </label>
                                  </li>
                                  <li className="d-flex custom-invoice-popup-width justify-content-between p-3 ">
                                    <label className="font-medium tertiary-color font-16">
                                      Order Total
                                    </label>
                                    <label className="font-semibold font-16 color-black">
                                      ${formatAmount(resel_price)}
                                    </label>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer border-0 justify-content-center px-3 mb-3"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* success popup Ends here */}
            </>
          </>
        </>
      ) : null}

      {loader ? (
        <div className="overlay">
          <div className="position-absolute top-50 start-50 translate-middle">
            <div
              className="spinner-border Loader text-light align-center "
              role="status"
            >
              <span className="visually-hidden"></span>
            </div>
            <p className="loading-text font-14 color-white mt-2 font-semibold">
              Loading..
            </p>
          </div>
        </div>
      ) : null}

      {showEmailSuccess ? (
        <div
          className="modal fade show"
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
          id="successpopup"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-hidden="true"
        >
          <div className="modal-dialog popup-w-400 modal-dialog-centered">
            <div className="modal-content px-3">
              <div className="modal-header border-0 justify-content-center">
                <span className="popup-icon-pos">
                  <img
                    src="Images/success-icon.svg"
                    alt="success-icon"
                    className="popup-icon"
                  />
                </span>
              </div>
              <div className="modal-body pt-5 px-3">
                <p className="text-center font-16 font-medium secondary-text-color">
                  Mail sent successfully
                </p>
              </div>
              <div className="modal-footer border-0 justify-content-center px-3">
                <button
                  type="button"
                  className="btn text-btn green-btn shadow-none px-3 font-14 font-medium"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    setShowEmailSuccess(false);
                  }}
                >
                  Ok
                </button>
                {/* <button
                  type="button"
                  className="btn primary-btn font-14 font-medium"
                >
                  Move to Details
                </button> */}
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {showEmailError ? (
        <div
          className="modal fade show"
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
          id="successpopup"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-hidden="true"
        >
          <div className="modal-dialog popup-w-400 modal-dialog-centered">
            <div className="modal-content px-3">
              <div className="modal-header border-0 justify-content-center">
                <span className="popup-icon-pos">
                  <img
                    src="Images/exclamatory-icon.svg"
                    alt="success-icon"
                    className="popup-icon"
                  />
                </span>
              </div>
              <div className="modal-body pt-5 px-3">
                <p className="text-center font-16 font-medium secondary-text-color">
                  {emailErrorMessage}
                </p>
              </div>
              <div className="modal-footer border-0 justify-content-center px-3">
                <button
                  type="button"
                  className="btn text-btn danger-btn shadow-none px-3 font-14 font-medium"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    setShowEmailError(false);
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default InvoiceGrid;
