import React, { useEffect, useState } from "react";
// import { useData } from './DataContext';
import { GetCustomerBrandDetails } from "../../services/customerService";
import { useLocation, useNavigate } from "react-router-dom";
import { ErrorLog } from "../../services/errorService";
import uploadFileToBlob from "../../configs/blobContainer";
import { sessionKeys, encryptStorage } from "../../configs/constant";
import { userSessionData } from "../../interface";
import { UpdateCustomerBrandDetails } from "../../services/customerService";
import { Loader } from "../loader";
//** PC_NO_1, PC_NO_2, PC_NO_3, PC_NO_4, PC_NO_5, Fetch the Customer Brand Elements Detils */
function CustomerBrandElementDetails() {
  let brandDetails = {
    customer_id: "",
    logo_url: "",
    theme_color: "",
    file_name: "",
  };
  const location = useLocation();
  const [disableSave, setDisableSave] = useState(true);
  const [CustomerBrandDetails, setCustomerBrandDetails] =
    useState<any>(brandDetails);
  const [fileErrorMessage, setFileErrorMessage] = useState("");
  const [attachmentState, setattachmentState] = useState<any>(null);
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    GetCustomerBrandDetailById();
  }, []);
//** PC_NO_13, PC_NO_14, PC_NO_15, PC_NO_16, PC_NO_17, PC_NO_18, PC_NO_19, 
// TO Fetch the Customer Brand Details*/

  async function GetCustomerBrandDetailById() {
    try {
      const customerRequest = {
        customer_id: location.state.customer_id,
      };
      const response = await GetCustomerBrandDetails(customerRequest);

      if (response.statusCode === 200) {
        setCustomerBrandDetails(response.responseData);
      }
    } catch (Error: any) {
      const errorObject = {
        stack_name: "frontend_react",
        module_name: "customer_module",
        function_name: "GetCustomerBrandDetailById",
        error_message: `${Error?.message}`,
      };

      const request: any = await ErrorLog(errorObject);
    }
  }
//** PC_NO_61.11, PC_NO_61.12, PC_NO_61.13, PC_NO_61.14, PC_NO_61.15, PC_NO_61.16, PC_NO_61.17
// To perform the Upload operation into the blob conatainer */
  const uploadImage = async (e: any) => {
    try {
      setFileErrorMessage("");
      setDisableSave(false);
      setattachmentState(e.target.files[0]);
      let format = e.target.files[0].name.split(".").pop().toLowerCase();

      if (e.target.files[0].size >= 10000000) {
        setFileErrorMessage("File size should be less than 10MB");
        return false;
      }

      const typeArr = ["jpg", "png", "jpeg", "svg"];
      let type = typeArr.includes(format);

      if (type) {
        CustomerBrandDetails.logo_url = "";
        CustomerBrandDetails.file_name = e.target.files[0].name;
        setCustomerBrandDetails({ ...CustomerBrandDetails });
      } else {
        setFileErrorMessage("Upload the certificate in jpg/ png/ format");
      }
    } catch (error) {}
  };
//** PC_NO_61.18 ,PC_NO_61.19 to perform the file validatationand upload that file into the blob container */
  const fileUpload = async () => {
    let file = attachmentState;
    if (file !== undefined && file != null) {
      let newFileName = attachmentState?.name;
      if (newFileName !== undefined && newFileName != null) {
        let fileData = await uploadFileToBlob(
          file,
          `AVAHUB/${CustomerBrandDetails.customer_id}/logo`
        );
        return fileData;
      }
    }
  };

  const clear = () => {
    CustomerBrandDetails.logo_url = "";
    CustomerBrandDetails.file_name = "";
    setCustomerBrandDetails({ ...CustomerBrandDetails });
    setattachmentState(null);
  };
//** PC_NO_55, PC_NO_56, PC_NO_57, PC_NO_58, PC_NO_59, PC_NO_60, PC_NO_61.1, PC_NO_61.2, PC_NO_61.3, PC_NO_61.4, PC_NO_61.5, PC_NO_61.6, PC_NO_61.7, PC_NO_61.8, PC_NO_61.9, PC_NO_61.10
// to upload the file into the Db */
  const submit = async () => {
    if (fileErrorMessage == "") {
      setShowLoader(true);
      let sessionUserDetails: userSessionData | null = JSON.parse(
        encryptStorage.getItem(sessionKeys.userDetails) || "null"
      );

      let Url: any;
      if (attachmentState) {
        Url = await fileUpload();
      }

      let paylod = {
        customer_id: CustomerBrandDetails.customer_id,
        logo_url: Url ? Url : CustomerBrandDetails.logo_url,
        theme_color: CustomerBrandDetails.theme_color,
        file_name: CustomerBrandDetails.file_name,
        user_id: sessionUserDetails?.user_id,
      };

      const response: any = await UpdateCustomerBrandDetails(paylod);
      if (response.status) {
        setShowLoader(false);
        GetCustomerBrandDetailById();
        setDisableSave(true);
      }
    }
  };

  return (
    <>
      <div
        className="tab-pane fade"
        id="branding-elements"
        role="tabpanel"
        aria-labelledby="brandingdetails-tab"
      >
        {showLoader ? <Loader /> : null}

        {location.state.mode == "view" && CustomerBrandDetails?.theme_color ? (
          <>
            <p className="font-18 font-semibold tertiary-color mb-lg-4 py-lg-3 mb-2 px-3">
              Branding Elements
            </p>
            <div className="row custom-row-height1">
              <div className="col-md-5">
                <div className="mb-5 px-3">
                  <label className="font-14 font-semibold primary-text-color mb-2">
                    Upload Logo
                  </label>
                  <span className="upload-btn-wrapper me-2 d-block cursor-pointer ">
                    {CustomerBrandDetails.file_name ? (
                      <span className="link-blue px-2">
                        <a
                          href={CustomerBrandDetails.logo_url}
                          target="_blank"
                          title=""
                          style={{ textDecoration: "none" }}
                        >
                          {CustomerBrandDetails.file_name}{" "}
                        </a>
                      </span>
                    ) : (
                      <span className="px-2">NA</span>
                    )}
                  </span>
                </div>
              </div>

              <div className="col-md-4">
                <label
                  htmlFor="exampleColorInput"
                  className="form-label font-14 font-semibold primary-text-color mb-3"
                >
                  Brand Color
                </label>
                <div className="mb-5 px-0 border rounded-2 w-50 position-relative d-flex">

                    <>
                      <input
                        type="color"
                        className="form-control custom-color-picker-input form-control-color border-0 position-relative"
                        id="exampleColorInput"
                        disabled={true}
                        value={CustomerBrandDetails?.theme_color}
                        name="theme_color"
                        title="Choose your color"
                      />
                      <label
                        htmlFor="exampleColorInput"
                        className="custom-color-picker"
                      >
                        <img
                          src="Images/color-picker.svg"
                          alt="color-picker"
                          className="clr-pic-img"
                        />
                      </label>
                      <span className="font-13 font-regular tertiary-color d-flex align-items-center ps-4">
                        {CustomerBrandDetails?.theme_color}
                      </span>
                    </>
                  
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <p className="font-18 font-semibold tertiary-color mb-lg-4 py-lg-3 mb-2 px-3">
              Branding Elements
            </p>
            <div className="row custom-row-height1">
              <div className="col-md-5">
                <div className="mb-5 px-3">
                  <label className="font-14 font-semibold primary-text-color mb-2">
                    Upload Logo
                  </label>
                  <span className="upload-btn-wrapper me-2 d-block cursor-pointer ">
                    {!CustomerBrandDetails.file_name ? (
                      <button
                        type="button"
                        className="font-14  font-medium primary-color upload-btn border-0 shadow-none cursor-pointer"
                      >
                        <img
                          src="Images/uploads.svg"
                          alt="browse"
                          className="me-2"
                        />
                        Upload
                      </button>
                    ) : null}
                    <span className="link-blue px-2">
                      {CustomerBrandDetails.logo_url ? (
                        <a
                          href={CustomerBrandDetails.logo_url}
                          target="_blank"
                          title=""
                          style={{ textDecoration: "none" }}
                        >
                          {CustomerBrandDetails.file_name}{" "}
                        </a>
                      ) : (
                        <>{CustomerBrandDetails.file_name}</>
                      )}
                      {CustomerBrandDetails.file_name ? (
                        <img
                          src="Images/red-cross.svg"
                          alt="Red cross icon"
                          className="ms-2"
                          onClick={() => {
                            clear();
                          }}
                        />
                      ) : null}
                    </span>
                    {!CustomerBrandDetails.file_name ? (
                      <input
                        type="file"
                        name="myfile"
                        className="cursor-pointer upload-btn-input"
                        onChange={(e) => uploadImage(e)}
                      />
                    ) : null}
                    {fileErrorMessage ? (
                      <label className="color-red font-14">
                        {fileErrorMessage}
                      </label>
                    ) : null}
                  </span>
                </div>
              </div>

              <div className="col-md-4">
                <label
                  htmlFor="exampleColorInput"
                  className="form-label font-14 font-semibold primary-text-color mb-3"
                >
                  Brand Color
                </label>
                <div className="mb-5 px-0 border rounded-2 w-50 position-relative d-flex">
                    <>
                      <input
                        type="color"
                        className="form-control custom-color-picker-input form-control-color border-0 position-relative"
                        id="exampleColorInput"
                        value={CustomerBrandDetails?.theme_color}
                        name="theme_color"
                        title="Choose your color"
                        onChange={(e) => {
                          const target = e.target as HTMLInputElement;
                          setCustomerBrandDetails({
                            ...CustomerBrandDetails,
                            [target.name]: target.value,
                          });
                          setDisableSave(false);
                        }}
                      />
                      <label
                        htmlFor="exampleColorInput"
                        className="custom-color-picker"
                      >
                        <img
                          src="Images/color-picker.svg"
                          alt="color-picker"
                          className="clr-pic-img"
                        />
                      </label>
                      <span className="font-13 font-regular tertiary-color d-flex align-items-center ps-4">
                        {CustomerBrandDetails?.theme_color}
                      </span>
                    </>
                 
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end mb-5">
              <button
                type="button"
                className="btn text border-0 font-14 font-semibold me-3 shadow-none text-btn"
                onClick={() => navigate("/customer")}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn primary-btn border-0 font-14 font-semibold "
                disabled={disableSave}
                onClick={() => {
                  submit();
                }}
              >
                Save
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default CustomerBrandElementDetails;
