import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getOrderByCustomerId } from "../../services/orderService";
import BreadCrumbs from "../subscriptions/breadcrumbs";
import { defaultLimit } from "../../configs/constant";
import { Loader } from "../loader";
//** PC_NO_O_1  Fetch the Order By ID grid details and render the details into the Grid Component */
function OrderDetails() {
  //PC_NO_O_2.3
  const navigate = useNavigate();
  const location = useLocation();
  const [orderDetailsById, setOrderDetailsById] = useState<any[]>([]);
  const [loader, setloader] = useState<boolean>(false);
  const [sortType, setSortType] = useState<string>("ASC");
  const [sortColumn, setSortColumn] = useState<string>("product_name");
  const [search_value, setSearch_value] = useState<string>("");
  const [productNameSort, setProductNameSort] = useState<boolean>(false);
  const [quantitySort, setQuantitySort] = useState<boolean>(false);
  const [microsoftPriceSort, setMicrosoftPriceSort] = useState<boolean>(false);
  const [resellerPriceSort, setResellerPriceSort] = useState<boolean>(false);
  const [count, setCount] = useState<number>(defaultLimit);
  const [recordCount, setRecordCount] = useState(0);
  const [totalRecordCount, setTotalRecordCount] = useState(0);
  let breadcrumb = [
    { title: "Order", isActive: true, path: "/order" },
    { title: location?.state?.customerName, isActive: true, path: null },
    { title: location?.state?.order_id, isActive: false, path: null },
  ];
//** PC_NO_O_3 To perform the iteration of the funcitonal call using this hooks */
  useEffect(() => {
    getOrderGridDetailsByCustomerId();
  }, [
    sortColumn,
    sortType,
    productNameSort,
    quantitySort,
    microsoftPriceSort,
    resellerPriceSort,
    count,
  ]);

///** PC_NO_O_14, PC_NO_O_15, PC_NO_O_16, PC_NO_O_17, PC_NO_O_18, PC_NO_O_19, PC_NO_O_20
// Fetch the order Grid details by Customer ID */
  async function getOrderGridDetailsByCustomerId() {
    

    setloader(true);
    const payload = {
      order_id: location.state.order_id,
      search_value: search_value,
      sort_column: sortColumn,
      sort_order: sortType,
      count: count,
    };

    const response = await getOrderByCustomerId(payload); 

    if (response.data.status) {
      setOrderDetailsById(response?.data?.responseData?.orderDetails); 
      setRecordCount(response?.data?.responseData?.recordCount);
      setTotalRecordCount(response?.data?.responseData?.totalRecordCount);
      setloader(false);
    }
  }
//** PC_NO_O_38.13 Fetch the Order details and iterate the order value into the grid Component */
  function RenderingOrderDetails() {
    

    return (
      <>
        {" "}
        {orderDetailsById.map((orderdata, index) => (
          <tr key={index} className="border-bottom-0">
            <td className="ps-5 text-start font-regular">
              {orderdata.product_name}
            </td>
            <td className="text-end font-regular">
              {orderdata.quantity ?  Number(orderdata.quantity) : "0"}
            </td>
            <td className="text-end font-regular">
              ${orderdata.microsoft_price ? Number(orderdata.microsoft_price).toFixed(2) : "0.00"}
            </td>
            <td className="text-end font-regular pe-5">
              ${orderdata.reseller_price ? Number(orderdata.reseller_price).toFixed(2) : "0.00"}
            </td>
          </tr>
        ))}
      </>
    );
  }
//** PC_NO_O_27.1 Perform the search operation */
  const onSearch = (e: any) => {
    

    if (e.key === "Enter") {
      getOrderGridDetailsByCustomerId();
    }
  };

  return (
    <>
      {/* header starts */}
      {/* header ends */}
      {/* Admin starts */}
      <div className="container-fluid px-4 pt-3 ">
        {loader ? <Loader /> : <></>}
        <div className="row justify-content-center">
          <div className="px-3">
            <BreadCrumbs data={breadcrumb} />
            <div className="d-block d-md-flex flex-wrap justify-content-between align-items-center  mb-4 mt-2">
              {/* <div class> */}
              <h2 className="font-20 font-bold dark-blue-color d-flex mb-0">
                <a
                  onClick={() =>
                    navigate("/order", { state: { mode: "view" } })
                  }
                >
                  <img
                    src="Images/back-arrow.svg"
                    alt="back-arrow"
                    title="Back"
                    className="me-2"
                  />
                </a>
                Order:{location.state.order_id}
                <span className="custom-admin-batch  font-12 d-flex align-items-center justify-content-center ms-2 px-2">
                  {totalRecordCount}
                </span>
                {/* </div> */}
              </h2>
              <div className="d-block d-md-flex">
                <div className="w-md-100 d-flex mb-3 mb-md-0">
                  {/* <span className="position-relative d-flex align-items-center w-100">
                                        <input
                                            type="text"
                                            className="border-0 font-regular w-230  rounded-3 font-14 top-0 bottom-0 py-2 px-3 custom-input"
                                            id="search"
                                            placeholder="Search"
                                            value={search_value}
                                            onKeyPress={(e) => { onSearch(e) }}
                                            onChange={(e:any) => { setSearch_value(e.target.value) }}
                                        />
                                        <span className="position-absolute search-icon">
                                            <img src="Images/search.svg" alt="search-icon"
                                            onClick={()=>{getOrderGridDetailsByCustomerId()}} />
                                        </span>
                                    </span> */}

                  <span className="position-relative d-flex align-items-center me-3 w-100">
                    <input
                      type="search"
                      className="border-0 font-regular w-230 cus-z-ind  rounded-2 font-14 top-0 bottom-0 py-2 px-3 custom-input"
                      id="search"
                      value={search_value}
                      placeholder="Search"
                      onKeyPress={(e) => {
                        onSearch(e);
                      }}
                      onChange={(e: any) => {
                        setSearch_value(e.target.value);
                      }}
                    />
                    <span className="position-absolute search-icon">
                      <img
                        src="Images/search.svg"
                        alt="search-icon"
                        onClick={() => {
                          getOrderGridDetailsByCustomerId();
                        }}
                      />
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* <div class="tab-content" id="pills-tabContent"> */}
          {/* first tab starts here */}
          {/* <div class="tab-pane fade show active" id="pills-notstarted" role="tabpanel" >  <div class="row justify-content-center"> */}
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="table-responsive rounded-2">
              <table className="table mb-0  themeTable ">
                <thead className="font-bold">
                  <tr>
                    <th className="ps-5 text-start">
                      License
                      <span
                        className="ms-2 cursor-pointer"
                        onClick={(e) => {
                          setProductNameSort(!productNameSort);
                          setSortColumn("product_name");
                          setSortType(productNameSort ? "desc" : "asc");
                        }}
                      >
                        <img
                          src={
                            productNameSort
                              ? "Images/sort-up-arrow.svg"
                              : "Images/sort-arrow.svg"
                          }
                          alt="sortarrow"
                          title="Sort"
                        />
                      </span>
                    </th>
                    <th
                      className="text-end"
                      onClick={(e) => {
                        setQuantitySort(!quantitySort);
                        setSortColumn("quantity");
                        setSortType(quantitySort ? "desc" : "asc");
                      }}
                    >
                      Quantity
                      <span className="ms-2 cursor-pointer">
                        <img
                          src={
                            quantitySort
                              ? "Images/sort-up-arrow.svg"
                              : "Images/sort-arrow.svg"
                          }
                          alt="sortarrow"
                          title="Sort"
                        />
                      </span>
                    </th>
                    <th
                      className="text-end"
                      onClick={(e) => {
                        setMicrosoftPriceSort(!microsoftPriceSort);
                        setSortColumn("microsoft_price");
                        setSortType(microsoftPriceSort ? "desc" : "asc");
                      }}
                    >
                      Microsoft Price
                      <span className="ms-2 cursor-pointer">
                        <img
                          src={
                            microsoftPriceSort
                              ? "Images/sort-up-arrow.svg"
                              : "Images/sort-arrow.svg"
                          }
                          alt="sortarrow"
                          title="Sort"
                        />
                      </span>
                    </th>
                    <th
                      className="text-end pe-5"
                      onClick={(e) => {
                        setResellerPriceSort(!resellerPriceSort);
                        setSortColumn("reseller_price");
                        setSortType(resellerPriceSort ? "desc" : "asc");
                      }}
                    >
                      Reseller Price{" "}
                      <span className="ms-2 cursor-pointer">
                        <img
                          src={
                            resellerPriceSort
                              ? "Images/sort-up-arrow.svg"
                              : "Images/sort-arrow.svg"
                          }
                          alt="sortarrow"
                          title="Sort"
                        />
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody className="font-medium">{RenderingOrderDetails()}</tbody>
              </table>
            </div>
            {recordCount > count ? (
              <div className="d-flex justify-content-center mb-5">
                <button
                  type="button"
                  className="btn tertiary-btn border-0 font-14 font-semibold"
                  name="count"
                  onClick={() => {
                    setCount(count + defaultLimit);
                  }}
                >
                  Load More
                </button>
              </div>
            ) : (
              <></>
            )}

            {orderDetailsById.length == 0 ? (
              <>
                <div className="d-flex justify-content-center color-white-bg rounded-bottom pt-100 pb-400 mb-5">
                  <div>
                    <img
                      src="Images/no-records-found.svg"
                      alt="No-Records-Found"
                      className="d-block"
                    />
                    <div>
                      <p className="d-flex font-20 font-regular tertiary-color mt-3 justify-content-center">
                        No records found
                      </p>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
      {/* </div>
    </div>
  </div> */}
      {/* Admin Ends */}
    </>
  );
}

export default OrderDetails;
