import { client } from "../client";
import { orderUrl, backendurl as url } from "../configs/constant";

//** //PC_NO_IN_78, PC_NO_IN_79, PC_NO_IN_80
// get User ActivityLog */
export async function getUserActivityLog(data: {} | undefined) {
    let Config = {
        url: url + orderUrl.getActivityLog,
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        body: data
    };

    let response = await client(Config);
    return response;
}

//** ///PC_NO_IN_78, PC_NO_IN_79,
//get Customer Order Grid Details */
export async function getCustomerOrderGridDetails(data: {} | undefined) {
    let Config = {
        url: url + orderUrl.getOrderDetailsByCustomer,
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        body: data
    };

    let response = await client(Config);
    return response;
}
// PC_NO_IN_80, PC_NO_IN_81, PC_NO_IN_82, 
export async function getOrderByCustomerId(data: {} | undefined) {
    let Config = {
        url: url + orderUrl.getOrderDetailsByOrderId,
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        body: data
    };

    let response = await client(Config);
    return response;
}


//**//PC_NO_IN_84, PC_NO_IN_85, PC_NO_IN_86, PC_NO_IN_87, PC_NO_IN_89, PC_NO_IN_90, 
//get Order Filter DropdownData */
export async function getOrderFilterDropdownData(data: {} ={}) {
    let Config = {
        url: url + orderUrl.getOrderFilterDropdownDetails,
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        body:data
    };

    let response = await client(Config);
    return response;
}
//**PC_NO_IN_91, PC_NO_IN_92, PC_NO_IN_94, PC_NO_IN_95, PC_NO_IN_96
// Insert the Activity log into the DB*/
export async function insertActivityLogDetails(data:any ={}) {
    let Config = {
        url: url + orderUrl.insertActivityLog,
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        body: data
    };

    let response = await client(Config);
    return response;
}