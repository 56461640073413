

import { client } from "../client";
import { InvoiceUrl, backendurl as url } from "../configs/constant";


//** PC_NO_O_39, PC_NO_O_40, PC_NO_O_41
// Fetch the Invoice Details */
export async function getInvoiceDetails(data: {} | undefined) {
    let Config = {
        url: url + InvoiceUrl.getInvoiceDetails,
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        body: data
    };

    let response = await client(Config);
    return response;
}
//** PC_NO_O_42, PC_NO_O_43, PC_NO_O_44, 
// Fetch the Invoice details by ID */ 
export async function getInvoiceDetailsById(data: {} | undefined) {
    let Config = {
        url: url + InvoiceUrl.getCustomerInvoiceDetailsById,
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        body: data
    };

    let response = await client(Config);
    return response;
}
//**PC_NO_O_45, PC_NO_O_46, PC_NO_O_47,
// Fetch the Invoice Download and Preview Details  */ 
export async function getInvoiceDownloadAndPreview(data: {} | undefined) {
    let Config = {
        url: url + InvoiceUrl.getCustomerInvoiceDownloadAndPreview,
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        body: data
    };

    let response = await client(Config);
    return response;
}
//** PC_NO_O_48, PC_NO_O_49
// Update the Invoice License Details*/  
export async function updateInvoicelicenseDetails(data: {} | undefined) {
    let Config = {
        url: url + InvoiceUrl.updateCustomerInvoicelicenseDetails,
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        body: data
    };

    let response = await client(Config);
    return response;
}
//** PC_NO_O_50, PC_NO_O_51
// post the new Invoice license details to create an new invoice  */ 
export async function createInvoicelicenseDetails(data: {} | undefined) {
    let Config = {
        url: url + InvoiceUrl.createCustomerInvoicelicenseDetails,
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        body: data
    };

    let response = await client(Config);
    return response;
}
//**PC_NO_O_52, PC_NO_O_53, PC_NO_O_54
// post the new Invoice details to create an new invoice */ 
export async function createInvoiceDetails(data: {} | undefined) {
    let Config = {
        url: url + InvoiceUrl.createCustomerInvoiceDetails,
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        body: data
    };

    let response = await client(Config);
    return response;
}

//** PC_NO_O_53, PC_NO_O_54
// Fetch hte invoice Filter drop down option value details */
export async function getInvoiceGridFilterDetails(data: {} | undefined) {
    let Config = {
        url: url + InvoiceUrl.getCustomerInvoiceGridFilterDetails,
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        body: data
    };

    let response = await client(Config);
    return response;
}



//** PC_NO_O_53, PC_NO_O_54
// Delete the invoice license details by ID */ 
export async function deleteInvoiceDetailsById(data: {} | undefined) {
    let Config = {
        url: url + InvoiceUrl.deleteCustomerInvoiceDetailsById,
        headers: {
            "content-type": "application/json",
        },
        method: "delete",
        body: data
    };

    let response = await client(Config);
    return response;
}
//** PC_NO_O_48, PC_NO_O_49, 
//post the update invoice license details to update the invoice */

export async function saveInvoicelicenseDetails(data: {} | undefined) {
    let Config = {
        url: url + InvoiceUrl.saveCustomerInvoicelicenseDetails,
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        body: data
    };

    let response = await client(Config);
    return response;
}
//** PC_NO_O_48, PC_NO_O_49, PC_NO_O_50, PC_NO_O_51, PC_NO_O_52, PC_NO_O_53, PC_NO_O_54
//Fetch the Invoice Filter drop down option details  */ 
export async function getInvoiceFilterStatus(data: {} = {}) {
    let Config = {
        url: url + InvoiceUrl.getCustomerInvoiceFilterStatus,
        headers: {
            "content-type": "application/json",
        },
        method: "get",
        body: data
    };

    let response = await client(Config);
    return response;
}


export async function getFilterDetails() {
    let Config = {
        url: url + InvoiceUrl.getCustomerFilterDetails,
        headers: {
            "content-type": "application/json",
        },
        method: "get",
    };

    let response = await client(Config);
    return response;
}


export async function invoiceCalculatorByCustomer(data: {} | undefined) {
    let Config = {
        url: url + InvoiceUrl.invoiceCalculator,
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        body: data
    };

    let response = await client(Config);
    return response;
}
//** PC_NO_O_50, PC_NO_O_51, PC_NO_O_52, PC_NO_O_53, PC_NO_O_54
// Fetch the Invoice License Details By ID*/ 


export async function sendInvoiceApi(data: {} | undefined) {
    let Config = {
        url: url + InvoiceUrl.sendinvoice,
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        body: data
    };

    let response = await client(Config);
    return response;
}

export async function downloadInvoiceApi(data: {} | undefined) {
    let Config = {
        url: url + InvoiceUrl.downloadinvoice,
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        body: data
    };

    let response = await client(Config);
    return response;
}


// export async function getInvoiceLicenseDetailsById(data: {} | undefined) {
//     let Config = {
//         url: url + InvoiceUrl.getCustomerInvoiceLicenseDetailsById,
//         headers: {
//             "content-type": "application/json",
//         },
//         method: "post",
//         body: data
//     };

//     let response = await client(Config);
//     return response;
// }


export async function getpreview(data: {} | undefined) {
    let Config = {
        url: url + InvoiceUrl.getprieview,
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        body: data
    };

    let response = await client(Config);
    return response;
}


export async function triggerInvoice(data: any = {}) {
    let Config = {
        url: url + InvoiceUrl.triggerInvoice,
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        body: data
    };
    let response = await client(Config);
    return response;
}


export async function validateCustomerEmail(data: any = {}) {
    let Config = {
        url: url + InvoiceUrl.validateCustomerEmail,
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        body: data
    };

    let response = await client(Config);
    return response;
}





