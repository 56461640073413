import React, { useEffect, useState } from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { sessionKeys, encryptStorage } from "../../configs/constant";
import { Loader } from "../loader";
import { customerSessionData, userSessionData,sessionTokens } from "../../interface";
import { getRole, isRpaUser } from "../../services/loginService";
import { insertActivityLogDetails } from "../../services/orderService";

/**
 *
 * @returns
 *PC_LL_11 to validate the user authorized or not
 */

export default function LoginAuth() {
  //PC_LL_3 state and obejct variable
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const accounts = instance.getAllAccounts();
  const navigate = useNavigate();
  //** PC_LL_13 useEffect call the fuction diring the rendering */
  useEffect(() => {
    if (isAuthenticated) {
      AcquireToken();
    }
  }, [isAuthenticated]);

  //** PC_LL_13, PC_LL_14, PC_LL_15, PC_LL_16, PC_LL_17, PC_LL_18, PC_LL_19, PC_LL_20
  // validate the user is authorized or not */
  const AcquireToken = async () => {
    if (accounts.length > 0) {
      const silentRequest: any = {
        account: accounts[0],
        scopes: ["user.read"],
      };

      let token: any = null;
      await instance
        .acquireTokenSilent(silentRequest)
        .then((response: any) => {
          const account = response;
          token = account?.accessToken;
        })
        .catch((error: any) => {
          console.log(
            "Error acquiring token or performing silent logout:",
            error
          );
        });

      if (token) {
        let payload = {
          user_id: silentRequest?.account?.localAccountId,
          token: token,
        };

        let rpaPayload = {
          user_id: silentRequest?.account?.localAccountId,
        };

        const isRpaUserResponse: any = await isRpaUser(rpaPayload);

        if (isRpaUserResponse.data.status == true && isRpaUserResponse.data.responseData == false) {
          const roleDetails: any = await getRole(payload);

          let sessionTokens:sessionTokens = {
            jwt:roleDetails.data.responseData.jwt,
            refresh_token:roleDetails.data.responseData.refresh_token
          }

          encryptStorage.setItem(
            sessionKeys.sessiontokens,
            JSON.stringify(sessionTokens)
          );


          if (roleDetails.data.status) {
            const activityPayload: any = {
              user_id: silentRequest?.account?.localAccountId,
              user_name: silentRequest?.account?.name,
              activity: "Logged In",
              comments: "Logged In",
              created_by: silentRequest?.account?.localAccountId,
            };
            const postActivityResponse: any = await insertActivityLogDetails(
              activityPayload
            );
            const profileImage = await axios
              .get(`https://graph.microsoft.com/v1.0/me/photo/$value`, {
                headers: { Authorization: `Bearer ${token}` },
                responseType: "blob",
              })
              .catch((error) => {});

            let userDetails: userSessionData = {
              user_id: silentRequest?.account?.localAccountId,
              user_name: silentRequest?.account?.name,
              user_mail: silentRequest?.account?.username,
              role: roleDetails?.data?.responseData?.role,
              department: roleDetails?.data?.responseData?.department,
              role_id: roleDetails?.data?.responseData?.roleId,
            };
            encryptStorage.setItem(
              sessionKeys.userDetails,
              JSON.stringify(userDetails)
            );

            if (profileImage) {
              const reader = new FileReader();
              reader.readAsDataURL(profileImage?.data);
              reader.onloadend = () => {
                const dataUrl: any = reader.result;
                encryptStorage.setItem(sessionKeys.profileImage, dataUrl);
              };
            }

            let customerSessionData: customerSessionData = {
              customer_Name: null,
              customer_id: null,
              customer_theme: null,
              view: "overall",
              customer_margin: 0,
              logo_url: null,
              tax: null,
            };

            encryptStorage.setItem(
              sessionKeys.customerDetails,
              JSON.stringify(customerSessionData)
            );
            window.location.href = "/dashboard";
          } else {
            //navigate to 404 page
            navigate("/oops");
          }
        } else {
          //navigate to rpa screen

          navigate("/rpatoken");
        }
      }
    }
  };
  return (
    <>
      <Loader />
    </>
  );
}
