import {
  UserData,
  Filter,
  role,
  userRole,
  filterUserName,
  filterUserDepartment,
  filterUserRole,
  FilterDetails,
} from "../../interface"; // PS_AD_01
import React, { useEffect, useState } from "react"; //PS_AD_02
import {
  getUserFilterDetails,
  getUserDetails,
  updateUserDetail,
} from "../../services/adminService";
import {
  defaultLimit,
  sessionKeys,
  encryptStorage,
} from "../../configs/constant";
import { ErrorLog } from "../../services/errorService";
import { userSessionData } from "../../interface";
const UsersGrid = () => {
  //** PS_AD_03, PS_AD_04 state and obect varaibles*/

  const [users, setUsers] = useState<UserData[]>([]);
  const [updaterole, setupdaterole] = useState<role>();
  const [getroles, setgetroles] = useState<userRole[]>([
    { roles_id: "", roles_name: "" },
  ]);
  const [loader, setloader] = useState<boolean>(false);
  const [isPopupVisible, setisPopupVisible] = useState(false);
  const [sort_column, setSort_column] = useState<string>("name");
  const [sort_order, setSort_order] = useState<string>("ASC");
  const [search_value, setSearch_value] = useState<string>("");
  const [nameSort, setnameSort] = useState<boolean>(false);
  const [customerNameSort, setCustomerNameSort] = useState<boolean>(false);
  const [employeeIdSort, setEmployeeIdSort] = useState<boolean>(false);
  const [email, setEmail] = useState<boolean>(false);
  const [phone, setPhone] = useState<boolean>(false);
  const [role, setRole] = useState<boolean>(false);
  const [department, setDepartment] = useState<boolean>(false);
  const [selectedRole, setselectedRole] = useState<string>("");
  const [FilterName, setFilterName] = useState<
    FilterDetails["filterUserName"][]
  >([{ customer_name: "" }]);
  const [FilterDepartment, setFilterDepartment] = useState<
    FilterDetails["filterUserDepartment"][]
  >([{ department: "" }]);
  const [FilterRole, setFilterRole] = useState<
    FilterDetails["filterUserRole"][]
  >([{ roles_id: "", roles_name: "" }]);
  const [userId, setUserId] = useState<string>("");
  const [errorStatus, setErrorStatus] = useState<boolean>(false);
  const [count, setcount] = useState<number>(defaultLimit);
  const [FilterDetails, setFilterDetails] = useState<Filter>({
    customer_name: "",
    department: "",
    role_name: "",
  });
  const [totalCount, setTotalCount] = useState<any>("");
  const [showCount, setshowCount] = useState(0);
  const[showfilterpopup,setshowfilterpopup]=useState(false)

  //PS_AD_05
  useEffect(() => {
    getUserData();
  }, [
    sort_column,
    sort_order,
    count,
    nameSort,
    customerNameSort,
    employeeIdSort,
    email,
    phone,
    role,
    department,
  ]);

  useEffect(() => {
    fetchFilterDropdown();
  }, []);

  const handleClear = () => {
    setFilterDetails({ customer_name: "", department: "", role_name: "" });
    let emptyObject = { customer_name: "", department: "", role_name: "" };
    getUserData("", emptyObject);
    // setisPopupVisible(false);
  };

  async function fetchFilterDropdown() {
    setloader(true);
    const response = await getUserFilterDetails();

    if (response.data.statusCode === 200) {
      setFilterName(response.data.responseData[0]);
      setFilterRole(response.data.responseData[1]);
      setFilterDepartment(response.data.responseData[2]);
      setloader(false);
    }
  }

  /**
   *
   * @param search
   * @param filter
   */
  async function getUserData(search = search_value, filter = FilterDetails) {
    //PS_AD_06
    try {
      //PS_AD_07
    
      let obj = {
        search_value: search,
        ...filter,
        sort_column: sort_column,
        sort_order: sort_order,
        count: count,
      };
      setloader(true);

      const response = await getUserDetails(obj); //PS_AD_08

      if (response.data.statusCode === 200) {
        //PS_AD_09
        setUsers(response.data.responseData[0]);
        setTotalCount(response.data.responseData[1][0]);
        setshowCount(response.data.responseData[2]?.[0]?.Count_value);
        setloader(false);
      } else {
        setErrorStatus(!errorStatus);
      }
    } catch (Error: any) {
      //PS_AD_10
      console.log("error fetching data", Error);
      const errorObject = {
        stack_name: "frontend_react",
        module_name: "Admin_module",
        function_name: "getUserData",
        error_message: `${Error?.message}`,
      };
      if (Error) {
        const request: any = await ErrorLog(errorObject);
        return request.data.statusCode === 200
          ? setErrorStatus(errorStatus)
          : setErrorStatus(!errorStatus); //PS_AD_11
      }
    }
  }
  //** PS_AD_13.4, PS_AD_13.5, PS_AD_13.6, PS_AD_13.7, PS_AD_13.8, PS_AD_13.9
  // to update the User Role */
  const updateUserRole = async () => {
    let sessionUserDetails: userSessionData | null = JSON.parse(
      encryptStorage.getItem(sessionKeys.userDetails) || "null"
    );
    let payload: any = {
      roles_id: updaterole?.roles_id,
      user_id: updaterole?.user_id,
      modified_by: sessionUserDetails?.user_id,
    };
    let response = await updateUserDetail(updaterole);

    if (response.data.statusCode === 200) {
      setisPopupVisible(false);
      getUserData();
    }
  };
  //PS_AD_13 to perdform the search operation
  const onSearch = (e: any) => {
    setSearch_value(e.target?.value);
    // handleClear();
    setloader(loader);
    if (e.key === "Enter") {
      getUserData();
    }
  };
  //** PS_AD_13.10, PS_AD_13.11, PS_AD_13.12, PS_AD_13.13, PS_AD_13.14
  // to render the value into the html component */
  function userTableRendering() {
    return (
      <>
        {users.map((user, index) => (
          <tr className="border-bottom-0" key={user.user_id}>
            <td className="ps-5 text-start font-regular ">{user.name}</td>
            <td className="text-start font-regular">
              {user.customer_name ? user.customer_name : "-"}
            </td>
            <td className="text-start font-regular">
              {user.employee_id ? user.employee_id : "-"}
            </td>
            <td className="text-start font-regular">
              {user.email ? user.email : " "}
            </td>
            <td className="text-start font-regular">
              {user.phone ? user.phone : "-"}
            </td>
            <td className="text-start font-regular">
              {user.department ? user.department : "-"}
            </td>
            <td className="text-start font-regular">
              {user.roles_name ? user.roles_name : "-"}
            </td>

            {/* <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal"> Role </button> */}
            <td
              className="text-center font-regular"
              // data-bs-toggle="modal" data-bs-target="#exampleModal"
              key={user.user_id}
              data-userid={user.user_id}
              onClick={(e) => {
                setisPopupVisible(true);
                setselectedRole(user.roles_name);
                setUserId(user.user_id);
              }}
            >
              <span className="cursor-pointer">
                <img src="Images/edit-icon.svg" alt="editIcon" title="Edit" />
              </span>
            </td>
          </tr>
        ))}
      </>
    );
  }

  return (
    //PS_AD_12
    <>
      {isPopupVisible ? (
        <>
          <div className="container-fluid">
            <div
              className="modal fade show"
              id="exampleModal"
              style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog popup-w-337 modal-dialog-centered">
                <div className="modal-content px-3 border-0">
                  <div className="modal-header border-0 justify-content-center ">
                    <h1
                      className="modal-title fs-5 tertiary-color font-16 font-semibold"
                      id="exampleModalLabel"
                    >
                      Role
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setisPopupVisible(false);
                      }}
                    />
                  </div>
                  <div className="modal-body px-3 mb-3">
                    <p className="font-14 font-bold">Choose Role</p>

                    {FilterRole.map((val: any, index: number) => (
                      <div className="form-check form-check-inline" key={index}>
                        {/* <div> */}
                        <input
                          className="custom-check form-check-input"
                          type="radio"
                          name="roles_name"
                          id={val?.roles_id}
                          value={val?.roles_name}
                          checked={selectedRole === val.roles_name}
                          onChange={(e) => {
                            setselectedRole(e.target.value);
                            setupdaterole((prev: any) => ({
                              ...prev,
                              user_id: userId,
                              roles_id: e.target.id,
                            }));
                          }}
                        />
                        <label
                          className="form-check-label font-14 font-medium color-black-v3"
                          htmlFor={val?.roles_id}
                        >
                          {val?.roles_name}
                        </label>
                      </div>
                      // </div>
                    ))}
                  </div>
                  <div className="modal-footer border-0 justify-content-end px-3">
                    <button
                      type="button"
                      className="btn text-btn shadow-none px-3 font-14 font-medium"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        setisPopupVisible(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn primary-btn font-14 font-medium secondary-color-bg"
                      onClick={() => {
                        updateUserRole();
                      }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      <>
        <div className="container-fluid px-4">
          <div className="row justify-content-center">
            <div className="px-4">
              <div className="d-block d-md-flex flex-wrap justify-content-between align-items-center  mb-4 mt-3">
                {/* <div class> */}
                <h2 className="font-20 font-bold primary-color mb-0 d-flex">
                  Users
                  <span className="custom-admin-batch  font-12 d-flex align-items-center justify-content-center ms-2 px-2">
                    {/* {showCount} */}
                    {totalCount?.Count_value}
                  </span>
                  {/* </div> */}
                </h2>
                <div className="d-block d-md-flex">
                  <div className="w-md-100 d-flex mb-3 mb-md-0">
                    {/* <span className="position-relative d-flex align-items-center me-4 w-100">
                      <input
                        type="search"
                        className="border-0 font-regular w-230 rounded-3 font-14 top-0 bottom-0 py-2 px-3 custom-input"
                        id="search"
                        placeholder="Search"
                        name="search_value"
                        value={search_value}
                        onKeyPress={(e) => { onSearch(e) }}
                        onChange={(e) => {
                          onSearch(e)
                        }}
                      />
                      <span
                        className="position-absolute search-icon"
                        onClick={() => { getUserData() }}
                      >
                        <img src="Images/search.svg" alt="search-icon" />
                      </span>
                    </span> */}
                    <span className="position-relative d-flex align-items-center me-3 w-100">
                      <input
                        type="search"
                        className="border-0 font-regular w-230 cus-z-ind  rounded-2 font-14 top-0 bottom-0 py-2 px-3 custom-input"
                        id="search"
                        placeholder="Search"
                        name="search_value"
                        value={search_value}
                        onKeyPress={(e) => {
                          onSearch(e);
                        }}
                        onChange={(e) => {
                          onSearch(e);
                        }}
                      />
                      <span className="position-absolute search-icon">
                        <img
                          src="Images/search.svg"
                          alt="search-icon"
                          onClick={() => {
                            getUserData();
                          }}
                        />
                      </span>
                    </span>
                    <div className="btn-group">
                      <button
                        type="button"
                        className="btn secondary-btn custom-filter rounded-3 "
                        onClick={()=>{
                          setshowfilterpopup(true)
                        }}
                      >
                        <img
                          src="Images/filter-active-icon.svg"
                          alt="Filter"
                          className="custom-filter-active-img"
                        />
                        <img
                          src="Images/Filter.svg"
                          alt="Filter"
                          className="custom-filter-img"
                        />
                      </button>
                      {showfilterpopup ?<div
                        className="dropdown-menu dropdown-menu-end adv-dropdown p-3 mt-1 border-0 popup-w-337 end-0 position-absolute"
                        style={{
                          display:'block',
                         
                        }}
                      >
                        <div className="row">
                          <div className="d-flex justify-content-between align-items-center mb-3">
                            <h6 className="font-16 font-semibold tertiary-color mb-0 ">
                              Filter
                            </h6>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={()=>{
                                setshowfilterpopup(false)
                              }}
                            />
                          </div>
                          <div className="col-sm-12 col-md-12 mb-3">
                            <label className="form-label font-14 font-semibold color-black">
                              Customer Name
                            </label>
                            <select
                              className=" form-select custom-select custom-input font-regular font-14"
                              value={FilterDetails.customer_name}
                              onChange={(e) => {
                                setFilterDetails({
                                  ...FilterDetails,
                                  customer_name: e.target.value,
                                });
                              }}
                            >
                              <option value="">Select</option>
                              {FilterName.map((val: any) => (
                                <option value={val.customer_name}>
                                  {val.customer_name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-sm-12 col-md-6 mb-5">
                            <label className="form-label font-14 font-semibold color-black">
                              Department
                            </label>
                            <select
                              className="form-select custom-select custom-input font-regular font-14"
                              value={FilterDetails.department}
                              onChange={(e) => {
                                setFilterDetails({
                                  ...FilterDetails,
                                  department: e.target.value,
                                });
                              }}
                            >
                              <option value="">Select</option>
                              {FilterDepartment.map((val: any) => (
                                <option value={val.department}>
                                  {val.department}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-sm-12 col-md-6 mb-5">
                            <label className="form-label font-14 font-semibold color-black">
                              Role
                            </label>
                            <select
                              className="form-select custom-select custom-input font-regular font-14"
                              value={FilterDetails.role_name}
                              onChange={(e) => {
                                setFilterDetails({
                                  ...FilterDetails,
                                  role_name: e.target.value,
                                });
                              }}
                            >
                              <option value="">Select</option>
                              {FilterRole.map((val: any) => (
                                <option value={val.roles_name}>
                                  {val.roles_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end">
                          <button
                            type="button"
                            className="btn text border-0 font-14 font-semibold me-3 shadow-none text-btn "
                            onClick={handleClear}
                          >
                            Clear
                          </button>
                          <button
                            type="button"
                            className="btn primary-btn border-0 font-14 font-semibold "
                            onClick={() => {
                              setshowfilterpopup(false);
                              getUserData("");
                            }}
                          >
                            Apply
                          </button>
                        </div>
                      </div>:null}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div class="tab-content" id="pills-tabContent"> */}
              {/* first tab starts here */}
              {/* <div class="tab-pane fade show active" id="pills-notstarted" role="tabpanel" >  <div class="row justify-content-center"> */}
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="table-responsive mb-4 rounded-2">
                  <table className="table  themeTable mb-0">
                    <thead className="font-bold">
                      <tr>
                        <th className="ps-5 text-start">
                          Name
                          <span
                            className="ms-2 cursor-pointer"
                            onClick={(e) => {
                              setnameSort(!nameSort);
                              setSort_column("name");
                              setSort_order(nameSort ? "asc" : "desc");
                            }}
                          >
                            <img
                              src={
                                nameSort
                                  ? "Images/sort-up-arrow.svg"
                                  : "Images/sort-arrow.svg"
                              }
                              alt="sortarrow"
                              title="Sort"
                            />
                          </span>
                        </th>
                        <th className="text-start">
                          Customer Name
                          <span
                            className="ms-2 cursor-pointer"
                            onClick={(e) => {
                              setCustomerNameSort(!customerNameSort);
                              setSort_column("customer_name");
                              setSort_order(customerNameSort ? "asc" : "desc");
                            }}
                          >
                            <img
                              src={
                                customerNameSort
                                  ? "Images/sort-up-arrow.svg"
                                  : "Images/sort-arrow.svg"
                              }
                              alt="sortarrow"
                              title="Sort"
                            />
                          </span>
                        </th>
                        <th className="text-start">
                          Employee ID
                          <span
                            className="ms-2 cursor-pointer"
                            onClick={(e) => {
                              setEmployeeIdSort(!employeeIdSort);
                              setSort_column("employee_id");
                              setSort_order(employeeIdSort ? "asc" : "desc");
                            }}
                          >
                            <img
                              src={
                                employeeIdSort
                                  ? "Images/sort-up-arrow.svg"
                                  : "Images/sort-arrow.svg"
                              }
                              alt="sortarrow"
                              title="Sort"
                            />
                          </span>
                        </th>
                        <th className="text-start">
                          Email
                          <span
                            className="ms-2 cursor-pointer"
                            onClick={(e) => {
                              setEmail(!email);
                              setSort_column("email");
                              setSort_order(email ? "asc" : "desc");
                            }}
                          >
                            <img
                              src={
                                email
                                  ? "Images/sort-up-arrow.svg"
                                  : "Images/sort-arrow.svg"
                              }
                              alt="sortarrow"
                              title="Sort"
                            />
                          </span>
                        </th>
                        <th className="text-start">
                          Phone #
                          <span
                            className="ms-2 cursor-pointer"
                            onClick={(e) => {
                              setPhone(!phone);
                              setSort_column("phone");
                              setSort_order(phone ? "asc" : "desc");
                            }}
                          >
                            <img
                              src={
                                phone
                                  ? "Images/sort-up-arrow.svg"
                                  : "Images/sort-arrow.svg"
                              }
                              alt="sortarrow"
                              title="Sort"
                            />
                          </span>
                        </th>
                        <th className="text-start">
                          Department
                          <span
                            className="ms-2 cursor-pointer"
                            onClick={(e) => {
                              setDepartment(!department);
                              setSort_column("department");
                              setSort_order(department ? "asc" : "desc");
                            }}
                          >
                            <img
                              src={
                                department
                                  ? "Images/sort-up-arrow.svg"
                                  : "Images/sort-arrow.svg"
                              }
                              alt="sortarrow"
                              title="Sort"
                            />
                          </span>
                        </th>
                        <th className="text-start">
                          Role
                          <span
                            className="ms-2 cursor-pointer"
                            onClick={(e) => {
                              setRole(!role);
                              setSort_column("r.roles_name");
                              setSort_order(role ? "asc" : "desc");
                            }}
                          >
                            <img
                              src={
                                role
                                  ? "Images/sort-up-arrow.svg"
                                  : "Images/sort-arrow.svg"
                              }
                              alt="sortarrow"
                              title="Sort"
                            />
                          </span>
                        </th>
                        <th className="text-center">Actions</th>
                      </tr>
                    </thead>
                    <tbody className="font-medium">
                      {userTableRendering()}
                    </tbody>
                  </table>

                  {users.length === 0 ? (
                    <>
                      <div className="d-flex justify-content-center color-white-bg rounded-bottom pt-100 pb-400 mb-5">
                        <div>
                          <img
                            src="Images/no-records-found.svg"
                            alt="No-Records-Found"
                            className="d-block"
                          />
                          <div>
                            <p className="d-flex font-20 font-regular tertiary-color mt-3 justify-content-center">
                              No records found
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {errorStatus ? (
                    <>
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-md-12 col-sm-12">
                            <div className="h-100vh d-flex align-items-center justify-content-center">
                              <div className="  text-center">
                                <div className="custom-gif">
                                  <img src="Images/404-4.svg" alt="number-4" />
                                  <img
                                    src="Images/Gif.gif"
                                    alt="404-image"
                                    className="error-img"
                                  />
                                  <img src="Images/404-4.svg" alt="number-4" />
                                </div>
                                <p className="font-40 font-semibold tertiary-color mt-3">
                                  Oops! Page Not Found
                                </p>
                                <p className="font-16 font-medium primary-text-color">
                                  We can’t find the page you are looking for,
                                  you can try again later.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
                <div className="d-flex justify-content-center mb-5">
                  {totalCount?.Count_value > users?.length ? (
                    <button
                      type="button"
                      className="btn tertiary-btn border-0 font-14 font-semibold"
                      name="count"
                      value={count}
                      onClick={() => {
                        setcount(count + 10);
                      }}
                    >
                      Load More
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          {loader ? (
            <div className="overlay">
              <div className="position-absolute top-50 start-50 translate-middle">
                <div
                  className="spinner-border Loader text-light align-center "
                  role="status"
                >
                  <span className="visually-hidden"></span>
                </div>
                <p className="loading-text font-14 color-white mt-2 font-semibold">
                  Loading..
                </p>
              </div>
            </div>
          ) : null}

          {/* </div>
</div> */}
        </div>
        {/* Admin Ends */}
      </>
    </>
  );
};

export default UsersGrid;
