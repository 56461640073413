import React from "react";

const ErrorPopup = (props:any) => {
    
  return (
    <>
        <div
          className="modal fade show"
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
          id="successpopup"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-hidden="true"
        >
          <div className="modal-dialog popup-w-400 modal-dialog-centered">
            <div className="modal-content px-3">
              <div className="modal-header border-0 justify-content-center">
                <span className="popup-icon-pos">
                  <img
                    src="Images/exclamatory-icon.svg"
                    alt="success-icon"
                    className="popup-icon"
                  />
                </span>
              </div>
              <div className="modal-body pt-5 px-3">
                <p className="text-center font-16 font-medium secondary-text-color">
                  {props.message}
                </p>
              </div>
              <div className="modal-footer border-0 justify-content-center px-3">
                <button
                  type="button"
                  className="btn text-btn danger-btn shadow-none px-3 font-14 font-medium"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    props.closePopup(false);
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
    </>
  );
}

export default ErrorPopup;
