import React from "react";
import { useEffect } from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { Interaction } from "chart.js";
import {InteractionType} from "@azure/msal-browser"


/**
 *PC_LL_1
PC_LL_2
 *to perform the login operation
 * @returns 
 * 
 */

export default function Login() { 
  //PC_LL_3 state and object variable
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const accounts = instance.getAllAccounts();
  const navigate = useNavigate();
//PC_LL_4 useEffect hooks to call the function or state change during the rendering
  useEffect(() => {

    
  }, [isAuthenticated]);

  // PC_LL_8,PC_LL_9 ,PC_LL_10 to handle the login operation
  const handleSignIn = async () => {

    await instance.loginPopup().then(()=>{
      navigate("/loginAuth");
      }).catch((err)=>{
        console.log(err);
        navigate('/');
      });
      
  };


  return (
    <>
      <div className="container-fluid login-container">
        <div className="row ">
          <div className=" col-lg-6 d-lg-block d-none p-0 bg-image bg-gradient-height  text-center position-relative my-0 rounded-0 align-self-center">
            <div className="mx-auto mb-5">
              <div className="">
                <img
                  src="Images/Login-image.svg"
                  alt="Login"
                  className=" pt-5"
                  style={{marginTop:'100px'}}
                />
              </div>
              <div className="login-para">
                
                {/* <p className="pt-5 font-24 font-semibold color-white"
                 style={{marginBottom:'100px'}} >
               
                </p> */}
                <p className="font-18 font-regular color-white mb-5">
                  <span className="d-block fw-bold mb-3"> Your One-Stop App Shop</span>
                Simplify your license purchase experience                </p>
              </div>
            </div>
          </div>
          {/*login form starts here */}
          <div className=" col-lg-6 col-md-12 col-sm-12 col-12 align-self-center">
            <div className="form-signup text-center ">
              <h5 className="font-bold font-22 mb-3">
                <img src="Images/logo.svg" className="me-2" alt="logo" />
                AvaHUB
              </h5>
              <h5 className="font-regular font-22 my-4">
              {/* Your One-Stop App Shop{" "} */}
                {/* <span className="font-bold font-22">AVAHUB!</span> */}
              </h5>
              <button
                className="btn  primary-btn w-100 font-14 font-semibold my-3"
                onClick={() => handleSignIn()}
              >
                Login
              </button>
            </div>
          </div>
          {/* login form ends here */}
        </div>
      </div>
    </>
  );
}
