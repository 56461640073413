import React, { useEffect, useState } from "react";
import {
  DeleteCustomerLicensePriceList,
  GetCustomerLicensePriceListById,
} from "../../services/customerService";
import { useLocation } from "react-router-dom";
import { CustomerLicenseData, userSessionData } from "../../interface";
import { ErrorLog } from "../../services/errorService";
import {
  sessionKeys,
  encryptStorage,
  formatAmount,
} from "../../configs/constant";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Norecords from "../norecords";
import { Button, Tooltip } from "@mui/material";
//** PC_NO_1, PC_NO_2, PC_NO_3, PC_NO_4, PC_NO_5, Fetch the Customer License Details */

function CustomerLicenseDetails() {
  const [CustomerLicenseDetails, setcustomerLicenseDetails] = useState<
    CustomerLicenseData[]
  >([
    {
      price_list_id: "",
      status: "",
      customer_id: "",
      product_id: "",
      product_name: "",
      sku_id: "",
      margin: "",
      term_duration: "",
      microsoft_price: "",
      reseller_price: "",
      is_promo: "",
      is_active: "",
    },
  ]);
  const [errorStatus, setErrorStatus] = useState<boolean>(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const location = useLocation();
  useEffect(() => {
    getCustomerLicensePriceListById();
  }, []);

  // Function to open the popover
  const handlePopoverOpen = (index: any) => {
    setHoveredIndex(index);
  };

  // Function to close the popover
  const handlePopoverClose = () => {
    setHoveredIndex(null);
  };
  //** PC_NO_28, PC_NO_29, PC_NO_30, PC_NO_31, PC_NO_32, PC_NO_33
  // to Fetch the Customer License price detils */
  async function getCustomerLicensePriceListById() {
    try {
      const customerRequest = {
        customer_id: location.state.customer_id,
      };

      const response = await GetCustomerLicensePriceListById(customerRequest);
      if (response.data.statusCode === 200) {
        console.log(response.data.responseData, "response.data.responseData");

        setcustomerLicenseDetails(response.data.responseData);
      }
    } catch (Error: any) {
      console.error("Error fetching data:", Error);
      const errorObject = {
        stack_name: "frontend_react",
        module_name: "customer_module",
        function_name: "getCustomerLicensePriceListById",
        error_message: `${Error?.message}`,
      };
      const request: any = await ErrorLog(errorObject);
    }
  }
  //** PC_NO_76, PC_NO_77, PC_NO_78, PC_NO_79, PC_NO_80, PC_NO_81, PC_NO_82.1
  // to perform the delete operation in grid wise */
  async function deleteLicensePriceList(price_list_id: any) {
    let sessionUserDetails: userSessionData | null = JSON.parse(
      encryptStorage.getItem(sessionKeys.userDetails) || "null"
    );
    const customerRequest = {
      customer_id: location.state.customer_id,
      price_list_id: price_list_id,
      user_id: sessionUserDetails?.user_id,
    };

    const response = await DeleteCustomerLicensePriceList(customerRequest);

    if (response.status) {
      getCustomerLicensePriceListById();
    }
  }

  return (
    <>
      <div
        className="tab-pane fade"
        id="license"
        role="tabpanel"
        aria-labelledby="license-tab"
      >
        <p className="font-18 font-semibold tertiary-color py-lg-3 ps-0 mb-0 d-flex">
          License Price List
          <span className="quote-number font-10 d-flex align-items-center primary-color-bg justify-content-center ms-2">
            {CustomerLicenseDetails?.length}
          </span>
        </p>
        <div className="row custom-row-height">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="table-responsive  rounded-3">
              <table className="table  themeTable ">
                <thead className="font-bold">
                  <tr>
                    <th className="ps-4 text-start">License</th>
                    <th className="text-start">Sku ID</th>
                    <th className="text-end">Margin</th>
                    <th className="text-start">Term Duration</th>
                    <th className="text-end">Microsoft Price</th>
                    <th className="text-end">Reseller Price</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody className="font-medium">
                  {CustomerLicenseDetails.map((item, index) => (
                    <tr className="border-bottom-0" key={index}>
                      <td
                        className="ps-4 text-start font-regular"
                        // title={item.product_name}
                      >
                        <Tooltip title={item.product_name} placement="top-end">
                          <Button className="text-fixer">
                            {item?.product_name?.length > 25
                              ? item?.product_name.substring(0, 25) + "..."
                              : item?.product_name}
                          </Button>
                        </Tooltip>
                        <>
                          {item.is_promo === "1" ? (
                            <>
                              <Tooltip
                                title="Promotions Included"
                                placement="top-end"
                              >
                                <img
                                  src="Images/tooltip-icon.svg"
                                  className="ms-2 custom-info-icon"
                                  alt="add-icon"
                                  title=""
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  data-bs-original-title="Promotions Included"
                                  aria-label="add item"
                                  aria-describedby={`tooltip${index}`}
                                  onMouseEnter={() => handlePopoverOpen(index)}
                                  onMouseLeave={handlePopoverClose}
                                />
                              </Tooltip>
                            </>
                          ) : null}
                        </>
                      </td>
                      <td
                        className="text-start font-regular"
                        title={item.sku_id}
                      >
                        {item.sku_id.length >= 8
                          ? item.sku_id.substring(0, 7) + "..."
                          : item.sku_id}
                      </td>
                      <td className="text-end font-regular">
                        {item.margin ? item.margin : "0"}%{" "}
                      </td>
                      <td className="text-start font-regular">
                        {item.term_duration}
                      </td>
                      <td className="text-end font-regular">
                        $
                        {formatAmount(
                          item.microsoft_price
                            ? Number(item.microsoft_price)
                            : 0.0
                        )}
                      </td>
                      <td className="text-end font-regular">
                        $
                        {formatAmount(
                          item.reseller_price
                            ? Number(item.reseller_price)
                            : 0.0
                        )}
                      </td>
                      <td className="text-center font-regular">
                        <span className="ms-3 cursor-pointer">
                          {location.state.mode == "view" ? (
                            <>
                              <img
                                src="Images/delete-dis-icon.svg"
                                alt="deleteIcon"
                                title="delete"
                              />
                            </>
                          ) : (
                            <>
                              {item.status == "1" ? (
                                <img
                                  src="Images/delete-icon.svg"
                                  alt="deleteIcon"
                                  title="Delete"
                                  onClick={() => {
                                    deleteLicensePriceList(item.price_list_id);
                                  }}
                                />
                              ) : (
                                <>
                                  <img
                                    src="Images/delete-dis-icon.svg"
                                    alt="deleteIcon"
                                    title="License must be Active or Suspended"
                                  />
                                </>
                              )}
                            </>
                          )}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {CustomerLicenseDetails.length <= 0 ? <Norecords /> : null}
          </div>
        </div>
        {/* </div> */}
      </div>
    </>
  );
}

export default CustomerLicenseDetails;
