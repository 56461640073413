import React, { useEffect, useState } from "react";
// import { GetCustomerDetails,GetCustomerDetailsForFilter } from '../../services/Customer/CustomerService';
import {
  GetCustomerDetails,
  GetCustomerDetailsForFilter,
} from "../../services/customerService";

import { sessionKeys, encryptStorage } from "../../configs/constant";
import { CustomerFilter, customerSessionData } from "../../interface";
import { defaultLimit } from "../../configs/constant";
import { useNavigate } from "react-router-dom";
import { Loader } from "../loader";
import Norecords from "../norecords";
// import { useData } from './DataContext';
//** PC_NO_1, PC_NO_2, PC_NO_3, PC_NO_4, PC_NO_5, Fetch the Customer Grid Details */

const CustomerGrid: React.FC = () => {
  const navigate = useNavigate();
  const [customerData, setCustomerData] = useState<any[]>([]);
  // const [sort_column, setSort_column] = useState<string>("company_name");
  // const [sort_order, setSort_order] = useState<string>("ASC");
  const [columnOrder, setColumnOrder] = useState("ASC");
  const [columnName, setColumnName] = useState("company_name");
  const [sorthide, setsorthide] = useState("company_name@ASC");
  const [search_value, setSearch_value] = useState<string>("");
  const [count, setCount] = useState<number>(defaultLimit); // Corrected the typo here (defalutLimit to defaultLimit)
  const [customerNameSort, setCustomerNameSort] = useState<boolean>(false);
  const [statusSort, setStatusSort] = useState<boolean>(false);
  const [subcriptionSort, setSubcriptionSort] = useState<boolean>(false);
  const [countrySort, setCountrySort] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [recordsCount, setRecordsCount] = useState<number>(0);
  const [showLoader, setShowLoader] = useState(false);
  const [showPopup, setShowpopup] = useState(false);

  const [customerFilter, setCustomerFilter] = useState<CustomerFilter>({
    companyName: [],
    isActive: [],
  });
  let filter = {
    customer_id: "",
    is_active: "",
  };
  const [filterDetails, setFilterDetails] = useState<any>(filter);

  useEffect(() => {
    fetchData();
  }, [columnOrder, columnName, count, sorthide]);

  // useEffect(() => {
  //   fetchDatas();
  // }, []);
//** PC_NO_82.47, PC_NO_82.48, PC_NO_82.49, PC_NO_82.50, PC_NO_82.51, PC_NO_82.52, PC_NO_82.53, PC_NO_82.54, PC_NO_82.55, PC_NO_82.56
// Fetch the Customer Details */
  async function fetchData(filter = filterDetails) {
    try {
      setShowLoader(true);
      const customerRequest = {
        search_value: search_value,
        Filter: filter,
        sortColumn: columnName,
        sortType: columnOrder,
        count: count,
      };

      const response = await GetCustomerDetails(customerRequest);

      if (response?.data?.status) {
        const data = response?.data?.responseData?.gridRecord;
        setTotalRecords(
          response?.data?.responseData?.totalRecords[0].total_record_count
        );
        setRecordsCount(
          response?.data?.responseData?.recordCount[0].record_count
        );
        setCustomerData(data);
        setShowLoader(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
//** PC_NO_82.57, PC_NO_82.58, PC_NO_82.59, PC_NO_82.60, PC_NO_82.61, PC_NO_82.62
// to Change the Customer details */
  async function changeCustomer(customer: any) {
    
    let customerDetails: customerSessionData | null = JSON.parse(
      encryptStorage.getItem(sessionKeys.customerDetails) || "null"
    );


    if (customerDetails) {
      customerDetails.customer_id = customer.customer_id;
      customerDetails.customer_Name = customer.company_name;
      customerDetails.customer_margin = customer.margin;
      customerDetails.logo_url = customer.logo_url;
      customerDetails.tax = customer.tax_certificate;
    }

    encryptStorage.setItem(
      sessionKeys.customerDetails,
      JSON.stringify(customerDetails)
    );
    window.location.href = `/dashboard`;
  }

  async function fetchDatas() {
    try {
      const response = await GetCustomerDetailsForFilter();
      const data = response.responseData;

      const companyName = Object.values(data.companyName).map(
        (item: any, index) => {
          return item.company_name;
        }
      );

      const isActive = Object.values(data.isActive).map((item: any, index) => {
        return item.is_active;
      });

      setCustomerFilter({
        companyName: companyName,
        isActive: isActive,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const onSearch = (e: any) => {
    if (e.key === "Enter") {
      fetchData();
    }
  };

  // const handleClear = () => {
  //   let obj = {
  //     company_name: "",
  //     is_active: "",
  //   };
  //   setFilterDetails({
  //     company_name: "",
  //     is_active: "",
  //   });
  //   fetchData(obj);
  // };
  // const { updateData } = useData();
//** PC_NO_82.63, PC_NO_82.64, PC_NO_82.65, PC_NO_82.66, PC_NO_82.67, PC_NO_82.68, PC_NO_82.69, PC_NO_82.70, PC_NO_82.71, PC_NO_82.72
// to perform the Sort operation */
  const sortClicked = (e: any) => {
    try {
      let sortID = e.target.id;
      let sortval = sortID.split("@");
      setColumnName(sortval[0]);
      setColumnOrder(sortval[1]);
      setsorthide(sortID);
      //setSearchValue("");
    } catch (e: any) {
      console.log(e.message);
    }
  };
//** PC_NO_82.73 to  perform the navigation operation */
  const HandleNavigator = (customerId: any, company_name: any) => {
  
    navigate("/customerdetails", {
      state: {
        mode: "view",
        customer_id: customerId,
        company_name: company_name,
      },
    });
  };
  return (
    <>
      {/* header starts */}
      {/* header ends */}
      {/* Admin starts */}
      <div className="container-fluid px-5">
        <div className="row justify-content-center">
          <div className="px-3">
            <div className="d-block d-md-flex flex-wrap justify-content-between align-items-center  mb-4 mt-3">
              {/* <div class> */}
              <h2 className="font-20 font-bold dark-blue-color d-flex mb-0">
                Customers
                <span className="custom-admin-batch  font-12 d-flex align-items-center justify-content-center ms-2 px-2">
                  {/* {totalRecords} */}
                  {recordsCount}
                </span>
                {/* </div> */}
              </h2>
              <div className="d-block d-md-flex">
                <div className="w-md-100 d-flex mb-3 mb-md-0">
                  {/* <span className="position-relative d-flex align-items-center w-100 me-4">
                    <input
                      type="search"
                      className="border-0 font-regular w-230 rounded-3 font-14 top-0 bottom-0 py-2 px-3 custom-input"
                      id="search"
                      placeholder="Search"
                      name="search_value"
                      value={search_value}
                      onChange={(e) => {
                        // onSearch(e as any);
                        setSearch_value(e.target.value);
                      }}
                      onKeyPress={(e) => {
                        onSearch(e);
                      }}
                    />

                    <span
                      className="position-absolute search-icon"
                      onClick={() => {
                        fetchData();
                      }}
                    >
                      <img src="Images/search.svg" alt="search-icon" />
                    </span>
                  </span> */}

                  <span className="position-relative d-flex align-items-center me-3 w-100">
                    <input
                      type="search"
                      className="border-0 font-regular w-230 cus-z-ind  rounded-2 font-14 top-0 bottom-0 py-2 px-3 custom-input"
                      id="search"
                      placeholder="Search"
                      name="search_value"
                      value={search_value}
                      onChange={(e) => {
                        // onSearch(e as any);
                        setSearch_value(e.target.value);
                      }}
                      onKeyPress={(e) => {
                        onSearch(e);
                      }}
                    />
                    <span className="position-absolute search-icon">
                      <img
                        src="Images/search.svg"
                        alt="search-icon"
                        onClick={() => {
                          fetchData();
                        }}
                      />
                    </span>
                  </span>
                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn secondary-btn custom-filter rounded-3 height-37"
                      onClick={() => {
                        setShowpopup(true);
                      }}
                    >
                      <img
                        src="Images/filter-active-icon.svg"
                        alt="Filter"
                        className="custom-filter-active-img"
                      />
                      <img
                        src="Images/Filter.svg"
                        alt="Filter"
                        className="custom-filter-img"
                      />
                    </button>
                    {showPopup ? (
                      <div
                        className="dropdown-menu dropdown-menu-end adv-dropdown p-3 mt-1 border-0 popup-w-337 show"
                        style={{
                          position: "absolute",
                          inset: "0px auto auto 0px",
                          margin: "0px",
                          transform: " translate(-340px, 42px)",
                        }}
                      >
                        <div className="row">
                          <div className="d-flex justify-content-between align-items-center mb-3">
                            <h6 className="font-16 font-semibold tertiary-color mb-0 ">
                              Filter
                            </h6>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={() => {
                                setShowpopup(false);
                              }}
                            />
                          </div>
                          {/* <div className="col-sm-12 col-md-12 mb-3">
                          <label
                            htmlFor="customername"
                            className="form-label font-14 font-semibold color-black"
                          >
                            Customer Name
                          </label>
                          <select
                            className="form-select custom-select custom-input font-regular font-14"
                            id="customername"
                            value={filterDetails.company_name}
                            onChange={(e) =>
                              setFilterDetails({
                                ...filterDetails,
                                company_name: e.target.value,
                              })
                            }
                          >
                            <option>Select</option>
                            {customerFilter.companyName ? (
                              customerFilter.companyName.map(
                                (val: any, index: any) => (
                                  <option key={index} value={val}>
                                    {val}
                                  </option>
                                )
                              )
                            ) : (
                              <option value="Loading">Loading...</option>
                            )}
                          </select>
                        </div> */}
                          <div className="col-sm-12 col-md-12 mb-5">
                            <label
                              htmlFor="status"
                              className="form-label font-14 font-semibold color-black"
                            >
                              Status
                            </label>
                            <select
                              className="form-select custom-select custom-input font-regular font-14"
                              id="status"
                              value={filterDetails.is_active}
                              onChange={(e) =>
                                setFilterDetails({
                                  ...filterDetails,
                                  is_active: e.target.value,
                                })
                              }
                            >
                              <option selected value="">
                                Select
                              </option>
                              <option value="1">Active</option>
                              <option value="0">Inactive</option>
                              {/* {customerFilter.isActive ? (
                              customerFilter.isActive.map(
                                (val: any, index: any) => (
                                  <option key={index} value={val}>
                                    {val === "1" ? "Active" : "InActive"}
                                  </option>
                                )
                              )
                            ) : (
                              <option value="Loading">Loading...</option>
                            )} */}
                            </select>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end">
                          <button
                            type="button"
                            className="btn text border-0 font-14 font-semibold me-3 shadow-none text-btn"
                            onClick={() => {
                              setFilterDetails(filter);
                              fetchData(filter);
                            }}
                          >
                            Clear
                          </button>
                          <button
                            type="button"
                            className="btn primary-btn border-0 font-14 font-semibold "
                            onClick={() => {
                              setShowpopup(false);
                              fetchData();
                            }}
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div class="tab-content" id="pills-tabContent"> */}
          {/* first tab starts here */}
          {/* <div class="tab-pane fade show active" id="pills-notstarted" role="tabpanel" >  <div class="row justify-content-center"> */}
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="table-responsive themetable-border rounded-2">
              <table className="table  themeTable mb-0 ">
                <thead className="font-bold">
                  <tr>
                    <th className="ps-4 text-start">
                      Customer Name
                      <span
                        className="ms-2 cursor-pointer"
                      // onClick={(e) => { sortClicked(e)
                      //   // setCustomerNameSort(!customerNameSort);
                      //   // setSort_column("company_name");
                      //   // setSort_order(customerNameSort ? "asc" : "desc");
                      // }}
                      >
                        <img
                          src="Images/sort-up-arrow.svg"
                          alt="sortarrow"
                          title="Sort"
                          id="company_name@ASC"
                          onClick={(e) => sortClicked(e)}
                          hidden={
                            sorthide == "company_name@DESC" || "" ? false : true
                          }
                        />

                        <img
                          src="Images/sort-arrow.svg"
                          alt="sortarrow"
                          title="Sort"
                          id="company_name@DESC"
                          onClick={(e) => sortClicked(e)}
                          hidden={
                            sorthide == "company_name@DESC" || "" ? true : false
                          }
                        />
                      </span>
                    </th>
                    <th className="text-end pe-5">
                      # Subscriptions
                      <span className="ms-2 cursor-pointer">
                        {/* <img
                          src={
                            subcriptionSort
                              ? "Images/sort-up-arrow.svg"
                              : "Images/sort-arrow.svg"
                          }
                          alt="sortarrow"
                          title="Sort"
                        /> */}

                        <img
                          src="Images/sort-up-arrow.svg"
                          alt="sortarrow"
                          title="Sort"
                          id="total_subscriptions@ASC"
                          onClick={(e) => sortClicked(e)}
                          hidden={
                            sorthide == "total_subscriptions@DESC" || ""
                              ? false
                              : true
                          }
                        />

                        <img
                          src="Images/sort-arrow.svg"
                          alt="sortarrow"
                          title="Sort"
                          id="total_subscriptions@DESC"
                          onClick={(e) => sortClicked(e)}
                          hidden={
                            sorthide == "total_subscriptions@DESC" || ""
                              ? true
                              : false
                          }
                        />
                      </span>
                    </th>
                    <th className="text-start">
                      Country
                      <span
                        className="ms-2 cursor-pointer"
                      // onClick={(e) => {
                      //   setCountrySort(!countrySort);
                      //   setSort_column("country");
                      //   setSort_order(countrySort ? "asc" : "desc");
                      // }}
                      >
                        {/* <img
                          src={
                            countrySort
                              ? "Images/sort-up-arrow.svg"
                              : "Images/sort-arrow.svg"
                          }
                          alt="sortarrow"
                          title="Sort"
                        /> */}

                        <img
                          src="Images/sort-up-arrow.svg"
                          alt="sortarrow"
                          title="Sort"
                          id="country@ASC"
                          onClick={(e) => sortClicked(e)}
                          hidden={
                            sorthide == "country@DESC" || "" ? false : true
                          }
                        />

                        <img
                          src="Images/sort-arrow.svg"
                          alt="sortarrow"
                          title="Sort"
                          id="country@DESC"
                          onClick={(e) => sortClicked(e)}
                          hidden={
                            sorthide == "country@DESC" || "" ? true : false
                          }
                        />
                      </span>
                    </th>
                    <th className="text-start">
                      Status
                      <span
                        className="ms-2 cursor-pointer"
                      // onClick={(e) => {
                      //   setStatusSort(!statusSort);
                      //   setSort_column("is_active");
                      //   setSort_order(statusSort ? "asc" : "desc");
                      // }}
                      >
                        {/* <img
                          src={
                            statusSort
                              ? "Images/sort-up-arrow.svg"
                              : "Images/sort-arrow.svg"
                          }
                          alt="sortarrow"
                          title="Sort"
                        /> */}

                        <img
                          src="Images/sort-up-arrow.svg"
                          alt="sortarrow"
                          title="Sort"
                          id="csp_status@ASC"
                          onClick={(e) => sortClicked(e)}
                          hidden={
                            sorthide == "csp_status@DESC" || "" ? false : true
                          }
                        />

                        <img
                          src="Images/sort-arrow.svg"
                          alt="sortarrow"
                          title="Sort"
                          id="csp_status@DESC"
                          onClick={(e) => sortClicked(e)}
                          hidden={
                            sorthide == "csp_status@DESC" || "" ? true : false
                          }
                        />
                      </span>
                    </th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody className="font-medium">
                  {customerData?.map((customer: any, index: any) => (
                    <tr key={index} className="border-bottom-0">
                      <td
                        className="ps-4 text-start font-regular link-blue cursor-pointer"
                        onClick={() => {
                          HandleNavigator(
                            customer.customer_id,
                            customer.company_name
                          );
                        }}
                      >
                        {customer.company_name ? customer.company_name : "-"}
                      </td>
                      <td className="text-end font-regular pe-5">
                        {customer.total_subscriptions}
                      </td>
                      <td className="text-start font-regular">
                        {customer.country ? customer.country : "-"}
                      </td>

                      <td
                        className={
                          customer.csp_status == "1"
                            ? "color-light-green text-start"
                            : "color-grey text-start"
                        }
                      >
                        <span className={
                          customer.csp_status == "1"
                            ? "custom-green-dot"
                            : "custom-grey-dot"
                        } ></span>
                        {customer.csp_status == "1" ? "Active" : "Inactive"}
                      </td>
                      <td className="text-center font-regular">
                        <span className="cursor-pointer">
                          <img
                            src="Images/edit-icon.svg"
                            alt="editIcon"
                            title="Edit"
                            onClick={() =>
                              navigate("/customerdetails", {
                                state: {
                                  mode: "edit",
                                  customer_id: customer?.customer_id,
                                  company_name: customer?.company_name,
                                },
                              })
                            }
                          />
                        </span>
                        <span className="ms-3 cursor-pointer">
                          <img
                            src="Images/Next-icon.svg"
                            alt="nextIcon"
                            title="Next"
                            onClick={() => {
                              changeCustomer(customer);
                            }}
                          />
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {recordsCount > customerData.length ? (
              <div className="d-flex justify-content-center mb-5">
                <button
                  type="button"
                  className="btn tertiary-btn border-0 font-14 font-semibold "
                  onClick={() => {
                    setCount(count + defaultLimit);
                  }}
                >
                  Load More
                </button>
              </div>
            ) : (
              <></>
            )}

            {customerData.length <= 0 ? <Norecords /> : null}
          </div>
        </div>

        {showLoader ? <Loader /> : <></>}
      </div>

      {/* </div>
    </div>
  </div> */}
      {/* Admin Ends */}
      {/* <footer className="fixed-bottom">
        <div className="tertiary-color-bg font-12 sticky-footer py-1">
          <span className="color-white font-12 font-semibold d-flex justify-content-center">
            © Avasoft Inc 2023
          </span>
        </div>
      </footer> */}
    </>
  );
};

export default CustomerGrid;
