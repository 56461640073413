
import { client } from "../client";
import { backendurl as url,customerUrl } from "../configs/constant";

//**PC_NO_83,PC_NO_84, PC_NO_85, PC_NO_86,PC_NO_87,PC_NO_88,PC_NO_89,PC_NO_90, 
// Fetch the Customer Details*/
export async function GetCustomerDetails(data: {} | undefined) {

  let Request = {
    url: url + customerUrl.getCustomerDetails,
    headers: {
      "content-type": "application/json",
    },
    method: "post",
    body: data,
  };

  let response = await client(Request);


  return response;
}

export async function GetCustomerDetailsForFilter() {

  let Request = {
    url: url + customerUrl.getCustomerDetailsForFilter,
    headers: {
      "content-type": "application/json",
    },
    method: "get",
  };

  let response = await client(Request);

  const result = await response.data;
  return result;
}

//** PC_NO_87, PC_NO_88, PC_NO_89, PC_NO_90
//Fetch the Customer Basic details */
export async function GetCustomerBasicDetailsByid(data: {} | undefined) {

  
  let Request = {
    url: url + customerUrl.getCustomerBasicDetailsByid,
    headers: {
      "content-type": "application/json",
    },
    method: "post",
    body: data,
  };

  let response = await client(Request);  
  return response;
}
//** PC_NO_91, PC_NO_92, PC_NO_93, PC_NO_94 
//Fetch the Customer Brand details */
export async function GetCustomerBrandDetails(data: {} | undefined) {


  let Request = {
    url: url + customerUrl.getCustomerBrandDetails,
    headers: {
      "content-type": "application/json",
    },
    method: "post",
    body: data,
  };

  let response = await client(Request);

  const result = await response.data;
  return result;
}
//PC_NO_94, PC_NO_95, PC_NO_96, PC_NO_97, PC_NO_98
// Fetch the Customer Contact details By ID
export async function GetCustomerContactDetailsById(data: {}) {

  let Request = {
    url: url + customerUrl.getCustomerContactDetailsById,
    headers: {
      "content-type": "application/json",
    },
    method: "post",
    body: data,
  };

  let response = await client(Request);

  // const result = await response.data;
  return response;
}
//PC_NO_99, PC_NO_100, PC_NO_101, PC_NO_102
// Fetch the Customer legal details By ID 
export async function GetCustomerLegalDetailsById(data:any= {}) {  


  

  let Request = {
    url: url + customerUrl.getCustomerLegalDetailsById,
    headers: {
      "content-type": "application/json",
    },
    method: "post",
    body: data,
  };

  let response = await client(Request);
  return response;
}
//PC_NO_103, PC_NO_104, PC_NO_105, PC_NO_106
// Fetch the Customer License Price list details BY ID 
export async function GetCustomerLicensePriceListById(data:any= {} ) {    
  let Request = {
    url: url + customerUrl.getCustomerLicensePriceListById,
    headers: {
      "content-type": "application/json",
    },
    method: "post",
    body: data,
  };
  let response = await client(Request);
  return response;
}
//PC_NO_107, PC_NO_108, PC_NO_109, PC_NO_110,
// Fetch the Customer notification details By ID 
export async function GetCustomerNotificationDetailsById(data: {} | undefined) {

  let Request = {
    url: url + customerUrl.getCustomerNotificationDetailsById,
    headers: {
      "content-type": "application/json",
    },
    method: "post",
    body: data,
  };

  let response = await client(Request);

  const result = response.data;
  return result;
}
//PC_NO_111, PC_NO_112, PC_NO_113, PC_NO_114,
// Update the basic details of the customer by Id
export async function UpdateBasicDetailsByid(data: {} | undefined) {

  let Request = {
    url: url + customerUrl.updateCustomerBasicDetailsByid,
    headers: {
      "content-type": "application/json",
    },
    method: "post",
    body: data,
  };

  let response = await client(Request);

  const result = response.data;
  return result;
}
//PC_NO_115, PC_NO_116, PC_NO_117, PC_NO_118
//Update the Brand element details of the Customer 
export async function UpdateCustomerBrandDetails(data: {} | undefined) {

  let Request = {
    url: url + customerUrl.updateCustomerBrandDetails,
    headers: {
      "content-type": "application/json",
    },
    method: "post",
    body: data,
  };

  let response = await client(Request);
  return response.data;
}
//PC_NO_119, PC_NO_120, PC_NO_121, PC_NO_122,
//Update the Contact details of the Customer By ID
export async function UpdateCustomerContactDetails(data:any = {}) {

  let Request = {
    url: url + customerUrl.updateCustomerContactDetails,
    headers: {
      "content-type": "application/json",
    },
    method: "post",
    body: data,
  };

  let response = await client(Request);

  const result = response.data;
  return result;
}
//PC_NO_130.1, PC_NO_130.2, PC_NO_130.3, PC_NO_130.4, PC_NO_130.5,
//Update the notification details of the Customer By ID 
export async function UpdateCustomerNotificationDetails(data:any={}) {

  let Request = {
    url: url + customerUrl.updateCustomerNotificationDetails,
    headers: {
      "content-type": "application/json",
    },
    method: "post",
    body: data,
  };

  let response = await client(Request);

  const result = response.data;
  return result;
}
//PC_NO_127, PC_NO_128, PC_NO_129, PC_NO_130,
// Delete the license Price list By ID of the Customer 
export async function DeleteCustomerLicensePriceList(data: {} | undefined) {

  let Request = {
    url: url + customerUrl.deleteCustomerLicensePriceList,
    headers: {
      "content-type": "application/json",
    },
    method: "post",
    body: data,
  };

  let response = await client(Request);

  const result = response.data;
  return result;
}



//PC_NO_123, PC_NO_124, PC_NO_125, PC_NO_126,
//Update the Custoemr Legal Details by ID
export async function updateCustomerLegalDetailsById(data:any ={}) {

  let Request = {
    url: url + customerUrl.updateCustomerLegalDetailsById,
    headers: {
      "content-type": "application/json",
    },
    method: "post",
    body: data,
  };

  let response = await client(Request);

  const result = response.data;
  return result;
}

//** PC_NO_130.2, PC_NO_130.3, PC_NO_130.4, PC_NO_130.5
// To trigger the manual Sync to fetch the latest customer details from the partner center*/
export async function manualSync(data:any ={}) {

  let Request = {
    url: url + customerUrl.manualSync,
    headers: {
      "content-type": "application/json",
    },
    method: "post",
    body: data,
  };

  let response = await client(Request);
  const result = response.data;
  return result;
}
