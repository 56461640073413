import React from "react";
import { useState, useEffect, useRef } from "react"; //ps_I_2
import {
  getproductsApi,
  getCategoriesApi,
  removeCategoryApi,
  addCategoryApi,
  addNewCategoryApi,
  removeCancelledCategory,
  getavailablityApi,
  getTermsApi,
  getAddonsAPi,
} from "../../services/inventoryservice";
import {
  allowOnlyNumberAndCharacter,
  removeHtmlTags,
} from "../../configs/constant";
import { OnlyText } from "../../configs/constant";
import InfiniteScroll from "react-infinite-scroll-component";
import { defaultLimit } from "../../configs/constant";
import { useNavigate, useLocation } from "react-router-dom";
import { Loader } from "../loader";
import { customerSessionData } from "../../interface";
import { sessionKeys } from "../../configs/constant";
import { encryptStorage } from "../../configs/constant";
import moment from "moment";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Norecords from "../norecords";
import CustomerDetails from "../customer/customerDetails";
import { Button, Tooltip } from "@mui/material";

//** ps_I_1, ps_I_2, ps_I_3, ps_I_3, ps_I_4, ps_I_5, ps_I_6, ps_I_7, ps_I_8, ps_I_9, ps_I_10, ps_I_11, ps_I_12, ps_I_13, ps_I_14, ps_I_15, ps_I_16, ps_I_17, ps_I_18, ps_I_19, ps_I_20, ps_I_21, ps_I_22, ps_I_23, ps_I_24, ps_I_25, ps_I_26, ps_I_27, ps_I_28, ps_I_29, ps_I_30, ps_I_31, ps_I_32, ps_I_33, ps_I_34, ps_I_35, ps_I_36, ps_I_37, ps_I_38, ps_I_39, ps_I_40, ps_I_41, ps_I_42, ps_I_43, ps_I_44, ps_I_45, ps_I_46, ps_I_47, ps_I_48, ps_I_49, ps_I_50, ps_I_51, ps_I_52, ps_I_53, ps_I_54, ps_I_55,
//To render the Inventory card  ***/
export default function Inventory() {
  const navigate = useNavigate();
  let location = useLocation();
  let lastCategoryId: number | null = null;
  //ps_I_3
  let products = {
    categoryID: 0,
    categoryName: "all",
  };
  //ps_I_4
  // const initialRender = useRef(false);
  let customerDetails: customerSessionData | null = JSON.parse(
    encryptStorage.getItem(sessionKeys.customerDetails) || "null"
  );

  const [openAccordion, setOpenAccordion] = useState<number | null>(0);
  const [getallProducts, setgetallProducts] = useState(products);
  const [bindProducts, setbindproducts] = useState<any>([]);
  const [loader, settloader] = useState(false);
  const [categories, setcategories] = useState<any>([]);
  const [oldCategories, setoldcategories] = useState<any>([]);
  const [checkedCategories, setcheckedCategories] = useState(false);
  const [uncheckedCategories, setuncheckedCategories] = useState(false);
  const [categoryId, setcategoryId] = useState<any>([]);
  const [productId, setProductsId] = useState<any>([]);
  // const [hasMounted, sethasMounted] = useState(false);
  const [rowCount, setrowCount] = useState<number>(0);
  const [searchCat, setsearchCat] = useState("");
  const [searchProducts, setsearchproducts] = useState<string>("");
  const [isSearchPerformed, setIssearchPerformed] = useState<boolean>(false);
  const [categoryDropdown, setcategoryDropdown] = useState(false);
  const [categoryPopup, setcategoryPopup] = useState<boolean>(false);
  const [Newcategory, setNewCategory] = useState<string>("");
  const [showPromotions, setshowPomotions] = useState(false);
  const [promotionsArray, setpromotionsArray] = useState<any>([]);
  const [isCustomer, setisCustomer] = useState<boolean>(false);
  // const [displayremoveButton, setdisplayremoveButton] = useState(false);
  // const [accordionButtonsState, setAccordionButtonsState] = useState<any>({});
  const [accordionButtonsState, setAccordionButtonsState] = useState<any>([]);
  const [addedCounts, setAddedCounts] = useState<any>({});
  const [availablityArr, setavailablityArr] = useState<any>([]);
  const [rerenderComponent, setrerenderComponent] = useState(false);
  const [count, setcount] = useState(false);
  const [checkCustomer, setcheckCustomer] = useState(
    location?.state?.customerId
  );
  const [addon, setaddon] = useState([]);
  const [isaddon, setisaddon] = useState(false);
  const [addonName, setaddonName] = useState("");
  const [availnotpresent, setavailnotpresent] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const [selectedProductCount, setselectedProductCount] = useState<any>(0);
  const [allcount, setallcount] = useState<any>(0);
  const [refreshCategory, setrefreshCategory] = useState(false);
  const [isOpenAccordions, setIsOpenAccordions] = useState(() => {
    const initialState = Array(bindProducts.length).fill(false);
    initialState[0] = true; // Set the first item to true
    return initialState;
  });
  const [data, setdata] = useState(true);
  const [DescriptionsDetaios, setDescriptionsDetails] = useState<any>();

  // useEffect(() => {
  //   // Bootstrap popover initialization code here
  //   var popoverTriggerList = [].slice.call(
  //     document.querySelectorAll('[data-bs-toggle="popover"]')
  //   );
  //   var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
  //     return new bootstrap.Popover(popoverTriggerEl);
  //   });

  // }, [])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    setcheckCustomer(location?.state?.customerId);

    if (location?.state?.customerId || customerDetails?.customer_id) {
      setisCustomer(true);
    }
    //ps_I_5
    getAllProducts();
  }, [rowCount]);

  const value = new URL(window.location.toString()).searchParams.get(
    "editquote"
  );

  // useEffect(() => {
  //   if (customerDetails?.customer_id) {
  //     const updatedState = {
  //       mode: "customer",
  //       customerId: customerDetails?.customer_id,
  //       customerName: customerDetails?.customer_Name,
  //       customerType: customerDetails?.customer_id
  //         ? "Existing customer"
  //         : "new customer",
  //     };

  //     const isLocationChanged =
  //       location.state &&
  //       (location.state.customerId !== updatedState.customerId ||
  //         location.state.customerName !== updatedState.customerName ||
  //         location.state.customerType !== updatedState.customerType);

  //     if (isLocationChanged) {
  //       navigate(location.pathname, { state: updatedState });
  //     }
  //   }
  // }, [customerDetails, location]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      addCategory(categoryId, productId);
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [checkedCategories]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      removeUncheckedCategory(categoryId, productId);
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [uncheckedCategories]);

  useEffect(() => {
    if (typeof location.state !== "undefined" && location.state !== null) {
      if (Object.keys(location?.state)?.length) {
        if ("cart" in location?.state) {
          setavailablityArr(location.state.cart);
        }
      }
    }
  }, []);

  useEffect(() => {
    getCategory();
  }, [refreshCategory]);
  //** ps_I_28, ps_I_29, ps_I_30, ps_I_31, ps_I_32, ps_I_33 Fetch the categories */
  async function getCategory() {
    let category = await getCategoriesApi();
    if (category.data.statusCode == 200) {
      setcategories(category.data.responseData.data);
      setoldcategories(category.data.responseData.data);
      setallcount(category.data.responseData.allCOunt);
      setselectedProductCount(category.data.responseData.productscount);
    }
  }

  async function getAllProducts(
    row = rowCount,
    isSearch = false,
    catId = getallProducts.categoryID
  ) {
    // if (searchProducts.length == 0) {
    settloader(true);
    //ps_I_6
    searchProducts ? setIssearchPerformed(true) : setIssearchPerformed(false);
    let prod = {
      categoryID: catId,
      search: searchProducts,
      rowsCount: row,
    };
    //** ps_I_6, ps_I_7, ps_I_8, ps_I_9 Fetch the Product Details */
    let product = await getproductsApi(prod);
    let category = await getCategoriesApi();
    if (category.data.statusCode == 200) {
      setcategories(category.data.responseData.data);
      setoldcategories(category.data.responseData.data);
      setallcount(category.data.responseData.allCOunt);
      setselectedProductCount(category.data.responseData.productscount);
    }
    if (product.data.statusCode == 200) {
      let ExistingProducts: any = bindProducts;

      let result = product.data.count.count / (rowCount + 10);
      setcount(result <= 1 ? false : true);
      if (isSearch) {
        setbindproducts(product?.data?.responseData);
      } else {
        ExistingProducts?.push(...product?.data?.responseData);
        setbindproducts(ExistingProducts);
      }

      settloader(false);
      // setcount(product.data.responseData)
    }
    // }
  }

  const parseHTML: any = (htmlString: string) => {
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    return Array.from(doc.body.childNodes);
  };
  //** ps_I_10, ps_I_11, ps_I_12, ps_I_13 To perform the search operation */
  async function handleSearch(row = rowCount) {
    //searchProducts.length < 0 && isSearchPerformed && getAllProducts(0,true)

    if (searchProducts.length <= 0) {
      isSearchPerformed && getAllProducts(0, true);
    } else if (searchProducts.length > 0) {
      getAllProducts(0, true);
    }
  }

  const DescriptionComponent = (val: any) => {
    const processedDescription = removeHtmlTags(val?.description);
    const truncatedDescription =
      processedDescription?.length > 40
        ? processedDescription?.substring(0, 39) + "..."
        : processedDescription;
    setDescriptionsDetails(val);

    setdata(true);
  };

  const provisionedCustomer = (sku: any) => {
    return new Set(sku?.flatMap((item: any) => item?.provisionedTo))?.size;
  };
  //** ps_I_36,ps_I_37,ps_I_38,ps_I_39 remove the product category */
  async function removeUncheckedCategory(val: any, dat: any) {
    // setrefreshCategory(!refreshCategory)

    // settloader(true);
    let obj = {
      categoryId: val,
      productId: dat,
    };
    if (val.length >= 1 && dat.length >= 1) {
      let removeCat = await removeCategoryApi(obj);
      if (removeCat.data.statusCode == 200) {
        // setrefreshCategory(true);
        setcategoryId([]);
        setProductsId([]);
      }
      setcategoryId([]);
      setProductsId([]);
    }

    // settloader(false);
  }
  //** ps_I_40, ps_I_41, ps_I_42, ps_I_43, ps_I_44, ps_I_45, ps_I_46, ps_I_47 add the product into the category */
  async function addCategory(val: any, dat: any) {
    // settloader(true);
    let obj = {
      categoryId: val,
      productId: dat,
    };
    //removed for speeding
    // setrefreshCategory(!refreshCategory);
    if (val.length >= 1 && dat.length >= 1) {
      let addCat = await addCategoryApi(obj);
      if (addCat.data.statusCode == 200) {
        setcategoryId([]);
        setProductsId([]);

        // getAllProducts();
        // settloader(false);
      }
      setcategoryId([]);
      setProductsId([]);
    }
  }
  //** ps_I_64, ps_I_65, ps_I_66, ps_I_67, ps_I_68, ps_I_69
  //to perform the hide and show operation for availability */
  function hideShowAdd(productId: string, skuId: string) {
    let show: any = false;
    let data = availablityArr?.filter((val: any, i: number) => {
      return val.product.id == productId && val.sku.id == skuId;
    });
    data.length > 0 ? (show = true) : (show = false);
    return show;
  }

  async function removeCategory(categoryID: any, category_name: string) {
    // settloader(true)

    let payload = {
      categoryId: categoryID,
    };
    setrefreshCategory(!refreshCategory);

    let removecategory = await removeCancelledCategory(payload);

    if ((removecategory.data.statusCode = 200)) {
      setbindproducts((bindproducts: any) => {
        const updatedProducts = bindproducts.map((dats: any) => {
          const filteredData = dats.category.filter(
            (dat: any) => dat !== category_name
          );
          return filteredData;
        });
        return updatedProducts;
      });
      const updatedCategories = categories.filter(
        (category: any) => category.category_id !== categoryID
      );
      setcategories(updatedCategories);
      setgetallProducts((prev: any) => ({
        ...prev,
        categoryID: 0,
        categoryName: "all",
      }));

      // setbindproducts((bindproducts: any) => {
      //   // Iterate through each item in bindproducts
      //   const updatedProducts = bindproducts.map((dats: any) => {
      //     // Use filter to remove specific category_name from each dats array
      //     const filteredData = dats.filter((dat: any) => dat !== category_name);
      //     return filteredData; // Return the filtered array for this dats
      //   });
      //   return updatedProducts; // Return the updated bindproducts array
      // });
    }
  }
  //** ps_I_48, ps_I_49, ps_I_50, ps_I_51, ps_I_52, ps_I_53, ps_I_54, ps_I_55 Fetch the availability of the Product */
  async function getAvailablity(
    productId: any,
    avalablity_url: any,
    price: any,
    ispromo: boolean,
    promotions: any,
    logo: any
  ) {
    settloader(true);
    // setAddedCounts((prevCounts: any) => ({
    //   ...prevCounts,
    //   [productId]: (prevCounts[productId] || 0) + 1,
    // }));
    let payload = {
      availablityUrl: avalablity_url,
    };
    let getTerms = await getTermsApi(payload);

    // let availablity = await getavailablityApi(payload);
    if (getTerms.data.statusCode == 200) {
      if (getTerms?.data?.availablity[0]?.items?.length > 0) {
        availablityArr.push({
          ...getTerms?.data?.availablity[0]?.items[0],
          MyTerms: [...getTerms?.data?.termDuration],
          priceDetails: price,
          margin: customerDetails?.customer_margin,
          is_promo: ispromo,
          promotions: [...promotions],
          promoEligibility: false,
          prmotionOfferCust: false,
          EligiblitypromoDetails: [],
          logoUrl: logo,
        });
      } else {
        setavailnotpresent(true);
      }

      setrerenderComponent(!rerenderComponent);

      settloader(false);
    }
  }
  //** ps_I_56, ps_I_57, ps_I_58, ps_I_59, ps_I_60, ps_I_61, ps_I_62, ps_I_63
  // remove the availablity of the product */
  function removeAvailablity(product_id: string, skuId: string) {
    setavailablityArr((prevData: any) => {
      const data = prevData.filter((dats: any) => {
        return !(dats.productId === product_id && dats.skuId === skuId);
      });

      return data;
    });
    setrerenderComponent(!rerenderComponent);
  }

  // function bindCheckbox(e: any, index: any, dats: any, val: any, i: any) {
  //   if (e.target.checked) {
  //     setcategories((prevCategories: any) => {
  //       const updatedCategories = [...prevCategories];
  //       updatedCategories[index] = {
  //         ...updatedCategories[index],
  //         product_id: [...updatedCategories[index].product_id, val.product_id],
  //       };
  //       return updatedCategories;
  //     });
  //     setcheckedCategories(!checkedCategories);
  //     setcategoryId((prevCategoryId: any) => [
  //       ...prevCategoryId,
  //       dats.category_id,
  //     ]);

  //     setProductsId((prevProductId: any) => [...prevProductId, val.product_id]);

  //     // addCategory(
  //     //   dats.category_id,
  //     //   val.product_id
  //     // );
  //   } else {
  //     setcategories((prevCategories: any) => {
  //       const updatedCategories = [...prevCategories];
  //       updatedCategories[index] = {
  //         ...updatedCategories[index],
  //         product_id: updatedCategories[index].product_id.filter(
  //           (id: any) => id !== val.product_id
  //         ),
  //       };
  //       return updatedCategories;
  //     });
  //     setuncheckedCategories(!uncheckedCategories);
  //     if (categoryId.includes(dats.category_id)) {
  //       setcategoryId((prevCategoryId: any) =>
  //         prevCategoryId.filter((id: any) => id !== dats.category_id)
  //       );

  //       setProductsId((prevProductId: any) =>
  //         prevProductId.filter((id: any) => id !== val.product_id)
  //       );
  //     } else {
  //       setcategoryId((prevCategoryId: any) => [
  //         ...prevCategoryId,
  //         dats.category_id,
  //       ]);

  //       setProductsId((prevProductId: any) => [
  //         ...prevProductId,
  //         val.product_id,
  //       ]);
  //     }

  //     // removeCategory(
  //     //   dats.category_id,
  //     //   val.product_id
  //     // );
  //   }

  //   // setbindproducts((prevCategories: any) => {
  //   //   const updatedCategories:any = [...prevCategories];
  //   //   updatedCategories[i] = {
  //   //     ...updatedCategories[i],
  //   //     category: e?.target.checked
  //   //       ? [...updatedCategories[i].category, dats.category_name]
  //   //       : updatedCategories[i].category.filter(
  //   //           (category: any) => category !== dats.category_name
  //   //         ),
  //   //   };
  //   //   return updatedCategories;
  //   // });
  //   setbindproducts((prevCategories: any) => {
  //     const updatedCategories: any = [...prevCategories];
  //     if (e?.target.checked) {
  //       // Checkbox is checked, add dats.category_name to the category array
  //       updatedCategories[i] = {
  //         ...updatedCategories[i],
  //         category: [...updatedCategories[i].category, dats.category_name],
  //       };
  //     } else {
  //       // Checkbox is unchecked, remove dats.category_name from the category array
  //       updatedCategories[i] = {
  //         ...updatedCategories[i],
  //         category: updatedCategories[i].category.filter(
  //           (category: any) => category !== dats.category_name
  //         ),
  //       };
  //     }
  //     return updatedCategories;
  //   });
  // }

  //** ps_I_70, ps_I_71, ps_I_72, ps_I_73
  //bind the check box details */
  function bindCheckbox(e: any, index: any, dats: any, val: any, i: any) {
    if (e.target.checked) {
      setcategories((prevCategories: any) => {
        const updatedCategories = [...prevCategories];
        updatedCategories[index] = {
          ...updatedCategories[index],
          product_id: [...updatedCategories[index].product_id, val.product_id],
        };
        return updatedCategories;
      });
      setcategoryId((prevCategoryId: any) => [
        ...prevCategoryId,
        dats.category_id,
      ]);
      setProductsId((prevProductId: any) => [...prevProductId, val.product_id]);
      setcheckedCategories((prevCheckedCategories) => !prevCheckedCategories);
      setbindproducts((prevCategories: any) => {
        const updatedCategories: any = [...prevCategories];

        // Checkbox is checked, add dats.category_name to the category array
        updatedCategories[i] = {
          ...updatedCategories[i],
          category: [...updatedCategories[i].category, dats.category_name],
          category_id: [...updatedCategories[i].category_id, dats.category_id],
        };

        return updatedCategories;
      });
    } else {
      setcategories((prevCategories: any) => {
        const updatedCategories = [...prevCategories];
        updatedCategories[index] = {
          ...updatedCategories[index],
          product_id: updatedCategories[index].product_id.filter(
            (id: any) => id !== val.product_id
          ),
        };
        return updatedCategories;
      });

      if (categoryId.includes(dats.category_id)) {
        setcategoryId((prevCategoryId: any) =>
          prevCategoryId.filter((id: any) => id !== dats.category_id)
        );
        setProductsId((prevProductId: any) =>
          prevProductId.filter((id: any) => id !== val.product_id)
        );
      } else {
        setcategoryId((prevCategoryId: any) => [
          ...prevCategoryId,
          dats.category_id,
        ]);
        setProductsId((prevProductId: any) => [
          ...prevProductId,
          val.product_id,
        ]);
      }
      setuncheckedCategories(
        (prevUncheckedCategories) => !prevUncheckedCategories
      );
      setbindproducts((prevCategories: any) => {
        const updatedCategories: any = [...prevCategories];

        // Checkbox is unchecked, remove dats.category_name from the category array
        updatedCategories[i] = {
          ...updatedCategories[i],
          category_id: updatedCategories[i].category_id.filter(
            (category: any) => Number(category) !== Number(dats.category_id)
          ),
          category: updatedCategories[i].category.filter(
            (category: any) =>
              category.trim().toLowerCase() !==
              dats.category_name.trim().toLowerCase()
          ),
        };
        return updatedCategories;
      });
    }

    // setbindproducts((prevCategories: any) => {
    //   const updatedCategories: any = [...prevCategories];
    //   if (e?.target?.checked) {
    //     // Checkbox is checked, add dats.category_name to the category array
    //     updatedCategories[i] = {
    //       ...updatedCategories[i],
    //       category: [...updatedCategories[i].category, dats.category_name],
    //       category_id: [...updatedCategories[i].category_id, dats.category_id],
    //     };
    //   } else {
    //     // Checkbox is unchecked, remove dats.category_name from the category array
    //     updatedCategories[i] = {
    //       ...updatedCategories[i],
    //       category: updatedCategories[i].category.filter(
    //         (category: any) =>
    //           category.trim().toLowerCase() !==
    //           dats.category_name.trim().toLowerCase()
    //       ),
    //       category_id: updatedCategories[i].category_id.filter(
    //         (category: any) => category !== dats.category_id
    //       ),
    //     };
    //   }
    //   return updatedCategories;
    // });
  }

  //** ps_I_14, ps_I_15, ps_I_16, ps_I_17 Fetch the addon products */
  async function getAddons(pre_requisite_skus: any) {
    settloader(true);
    let payload = {
      pre_requisite_skus: pre_requisite_skus,
    };

    let addon = await getAddonsAPi(payload);

    if (addon.data.statusCode == 200) {
      setaddon(addon.data.responseData);
      settloader(false);
      setisaddon(true);
    }
  }
  //** ps_I_21, ps_I_22, ps_I_23, ps_I_24, ps_I_25 add the new category product */
  async function addNewCategory() {
    let newcategory = {
      newCategory: Newcategory,
    };

    let category = await addNewCategoryApi(newcategory);
    if (category.data.statusCode == 200) {
      setNewCategory("");
      setcategoryPopup(false);
      getAllProducts();
    }
  }
  //** ps_I_74, ps_I_75, ps_I_76, ps_I_77, ps_I_78, ps_I_79, ps_I_80, ps_I_81, ps_I_82, ps_I_83
  // show the product details */
  function showProducts() {
    return (
      <>
        {bindProducts?.map((val: any, index: number) => {
          const isOpen = isOpenAccordions[index];

          let filteredData = availablityArr.filter((data: any) => {
            return data?.product?.id === val?.product_id;
          });

          const accordionIndex = `accordion-${index}`;

          // const selectedSkus = val?.sku?.filter((_:any, skuIndex:number) => accordionButtonsState[`${accordionIndex}-sku-${skuIndex}`]);
          return (
            <>
              <tr className="border-rad-row active custom-inventory-spacing">
                <td className="text-center vertical-align-middle">
                  <span>
                    <img
                      src="Images/accordion-arrow.svg"
                      alt="Arrow"
                      // className="cursor-pointer collapsed accordion-arrow"
                      // data-bs-toggle="collapse"
                      // data-bs-target="#customer1"
                      className={`cursor-pointer accordion-arrow ${
                        isOpen ? "" : "collapsed"
                      }`}
                      data-bs-target={`#customer${index + 1}`}
                      aria-expanded={isOpen ? "true" : "false"}
                      onClick={() => {
                        const updatedAccordions = [...isOpenAccordions];
                        updatedAccordions[index] = !updatedAccordions[index];
                        setIsOpenAccordions(updatedAccordions);
                      }}
                    />
                  </span>
                </td>
                <td className="text-start w-25">
                  <div className=" ">
                    <div className="d-flex">
                      <div className="d-flex">
                        <img
                          src={
                            val?.logo
                              ? val?.logo
                              : "Images/image-not-available.svg"
                          }
                          alt="MS-Defender"
                        />
                      </div>
                      <div className="my-3 ms-2">
                        <p
                          className="font-semibold font-14 color-black-v2 mb-0 p-2"
                          style={{
                            fontFamily: "SF Pro Text Semibold",
                            marginLeft: "-10px",
                          }}
                        >
                          {val?.name?.length > 25 ? (
                            <Tooltip title={val.name} placement="top-end">
                              <Button
                                style={{ fontFamily: "SF Pro Text Semibold" }}
                                className="text-fixer"
                              >
                                {val?.name?.substring(0, 25) + "..."}
                              </Button>
                            </Tooltip>
                          ) : (
                            val?.name
                          )}
                        </p>

                        <p>
                          {val?.description
                            ? removeHtmlTags(val?.description)?.substring(
                                0,
                                25
                              ) + "..."
                            : null}

                          {val?.description?.length > 25 ? (
                            <a
                              className="link"
                              style={{
                                textDecoration: "none",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                DescriptionComponent(val);
                              }}
                              data-bs-toggle="modal"
                              data-bs-target="#inventory-subs"
                            >
                              {" "}
                              read more
                            </a>
                          ) : null}
                        </p>
                      </div>
                    </div>
                  </div>
                </td>
                <>

                </>
                <td className="text-end vertical-align-middle">
                  {val?.sku?.length}
                </td>
                <td className="text-end vertical-align-middle">
                  {provisionedCustomer(val.sku)}
                </td>
                {val?.category_id?.length > 0 ? (
                  <td className="text-start vertical-align-middle">
                    <span className="custom-category-batch primary-color font-14 font-semibold">
                      all
                    </span>
                    <span className="custom-category-batch primary-color font-14 font-semibold">
                      {val?.category?.length > 0
                        ? val?.category[0]
                        : val?.categories.split(",")[0]}
                      {/* {
                         categories?.filter((data: any) => {
                            return data?.product_id?.includes(val?.product_id);
                          })[0]?.category_name} */}
                    </span>
                    {val?.category_id?.length > 1 ? (
                      <>
                        <span className="primary-color font-14 font-semibold ms-2">
                          +{val?.category_id?.length - 1}
                        </span>
                      </>
                    ) : null}
                  </td>
                ) : (
                  <td>
                    {" "}
                    <span className="custom-category-batch primary-color font-14 font-semibold">
                      all
                    </span>
                  </td>
                )}
                {isCustomer ? (
                  <td className="text-center color-light-green vertical-align-middle">
                    <img
                      src="Images/Green-tick.svg"
                      className="me-2"
                      alt="Green tick"
                    />
                    {filteredData?.length} Added
                  </td>
                ) : null}
                {!isCustomer ? (
                  <td className="text-center">
                    <div className="btn-group d-flex justify-content-center mb-lg-0 mb-3 ">
                      <div className="dropdown">
                        <button
                          className="form-select custom-category-dropdown border-0"
                          id="Category2"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                          onClick={() => {
                            setsearchCat("");
                            setrefreshCategory(!refreshCategory);
                          }}
                        >
                          <span>
                            <img
                              src="Images/settings.svg"
                              alt="settings"
                              className="circle-logo"
                            />
                          </span>
                          <span className="ps-2 tertiary-color">Category</span>
                        </button>
                        <ul
                          className="dropdown-menu custom-dropdown-menu border-0 shadow-sm p-1 "
                          aria-labelledby="Category2"
                          data-bs-popper="none"
                        >
                          <li>
                            <span className="primary-text-color font-13 font-regular mb-1 p-1">
                              <span className="position-relative d-flex align-items-center me-3 w-100">
                                <input
                                  type="search"
                                  // className=" font-regular font-14 top-0 bottom-0 py-2 px-3 custom-input cus-z-ind"
                                  className="border-0 font-regular w-230 cus-z-ind  rounded-2 font-14 top-0 bottom-0 py-2 px-3 custom-input"
                                  id="search2"
                                  placeholder="Search"
                                  value={searchCat}
                                  onChange={(e: any) => {
                                    setsearchCat(e.target.value);
                                  }}
                                  onKeyDown={(e: any) => {
                                    if (e.key === "Enter") {
                                      if (e.target.value.length >= 1) {
                                        const filteredCategories =
                                          categories.filter((category: any) =>
                                            category.category_name
                                              .toLowerCase()
                                              .includes(
                                                e.target.value.toLowerCase()
                                              )
                                          );
                                        setcategories(filteredCategories);
                                      } else if (e.target.value == "") {
                                        setrefreshCategory(!refreshCategory);
                                      }
                                    }
                                  }}
                                />
                                <span className="position-absolute search-icon">
                                  <img
                                    src="Images/search.svg"
                                    alt="search-icon"
                                    onClick={(e: any) => {
                                      if (searchCat === "") {
                                        setrefreshCategory(!refreshCategory);
                                      } else {
                                        const filteredCategories =
                                          categories.filter((category: any) =>
                                            category.category_name
                                              .toLowerCase()
                                              .includes(searchCat.toLowerCase())
                                          );
                                        setcategories(filteredCategories);
                                      }
                                    }}
                                  />
                                </span>
                              </span>
                            </span>
                          </li>

                          <li className="dd-li-scrl">
                            <div className="dropdown-item custom-dropdown-item primary-text-color font-13 font-regular p-1">
                              <div className="form-check">
                                <input
                                  className="custom-check-box form-check-input"
                                  type="checkbox"
                                  id="featured101"
                                  checked={true}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="featured101"
                                >
                                  all
                                </label>
                              </div>
                            </div>
                            {categories.map(
                              (category: any, categoryIndex: any) => (
                                <div
                                  key={`${category.category_id}-${categoryIndex}`}
                                  className="w-100"
                                >
                                  <div className="dropdown-item cursor-pointer custom-dropdown-item primary-text-color font-13 font-regular mb-1 p-1">
                                    <div className="form-check">
                                      <input
                                        className="custom-check-box form-check-input"
                                        type="checkbox"
                                        id={`checkbox-${category.category_id}`}
                                        checked={category.product_id.includes(
                                          val.product_id
                                        )}
                                        onChange={(e) => {
                                          bindCheckbox(
                                            e,
                                            categoryIndex,
                                            category,
                                            val,
                                            index
                                          );
                                        }}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`checkbox-${category.category_id}`}
                                      >
                                        {category.category_name}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </td>
                ) : null}
              </tr>
              <tr
                // id="customer1"
                // className="accordion-collapse collapse themeTable custom-second-table-margin show"
                id={`customer${index + 1}`}
                className={`accordion-collapse collapse ${
                  isOpen ? "show" : ""
                } themeTable`}
              >
                {/* <div > */}
                <td className="color-white-bg p-0" colSpan={6}>
                  <div className="w-100 split-delv-table" style={{display:'inline-block',maxHeight:'245px',overflowY:'scroll'}}>
                    {/* <div class="rounded-3"> */}
                    <table className="custom-second-table w-100">
                      {/*Inner table starts here*/}
                      <thead></thead>
                      <tbody>
                        {val?.sku?.map((value: any, skuIndex: number) => {
                          let promo =
                            value.promotions.length > 1 ? true : false;

                          const skuAccordionIndex = `${index}-sku-${skuIndex}`;
                          const isSkuButtonsVisible =
                            accordionButtonsState[skuAccordionIndex];
                          return (
                            <>
                              <tr
                                className="sec-table-border-bottom custom-second-table-tr-border-left"
                                key={skuIndex}
                              >
                                <td className="cursor-pointer">
                                  {value.name.length > 41 ? (
                                    <Tooltip
                                      title={value.name}
                                      placement="top-end"
                                    >
                                      <Button
                                        style={{
                                          fontFamily: "SF Pro Text Semibold",
                                        }}
                                        className="text-fixer ps-0"
                                      >
                                        {value.name.substring(0, 41) + "..."}
                                      </Button>
                                    </Tooltip>
                                  ) : (
                                    value.name
                                  )}
                                  {value?.is_trial == 1 ? (
                                    <span className="custom-trial-batch  primary-color font-14 font-semibold ms-2">
                                      Trial
                                    </span>
                                  ) : null}
                                  {value?.promotions?.length > 0 ? (
                                    <span
                                      className="link-blue ms-2"
                                      // data-bs-toggle="modal"
                                      // data-bs-target="#inventory-prom"
                                      onClick={() => {
                                        setshowPomotions(true);
                                        setpromotionsArray(value.promotions);
                                      }}
                                    >
                                      Promotions
                                    </span>
                                  ) : null}
                                  {value?.is_add_on == 1 ? (
                                    <span
                                      className="link-blue ms-2"
                                      onClick={() => {
                                        setaddonName(value.name);
                                        getAddons(value.pre_requisite_skus);
                                      }}
                                    >
                                      Add-On
                                    </span>
                                  ) : null}
                                  {/* <p
                                          className="mb-0 text-truncate custom-text-truncate-width custom-popover"
                                          data-bs-toggle="popover"
                                          data-bs-placement="top"
                                          title="Scale business plan for 200 users. It is the System user "
                                          data-bs-content="ID: CFQ7TTC0LF8S:0003"
                                          data-bs-trigger="hover focus"
                                        >
                                          Scale business plan for 100 users. It
                                          is the System user...
                                        </p> */}
                                  {/* {DescriptionComponent(value)} */}
                                  <p>
                                    {" "}
                                    {value?.description
                                      ? removeHtmlTags(
                                          value.description
                                        ).substring(0, 25) + "..."
                                      : null}
                                    <a
                                      className="link"
                                      style={{ textDecoration: "none" }}
                                      onClick={() => {
                                        DescriptionComponent(value);
                                      }}
                                      data-bs-toggle="modal"
                                      data-bs-target="#inventory-sub"
                                    >
                                      {" "}
                                      read more
                                    </a>
                                  </p>
                                </td>

                                <td>
                                  <span className="font-semibold">Price </span>{" "}
                                  : $
                                  {value?.inventoryShowprice
                                    ? value?.inventoryShowprice
                                    : "0"}
                                  /mo{" "}
                                </td>
                                <td>
                                  <span className="font-semibold">
                                    Provisioned to{" "}
                                  </span>
                                  :
                                  {value.provisionedTo.length >= 1
                                    ? value.provisionedTo.join(",")
                                    : "-"}
                                </td>

                                {isCustomer ? (
                                  <td className="text-center">
                                    {hideShowAdd(
                                      value.product_id,
                                      value.sku_id
                                    ) == false ? (
                                      <button
                                        type="button"
                                        className="btn custom-add-batch primary-color"
                                        disabled={
                                          filteredData[0]?.product?.id ==
                                          value?.product_id
                                        }
                                        onClick={() => {
                                          getAvailablity(
                                            value.product_id,
                                            value.avalablity_url,
                                            value.price_list_details,
                                            promo,
                                            value.promotions,
                                            val?.logo
                                          );
                                        }}
                                      >
                                        + Add
                                      </button>
                                    ) : (
                                      <span
                                        className="custom-remove-batch color-red"
                                        onClick={() => {
                                          removeAvailablity(
                                            value.product_id,
                                            value.sku_id
                                          );
                                        }}
                                      >
                                        - Remove
                                      </span>
                                    )}
                                  </td>
                                ) : null}
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                    {/* </div> */}
                  </div>
                </td>
                {/* </div> */}
              </tr>
            </>
          );
        })}
      </>
    );
  }
  //** ps_I_84, ps_I_85, ps_I_86, ps_I_87, ps_I_88, ps_I_89
  //Add product into the cart */
  function addCart() {
    let cartDetails = {
      Availablity: availablityArr,
    };

    encryptStorage.setItem(
      sessionKeys.customerCart,
      JSON.stringify(cartDetails)
    );
    // navigate("/cart", {
    //   state: {
    //     cart: availablityArr,
    //     customerName: location?.state?.customerName,
    //     customerId: location?.state?.customerId,
    //     customerType: location?.state?.customerType,
    //     ...(location?.state?.quote_id && { quote_id: location.state.quote_id }),
    //     mode: location?.state?.mode,
    //   },
    // });
    navigate(location?.state?.quote_id ? "/cart?editquote" : "/cart", {
      state: {
        cart: availablityArr,
        customerName: location.state
          ? location?.state?.customerName
          : customerDetails?.customer_Name,
        customerId: location.state
          ? location?.state?.customerId
          : customerDetails?.customer_id,
        customerType: location?.state?.customerId
          ? location?.state?.customerType
            ? customerDetails?.customer_Name
            : "Existing Customer"
          : "",
        ...(location?.state?.quote_id && { quote_id: location.state.quote_id }),
        mode: location?.state?.mode,
        FromChoosedCustomer: location.state.FromChoosedCustomer ? true : false,
        newQuote: location?.state?.NewQuote ? location?.state?.NewQuote : false,
        showLoader: false,
      },
    });
  }

  //**ps_I_90 return the response into the html component */
  return (
    <>
      {/* <button
        type="button"
        className="btn btn-primary"
        data-bs-toggle="popover"
        title="Popover Title"
        data-bs-content="Popover content goes here."
      >
        Click me
      </button> */}

      <div className="container-fluid px-4 pt-1">
        {isCustomer ? (
          <>
            <div className="px-5">
              {("FromChoosedCustomer" in location.state &&
                location.state.FromChoosedCustomer) ||
              value != null ? (
                <p className="font-20 font-semibold table-title-color mt-2">
                  <img
                    src="Images/back-arrow.svg"
                    className="me-2"
                    alt="back-arrow"
                    onClick={() => {
                      if (
                        "FromChoosedCustomer" in location.state &&
                        location.state.FromChoosedCustomer
                      ) {
                        // navigate("/newquoteprovision", { state: {} });
                        navigate("/newquoteprovision", {
                          state: {
                            fromPrev: true,
                            cust_name: location?.state
                              ? location?.state.customerName
                              : customerDetails?.customer_Name,
                            customertype: !(
                              location?.state?.customerId?.slice(0, 3) === "new"
                            )
                              ? "Existing customer"
                              : "new customer",
                            customerId: location?.state?.customerId,
                            NewQuote: location?.state?.NewQuote
                              ? location?.state?.NewQuote
                              : false,
                          },
                        });
                      } else if (value != null) {
                        navigate("/editquote", {
                          state: { quote_id: location.state.quote_id },
                        });
                      }
                    }}
                  />
                  {location?.state?.FromChoosedCustomer
                    ? "New Quote/Provision"
                    : value != null
                    ? "Add Quote"
                    : ""}
                </p>
              ) : null}
            </div>
            <div className="row mt-2">
              {/* Wizard starts here */}

              <div className="d-flex justify-content-center w-100">
                <div className="col-12 col-sm-12 col-md-11 col-lg-9 col-xl-5">
                  <div className="wizard-conatiner mt-2 mb-2 d-flex justify-content-between position-relative">
                    <div className="wiz-step  wizard-after active completed">
                      <div className="d-flex justify-content-center">
                        <span className="wiz-count font-regular font-14">
                          1
                        </span>
                      </div>
                      <span className="font-semibold d-none d-sm-inline-block wiz-text pt-2">
                        Choose Customer
                      </span>
                    </div>
                    <div className="wiz-step wizard-after active">
                      <div className="d-flex justify-content-center">
                        <span className="wiz-count font-regular font-14">
                          2
                        </span>
                      </div>
                      <span className="font-semibold d-none d-sm-inline-block wiz-text pt-2">
                        Add License
                      </span>
                    </div>
                    <div className="wiz-step wiz-step wizard-after">
                      <div className="d-flex justify-content-center">
                        <span className="wiz-count font-regular font-14">
                          3
                        </span>
                      </div>
                      <span className="font-semibold d-none d-sm-inline-block wiz-text pt-2">
                        Quote/Provision
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Wizard ends here */}
            </div>
          </>
        ) : null}
        {/* {bindProducts?.length} */}
        {isCustomer ? (
          <p className="font-18">
            <span className=" font-semibold tertiary-color">
              Customer Name :{" "}
            </span>
            <span className="color-black-v2 font-semibold">
              {location?.state
                ? location?.state.customerName
                : customerDetails?.customer_Name}
            </span>
          </p>
        ) : (
          <>
            <h2 className="font-20 font-bold dark-blue-color d-flex mb-0 mt-4">
              Inventory
            </h2>
          </>
        )}
        <div className="d-block d-md-flex flex-wrap justify-content-between align-items-center  mb-4 mt-4">
          <div className="d-flex align-items-center justify-content-center ">
            <label className="me-2 dark-blue-color font-14 font-semibold">
              Category
            </label>
            <div className="input-group">
              <div className="dropdown">
                <button
                  className="btn custom-dropdown-btn border-0 custom-select custom-drpdwn-width py-2 rounded-2 d-flex align-items-start"
                  id="Category1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  type="button"
                  onClick={() => {
                    setrefreshCategory(!refreshCategory);
                  }}
                >
                  <span>
                    {/* {getallProducts.categoryName}({categories?.length}) */}
                    {getallProducts.categoryName == "all"
                      ? `All(${allcount?.whole_count})`
                      : `${getallProducts.categoryName}(${
                          selectedProductCount.find(
                            (data: any) =>
                              data.category_id ==
                              Number(getallProducts.categoryID)
                          )?.product_count || 0
                        })`}
                  </span>
                </button>

                <ul
                  className="dropdown-menu border-0 shadow-sm p-1 Done"
                  aria-labelledby="Category1"
                  // style={{ display: "block" }}
                >
                  <li
                    onClick={(e: any) => {
                      //  getAllProducts(rowCount, false, e.target.id);
                      // setbindproducts([])
                      getAllProducts(0, true, 0);
                      setgetallProducts((prev: any) => ({
                        ...prev,
                        categoryID: 0,
                        categoryName: "all",
                      }));
                      setcategoryDropdown(false);
                    }}
                  >
                    <a className="dropdown-item custom-dropdown-item primary-text-color font-13 font-regular mb-1 p-1 d-flex justify-content-between">
                      all
                      {/* <img
                        src="Images/dropdown-cross-black.svg"
                        alt="header-profile-img"
                        className="circle-logo drpdn-cross"
                      /> */}
                    </a>
                  </li>
                  {categories.map((datum: any, index: any) => {
                    return (
                      <>
                        <li>
                          <a
                            className="dropdown-item custom-dropdown-item primary-text-color font-13 font-regular mb-1 p-1 d-flex align-items-center justify-content-between"
                            id={datum.category_id}
                            onClick={(e: any) => {
                              //  getAllProducts(rowCount, false, e.target.id);
                              // setbindproducts([])

                              getAllProducts(0, true, Number(e.target.id));
                              setgetallProducts((prev: any) => ({
                                ...prev,
                                categoryID: Number(e.target.id),
                                categoryName: datum.category_name,
                              }));

                              setcategoryDropdown(false);
                            }}
                          >
                            {datum.category_name}
                            {!isCustomer ? (
                              <img
                                src="Images/dropdown-cross-black.svg"
                                alt="header-profile-img"
                                className=" drpdn-cross"
                                onClick={(e: any) => {
                                  removeCategory(
                                    datum.category_id,
                                    datum.category_name
                                  );
                                }}
                              />
                            ) : null}
                          </a>
                        </li>
                      </>
                    );
                  })}
                </ul>
              </div>

              {!isCustomer ? (
                <button
                  className="btn  btn-outline-secondary border-top-0 border-bottom-0 border-end-0 custom-border-left color-white-bg tertiary-color font-14"
                  type="button"
                  onClick={() => {
                    setcategoryPopup(true);
                  }}
                >
                  <img
                    src="Images/blue-add-icon.svg"
                    alt="AddIcon"
                    className="me-2"
                  />
                  New Category
                </button>
              ) : null}
            </div>
          </div>
          <div className="d-block d-md-flex">
            <div className="w-md-100 d-flex mb-3 mb-md-0">
              <span className="position-relative d-flex align-items-center me-4 w-100">
                {isCustomer ? (
                  <span className="me-2 mb-0">
                    <span className="font-14 font-semibold dark-blue-color">
                      Selected Products # :
                    </span>
                    <span className="color-black font-14 font-semibold">
                      {availablityArr?.length}
                    </span>
                  </span>
                ) : null}
                <input
                  type="search"
                  className="border-0 font-regular cus-z-ind rounded-pill w-290 font-14 top-0 bottom-0 py-2 px-3 custom-input"
                  id="search1"
                  placeholder="Search by products"
                  value={searchProducts}
                  onKeyDown={(e) => {
                    if (e.keyCode == 13) {
                      handleSearch();
                    }
                  }}
                  onChange={(e) => {
                    setsearchproducts(e.target.value);
                  }}
                />
                <span className="position-absolute search-icon">
                  <img
                    src="Images/search.svg"
                    alt="search-icon"
                    onClick={() => {
                      handleSearch();
                    }}
                  />
                </span>
              </span>
              {!isCustomer ? (
                <button
                  type="button"
                  className="btn primary-btn border-0 font-14 font-semibold ms-md-3 ms-0 table-btn w-auto text-nowrap w-md-100"
                  onClick={() => {
                    navigate("/newquoteprovision", {
                      state: {
                        NewQuote: location?.state?.NewQuote
                          ? location?.state?.NewQuote
                          : false,
                      },
                    });
                  }}
                >
                  <img
                    src="Images/white-plus-icon.svg"
                    alt="PlusIcon"
                    className="me-2"
                  />{" "}
                  New Quote/Provision
                </button>
              ) : null}
            </div>
          </div>
        </div>
        {/* Table starts here */}
        <div className="rounded-2 table-responsive mb-3">
          <InfiniteScroll
            dataLength={bindProducts.length}
            next={() => {
              setrowCount(rowCount + defaultLimit);
            }}
            loader={loader}
            scrollThreshold={
              rowCount > 160 && rowCount > 300
                ? 0.6
                : rowCount > 300 &&  rowCount > 500
                ? 0.5
                : rowCount > 500 && rowCount > 800
                ? 0.4
                : 0.9
            }
            hasMore={count}
          >
            <table className="table mb-0 rounded-2 themeTable custom-inventory-table custom-inventory-spacing ">
              <thead className=" bg-white">
                <tr className="border-rad-row">
                  <th className="custom-th-width" />
                  <th className="text-start w-25">License</th>
                  <th className="text-end"># Sku </th>
                  <th className="text-end">Provisioned Customer </th>
                  <th className="text-start ">Category</th>
                  {!isCustomer ? (
                    <th className="text-center ">Action</th>
                  ) : (
                    <th className="text-center "># Added</th>
                  )}
                </tr>
              </thead>
              <tbody>{showProducts()}</tbody>
            </table>
            {categoryPopup ? (
              <div
                className="modal fade show"
                id="inventory-subs"
                style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
                tabIndex={-1}
                // aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered popup-w-400">
                  <div className="modal-content p-3">
                    <div className="modal-header px-1 pt-0 border-0 d-flex align-items-center">
                      <h3 className="font-16 font-semibold tertiary-color mb-0">
                        Category
                      </h3>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={(e) => {
                          setcategoryPopup(false);
                        }}
                      />
                    </div>
                    <div className="modal-body px-1 pt-0 pb-2">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label font-semibold font-14"
                        >
                          New Category
                        </label>
                        <input
                          type="text"
                          className="form-control custom-input"
                          id="exampleFormControlInput1"
                          placeholder="Enter Category Name"
                          value={Newcategory}
                          maxLength={225}
                          onChange={(e: any) => {
                            setNewCategory(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="modal-footer border-0 p-0">
                      <button
                        type="button"
                        className="btn text-btn shadow-none px-3 font-14 font-medium"
                        data-bs-dismiss="modal"
                        onClick={() => {
                          setNewCategory("");
                          setcategoryPopup(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className=" cursor btn primary-btn font-14 font-medium"
                        disabled={Newcategory.length <= 0}
                        onClick={() => {
                          addNewCategory();
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </InfiniteScroll>
        </div>

        {showPromotions ? (
          <div
            className="modal fade show"
            style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
            id="inventory-prom"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content p-3">
                <div className="modal-header px-1 pt-0 border-0 d-flex align-items-center">
                  <h3 className="font-16 font-semibold tertiary-color mb-0">
                    Promotions
                  </h3>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setshowPomotions(false);
                    }}
                  />
                </div>
                <div className="modal-body px-1 pt-0 pb-2 custom-promotion-popup-scroll">
                  <h5 className="font-14 font-semibold tertiary-color">
                    Available Promotions
                  </h5>
                  <p className="font-14 font-regular secondary-text-color">
                    Customer eligibility will be validated when reviewing the
                    cart.
                  </p>
                  {promotionsArray?.map((promo: any) => {
                    return (
                      <>
                        <h5 className="font-14 font-semibold tertiary-color">
                          {promo.product_name}
                        </h5>
                        <p className="font-14 font-regular secondary-text-color">
                          ID : {promo.promotion_id}
                        </p>
                        <div className="table-responsive mb-4 rounded-3">
                          <table className="table  themeTable ">
                            <thead className="font-bold">
                              <tr>
                                <th className="text-start">Term Duration</th>
                                <th className="text-start">Billing Cycle</th>
                                <th className="text-start">Discount</th>
                                <th className="text-start">End Date</th>
                              </tr>
                            </thead>
                            <tbody className="font-medium">
                              <tr className="border-bottom-0 ">
                                <td className="text-start font-regular">
                                  {promo?.duration}
                                </td>
                                <td className="text-start font-regular">
                                  {promo?.billing_cycle}
                                </td>
                                <td className="text-start font-regular">
                                  {promo?.value}%
                                </td>
                                <td className="text-start font-regular">
                                  {moment(promo?.end_date).format("MM/DD/YYYY")}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {loader ? <Loader /> : null}
      </div>
      {isCustomer ? (
        <div className="fixed-bottom fixed-bottom-action">
          <div className="d-flex justify-content-between m-0 p-3 rounded-3 color-white-bg ">
            <div>
              {location.state.FromChoosedCustomer ? (
                <button
                  type="button"
                  className="btn outline-btn text border-0 font-14 font-semibold me-3 shadow-none"
                  onClick={() => {
                    navigate("/newquoteprovision", {
                      state: {
                        fromPrev: true,
                        cust_name: location?.state
                          ? location?.state.customerName
                          : customerDetails?.customer_Name,
                        customertype: !(
                          location?.state?.customerId?.slice(0, 3) === "new"
                        )
                          ? "Existing customer"
                          : "new customer",
                        customerId: location?.state?.customerId,
                        NewQuote: location?.state?.NewQuote
                          ? location?.state?.NewQuote
                          : false,
                      },
                    });
                  }}
                >
                  <img
                    src="Images/previous-arrow.svg"
                    alt="previous-arrow"
                    className="me-2"
                  />
                  Previous
                </button>
              ) : null}
            </div>

            <div className="">
              <button
                type="button"
                className="btn text-btn border-0 font-14 font-semibold me-3 shadow-none"
                onClick={() => {
                  if (
                    "FromChoosedCustomer" in location.state &&
                    location.state.FromChoosedCustomer
                  ) {
                    setisCustomer(false);
                    navigate("/inventory", { state: {} });
                  } else if (value != null) {
                    navigate("/editquote", {
                      state: { quote_id: location?.state?.quote_id },
                    });
                  } else if (customerDetails?.customer_id != null) {
                    navigate("/dashboard");
                  }
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn primary-btn border-0 font-14 font-semibold "
                disabled={availablityArr.length <= 0}
                onClick={() => {
                  addCart();
                }}
              >
                Next
                <img
                  src="Images/next-white-arrow.svg"
                  alt="arrow"
                  className="ms-2"
                />
              </button>
            </div>
          </div>
        </div>
      ) : null}

      {isaddon ? (
        <div
          className="modal fade show"
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
          id="inventory-subs"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content p-3">
              <div className="modal-header px-1 pt-0 border-0 d-flex align-items-center">
                <h3 className="font-16 font-semibold tertiary-color mb-0">
                  Compatible Base Product Subscription
                </h3>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => {
                    setisaddon(false);
                  }}
                />
              </div>
              <div className="modal-body px-1 pt-0 pb-2 custom-promotion-popup-scroll">
                <p className="font-14 font-medium secondary-text-color">
                  The base product subscriptions listed below are compatible
                  with this add-on:
                  <span className="font-bold">{addonName}</span>
                </p>
                <h5 className="font-14 font-bold primary-text-color border-bottom pb-3 pt-2 mb-3">
                  Products
                </h5>
                {addon?.map((data: any) => {
                  return (
                    <>
                      <div className="invet-subs-scrl ">
                        <h5 className="font-14 font-semibold tertiary-color">
                          {data?.productName}
                        </h5>
                        {data.sku.map((sku: any) => {
                          return (
                            <>
                              <ul className="list-unstyled">
                                <li className="font-12 font-regular secondary-text-color mb-2">
                                  {data.productId}:{sku}
                                </li>
                              </ul>
                            </>
                          );
                        })}
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {availnotpresent ? (
        <div
          className="modal fade show"
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
          id="successpopup"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-hidden="true"
        >
          <div className="modal-dialog popup-w-400 modal-dialog-centered">
            <div className="modal-content px-3">
              <div className="modal-header border-0 justify-content-center">
                <span className="popup-icon-pos">
                  <img
                    src="Images/exclamatory-icon.svg"
                    alt="exclamatory-icon"
                    className="popup-icon"
                  />
                </span>
              </div>
              <div className="modal-body pt-5 px-3">
                <p className="text-center font-14 font-medium secondary-text-color">
                  Availablity not present
                </p>
              </div>
              <div className="modal-footer border-0 justify-content-center px-3">
                <button
                  type="button"
                  className="btn text-btn danger-btn shadow-none px-3 font-14 font-medium"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    setavailnotpresent(false);
                  }}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {bindProducts.length <= 0 ? <Norecords /> : null}
      <div
                    className="modal fade"
                    id="inventory-subs"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex={-1}
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content p-3">
                        <div className="modal-header px-1 pt-0 border-0 d-flex align-items-center">
                          <h3 className="font-16 font-semibold tertiary-color mb-0">
                            Descriptions
                          </h3>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          />
                        </div>
                        <div className="modal-body px-1 pt-0 pb-2 custom-promotion-popup-scroll">
                          <div className="invet-subs-scrl ">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: DescriptionsDetaios?.description,
                              }}
                            ></div>
                            <p className="font-regular font-14">
                              ID: {DescriptionsDetaios?.product_id}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <>
                                  <div
                                    className="modal fade"
                                    id="inventory-sub"
                                    data-bs-backdrop="static"
                                    data-bs-keyboard="false"
                                    tabIndex={-1}
                                    aria-hidden="true"
                                    style={{ whiteSpace: "normal" }}
                                  >
                                    <div className="modal-dialog modal-dialog-centered">
                                      <div className="modal-content p-3">
                                        <div className="modal-header px-1 pt-0 border-0 d-flex align-items-center">
                                          <h3 className="font-16 font-semibold tertiary-color mb-0">
                                            Description
                                          </h3>
                                          <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            onClick={() => {
                                              setDescriptionsDetails("");
                                            }}
                                          />
                                        </div>
                                        <div className="modal-body px-1 pt-0 pb-2 custom-promotion-popup-scroll">
                                          <div className="invet-subs-scrl ">
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  DescriptionsDetaios?.description,
                                              }}
                                            ></div>
                                            <p className="font-regular font-14">
                                              ID:{" "}
                                              {DescriptionsDetaios?.product_id}:
                                              {DescriptionsDetaios?.sku_id}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
    </>
  );
}
