export const Loader =()=>{

    return(
        <>
          <div className="overlay">
            <div className="position-absolute top-50 start-50 translate-middle">
              <div
                className="spinner-border Loader text-light align-center "
                role="status"
              >
                <span className="visually-hidden" />
              </div>
              <p className="loading-text font-14 color-white mt-2 font-semibold">
                Loading..
              </p>
            </div>
          </div>
        
        </>
    )

}