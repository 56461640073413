import React from "react";
import { manualSync } from "../../services/customerService";
import { useLocation } from "react-router-dom";

//** PC_NO_1, PC_NO_2, PC_NO_3, PC_NO_4, PC_NO_5,Fetch the Latest Customer Details */
function CustomerManualSync() {
  let location = useLocation();

  const triggerManualSync = async () => {
    manualSync();
  };

  return (
    <>
      <div
        className="tab-pane fade"
        id="manual-sync"
        role="tabpanel"
        aria-labelledby="manual-sync-tab"
      >
        <p className="font-18 font-semibold tertiary-color mb-0 py-lg-3  ps-0">
          Manual Sync
        </p>
        <div className="row ">
          <p className="font-14 color-black-v2 mb-3 ps-2 font-regular">
            Customer data is synced with Microsoft on daily bases. You can also
            trigger sync manually if you need refresh immediately.
          </p>
          <div className="mb-5">
            <button
              type="button"
              className="btn primary-btn border-0 font-14 font-semibold "
              onClick={() => {
                triggerManualSync();
              }}
              disabled={location.state.mode == "view"}
            >
              Manual Sync
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomerManualSync;
