import React, { useEffect, useState } from "react";
import {
  getsubscriptionHistoryApi,
  historyDropdownApi,
} from "../../services/subscriptionservice";
import moment from "moment";
import Norecords from "../norecords";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Tooltip } from "@mui/material";

//** PS_SH_1, PS_SH_2, PS_SH_3, PS_SH_4, PS_SH_5, PS_SH_6, PS_SH_7, PS_SH_8, PS_SH_9, PS_SH_10, PS_SH_11, PS_SH_12, PS_SH_13, PS_SH_14, PS_SH_15, PS_SH_16, PS_SH_17, PS_SH_18
// to fetch history details and render then into the grid component */
const HistoryComponent = (props: any) => {
  //PS_SH_1 PS_SH_2 PS_SH_3 PS_SH_4 PS_SH_5 PS_SH_6 PS_SH_7

  let obj = {
    changeType: "",
    changeBy: "",
    from: "",
    to: "",
  };
  const [subHistory, setsubHistory] = useState(obj);
  const [sortColumn, setsortColumn] = useState<string>("created_date");
  const [sortType, setsortType] = useState<string>("desc");
  const [bindsubscriptionHistory, setbindsubscriptionHistory] = useState<any>(
    []
  );
  //   const [licenseSort, setlicenseSort] = useState(false);
  const [changeSort, setchangeSort] = useState<boolean>(false);
  const [quantitySort, setquantitySort] = useState<boolean>(false);
  const [changedBySort, setchangedBySort] = useState<boolean>(false);
  const [changedOnSort, setchangedOnSort] = useState<boolean>(false);
  const [changesSort, setchangesSort] = useState<boolean>(false);
  const [changeTypeDropdown, setchangeTypeDropdown] = useState<any>([]);
  const [changedByDropdown, setchangedByDropdown] = useState<any>([]);
  const [showFilter, setshowFilter] = useState<boolean>(false);
  const [loader, setloader] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<any>();
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const[showfilterpopup,setshowfilterpopup]=useState(false)

  useEffect(() => {
    getsubscriptionHistory();
  }, [
    changeSort,
    quantitySort,
    changedOnSort,
    changesSort,
    sortType,
    sortColumn,
  ]);

  useEffect(() => {
    getHistoryDropdown();
  }, []);




  // Function to open the popover
  const handlePopoverOpen = (index:any) => {
    setHoveredIndex(index);
  };

  // Function to close the popover
  const handlePopoverClose = () => {
    setHoveredIndex(null);
  };
  //PS_SH_8 PS_SH_9 PS_SH_10 PS_SH_11
  async function getHistoryDropdown() {
    setloader(true);
    let dropdown: any = await historyDropdownApi();

    if (dropdown.data.statusCode == 200) {
      setchangeTypeDropdown(dropdown.data.responseData[0]);
      setchangedByDropdown(dropdown.data.responseData[1]);
      setloader(false);
    }
  }


  //PS_SH_12 PS_SH_13 PS_SH_14
  async function getsubscriptionHistory(histories = subHistory) {
    setloader(true)
    let obj = {
      subscriptionId: props?.subscriptionId,
      ...histories,
      columnName: sortColumn,
      sortType: sortType,
    };
    let history = await getsubscriptionHistoryApi(obj);


    if (history.data.statusCode == 200) {
      setbindsubscriptionHistory(history.data.responseData[0]);
      setTotalCount(history.data.responseData[1]?.[0]?.count);
    }
    setloader(false)
  }
  // PS_SH_15 PS_SH_16 PS_SH_17 PS_SH_18
  async function onchangeSubcriptionsHistory(e: any) {
    setsubHistory((Applyfilterdata) => ({
      ...Applyfilterdata,
      [e.target.name]: e.target.value,
    }));
  }

  return (
    <div
      style={{ display: "block" }}
      className="tab-pane fade show active"
      id="profile-tab-pane"
      role="tabpanel"
      aria-labelledby="profile-tab"
      tabIndex={0}
    >
      <div className="container-fluid px-5">
        <div className="row justify-content-center">
          <div className="px-3">
            <div className="d-block d-md-flex flex-wrap justify-content-between align-items-center  mb-4 mt-3">
              {/* <div class> */}
              <h2 className="font-20 font-bold dark-blue-color d-flex mb-0">
                History
                <span className="custom-admin-batch  font-12 d-flex align-items-center justify-content-center ms-2 px-2">
                  {totalCount}
                </span>
                {/* </div> */}
              </h2>
              <div className="d-block d-md-flex">
                <div className="w-md-100 d-flex mb-3 mb-md-0">
                  {/* <span className="position-relative d-flex align-items-center w-100 me-4">
                    <input
                      type="text"
                      className="border-0 font-regular rounded-3 font-14 top-0 bottom-0 w-230 py-2 px-3 custom-input"
                      id="search"
                      placeholder="Search"
                    />
                    <span className="position-absolute search-icon">
                      <img src="Images/search.svg" alt="search-icon" />
                    </span>
                  </span> */}
                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn secondary-btn custom-filter rounded-3 height-37"
                      //   data-bs-toggle="dropdown"
                      //   aria-expanded="false"
                      onClick={() => {
                        setshowFilter(true);
                      }}
                    >
                      <img
                        src="Images/filter-active-icon.svg"
                        alt="Filter"
                        className="custom-filter-active-img"
                      />
                      <img
                        src="Images/Filter.svg"
                        alt="Filter"
                        className="custom-filter-img"
                      />
                    </button>
                    {showFilter ? (
                      <div
                        className="dropdown-menu dropdown-menu-end adv-dropdown p-3 mt-1 border-0 popup-w-337 show end-0 position-absolute"
                      >
                        <div className="row">
                          <div className="d-flex justify-content-between align-items-center mb-3">
                            <h6 className="font-16 font-semibold tertiary-color mb-0 ">
                              Filter
                            </h6>
                            <button
                              type="button"
                              className="btn-close"
                              onClick={() => {
                                setshowFilter(false);
                              }}
                            />
                          </div>
                          <div className="col-sm-12 col-md-6 mb-4">
                            <label className="form-label font-14 font-semibold color-black">
                              Change Type
                            </label>
                            <select
                              name="changeType"
                              className=" form-select custom-select custom-input font-regular font-13"
                              value={subHistory.changeType}
                              onChange={(e) => {
                                onchangeSubcriptionsHistory(e);
                              }}
                            >
                              <option>Select</option>
                              {changeTypeDropdown.map((vals: any) => {
                                return (
                                  <>
                                    <option>{vals.change_type}</option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                          <div className="col-sm-12 col-md-6 mb-4">
                            <label className="form-label font-14 font-semibold color-black">
                              Changed By
                            </label>
                            <select
                              name="changeBy"
                              className=" form-select custom-select custom-input font-regular font-13"
                              value={subHistory.changeBy}
                              onChange={(e) => {
                                onchangeSubcriptionsHistory(e);
                              }}
                            >
                              <option selected value="">
                                Select
                              </option>
                              {changedByDropdown.map((val: any) => {
                                return (
                                  <>
                                    <option value={val.roles_id}>{val.created_by}</option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                          <h6 className="font-16 font-semibold tertiary-color mb-3 ">
                            Changed On
                          </h6>
                          <div className="col-sm-12 col-md-6 mb-5">
                            <label
                              htmlFor="from1"
                              className="form-label font-14 font-semibold color-black"
                            >
                              From
                            </label>
                            <input
                              type="date"
                              className="form-control custom-input font-regular font-13"
                              id="from1"
                              value={subHistory.from}
                              name="from"
                              max={subHistory.to}
                              onChange={(e) => {
                                onchangeSubcriptionsHistory(e);
                              }}
                            />
                          </div>
                          <div className="col-sm-12 col-md-6 mb-5">
                            <label
                              htmlFor="to1"
                              className="form-label font-14 font-semibold color-black"
                            >
                              To
                            </label>
                            <input
                              type="date"
                              className="form-control custom-input font-regular font-13"
                              id="to1"
                              value={subHistory.to}
                              name="to"
                              min={subHistory.from}
                              onChange={(e) => {
                                onchangeSubcriptionsHistory(e);
                              }}
                            />
                          </div>
                          <div className="d-flex justify-content-end">
                            <button
                              type="button"
                              className="btn text border-0 font-14 font-medium me-3 shadow-none"
                              onClick={() => {
                                setsubHistory(obj);
                                getsubscriptionHistory(obj);
                              }}
                            >
                              Clear
                            </button>
                            <button
                              type="button"
                              className="btn primary-btn border-0 font-14 font-medium "
                              onClick={() => {
                                setshowFilter(false);
                                getsubscriptionHistory();
                              }}
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div class="tab-content" id="pills-tabContent"> */}
          {/* first tab starts here */}
          {/* <div class="tab-pane fade show active" id="pills-notstarted" role="tabpanel" >  <div class="row justify-content-center"> */}
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="table-responsive themetable-border mb-4 rounded-2">
              <table className="table  themeTable mb-0 ">
                <thead className="font-bold">
                  <tr>
                    <th className="ps-4 text-start">
                      License
                      <span
                        className="ms-2 cursor-pointer"
                        // onClick={() => {
                        //   setlicenseSort(!licenseSort);
                        //   setsortColumn("license_name");
                        //   setsortType(licenseSort ? "asc" : "desc");
                        // }}
                      >
                        {/* <img
                          src={
                            licenseSort
                              ? "Images/sort-up-arrow.svg"
                              : "Images/sort-arrow.svg"
                          }
                          alt="sortarrow"
                          title="Sort"
                          onClick={()=>{
                            getsubscriptionHistory()
                          }}
                        /> */}
                      </span>
                    </th>
                    <th className="text-start">
                      Change Type
                      <span
                        className="ms-2 cursor-pointer"
                        onClick={() => {
                          setchangeSort(!changeSort);
                          setsortColumn("change_type");
                          setsortType(changeSort ? "asc" : "desc");
                        }}
                      >
                        <img
                          src={
                            changeSort
                              ? "Images/sort-up-arrow.svg"
                              : "Images/sort-arrow.svg"
                          }
                          alt="sortarrow"
                          title="Sort"
                        />
                      </span>
                    </th>
                    <th className="text-end pe-5">
                      Quantity
                      <span
                        className="ms-2 cursor-pointer"
                        onClick={() => {
                          setquantitySort(!quantitySort);
                          setsortColumn("quantity");
                          setsortType(quantitySort ? "asc" : "desc");
                        }}
                      >
                        <img
                          src={
                            quantitySort
                              ? "Images/sort-up-arrow.svg"
                              : "Images/sort-arrow.svg"
                          }
                          alt="sortarrow"
                          title="Sort"
                        />
                      </span>
                    </th>
                    <th className="text-start">
                      Changed By{" "}
                      <span
                        className="ms-2 cursor-pointer"
                        onClick={() => {
                          setchangedBySort(!changedBySort);
                          setsortColumn("created_by");
                          setsortType(changedBySort ? "asc" : "desc");
                        }}
                      >
                        <img
                          src={
                            changedBySort
                              ? "Images/sort-up-arrow.svg"
                              : "Images/sort-arrow.svg"
                          }
                          alt="sortarrow"
                          title="Sort"
                        />
                      </span>
                    </th>
                    <th className="text-start">
                      Changed On{" "}
                      <span
                        className="ms-2 cursor-pointer"
                        onClick={() => {
                          setchangedOnSort(!changedOnSort);
                          setsortColumn("created_date");
                          setsortType(changedOnSort ? "asc" : "desc");
                        }}
                      >
                        <img
                          src={
                            changedOnSort
                              ? "Images/sort-up-arrow.svg"
                              : "Images/sort-arrow.svg"
                          }
                          alt="sortarrow"
                          title="Sort"
                        />
                      </span>
                    </th>
                    <th className="text-start">
                      Changes{" "}
                      <span
                        className="ms-2 cursor-pointer"
                        onClick={() => {
                          setchangesSort(!changesSort);
                          setsortColumn("changes");
                          setsortType(changesSort ? "asc" : "desc");
                        }}
                      >
                        <img
                          src={
                            changesSort
                              ? "Images/sort-up-arrow.svg"
                              : "Images/sort-arrow.svg"
                          }
                          alt="sortarrow"
                          title="Sort"
                        />
                      </span>
                    </th>
                    {/* <th className="text-center">Actions</th> */}
                  </tr>
                </thead>
                <tbody className="font-medium">
                  {bindsubscriptionHistory.map((data: any, index: number) => {
                    return (
                      <>
                        <tr className="border-bottom-0 ">
                          <td className="ps-4 text-start font-regular  text-truncate custom-text-truncate-width ">
                            {data.license_name}
                          </td>
                          <td className="text-start font-regular">
                            {data.change_type}
                          </td>
                          <td className="text-end font-regular pe-5">
                            {data.quantity}

                            {data.changes == "License Count" ? (
                              <>
                                {/* <img
                                  src="Images/tooltip-icon.svg"
                                  className="ms-2 custom-info-icon"
                                  alt="add-icon"
                                  title=""
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  data-bs-original-title={data.change_type}
                                  aria-label="add item"
                                  aria-describedby={`tooltip${index}`}

                                  // onMouseOver={()=>{

                                  // }}
                                /> */}
                                 <Tooltip
                                title={data.description}
                                placement="top-end"
                              >
                                 <img
                                  src="Images/tooltip-icon.svg"
                                  className="ms-2 custom-info-icon"
                                  alt="add-icon"
                                  title=""
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  data-bs-original-title={data.change_type}
                                  aria-label="add item"
                                  aria-describedby={`tooltip${index}`}
                                  onMouseEnter={() =>
                                    handlePopoverOpen(index)
                                  } // Open popover on icon hover
                                  onMouseLeave={handlePopoverClose} // Close popover when leaving the icon
                                />
                              </Tooltip>
                              </>
                            ) : null}
                          </td>
                          <td className="text-start font-regular">
                            {/* <span className="custom-green-dot" /> */}
                            {data.screated_by}
                          </td>
                          <td className="text-start font-regular">
                            {moment(data.created_date).format("MM/DD/YY")}
                          </td>
                          <td className="text-start font-regular">
                            {data.changes}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {loader ? (
          <div className="overlay">
            <div className="position-absolute top-50 start-50 translate-middle">
              <div
                className="spinner-border Loader text-light align-center "
                role="status"
              >
                <span className="visually-hidden" />
              </div>
              <p className="loading-text font-14 color-white mt-2 font-semibold">
                Loading..
              </p>
            </div>
          </div>
        ) : null}
      </div>
      {bindsubscriptionHistory.length <= 0 ? <Norecords /> : null}
    </div>
  );
};

export default HistoryComponent;
