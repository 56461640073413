import axios from "axios";

import { encrypt, decrypt } from "./configs/encryption";
import { sessionTokens } from "./interface";
import { jwtDecode } from "jwt-decode";
import { backendurl as URL, encryptStorage, sessionKeys } from "./configs/constant";
import { configs } from "./configs/constant";


async function validateTokenExpiration() {
  let getToken: sessionTokens | null = JSON.parse(
    encryptStorage.getItem(sessionKeys.sessiontokens) || "null"
  );
  const token = getToken?.jwt ?? '';
  const tokenParts = token.split('.');

  // Get the payload part
  const payload = tokenParts[1];

  // Decode the base64-encoded payload
  // const decodedPayload = Buffer.from(payload, 'base64').toString('utf-8');

  // Parse the decoded payload as JSON
  // const parsedPayload = JSON.parse(decodedPayload);
  const decodedPayload = atob(payload); // Use atob to decode Base64 in the browser
  const parsedPayload = JSON.parse(decodedPayload);


  if (parsedPayload.exp) {
    const currentTimestamp = Math.floor(Date.now() / 1000); // Convert to seconds

    if (parsedPayload.exp < currentTimestamp) {
      return true;
    }
    else {
      return false;
    }
  }

}

async function getByrefreshTokens() {
  let getToken: sessionTokens | null = JSON.parse(
    encryptStorage.getItem(sessionKeys.sessiontokens) || "null"
  );
  let bodies: any = {
    refresh_token: getToken?.refresh_token,
  };
  let payload = {
    headers: {
      'Content-Type': 'application/json',
      'authorization': `Bearer ${getToken?.jwt}`
    },
    data: bodies,
    url: URL + '/login/generateTokenbyRefreshToken', // Add the url property
    method: "post", // Add the method property
  }
  let response = await axios(payload);
  let sessionTokens: sessionTokens = {
    jwt: response.data.jwt,
    refresh_token: response.data.refresh_token
  }

  encryptStorage.setItem(
    sessionKeys.sessiontokens,
    JSON.stringify(sessionTokens)
  );




}



export async function client(Request: any) {

    
    let { header, url, method, body } = Request;
    if (!(url.includes("/login"))) {
      let validateToken = await validateTokenExpiration()
      if (validateToken) {
        let data = await getByrefreshTokens()
      }
    }
    let token: sessionTokens | null = JSON.parse(
      encryptStorage.getItem(sessionKeys.sessiontokens) || "null"
    );

    // Encrypt the request data
    const encryptedData = encrypt({ key: configs, data: JSON.parse(JSON.stringify(body)) });

    let Config = {
      headers: {
        'Content-Type': 'application/json',
        'authorization': `Bearer ${token?.jwt}`
      },
      data: {
        data: encryptedData,
      },
      url,
      method
    };

    let apiResponse = await axios(Config);

    // Decrypt the response data
    // if(!(url.includes("/login"))){
    const decryptedResponse = decrypt(configs, apiResponse?.data);
    apiResponse.data = decryptedResponse;
    // }
    return apiResponse;
}



export async function getprofile() {

  const profileImage = await axios.get(

    `https://graph.microsoft.com/v1.0/me/photo/$value`,

    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
      },
      responseType: "blob",

    }

  );

  return profileImage.data;

}


