import React, { useEffect, useState } from "react";
import { getUserActivityLog } from "../../services/orderService";
import { ActivityLog, customerSessionData, userSessionData } from "../../interface";
import { useNavigate } from "react-router-dom";
import { defaultLimit, encryptStorage, sessionKeys } from "../../configs/constant";
import { Loader } from "../loader";
import moment from "moment";
//** PC_NO_O_1,PC_NO_O_2.2 Fetch the Activity log details and render the details into the Grid Component */
function ActivityGrid(props: any) {
    
//** PC_NO_O_2.2 state variable to perform the grid component operation using it value */
  const [getOrderActivityLog, setGetOrderActivityLog] = useState<ActivityLog[]>([]);
  const [sortType, setSortType] = useState<string>("DESC");
  const [sortColumn, setSortColumn] = useState<string>("created_date");
  const [count, setCount] = useState<number>(defaultLimit);
  const [nameSort, setNameSort] = useState<boolean>(false);
  const [activitySort, setActivitySort] = useState<boolean>(false);
  const [timeStampSort, setTimeStampSort] = useState<boolean>(false);
  const [commentsSort, setCommentsSort] = useState<boolean>(false);
  const [loader, setloader] = useState<boolean>(false);
  const [recordCount, setRecordCount] = useState<any>(0);
//** PC_NO_O_3 call the Fuction during the first render  */
  useEffect(() => {
    fetchUserActivityLogDetails();
  }, [
    sortType,
    sortColumn,
    commentsSort,
    nameSort,
    activitySort,
    timeStampSort,
    commentsSort,
    count,
  ]);
  let userDetails: userSessionData | null = JSON.parse(
    encryptStorage.getItem(sessionKeys.userDetails) || "null"
  );
  let customerSessionDetails: customerSessionData | null = JSON.parse(
    encryptStorage.getItem(sessionKeys.customerDetails) || "null"
  );
//** PC_NO_O_3 to call perform the opeartion or condition during te rendering time */
  useEffect(() => {
    if (props && (props.action == "search" || props.action == "filter")) {
      fetchUserActivityLogDetails();
    } else if (props && props.action == "filterClear") {
      fetchUserActivityLogDetails({ from: "", to: "" });
    }
  }, [props.makeCall]);
//** PC_NO_O_29, PC_NO_O_30, PC_NO_O_31, PC_NO_O_32, PC_NO_O_33, PC_NO_O_34, PC_NO_O_35, PC_NO_O_36, PC_NO_O_37, PC_NO_O_38.1 
// to fetch the acitivity log details */
  async function fetchUserActivityLogDetails(filter = props?.filterValue) {
    setloader(true);
    
    const payload = {
      search_value: props?.searchValue,
      from_date: filter.from,
      to_date: filter.to,
      sort_column: sortColumn,
      sort_order: sortType,
      count: count,
      user_id:customerSessionDetails?.customer_id ? userDetails?.user_id :''
    };

    const response = await getUserActivityLog(payload);
    if (response.data.statusCode == 200) {
      setGetOrderActivityLog(response?.data?.responseData?.activityRecord);
      setRecordCount(response?.data?.responseData?.recordCount);
      props.activity(response?.data?.responseData?.recordCount)
      setloader(false);
    }
  }
  
  
//** PC_NO_O_38.14, PC_NO_O_38.15, PC_NO_O_38.16, PC_NO_O_38.17, PC_NO_O_38.18, PC_NO_O_38.19, PC_NO_O_38.20
// To fetch the value and render them  into the html component */
  function generateTableRows() {
    return getOrderActivityLog?.map((item: ActivityLog, index: any) => (
      <tr className="border-bottom-0">
        <td className="ps-5 text-start font-regular">{item?.name}</td>
        <td className="text-start font-regular">{item?.activity}</td>
        <td className="text-start font-regular">
          {moment.utc(item?.created_date).format('MM/DD/YYYY h:m A')}
        </td>
        <td className="text-start font-regular">{item?.comments}</td>
      </tr>
    ));
  }

  function formatDateToCustomFormat(isoDate: any) {
    const date = new Date(isoDate);
    const formattedDate = date.toLocaleString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return formattedDate.replace(",", "");
  }

  return (
    <>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        {/*<div className="table-responsive themetable-border mb-4 rounded-2">  */}
        <div className="table-responsive themetable-border rounded-2">
          <table className="table  themeTable mb-0 ">
            <thead className="font-bold">
              <tr>
                <th className="ps-5 text-start">
                  Name
                  <span
                    className="ms-2 cursor-pointer"
                    onClick={(e) => {
                      setNameSort(!nameSort);
                      setSortColumn("user_name");
                      setSortType(nameSort ? "desc" : "asc");
                    }}
                  >
                    <img
                      src={
                        nameSort
                          ? "Images/sort-up-arrow.svg"
                          : "Images/sort-arrow.svg"
                      }
                      alt="sortarrow"
                      title="Sort"
                    />
                  </span>
                </th>
                <th className="text-start">
                  Activity
                  <span
                    className="ms-2 cursor-pointer"
                    onClick={(e) => {
                      setActivitySort(!activitySort);
                      setSortColumn("activity");
                      setSortType(activitySort ? "desc" : "asc");
                    }}
                  >
                    <img
                      src={
                        activitySort
                          ? "Images/sort-up-arrow.svg"
                          : "Images/sort-arrow.svg"
                      }
                      alt="sortarrow"
                      title="Sort"
                    />
                  </span>
                </th>
                <th className="text-start">
                  Time Stamp
                  <span
                    className="ms-2 cursor-pointer"
                    onClick={(e) => {
                      setTimeStampSort(!timeStampSort);
                      setSortColumn("created_date");
                      setSortType(timeStampSort ? "desc" : "asc");
                    }}
                  >
                    <img
                      src={
                        timeStampSort
                          ? "Images/sort-up-arrow.svg"
                          : "Images/sort-arrow.svg"
                      }
                      alt="sortarrow"
                      title="Sort"
                    />
                  </span>
                </th>
                <th className="text-start">
                  Comments{" "}
                  {/* <span
                    className="ms-2 cursor-pointer"
                    onClick={(e) => {
                      setCommentsSort(!commentsSort);
                      setSortColumn("comments");
                      setSortType(timeStampSort ? "desc" : "asc");
                    }}
                  >
                    <img
                      src={
                        commentsSort
                          ? "Images/sort-up-arrow.svg"
                          : "Images/sort-arrow.svg"
                      }
                      alt="sortarrow"
                      title="Sort"
                    />
                  </span> */}
                </th>
              </tr>
            </thead>
            <tbody className="font-medium">{generateTableRows()}</tbody>
          </table>
        </div>
        {recordCount > count ? (
          <div className="d-flex justify-content-center my-3">
            <button
              type="button"
              className="btn tertiary-btn border-0 font-14 font-semibold"
              name="count"
              value={count}
              onClick={() => {
                setCount(count + defaultLimit);
              }}
            >
              Load More
            </button>
          </div>
        ) : null}

        {getOrderActivityLog.length === 0 ? (
          <>
            <div className="d-flex justify-content-center color-white-bg rounded-bottom pt-100 pb-400 mb-5">
              <div>
                <img
                  src="Images/no-records-found.svg"
                  alt="No-Records-Found"
                  className="d-block"
                />
                <div>
                  <p className="d-flex font-20 font-regular tertiary-color mt-3 justify-content-center">
                    No records found
                  </p>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
      {loader ? <Loader /> : null}
    </>
  );
}

export default ActivityGrid;
