import React from "react";
import { useState, useEffect } from "react";
import { defaultLimit, sessionKeys,encryptStorage, formatAmount } from "../../configs/constant";
import {
  customerSessionData,
  userSessionData
} from "../../interface";
import { Loader } from "../loader";
import { getUpcomingRenewal } from "../../services/dashboardService";
import moment from "moment";
import BreadCrumbs from "../subscriptions/breadcrumbs";
import Norecords from "../norecords";

// to render the Upcoming Renewal details into the grid component
// PC_NO_34 PC_NO_35 PC_NO_36 PC_NO_37 PC_NO_38 PC_NO_39 PC_NO_40
export default function UpcomingRenewal() {
  const [ColumnName, setColumnName] = useState("commitment_end_date");
  const [columnOrder, setColumnOrder] = useState("asc");
  const [sorthide, setsorthide] = useState("");
  const [loadmore, setloadmore] = useState(defaultLimit);
  const [loader, setloader] = useState(false);
  const [searchvalue, setsearchvalue] = useState("");
  const [upcomingRenewData, setupcomingRenewData] = useState([]);
  const [search_value, setsearch_value] = useState("");
  const [totalCount, settotalCount] = useState<any>(0);
  const [customerId, setCustomerId] = useState(
    sessionStorage.getItem(sessionKeys.customerId)
  );
  let userDetails: userSessionData | null = JSON.parse(
    encryptStorage.getItem(sessionKeys.userDetails) || "null"
  );
  let customerSessionDetails: customerSessionData | null = JSON.parse(
    encryptStorage.getItem(sessionKeys.customerDetails) || "null"
  );
  const [showLoadmore, setshowLoadmore] = useState(0);

  let breadcrumb = [
    { title: "Dashboard", isActive: true, path: "/dashboard" },
    {
      title: "Upcoming Renewal",
      isActive: false,
      path: null,
    },
  ];

  useEffect(() => {
    getupcomingRenewal();
  }, [loadmore, columnOrder, ColumnName]);

  async function getupcomingRenewal() {
    setloader(true);
    let payload = {
      customer_id: customerSessionDetails?.customer_id ? customerSessionDetails?.customer_id : customerId,
      search_value: search_value,
      column_name: ColumnName,
      column_type: columnOrder,
      record_count: loadmore,
    };

    let upcomingrenwaldata: any = await getUpcomingRenewal(payload);

    if (upcomingrenwaldata.data.statusCode == 200) {
      setshowLoadmore(
        upcomingrenwaldata.data.responseData.record_count[0].record_count
      );
      setupcomingRenewData(
        upcomingrenwaldata.data.responseData.upcomming_renewals
      );
      settotalCount(upcomingrenwaldata.data.responseData.TotalCount);
    }
    setloader(false);
  }

  const sortClicked = (e: any) => {
    try {
      let sortID = e.target.id;
      let sortval = sortID.split("@");
      setColumnName(sortval[0]);
      setColumnOrder(sortval[1]);
      setsorthide(sortID);
    } catch (e: any) {
      console.log(e.message);
    }
  };

  const onSearch = (e: any) => {
    if (e.key === "Enter") {
      getupcomingRenewal();
    }
  };

  return (
    <>
      <div className="container-fluid px-4 ">
        <BreadCrumbs data={breadcrumb} />
        <div
          className="tab-pane fade show mt-2 mx-4 pt-4"
          id="activity"
          role="tabpanel"
          aria-labelledby="activity-tab"
        >
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12   ">
            <div className="d-flex justify-content-between mb-3">
              <h2 className="font-20 font-bold dark-blue-color d-flex mb-0">
                Upcoming Renewal
                <span className="custom-admin-batch  font-12 d-flex align-items-center justify-content-center ms-2 px-2">
                  {/* {totalCount} */}
                  {showLoadmore}
                </span>
                {/* </div> */}
              </h2>
              <div className="d-block d-md-flex">
                <div className="w-md-100 d-flex mb-3 mb-md-0 align-items-center">
                  {/* <span className="position-relative d-flex align-items-center  me-0 w-100">
                    <input
                      type="text"
                      className="border-0 font-regular rounded-3 w-230  font-14 top-0 bottom-0 py-2 px-3 custom-input"
                      id="search"
                      placeholder="Search"
                      value={search_value}
                      onChange={(e: any) => {
                        setsearch_value(e.target.value);
                      }}
                      onKeyPress={(e) => {
                        onSearch(e);
                      }}
                    />
                    <span className="position-absolute search-icon">
                      <img
                        src="Images/search.svg"
                        alt="search-icon"
                        onClick={() => {
                          getupcomingRenewal();
                        }}
                      />
                    </span>
                  </span> */}

                  <span className="position-relative d-flex align-items-center me-3 w-100">
                    <input
                      type="search"
                      className="border-0 font-regular w-230 cus-z-ind  rounded-2 font-14 top-0 bottom-0 py-2 px-3 custom-input"
                      id="search"
                      placeholder="Search"
                      value={search_value}
                      onChange={(e: any) => {
                        setsearch_value(e.target.value);
                      }}
                      onKeyPress={(e) => {
                        onSearch(e);
                      }}
                    />
                    <span className="position-absolute search-icon">
                      <img
                        src="Images/search.svg"
                        alt="search-icon"
                        onClick={() => {
                          getupcomingRenewal();
                        }}
                      />
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div className="table-responsive themetable-border rounded-2">
              <table className="table  themeTable mb-0 ">
                <thead className="font-bold">
                  <tr>
                    <th className="ps-5 text-start">
                      {" "}
                      Customer Name
                      <span className="ms-2 cursor-pointer">
                        <img
                          src="Images/sort-arrow.svg"
                          id="cd.company_name@ASC"
                          onClick={(e) => sortClicked(e)}
                          hidden={
                            sorthide == "cd.company_name@ASC" || ""
                              ? true
                              : false
                          }
                        />

                        <img
                          src="Images/sort-up-arrow.svg"
                          id="cd.company_name@DESC"
                          onClick={(e) => sortClicked(e)}
                          hidden={
                            sorthide == "cd.company_name@ASC" ? false : true
                          }
                        />
                      </span>
                    </th>
                    <th className="text-start">
                      License
                      <span className="ms-2 cursor-pointer">
                        <img
                          src="Images/sort-arrow.svg"
                          id="s.license_name@ASC"
                          onClick={(e) => sortClicked(e)}
                          hidden={
                            sorthide == "s.license_name@ASC" || ""
                              ? true
                              : false
                          }
                        />

                        <img
                          src="Images/sort-up-arrow.svg"
                          id="s.license_name@DESC"
                          onClick={(e) => sortClicked(e)}
                          hidden={
                            sorthide == "s.license_name@ASC" ? false : true
                          }
                        />
                      </span>
                    </th>
                    <th className="text-start">
                      Invoice Date
                      <span className="ms-2 cursor-pointer">
                        <img
                          src="Images/sort-arrow.svg"
                          id="commitment_end_date@ASC"
                          onClick={(e) => sortClicked(e)}
                          hidden={
                            sorthide == "commitment_end_date@ASC" || ""
                              ? true
                              : false
                          }
                        />

                        <img
                          src="Images/sort-up-arrow.svg"
                          id="commitment_end_date@DESC"
                          onClick={(e) => sortClicked(e)}
                          hidden={
                            sorthide == "commitment_end_date@ASC" ? false : true
                          }
                        />
                      </span>
                    </th>
                    <th className="text-end pe-5">
                      Amount{" "}
                      <span className="ms-2 cursor-pointer">
                        <img
                          src="Images/sort-arrow.svg"
                          id="amount@ASC"
                          onClick={(e) => sortClicked(e)}
                          hidden={sorthide == "amount@ASC" || "" ? true : false}
                        />

                        <img
                          src="Images/sort-up-arrow.svg"
                          id="amount@DESC"
                          onClick={(e) => sortClicked(e)}
                          hidden={sorthide == "amount@ASC" ? false : true}
                        />
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody className="font-medium">
                  {upcomingRenewData.map((data: any) => {
                    return (
                      <>
                        <tr className="border-bottom-0 ">
                          <td className="ps-5 text-start font-regular">
                            {data.company_name}
                          </td>
                          <td className="text-start font-regular">
                            {data.license_name}
                          </td>
                          <td className="text-start font-regular">
                            {moment(data.commitment_end_date).format(
                              "MM/DD/YYYY"
                            )}
                          </td>
                          <td className="text-end font-regular pe-5">
                            ${data.amount ? formatAmount(data.amount) : '0.00'}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {upcomingRenewData.length <= 0 ? <Norecords /> : null}
            {upcomingRenewData.length != showLoadmore ? (
              <div className="d-flex justify-content-center mb-5">
                <button
                  type="button"
                  className="btn tertiary-btn border-0 font-14 font-semibold mt-2 "
                  onClick={() => {
                    setloadmore(loadmore + defaultLimit);
                  }}
                >
                  Load More
                </button>
              </div>
            ) : null}
          </div>
        </div>

        {loader ? <Loader /> : null}
      </div>
    </>
  );
}
