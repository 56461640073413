
import { useState, useEffect } from "react";
import { getCardCount } from "../../services/dashboardService";
import { getCardCounts, customerSessionData } from "../../interface";
import { sessionKeys, encryptStorage } from "../../configs/constant";
import { useNavigate } from "react-router-dom";
import { Loader } from "../loader";
//** PS_D_19,PS_D_20 to render the card details into the card html component */
export default function Displaycard() {
  //PS_D_21 state variable and objects
  let customerDetails: customerSessionData | null = JSON.parse(
    encryptStorage.getItem(sessionKeys.customerDetails) || "null"
  );
  const [customerId, setCustomerId] = useState(customerDetails?.customer_id);
  const navigate = useNavigate();
  const [cardData, setCardData] = useState<getCardCounts>({
    up_comming_renewal_count: 0,
    active_license_count: 0,
    in_active_license_count: 0,
    total_license_count: 0,
    customer_count: 0,
  });
  const[showLoader, setShowLoader] = useState(false);
//** PS_D_22 UseEffect() hooks to call the function during rendering */
  useEffect(() => {
    getCarddata();
  }, []);

  async function getCarddata() {
    setShowLoader(true);
    let payload2 = {
      customer_id: customerId,
    };
    let cardResult = await getCardCount(payload2);
    if (cardResult.data.statusCode == 200) {
      setCardData(cardResult.data.responseData);
    }
    setShowLoader(false);
  }

  return (
    <>
    {showLoader? <Loader/> : <></>}
      <div className="col-lg-12 col-xl-3 col-md-12 col-12  p-1"
        onClick={() => { navigate("/upcomingrenewal") }}>
        <div className="color-white-bg px-4 pt-4 pb-3 d-flex align-items-center justify-content-between border-radius-8 cursor-pointer">
          <p className="font-semibold font-14 color-black mb-0">
            Upcoming Renewal
            <span className="font-24 font-bold mt-3 mb-1 color-black-v2 d-block">
              {cardData.up_comming_renewal_count}
            </span>
          </p>
          <span>
            <img src="Images/renewal.svg" alt="Renewal" />
          </span>
        </div>
      </div>
      <div className="col-lg-12 col-xl-3 col-md-12 col-12 mb-1 p-1"
        onClick={() => { navigate("/subscription", { state: { status: "active" } }) }}
      >
        <div className="color-white-bg px-4 pt-4 pb-3 d-flex align-items-center justify-content-between border-radius-8 cursor-pointer">
          <p className="font-semibold font-14 color-black mb-0">
            Active License
            <span className="font-24 font-bold mt-3 mb-1 color-black-v2  d-block">
              {cardData.active_license_count}
            </span>
          </p>
          <span>
            <img src="Images/active-license.svg" alt="Renewal" />
          </span>
        </div>
      </div>
      <div className="col-lg-12 col-xl-3 col-md-12 col-12 mb-1 p-1"
        onClick={() => { navigate("/subscription") }}
      >
        <div className="color-white-bg px-4 pt-4 pb-3 d-flex align-items-center justify-content-between border-radius-8 cursor-pointer">
          <p className="font-semibold font-14 color-black mb-0">
            Total License
            <span className="font-24 font-bold mt-3 mb-1 color-black-v2 d-block">
              {cardData.total_license_count}
            </span>
          </p>
          <span>
            <img src="Images/total-license.svg" alt="Renewal" />
          </span>
        </div>
      </div>

      {/* 
    {customerDetails?.customer_id?  (): ()} */}
      {customerDetails?.customer_id ? <div className="col-lg-12 col-xl-3 col-md-12 col-12 mb-1 p-1"
        onClick={() => { navigate("/subscription", { state: { status: "deleted" } }) }}
      >
        <div className="color-white-bg px-4 pt-4 pb-3 d-flex align-items-center justify-content-between border-radius-8 cursor-pointer">
          <p className="font-semibold font-14 color-black mb-0">
            Inactive License
            <span className="font-24 font-bold mt-3 mb-1 color-black-v2 d-block">
              {cardData.in_active_license_count}
            </span>
          </p>
          <span>
            <img src="Images/renewal.svg" alt="Renewal" />
          </span>
        </div>
      </div>
        :
        <div className="col-lg-12 col-xl-3 col-md-12 col-12 mb-1 p-1"
        onClick={() => { navigate("/customer") }}
        >
          <div className="color-white-bg px-4 pt-4 pb-3 d-flex align-items-center justify-content-between border-radius-8 cursor-pointer">
            <p className="font-semibold font-14 color-black mb-0">
              Customer Count
              <span className="font-24 font-bold mt-3 mb-1 color-black-v2 d-block">
                {cardData.customer_count}
              </span>
            </p>
            <span>
              <img src="Images/customer-count.svg" alt="Renewal" />
            </span>
          </div>
        </div>}
    </>
  );
}
