import React, { useState,useEffect } from 'react';
//** ps_I_55.18,ps_I_55.19, ps_I_55.20 */
function ImageList() {
  const [imageData, setImageData] = useState<string | null>(null);

useEffect(()=>{

},[])

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        
        if (typeof reader.result === 'string') {
          setImageData(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} />
      {imageData && <img src={imageData} alt="Uploaded Image" />}
    </div>
  );
}

export default ImageList;
