import React, { useEffect, useState } from 'react'
import CustomerBasicDetails from '../customer/customerBasicDetails'
import CustomerContactDetails from '../customer/customerContactDetails'
import CustomerLegalDetails from '../customer/customerLegalDetails'
import CustomerBrandElementDetails from '../customer/customerBrandElementDetails'
import CustomerNotificationDetails from '../customer/customerNotificationDetails'
import CustomerLicenseDetails from '../customer/customerLicenseDetails'
import CustomerManualSync from '../customer/customerManualSync'
// import { useData } from './DataContext'
import BreadCrumbs from '../subscriptions/breadcrumbs'
import { useLocation, useNavigate } from 'react-router-dom'
//** PC_NO_1, PC_NO_2, PC_NO_3, PC_NO_4, PC_NO_5, Main Component to Arrange the Customer Information Module */
const CustomerDetails: React.FC<any> = () => {

    const navigate = useNavigate();

    let location: any = useLocation();
    let breadcrumb = [
        { title: "Customers", isActive: true, path: "/customer" },
        { title: location.state.company_name, isActive: false, path: null },
      ];

    return (
        <>
            <>
                {/* header starts */}
                {/* header ends */}
                <div className="container-fluid">
                <BreadCrumbs data={breadcrumb} />
                    <div className="row color-white-bg mt-2 rounded-2 mx-4 mb-5">
                        <div className="col-12 col-md-12 col-lg-3 col-xl-3 color-white-bg px-2 border-end rounded-2">
                            <div className="d-lg-flex d-none align-items-start v-tab-border ">
                                <div
                                    className="nav flex-column nav-pills custom-v-pills w-100 pb-2"
                                    id="v-pills-tab"
                                    role="tablist"
                                    aria-orientation="vertical"
                                >
                                    <p className="font-18 font-semibold tertiary-color mb-lg-4 py-lg-3 mb-2 ps-0 ps-lg-3 border-bottom">
                                        Information
                                    </p>
                                    <button
                                        className="nav-link text-start rounded-0 py-3 font-semibold font-14 color-grey custom-v-pills d-flex align-items-center active "
                                        id="basicdetail-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#basic-detail"
                                        type="button"
                                        role="tab"
                                        aria-selected="true"
                                        // onClick={() => navigate('/cust-basic', { state: { mode: "view", "customer_id": location.state.customer_id } })}
                                    >
                                        <img
                                            src="Images/basic-details-fill.svg"
                                            alt="basic-details-icon"
                                            className="me-2"
                                        />
                                        <img src="Images/basic-details.svg" alt="" className=" me-2" />
                                        Basic Details
                                    </button>
                                    <button
                                        className="nav-link text-start rounded-0 py-3 font-semibold font-14 color-grey custom-v-pills d-flex align-items-center "
                                        id="contactdetail-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#contact-detail"
                                        type="button"
                                        role="tab"
                                        aria-selected="true"
                                    >
                                        <img
                                            src="Images/contact-details-fill.svg"
                                            alt="contact-details-icon"
                                            className="me-2"
                                        />
                                        <img src="Images/contact.svg" alt="" className=" me-2" />
                                        Contact Details
                                    </button>
                                    <button
                                        className="nav-link text-start rounded-0 py-3 font-semibold font-14 color-grey custom-v-pills d-flex align-items-center "
                                        id="legaldetail-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#legal-detail"
                                        type="button"
                                        role="tab"
                                        aria-selected="true"
                                    >
                                        <img
                                            src="Images/legal-details-fill.svg"
                                            alt="legal-details-icon"
                                            className="me-2"
                                        />
                                        <img src="Images/legal-details.svg" alt="" className=" me-2" />
                                        Legal Details
                                    </button>
                                    <button
                                        className="nav-link text-start rounded-0 py-3 font-semibold font-14 color-grey custom-v-pills d-flex align-items-center "
                                        id="brandingdetails-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#branding-elements"
                                        type="button"
                                        role="tab"
                                        aria-selected="true"
                                        // onClick={() => navigate('/cust-basic', { state: { mode: "view", "customer_id": location.state.customer_id } })}
                                    >
                                        <img
                                            src="Images/branding-elements-fill.svg"
                                            alt="branding elements-icon"
                                            className="me-2"
                                        />
                                        <img
                                            src="Images/branding-elements.svg"
                                            alt=""
                                            className=" me-2"
                                        />
                                        Branding Elements
                                    </button>
                                    <button
                                        className="nav-link text-start rounded-0 py-3 font-semibold font-14 color-grey custom-v-pills d-flex align-items-center "
                                        id="notificaions-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#notifications"
                                        type="button"
                                        role="tab"
                                        aria-selected="true"
                                    >
                                        <img
                                            src="Images/notification-fill.svg"
                                            alt="notification-icon"
                                            className="me-2"
                                        />
                                        <img src="Images/notifications.svg" alt="" className=" me-2" />
                                        Notifications
                                    </button>
                                    <button
                                        className="nav-link text-start rounded-0 py-3 font-semibold font-14 color-grey custom-v-pills d-flex align-items-center "
                                        id="license-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#license"
                                        type="button"
                                        role="tab"
                                        aria-selected="true"
                                    >
                                        <img
                                            src="Images/license-price-list-fill.svg"
                                            alt="license-price-list-icon"
                                            className="me-2"
                                        />
                                        <img
                                            src="Images/license-price-list.svg"
                                            alt=""
                                            className=" me-2"
                                        />
                                        License Price List
                                    </button>
                                    <button
                                        className="nav-link text-start rounded-0 py-3 font-semibold font-14 color-grey custom-v-pills d-flex align-items-center "
                                        id="manual-sync-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#manual-sync"
                                        type="button"
                                        role="tab"
                                        aria-selected="true"
                                    >
                                        <img
                                            src="Images/manual-sync-fill.svg"
                                            alt="manual-sync-icon"
                                            className="me-2"
                                        />
                                        <img src="Images/manual-sync.svg" alt="" className=" me-2" />
                                        Manual Sync
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-9 col-xl-9">
                            <div className="tab-content px-2" id="v-pills-tabContent">
                                <CustomerBasicDetails></CustomerBasicDetails>
                                <CustomerContactDetails></CustomerContactDetails>
                                <CustomerLegalDetails></CustomerLegalDetails>
                                <CustomerBrandElementDetails></CustomerBrandElementDetails>
                                <CustomerNotificationDetails></CustomerNotificationDetails>
                                <CustomerLicenseDetails></CustomerLicenseDetails>
                                <CustomerManualSync></CustomerManualSync>
                            </div>
                        </div>
                    </div>
                </div>
                {/*        
 </div>
    </div>    */}
                <footer className="fixed-bottom">
                    <div className="tertiary-color-bg font-12 sticky-footer py-1">
                        <span className="color-white font-12 font-semibold d-flex justify-content-center">
                            © Avasoft Inc 2023
                        </span>
                    </div>
                </footer>
            </>

        </>
    )
}

export default CustomerDetails


