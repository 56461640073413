import CryptoJS from "crypto-js";

export function encrypt({ key, data }: any): string {
  const keyWordArray = CryptoJS.enc.Utf8.parse(key);
  const iv = CryptoJS.lib.WordArray.random(16);
  const plaintext = JSON.stringify(data);
  const encrypted = CryptoJS.AES.encrypt(plaintext, keyWordArray, {
    iv: iv,
    mode: CryptoJS.mode.CFB,
    padding: CryptoJS.pad.NoPadding,
  });
  const encryptedMessage = iv
    .concat(encrypted.ciphertext)
    .toString(CryptoJS.enc.Base64);
  return encryptedMessage;
}

export function decrypt(key:any, encryptedMessage:any): string {
  const keyWordArray = CryptoJS.enc.Utf8.parse(key);
  const encryptedMessageWordArray = CryptoJS.enc.Base64.parse(encryptedMessage);
  const iv = encryptedMessageWordArray.clone();
  iv.sigBytes = 16;
  encryptedMessageWordArray.words.splice(0, 4);
  encryptedMessageWordArray.sigBytes -= 16;

  const cipherParams = CryptoJS.lib.CipherParams.create({
    ciphertext: encryptedMessageWordArray,
    key: keyWordArray,
  });

  const decrypted = CryptoJS.AES.decrypt(cipherParams, keyWordArray, {
    iv: iv,
    mode: CryptoJS.mode.CFB,
    padding: CryptoJS.pad.NoPadding,
  });

  const decryptedPlaintext = decrypted.toString(CryptoJS.enc.Utf8);
  const decryptedObject = JSON.parse(decryptedPlaintext);
  return decryptedObject;
}