import { quotesUrl, backendurl as url } from "../configs/constant";
import { client } from "../client";

//PC_GQ_1, PC_GQ_2, PC_GQ_3, PC_GQ_4, PC_GQ_5
// get Customer Quotes Grid Details
export async function getCustomerQuotesGridDetails(data: {} | undefined) {
    
    let Config = {
        url: url + quotesUrl.getquotes,
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        body: data
    };

    let response = await client(Config);
    return response;
}
//PC_UQ_6 PC_UQ_7 PC_UQ_8 PC_UQ_9 
//soft delete the customer Quotes Details
export async function deleteQuotesDetails(data: {} | undefined) {
    let Config = {
        url: url + quotesUrl.deleteQuotes,
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        body: data
    };

    let response = await client(Config);
    return response;
}

//PC_NO_IN_106, PC_NO_IN_107, PC_NO_IN_109
//get the Quotes License Details By Id from DB
export async function getQuotesLicenseDetailsById(data: {} | undefined) {
    let Config = {
        url: url + quotesUrl.editquotes,
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        body: data
    };

    let response = await client(Config);
    return response;
}
// PC_NO_IN_110, PC_NO_IN_111, 
// get the From Details from the database through this Api
export async function getFromDetailsApi(data: {} | undefined = {}) {
    let Config = {
        url: url + quotesUrl.getfromDetails,
        headers: {
            "content-type": "application/json",
        },
        method: "get",
        body: data
    };

    let response = await client(Config);
    return response;
}
//PC_NO_IN_112.1, PC_NO_IN_112.2,
// update the Quotes License Details ById
export async function updateQuotesLicenseDetailsById(data: {} | undefined) {
    let Config = {
        url: url + quotesUrl.updatelicense,
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        body: data
    };

    let response = await client(Config);
    return response;
}
// send the Quotes to the customer
// PC_DQ_10 PC_DQ_11 PC_DQ_12 PC_DQ_13
export async function sendQuotesApi(data: {} | undefined) {
    let Config = {
        url: url + quotesUrl.sendQuotes,
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        body: data
    };

    let response = await client(Config);
    return response;
}

//previewQuotes
export async function previewQuotes(data: {} | undefined) {
    let Config = {
        url: url + quotesUrl.previewQuotes,
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        body: data
    };

    let response = await client(Config);
    return response;
}