import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function BreadCrumbs(props: any) {
  const navigate: any = useNavigate();
  const [breadCrumbs, setBreadcrumbs] = useState<any>([]);

  useEffect(() => {

   setBreadcrumbs(props.data);
  }, [props]);

  return (
   
    <div className="mb-2 mt-4 ms-4">
      <ul className="breadcrumb mb-0 font-semibold">
        {breadCrumbs !== undefined
          ? breadCrumbs.map((breadcrumb: any, index: any) => {
              return breadcrumb.isActive ? (
                <li key={index}>
                  {breadcrumb.path ? (
                    <a
                      className="cursor"
                      onClick={() => navigate(breadcrumb.path)}
                    >
                      {breadcrumb.title}
                    </a>
                  ) : (
                    <span className="cursor font-medium color-black-v3 pe-2 font-14">{breadcrumb.title}</span>
                  )}
                </li>
              ) : (
                <li key={index} className="pe-2 font-semibold">
                  {breadcrumb.title}
                </li>
              );
            })
          : null}
      </ul>
    </div>
  );
}
