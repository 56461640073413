import { client } from "../../src/client";
import { backendurl as url ,loginUrl } from "../configs/constant";//PS_AD_14


export async function getRole(data:any ={}) {//PS_AD_15

  let Config = {//PS_AD_16
    url: url + loginUrl.getRole,
    headers: {
      "content-type": "application/json",
    },
    method: "post",
    body: data,
  };

  let response = await client(Config);//PS_AD_17
  return response;//PS_AD_18
}



export async function isRpaUser(data:any ={}) {//PS_AD_15

  let Config = {//PS_AD_16
    url: url + loginUrl.isRpaUser,
    headers: {
      "content-type": "application/json",
    },
    method: "post",
    body: data,
  };

  let response = await client(Config);//PS_AD_17
  return response;//PS_AD_18
}


export async function uploadProductLogo(data:any ={}) {//PS_AD_15

  let Config = {//PS_AD_16
    url: url+`/login/uploadProductLogo`,
    headers: {
      "content-type": "application/json",
    },
    method: "post",
    body: data,
  };

  let response = await client(Config);//PS_AD_17
  return response;//PS_AD_18
}





