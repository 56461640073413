import React from "react"
import axios from "axios"
import { invetoryUrl, backendurl as url } from "../configs/constant"
import { client } from "../../src/client"
//**ps_IS_2, ps_IS_3, ps_IS_4, ps_IS_5
//Fetch the product details */
export const getproductsApi = async (data: any = {}) => {

    let config = {
        method: 'POST',
        url: url + invetoryUrl.getProducts,
        headers: {
            'Content-Type': 'application/json'
        },
        body: data
    }

    let response = await client(config)
    return response
}

//** ps_IS_6, ps_IS_7, ps_IS_8, ps_IS_9
// Fetch the Categories details */
export const getCategoriesApi = async (data: any = {}) => {


    let config = {
        method: 'get',
        url: url + invetoryUrl.getCategories,
        headers: {
            'Content-Type': 'application/json'
        },
        body: data
    }

    let response = await client(config)
    return response
}
//** ps_IS_34, ps_IS_35, ps_IS_36, ps_IS_37 remove the category of the product */
export const removeCategoryApi = async (data: any = {}) => {


    let config = {
        method: 'post',
        url: url + invetoryUrl.removeCategories,
        headers: {
            'Content-Type': 'application/json'
        },
        body: data
    }

    let response = await client(config)
    return response
}
//** ps_IS_38, ps_IS_39, ps_IS_40, ps_IS_41 add the category  */
export const addCategoryApi = async (data: any = {}) => {


    let config = {
        method: 'post',
        url: url + invetoryUrl.addcategory,
        headers: {
            'Content-Type': 'application/json'
        },
        body: data
    }

    let response = await client(config)
    return response
}
//** ps_IS_6, ps_IS_7, ps_IS_8, ps_IS_9
// To insert the new category product details */
export const addNewCategoryApi = async (data: any = {}) => {

    let config = {
        method: 'post',
        url: url + invetoryUrl.newCategory,
        headers: {
            'Content-Type': 'application/json'
        },
        body: data
    }

    let response = await client(config)
    return response
}
//** ps_IS_38, ps_IS_39, ps_IS_40, ps_IS_41 remove the category  */
export const removeCancelledCategory = async (data: any = {}) => {

    let config = {
        method: 'post',
        url: url + invetoryUrl.cancelledcategory,
        headers: {
            'Content-Type': 'application/json'
        },
        body: data
    }

    let response = await client(config)
    return response
}

//** ps_IS_46, ps_IS_47, ps_IS_48, ps_IS_49 Fetch the addon product details*/
export const getAddonsAPi = async (data: any = {}) => {

    let config = {
        method: 'post',
        url: url + invetoryUrl.addons,
        headers: {
            'Content-Type': 'application/json'
        },
        body: data
    }

    let response = await client(config)
    return response
}

  //** ps_IS_22, ps_IS_23, ps_IS_24, ps_IS_25 Fetch the Customer detail */
export const getcustomerApi = async (data: any = {}) => {

    let config = {
        method: 'get',
        url: url + invetoryUrl.getcustomer,
        headers: {
            'Content-Type': 'application/json'
        },
        body: data
    }

    let response = await client(config)
    return response
}
//**ps_IS_30,ps_IS_31,ps_IS_32,ps_IS_33 Fetch the Availability of the product*/
export const getavailablityApi = async (data: any = {}) => {

    let config = {
        method: 'post',
        url: url + invetoryUrl.getavailablity,
        headers: {
            'Content-Type': 'application/json'
        },
        body: data
    }

    let response = await client(config)
    return response
}
///** ps_IS_50, ps_IS_51, ps_IS_52, ps_IS_53 Check the eligibility */
export const CheckPromoEligibility = async (data: any = {}) => {

    let config = {
        method: 'post',
        url: url + invetoryUrl.promo,
        headers: {
            'Content-Type': 'application/json'
        },
        body: data
    }

    let response = await client(config)
    return response
}
//** ps_IS_54, ps_IS_55, ps_IS_56, ps_IS_57 Fetch the termduration of the product */
export const getTermsApi = async (data: any = {}) => {

    let config = {
        method: 'post',
        url: url + invetoryUrl.getTerms,
        headers: {
            'Content-Type': 'application/json'
        },
        body: data
    }

    let response = await client(config)
    return response
}
//** ps_IS_26, ps_IS_27, ps_IS_28, ps_IS_29 Fetch the cart details  */
export const addCart = async (data: any = {}) => {

    let config = {
        method: 'post',
        url: url + invetoryUrl.addcart,
        headers: {
            'Content-Type': 'application/json'
        },
        body: data
    }

    let response = await client(config)
    return response
}

//** ps_IS_58, ps_IS_59, ps_IS_60, ps_IS_61 Add the quotes details  */
export async function addQuotesApi(data: {} | undefined) {
    let Config = {
        url: url + invetoryUrl.addQuotes,
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        body: data
    };

    let response = await client(Config);
    return response;
}
//** ps_IS_62, ps_IS_63, ps_IS_64, ps_IS_65 insert the license details */
export async function insertLicenseApi(data: {} | undefined) {
    let Config = {
        url: url + invetoryUrl.addLicense,
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        body: data
    };

    let response = await client(Config);
    return response;
}









