import React from "react";
import { backendurl as url, customerUrl, dashboardUrl } from "../configs/constant";
import { client } from "../client";


//** //PC_D_1 PC_D_2 PC_D_3 PC_D_4 PC_D_5
// to get the count of card */
export async function getCardCount(request: any) {
    
    let Request = {
        url: url + dashboardUrl.getCardCount,
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        body: request,
    };

    let response = await client(Request);//PC_NO_102
    return response;
}

//** //  //** PC_NO_127 PC_NO_128 PC_NO_129 PC_NO_130 */
// get the revenue cost graph */
export async function getRevenueCostGraph(request: any) {
    let Request = {
        url: url + dashboardUrl.getRevenueCostGraph,
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        body: request,
    };

    let response = await client(Request);//PC_NO_102
    return response;
}

//**// get the top ten customer details woth profit
  //** PC_NO_127 PC_NO_128 PC_NO_129 PC_NO_130 */ */

export async function getTopTenCustomerProfit(request: any) {

    let Request = {
        url: url + dashboardUrl.getTopTenCustomerProfit,
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        body: request,
    };

    let response = await client(Request);//PC_NO_102
    return response;
}
/**     //PC_D_6 PC_D_7 PC_D_8 PC_D_9
// get the upcoming renewal */
export async function getUpcomingRenewal(request: any) {

    let Request = {
        url: url + dashboardUrl.getupcomingrenewal,
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        body: request,
    };

    let response = await client(Request);//PC_NO_102
    return response;
}
