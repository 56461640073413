import React, { useEffect, useState } from "react";
//import ActivityGrid from './ActivityGrid'
import { useNavigate } from "react-router-dom";
import {
  getCustomerOrderGridDetails,
  getOrderFilterDropdownData,
} from "../../services/orderService";
import {
  FilterDropDown,
  OrderFilter,
  customerSessionData,
  userSessionData
} from "../../interface";
import { ErrorLog } from "../../services/errorService";
import {
  defaultLimit,
  sessionKeys,
  encryptStorage,

} from "../../configs/constant";
import { Loader } from "../loader";
import ActivityGrid from "./activityGrid";
//** PC_NO_O_1,PC_NO_O_2.1
//Fetch the Order details  */
function OrderGrid() {
  const navigate = useNavigate();
  let customerSessionDetails: customerSessionData | null = JSON.parse(
    encryptStorage.getItem(sessionKeys.customerDetails) || "null"
  );
  let filterData: OrderFilter = {
    customer_id: customerSessionDetails?.customer_id?.toString(),
    status: "",
    from: "",
    to: "",
  };
  const [customerId, setCustomerId] = useState(
    customerSessionDetails?.customer_id
  );
  const [orderDetails, setOrderDetails] = useState<any[]>([]);
  const [loader, setloader] = useState<boolean>(false);
  const [sortType, setSortType] = useState<string>("DESC");
  const [sortColumn, setSortColumn] = useState<string>("created_date");
  const [companyName, setCompanyNameSort] = useState<boolean>(false);
  const [orderIDSort, setOrderIDSort] = useState<boolean>(false);
  const [quantitySort, setQuantitySort] = useState<boolean>(false);
  const [provisionedSort, setProvisionedSort] = useState<boolean>(false);
  const [StatusSort, setStatusSort] = useState<boolean>(false);
  const [count, setCount] = useState<number>(defaultLimit);
  const [search_value, setSearch_value] = useState<string>("");
  const [filterCompanyName, setFilterCompanyName] = useState<FilterDropDown[]>(
    []
  );
  const [filterStatus, setFilterStatus] = useState<FilterDropDown[]>([]);
  const [FilterDetails, setFilterDetails] = useState<OrderFilter>(filterData);
  const [errorStatus, setErrorStatus] = useState<boolean>(false);
  const [sortHide, setSortHide] = useState<string>("");
  const [OrderCount, setOrderCount] = useState<any>(0);
  const [ActivityCount, setActivityCount] = useState<any>(0);
  const [recordCount, setRecordCount] = useState<any>(0);

  const [activeTab, setActiveTab] = useState("orders");
  const [invokeActivity, setInvokeActivity] = useState<boolean>(false);
  const [action, setAction] = useState("noAction");
  const [view, setView] = useState(
    customerSessionDetails?.customer_id ? "customerView" : "overallview"
  );
  const [isOpenAccordions, setIsOpenAccordions] = useState(() => {
    const initialState = Array(orderDetails.length).fill(false);
    initialState[0] = true; // Set the first item to true
    return initialState;
  });
  const [rerenderComponent, setrerenderComponent] = useState(false);
  const [filterPopup, setFilterPopup] = useState<boolean>(false);

  let userDetails: userSessionData | null = JSON.parse(
    encryptStorage.getItem(sessionKeys.userDetails) || "null"
  );
  //PC_NO_O_3
  useEffect(() => {
    getOrderGridDetails();
  }, [
    sortColumn,
    sortType,
    companyName,
    orderIDSort,
    quantitySort,
    provisionedSort,
    StatusSort,
    count,
    rerenderComponent
  ]);
  useEffect(() => {
    getFilterDetails();
  }, []);

  const [openAccordion, setOpenAccordion] = useState<number | null>(null);

  // const toggleAccordion = (index: number) => {
  //   setOpenAccordion((prevIndex) => (prevIndex === index ? null : index));
  // };
  const handleAccordionClick = (index: any) => {
    const updatedAccordions = [...isOpenAccordions];
    updatedAccordions[index] = !updatedAccordions[index];
    setIsOpenAccordions(updatedAccordions);
  };
  //** PC_NO_O_4, PC_NO_O_5, PC_NO_O_6, PC_NO_O_7, PC_NO_O_8, PC_NO_O_9, PC_NO_O_10,
  //Fetch the Order Grid details */
  async function getOrderGridDetails(filter = FilterDetails) {
    try {
      setloader(true);
      const payload: any = {
        user_id: customerSessionDetails?.customer_id ? userDetails?.user_id : '',
        customer_id: filter.customer_id ? filter.customer_id : customerSessionDetails?.customer_id || '',
        status: filter.status,
        from_date: filter.from,
        to_date: filter.to,
        search_value: search_value,
        sort_column: sortColumn,
        sort_order: sortType,
        count: count,
        view: view,
      };

      const response = await getCustomerOrderGridDetails(payload);

      if (response.data.statusCode === 200) {
        setOrderDetails(response?.data?.responseData?.orderDetails);
        setOrderCount(response?.data?.responseData?.totalRecordCount);
        setRecordCount(response?.data?.responseData?.recordCount);
        setActivityCount(
          response?.data?.responseData?.activityTotalRecordCount
        );
        setloader(false);
      }
    } catch (Error: any) {
      console.error(Error);

      const errorObject = {
        stack_name: "frontend_react",
        module_name: "Order_module",
        function_name: "getOrderGridDetails",
        error_message: `${Error?.message}`,
      };
      if (Error) {
        const request: any = await ErrorLog(errorObject);
        return request.data.statusCode === 200
          ? setErrorStatus(errorStatus)
          : setErrorStatus(!errorStatus);
      }
    }
  }
  //**  PC_NO_O_11, PC_NO_O_12
  //Fetch the Filter Details */
  async function getFilterDetails(
    search = search_value,
    filter = FilterDetails
  ) {
    const response = await getOrderFilterDropdownData();
    if (response.data.status) {
      setFilterCompanyName(response.data.responseData[0]);
      setFilterStatus(response.data.responseData[1]);
    }
  }
  //** , PC_NO_O_38.7 Perform the Clear operation */
  const handleClear = () => {
    let filterDatas: OrderFilter = {
      customer_id: "",
      status: "",
      from: "",
      to: "",
    };

    setFilterDetails(filterDatas);
    if (activeTab == "orders") {
      getOrderGridDetails(filterDatas);
    } else {
      setInvokeActivity(!invokeActivity);
      setAction("filterClear");
    }
  };
  //** PC_NO_O_38.8 Perform the apply peration in the Filter pop-up */
  const applyClick = () => {
    if (activeTab == "orders") {
      getOrderGridDetails();
    } else {
      setInvokeActivity(!invokeActivity);
      setAction("filter");
    }
    setFilterPopup(false);
  };
  //** ,PC_NO_O_13 To perform the search operation */
  const onSearch = (e: any) => {
    if (e.key === "Enter") {
      if (activeTab == "activity") {
        setInvokeActivity(!invokeActivity);
        setAction("search");
      } else {
        getOrderGridDetails();
      }
    }
  };
  //** PC_NO_O_38.9 Perform the Search operation during the click function call */
  const onSearchClick = () => {
    if (activeTab == "orders") {
      getOrderGridDetails();
    } else {
      setInvokeActivity(!invokeActivity);
      setAction("search");
    }
  };
  //PC_NO_O_38.10 Perform the date formating in the grid part
  function formatDateToCustomFormat(isoDate: any) {
    const date = new Date(isoDate);
    const formattedDate = date.toLocaleString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
    return formattedDate;
  }

  //**PC_NO_O_38.11 Fetch the Order status details and based on details to render the order status */
  function RenderComponent(props: { condition: any }) {
    const { condition } = props;

    let componentToRender;

    switch (condition.toLowerCase()) {
      case "suspended":
        componentToRender = (
          <>
            <td className="custom-suspended-color text-start">
              <span className="custom-suspended-dot"></span>Suspended
            </td>
          </>
        );
        break;

      case "provisioned":
        componentToRender = (
          <>
            <td className="color-light-green text-start">
              <span className="custom-green-dot"></span>Provisioned
            </td>
          </>
        );
        break;

      case "expired":
        componentToRender = (
          <>
            <td className="custom-expired-color text-start">
              <span className="custom-expired-dot"></span>Expired
            </td>
          </>
        );
        break;
      case "cancelled":
        componentToRender = (
          <>
            <td className="custom-expired-color text-start">
              <span className="custom-expired-dot"></span>Cancelled
            </td>
          </>
        );
        break;
      case "completed":
        componentToRender = (
          <>
            <td className="color-light-green text-start">
              <span className="custom-green-dot"></span>Completed
            </td>
          </>
        );
        break;
      case "error":
        componentToRender = (
          <>
            <td className="color-red text-start">
              <span className="custom-deleted-dot"></span>Error
            </td>
          </>
        );
        break;
      case "pending":
        componentToRender = (
          <>
            <td className="custom-suspended-color text-start">
              <span className="custom-suspended-dot"></span>Pending
            </td>
          </>
        );
        break;
      default:
        componentToRender = (
          <>
            <td className="text-start">
              <span className=""></span>
              {condition}
            </td>
          </>
        );
    }

    return <>{componentToRender}</>;
  }

  //old
  // function CompanyOrdersTable() {

  //     return (
  //         <>
  //             {orderDetails?.map((orders: any, index: any) => (
  //                 <React.Fragment key={index}>
  //                     <tr className="">
  //                         <td className="text-center">
  //                             <span>
  //                                 <img
  //                                     src="Images/accordion-arrow.svg"
  //                                     alt="acc-arrow"
  //                                     className="cursor-pointer collapsed accordion-arrow"
  //                                     data-bs-toggle="collapse"
  //                                     data-bs-target={`#customer${index + 1}`}
  //                                     aria-expanded="false"
  //                                 />
  //                             </span>
  //                         </td>
  //                         <td>{orders?.companyName}</td>
  //                         <td />
  //                         <td />
  //                         <td />
  //                         <td />
  //                         <td />
  //                         <td />
  //                     </tr>
  //                     {orders?.orderData?.map((order: any, orderIndex: any) => (
  //                         <tr
  //                             key={orderIndex}
  //                             id={`customer${index + 1}`}
  //                             className="accordion-collapse collapse themeTable"
  //                         >
  //                             <td className="color-white-bg" />
  //                             <td />
  //                             <td className="link-blue cursor-pointer text-start"
  //                                 onClick={() => navigate('/orderDetails', { state: { mode: "view", "order_id": order.order_id, "customerName": orders?.companyName } })} >{order.order_id}</td>
  //                             <td className="text-end pe-5">{order.quantity}</td>
  //                             <td className="text-start">{order.created_by}</td>
  //                             <td className="text-start">{formatDateToCustomFormat(order.created_date)}</td>
  //                             <RenderComponent condition={order.status} ></RenderComponent>
  //                             <td className="color-white-bg" />
  //                         </tr>
  //                     ))}
  //                 </React.Fragment>
  //             ))}
  //         </>
  //     );
  // }
  //** PC_NO_O_38.12 to iterate the orderDetails state variable value into the  html grid component */
  function CompanyOrdersTable() {
    // return (
    //   <>
    //     {orderDetails?.map((orders: any, index: any) => (

    //       <React.Fragment key={index}>
    //         <tr className="">
    //           <td className="text-center">
    //             <span>
    //               <img
    //                 src="Images/accordion-arrow.svg"
    //                 alt="acc-arrow"
    //                 className={`cursor-pointer accordion-arrow ${isOpen ? "" : "collapsed"
    //               }`}
    //             onClick={() => handleAccordionClick(index)}
    //             data-bs-toggle="collapse"
    //             data-bs-target={`#customer${index + 1}`}
    //             aria-expanded={isOpen ? "true" : "false"}
    //               />
    //             </span>
    //           </td>
    //           <td>{orders?.companyName}</td>
    //           <td />
    //           <td />
    //           <td />
    //           <td />
    //           <td />
    //           <td />
    //         </tr>
    //         {orders?.orderData?.map((order: any, orderIndex: any) => (
    //           <tr
    //             key={orderIndex}
    //             id={`customer${index + 1}`}
    //             className={`accordion-collapse collapse themeTable ${
    //               openAccordion === index ? "show" : ""
    //             }`}
    //           >
    //             <td className="color-white-bg" />
    //             <td />
    //             <td
    //               className="link-blue cursor-pointer text-start"
    //               onClick={() =>
    //                 navigate("/orderDetails", {
    //                   state: {
    //                     mode: "view",
    //                     order_id: order.order_id,
    //                     customerName: orders?.companyName,
    //                   },
    //                 })
    //               }
    //             >
    //               {order.order_id}
    //             </td>
    //             <td className="text-end pe-5">{order.quantity}</td>
    //             <td className="text-start">{order.created_by}</td>
    //             <td className="text-start">
    //               {formatDateToCustomFormat(order.created_date)}
    //             </td>
    //             <RenderComponent condition={order.status}></RenderComponent>
    //             <td className="color-white-bg" />
    //           </tr>
    //         ))}
    //       </React.Fragment>
    //     ))}
    //   </>
    // );
    return orderDetails.map((orders: any, index: any) => {
      const isOpen = isOpenAccordions[index];

      return (
        <>
          <React.Fragment key={index}>
            <tr className="">
              <td className="text-center">
                <span>
                  <img
                    src="Images/accordion-arrow.svg"
                    alt="acc-arrow"
                    className={`cursor-pointer accordion-arrow ${isOpen ? "" : "collapsed"
                      }`}
                    onClick={() => handleAccordionClick(index)}
                    data-bs-toggle="collapse"
                    data-bs-target={`#customer${index + 1}`}
                    aria-expanded={isOpen ? "true" : "false"}
                  />
                </span>
              </td>
              <td>{orders?.companyName}</td>
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
            </tr>
            {orders?.orderData?.map((order: any, orderIndex: any) => (
              <tr
                id={`customer${index + 1}`}
                className={`accordion-collapse collapse ${isOpen ? "show" : ""
                  } themeTable`}
              >
                <td className="color-white-bg" />
                <td />
                <td
                  className="link-blue cursor-pointer text-start"
                  onClick={() =>
                    navigate("/orderDetails", {
                      state: {
                        mode: "view",
                        order_id: order.order_id,
                        customerName: orders?.companyName,
                      },
                    })
                  }
                >
                  {order.order_id}
                </td>
                <td className="text-end pe-5">{order.quantity}</td>
                <td className="text-start">{order.created_by}</td>
                <td className="text-start">
                  {formatDateToCustomFormat(order.created_date)}
                </td>
                <RenderComponent condition={order.status}></RenderComponent>
                <td className="color-white-bg" />
              </tr>
            ))}
          </React.Fragment>
        </>
      );
    });
  }

  const onSort = (e: any) => {
    try {
      let sortID = e.target.id;
      let sortval = sortID.split("@");
      setSortColumn(sortval[0]);
      setSortType(sortval[1]);
      setSortHide(sortID);
    } catch (e: any) {
      console.log(e.message);
    }
  };

  function CallbackActivityCount(count: any) {
    setActivityCount(count);
  }

  return (
    <>
      <div className="container-fluid px-4 pt-3">
        {loader ? <Loader /> : <></>}
        <div className="row">
          <div className="d-flex justify-content-between mb-4">
            <div className="d-inline-flex">
              <ul
                className="nav nav-pills font-14 font-semibold custom-pills color-white-bg rounded-2"
                id="newtab"
                role="tablist"
              >
                <li className="  nav-item  m-2" role="presentation">
                  <button
                    className=" custom-tab-btn nav-link  active d-flex"
                    id="order-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#order"
                    type="button"
                    role="tab"
                    aria-controls="order"
                    aria-selected="true"
                    onClick={(e) => {
                      setActiveTab("orders");
                      setSearch_value("");
                      setFilterDetails(filterData);
                      setCount(defaultLimit);
                      setrerenderComponent(!rerenderComponent)
                    }}
                  >
                    Orders
                    <span className="tab-batch font-9 d-flex align-items-center justify-content-center ms-2">
                      {OrderCount}
                    </span>
                  </button>
                </li>
                <li className="  nav-item  m-2" role="presentation">
                  <button
                    className=" custom-tab-btn nav-link d-flex px-2"
                    id="activity-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#activity"
                    type="button"
                    role="tab"
                    aria-controls="activity"
                    aria-selected="false"
                    onClick={(e) => {
                      setActiveTab("activity");
                      setSearch_value("");
                      setFilterDetails(filterData);
                    }}
                  >
                    Activity Log
                    <span className="tab-batch font-9 d-flex align-items-center justify-content-center ms-2">
                      {ActivityCount}
                    </span>
                  </button>
                </li>
              </ul>
            </div>
            <div className="d-block d-md-flex">
              <div className="w-md-100 d-flex mb-3 mb-md-0 align-items-center">
                {/* <span className="position-relative d-flex align-items-center  me-4 w-100">
                                    <input
                                        type="text"
                                        className="border-0 font-regular rounded-3 w-230  font-14 top-0 bottom-0 py-2 px-3 custom-input"
                                        id="search"
                                        placeholder="Search"
                                        value={search_value}
                                        onKeyPress={(e) => { onSearch(e) }}
                                        onChange={(e:any) => { setSearch_value(e.target.value) }}
                                    />
                                    <span className="position-absolute search-icon" onClick={() => {onSearchClick()}}>
                                        <img src="Images/search.svg" alt="search-icon" />
                                    </span>
                                </span> */}
                <span className="position-relative d-flex align-items-center me-3 w-100">
                  <input
                    type="search"
                    className="border-0 font-regular w-230 cus-z-ind  rounded-2 font-14 top-0 bottom-0 py-2 px-3 custom-input"
                    id="search"
                    placeholder="Search"
                    value={search_value}
                    onKeyPress={(e) => {
                      onSearch(e);
                    }}
                    onChange={(e: any) => {
                      setSearch_value(e.target.value);
                    }}
                  />
                  <span className="position-absolute search-icon">
                    <img
                      src="Images/search.svg"
                      alt="search-icon"
                      onClick={() => {
                        onSearchClick();
                      }}
                    />
                  </span>
                </span>
                <div className="btn-group">
                  <button
                    type="button"
                    className="btn secondary-btn custom-filter rounded-3 height-37"
                    onClick={() => { setFilterPopup(true) }}
                  >
                    <img
                      src="Images/filter-active-icon.svg"
                      alt="Filter"
                      className="custom-filter-active-img"
                    />
                    <img
                      src="Images/Filter.svg"
                      alt="Filter"
                      className="custom-filter-img"
                    />
                  </button>
                  {filterPopup ? <div className="dropdown-menu dropdown-menu-end adv-dropdown p-3 mt-1 border-0 end-0 psoition-absolute show">
                    <div className="row">
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <h6 className="font-16 font-semibold tertiary-color mb-0 ">
                          Filter
                        </h6>
                        <button
                          type="button"
                          className="btn-close"
                          onClick={() => { setFilterPopup(false) }}
                        />
                      </div>
                      {customerId || activeTab == "activity" ? (
                        <></>
                      ) : (
                        <div className="col-sm-12 col-md-6 mb-4">
                          <label className="form-label font-14 font-semibold color-black">
                            Customer Name
                          </label>

                          <select
                            className="form-select custom-select custom-input font-regular font-14"
                            id="customer_id"
                            value={FilterDetails?.customer_id?.toString()}
                            onChange={(e) => {
                              setFilterDetails({
                                ...FilterDetails,
                                customer_id: e.target.value,
                              });
                            }}
                          >
                            <option selected value="">
                              Select
                            </option>
                            {filterCompanyName?.map((val: any, index: any) => (
                              <option value={val.customer_id}>
                                {" "}
                                {val.company_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                      {activeTab == "activity" ? (
                        <></>
                      ) : (
                        <div className="col-sm-12 col-md-6 mb-4">
                          <label className="form-label font-14 font-semibold color-black">
                            Status
                          </label>
                          <select
                            className=" form-select custom-select custom-input font-regular font-14"
                            value={FilterDetails.status}
                            onChange={(e) => {
                              setFilterDetails({
                                ...FilterDetails,
                                status: e.target.value,
                              });
                            }}
                          >
                            <option selected value="">
                              Select
                            </option>
                            {filterStatus?.map((val: any, index: any) => (
                              <option value={val.status}>{val.status}</option>
                            ))}
                          </select>
                        </div>
                      )}
                      <h6 className="font-16 font-semibold tertiary-color mb-3 ">
                        Date Range
                      </h6>
                      <div className="col-sm-12 col-md-6 mb-5">
                        <label
                          htmlFor="date1"
                          className="form-label font-14 font-semibold color-black"
                        >
                          From
                        </label>
                        <input
                          type="date"
                          className="form-control custom-input"
                          id="date1"
                          value={FilterDetails.from}
                          max={FilterDetails.to}
                          onChange={(e) => {
                            setFilterDetails({
                              ...FilterDetails,
                              from: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 mb-5">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label font-14 font-semibold color-black"
                        >
                          To
                        </label>
                        <input
                          type="date"
                          className="form-control custom-input"
                          id="exampleFormControlInput1"
                          value={FilterDetails.to}
                          min={FilterDetails.from}
                          onChange={(e) => {
                            setFilterDetails({
                              ...FilterDetails,
                              to: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="d-flex justify-content-end">
                        <button
                          type="button"
                          className="btn text border-0 font-14 font-semibold me-3 shadow-none text-btn"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={() => {
                            handleClear();
                          }}
                        >
                          Clear
                        </button>
                        <button
                          type="button"
                          className="btn primary-btn border-0 font-14 font-semibold "
                          onClick={() => {
                            applyClick();
                          }}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div> : ''}
                </div>
              </div>
            </div>
          </div>
        </div>

        {activeTab == "orders" ? (
          <>
            <div className="tab-content mb-5 pb-5" id="pills-newtab">
              <div className="table-responsive">
                <table className="table mb-0 themeTable  ">
                  <thead className="bg-white">
                    <tr className="">
                      <th className="custom-th-width" />
                      <th className="text-start">
                        Customer Name
                        <span className="ms-2 cursor-pointer">
                          <img
                            src="Images/sort-up-arrow.svg"
                            alt="sortarrow"
                            title="Sort"
                            id="company_name@DESC"
                            onClick={(e) => onSort(e)}
                            hidden={
                              sortHide === "company_name@ASC" ? false : true
                            }
                          />
                          <img
                            src="Images/sort-arrow.svg"
                            alt="sortarrow"
                            title="Sort"
                            id="company_name@ASC"
                            onClick={(e) => onSort(e)}
                            hidden={
                              sortHide === "company_name@ASC" ? true : false
                            }
                          />
                        </span>{" "}
                      </th>
                      <th className="text-start">
                        Order ID
                        <span className="ms-2 cursor-pointer">
                          <img
                            src="Images/sort-up-arrow.svg"
                            alt="sortarrow"
                            title="Sort"
                            id="order_id@DESC"
                            onClick={(e) => onSort(e)}
                            hidden={sortHide === "order_id@ASC" ? false : true}
                          />
                          <img
                            src="Images/sort-arrow.svg"
                            alt="sortarrow"
                            title="Sort"
                            id="order_id@ASC"
                            onClick={(e) => onSort(e)}
                            hidden={sortHide === "order_id@ASC" ? true : false}
                          />
                        </span>
                      </th>
                      <th className="text-end pe-5">
                        Quantity
                        <span className="ms-2 cursor-pointer">
                          <img
                            src="Images/sort-up-arrow.svg"
                            alt="sortarrow"
                            title="Sort"
                            id="quantity@DESC"
                            onClick={(e) => onSort(e)}
                            hidden={sortHide === "quantity@ASC" ? false : true}
                          />
                          <img
                            src="Images/sort-arrow.svg"
                            alt="sortarrow"
                            title="Sort"
                            id="quantity@ASC"
                            onClick={(e) => onSort(e)}
                            hidden={sortHide === "quantity@ASC" ? true : false}
                          />
                        </span>
                      </th>
                      <th className="text-start ">
                        Provisioned By
                        <span className="ms-2 cursor-pointer">
                          <img
                            src="Images/sort-up-arrow.svg"
                            alt="sortarrow"
                            title="Sort"
                            id="created_by@DESC"
                            onClick={(e) => onSort(e)}
                            hidden={
                              sortHide === "created_by@ASC" ? false : true
                            }
                          />
                          <img
                            src="Images/sort-arrow.svg"
                            alt="sortarrow"
                            title="Sort"
                            id="created_by@ASC"
                            onClick={(e) => onSort(e)}
                            hidden={
                              sortHide === "created_by@ASC" ? true : false
                            }
                          />
                        </span>
                      </th>
                      <th className="text-start ">
                        Date
                        <span className="ms-2 cursor-pointer">
                          <img
                            src="Images/sort-up-arrow.svg"
                            alt="sortarrow"
                            title="Sort"
                            id="created_date@DESC"
                            onClick={(e) => onSort(e)}
                            hidden={
                              sortHide === "created_date@ASC" ? false : true
                            }
                          />
                          <img
                            src="Images/sort-arrow.svg"
                            alt="sortarrow"
                            title="Sort"
                            id="created_date@ASC"
                            onClick={(e) => onSort(e)}
                            hidden={
                              sortHide === "created_date@ASC" ? true : false
                            }
                          />
                        </span>
                      </th>
                      <th className="text-start">
                        Status{" "}
                        <span className="ms-2 cursor-pointer">
                          <img
                            src="Images/sort-up-arrow.svg"
                            alt="sortarrow"
                            title="Sort"
                            id="status@DESC"
                            onClick={(e) => onSort(e)}
                            hidden={sortHide === "status@ASC" ? false : true}
                          />
                          <img
                            src="Images/sort-arrow.svg"
                            alt="sortarrow"
                            title="Sort"
                            id="status@ASC"
                            onClick={(e) => onSort(e)}
                            hidden={sortHide === "status@ASC" ? true : false}
                          />
                        </span>
                      </th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>{CompanyOrdersTable()}</tbody>
                </table>
              </div>

              {recordCount > count ? (
                <div className="d-flex justify-content-center my-3">
                  <button
                    type="button"
                    className="btn tertiary-btn border-0 font-14 font-semibold"
                    name="count"
                    value={count}
                    onClick={() => {
                      setCount(count + defaultLimit);
                    }}
                  >
                    Load More
                  </button>
                </div>
              ) : (
                <></>
              )}
              {orderDetails.length == 0 ? (
                <>
                  <div className="d-flex justify-content-center color-white-bg rounded-bottom pt-100 pb-400 mb-5">
                    <div>
                      <img
                        src="Images/no-records-found.svg"
                        alt="No-Records-Found"
                        className="d-block"
                      />
                      <div>
                        <p className="d-flex font-20 font-regular tertiary-color mt-3 justify-content-center">
                          No records found
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </>
        ) : (
          <ActivityGrid
            searchValue={search_value}
            filterValue={FilterDetails}
            makeCall={invokeActivity}
            action={action}
            activity={CallbackActivityCount}
          />
        )}
      </div>
    </>
  );
}

export default OrderGrid;
