import React, { useEffect, useState, useRef } from "react";
import {
  GetCustomerLegalDetailsById,
  updateCustomerLegalDetailsById,
} from "../../services/customerService";
import { ErrorLog } from "../../services/errorService";
import { useLocation, useNavigate } from "react-router-dom";
import uploadFileToBlob from "../../configs/blobContainer";
import { sessionKeys, encryptStorage } from "../../configs/constant";
import { userSessionData } from "../../interface";
import { Loader } from "../loader";
//** PC_NO_1, PC_NO_2, PC_NO_3, PC_NO_4, PC_NO_5,Fetch the Customer Legal details */

function CustomerLegalDetails() {

  const location = useLocation();

  let legalDetail = {
    tax_certification_url: "",
    tax_certificate: "",
    file_name: "",
  };
  const attachment: any = useRef();
  const navigate = useNavigate();
  const [CustomerLegalDetails, setCustomerLegalDetails] =
    useState<any>(legalDetail);
  const [customerId, setCustomerId] = useState(location?.state?.customer_id);

  const [errorStatus, setErrorStatus] = useState<boolean>(false);
  const [attachmentState, setattachmentState] = useState<any>(null);

  const [enable, setEnable] = useState(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [disableSave, setDisableSave] = useState(true);
  const [fileErrorMessage, setFileErrorMessage] = useState("");

  useEffect(() => {
    getCustomerLegalDetailsById();
  }, []);
//** PC_NO_20, PC_NO_21, PC_NO_22, PC_NO_23, PC_NO_24, PC_NO_25 to Fetch the Customer Legal details */

  async function getCustomerLegalDetailsById() {
    try {
      const customerRequest = {
        customer_id: customerId,
      };
      const response = await GetCustomerLegalDetailsById(customerRequest);

      if (response.data.statusCode === 200) {
        let data = response?.data?.responseData;
        data.tax_certificate = data?.tax_certificate ? "1" : "0";
        setCustomerLegalDetails(data);
      }
    } catch (Error: any) {
      console.error("Error fetching data:", Error);
      const errorObject = {
        stack_name: "frontend_react",
        module_name: "customer_module",
        function_name: "GetCustomerBasicDetailsByid",
        error_message: `${Error?.message}`,
      };
      if (Error) {
        const request: any = await ErrorLog(errorObject);
        return request.data.statusCode === 200
          ? setErrorStatus(errorStatus)
          : setErrorStatus(!errorStatus);
      }
    }
  }
//** PC_NO_61.11, PC_NO_61.12, PC_NO_61.13, PC_NO_61.14, PC_NO_61.15, PC_NO_61.16, PC_NO_61.17
// To perform the Upload operation into the blob conatainer */
  const uploadImage = async (e: any) => {
    try {
      setFileErrorMessage("");
      setDisableSave(false);
      let inputValue = e.target.files[0];
      setattachmentState(e.target.files[0]);
      let format = e.target.files[0].name.split(".").pop().toLowerCase();

      if (e.target.files[0].size >= 10000000) {
        setFileErrorMessage("File size should be less than 10MB");
        return false;
      }

      const typeArr = ["jpg", "png", "docx", "pdf"];
      let type = typeArr.includes(format);

      if (type) {
        // if (e.target.name === 'profile') setProfileValidate("")
        // else setCoverValidate("")

        //setattachmentState(e.target.files[0].name);

        //let img_url:any = await uploadFileToBlob(e.target.files[0], process.env.REACT_APP_Employee);

        CustomerLegalDetails.tax_certification_url = "";
        CustomerLegalDetails.file_name = e.target.files[0].name;
        setCustomerLegalDetails({ ...CustomerLegalDetails });

        // let response = await postProfileAndCoverImage({
        //     employee_id: empDetails.employeeId,
        //     [e.target.name === 'profile' ? 'profile_url' : 'cover_url']: img_url
        // });

        // if (response.status) loadEmployeeDetails();
      } else {
        setFileErrorMessage(
          "Upload the certificate in jpg/ png/ jpg/ pdf/ doc format"
        );
        // if (e.target.name === 'profile') setProfileValidate("Please upload the logo in jpg/ png/ jpeg/ webp/ svg/ svg+xml format")
        // else setCoverValidate("Please upload the logo in jpg/ png/ jpeg/ webp/ svg/ svg+xml format")
      }
    } catch (error) {}
  };
//** PC_NO_61.18 ,PC_NO_61.19 to perform the file validatationand upload that file into the blob container */
  const fileUpload = async () => {
    let file = attachmentState;
    if (file !== undefined && file != null) {
      let newFileName = attachmentState?.name;
      if (newFileName !== undefined && newFileName != null) {
        let fileData = await uploadFileToBlob(
          file,
          `AVAHUB/${customerId}/certificate`
        );
        return fileData;
      }
    }
  };
//** PC_NO_69, PC_NO_70, PC_NO_71, PC_NO_72, PC_NO_73, PC_NO_74, PC_NO_75
// to Update the legal details */
  const save = async () => {
    if (validate()) {
      setShowLoader(true);
      let Url: any;

      if (attachmentState) {
        Url = await fileUpload();
      }

      let sessionUserDetails: userSessionData | null = JSON.parse(
        encryptStorage.getItem(sessionKeys.userDetails) || "null"
      );

      Url = Url ? Url : CustomerLegalDetails.tax_certification_url;
      let payload = {
        tax_certification_url:
          CustomerLegalDetails.tax_certificate == "1" ? Url : null,
        tax_certificate: CustomerLegalDetails.tax_certificate,
        file_name:
          CustomerLegalDetails.tax_certificate == "1"
            ? CustomerLegalDetails.file_name
            : null,
        customer_id: customerId,
        user_id: sessionUserDetails?.user_id,
      };

      let resp = await updateCustomerLegalDetailsById(payload);

      if (resp.status) {
        getCustomerLegalDetailsById();
        setShowLoader(false);
        setDisableSave(true);
      }
    }
  };
//** PC_NO_82.74, PC_NO_82.75, PC_NO_82.76, PC_NO_82.77, PC_NO_82.78, PC_NO_82.79
// to clear the state variable for th Filter functionality */
  const clear = () => {
    CustomerLegalDetails.tax_certification_url = "";
    CustomerLegalDetails.file_name = "";
    setCustomerLegalDetails({ ...CustomerLegalDetails });
    setattachmentState(null);
    setEnable(false);
  };
//PC_NO_82.80, PC_NO_82.81, PC_NO_82.82 to perform the Validation
  const validate = () => {
    if (
      CustomerLegalDetails.tax_certificate == "1" &&
      !CustomerLegalDetails.tax_certification_url &&
      !attachmentState
    ) {
      setFileErrorMessage("Upload certificate");
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <div
        className="tab-pane fade"
        id="legal-detail"
        role="tabpanel"
        aria-labelledby="legaldetail-tab"
      >
        {showLoader ? <Loader /> : <></>}

        {location.state.mode == "view" ? (
          <>
            <p className="font-18 font-semibold tertiary-color mb-lg-4 py-lg-3 mb-2 px-3">
              Legal Details
            </p>
            <div className="row custom-row-height1">
              <div className="col-md-4">
                <div className="mb-5 px-3">
                  <label
                    htmlFor="legaltax"
                    className="form-label color-black-v2 font-14 font-bold"
                  >
                    Do you have tax certificate?
                  </label>
                  <div className="form-check form-switch mt-2 theme-switch">
                  <input
                      className="form-check-input "
                      type="checkbox"
                      disabled={true}
                      role="switch"
                      name="tax_certificate"
                      id="legaltax"
                      checked={
                        CustomerLegalDetails?.tax_certificate == "1"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        setCustomerLegalDetails({
                          ...CustomerLegalDetails,
                          [e.target.name]: e.target.checked ? "1" : "0",
                        });
                        setEnable(!enable);
                        setDisableSave(false);
                      }}
                    />
                    <label className="form-check-label" htmlFor="legaltax">
                    {CustomerLegalDetails?.tax_certificate == "1"
                        ? "Yes"
                        : "No"}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                {CustomerLegalDetails.tax_certificate == "1" ? (
                  <div className="mb-5">
                    <label className="font-14 font-bold primary-text-color mb-2">
                      Upload Certificate
                    </label>
                    <span className="upload-btn-wrapper me-2 d-block cursor-pointer ">
                      {!CustomerLegalDetails.file_name ? (
                        <button
                          type="button"
                          className="font-14  font-medium primary-color upload-btn border-0 shadow-none cursor-pointer"
                        >
                          <img
                            src="Images/uploads.svg"
                            alt="browse"
                            className="me-2"
                          />
                          Upload
                        </button>
                      ) : null}
                      <span className="link-blue px-2">
                        <a
                          href={CustomerLegalDetails.tax_certification_url}
                          target="_blank"
                          title=""
                          style={{ textDecoration: "none" }}
                        >
                          {CustomerLegalDetails.file_name}{" "}
                        </a>
                      </span>
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          </>
        ) : (
          <>
            <p className="font-18 font-semibold tertiary-color mb-lg-4 py-lg-3 mb-2 px-3">
              Legal Details
            </p>
            <div className="row custom-row-height1">
              <div className="col-md-4">
                <div className="mb-5 px-3">
                  <label
                    htmlFor="legaltax"
                    className="form-label color-black-v2 font-14 font-bold"
                  >
                    Do you have tax certificate?
                  </label>
                  <div className="form-check form-switch mt-2 theme-switch">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      role="switch"
                      name="tax_certificate"
                      id="legaltax"
                      checked={
                        CustomerLegalDetails?.tax_certificate == "1"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        setCustomerLegalDetails({
                          ...CustomerLegalDetails,
                          [e.target.name]: e.target.checked ? "1" : "0",
                        });
                        setEnable(!enable);
                        setDisableSave(false);
                      }}
                    />
                    <label className="form-check-label" htmlFor="legaltax">
                      {CustomerLegalDetails?.tax_certificate == "1"
                        ? "Yes"
                        : "No"}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                {CustomerLegalDetails.tax_certificate == "1" ? (
                  <div className="mb-5">
                    <label className="font-14 font-semibold primary-text-color mb-2">
                      Upload Certificate
                    </label>
                    <span className="upload-btn-wrapper me-2 d-block cursor-pointer ">
                      {!CustomerLegalDetails.file_name ? (
                        <button
                          type="button"
                          className="font-14  font-medium primary-color upload-btn border-0 shadow-none cursor-pointer"
                        >
                          <img
                            src="Images/uploads.svg"
                            alt="browse"
                            className="me-2"
                          />
                          Upload
                        </button>
                      ) : null}
                      <span className="link-blue px-2">
                        {CustomerLegalDetails.tax_certification_url ? (
                          <a
                            href={CustomerLegalDetails.tax_certification_url}
                            target="_blank"
                            title=""
                            style={{ textDecoration: "none" }}
                          >
                            {CustomerLegalDetails.file_name}{" "}
                          </a>
                        ) : (
                          <>{CustomerLegalDetails.file_name}</>
                        )}
                        {CustomerLegalDetails.file_name ? (
                          <img
                            src="Images/red-cross.svg"
                            alt="Red cross icon"
                            className="ms-2"
                            onClick={() => {
                              clear();
                            }}
                          />
                        ) : null}
                      </span>
                      {!CustomerLegalDetails.file_name ? (
                        <input
                          type="file"
                          name="myfile"
                          className="cursor-pointer upload-btn-input"
                          onChange={(e) => uploadImage(e)}
                        />
                      ) : null}
                      {fileErrorMessage ? (
                        <label className="color-red font-14">
                          {fileErrorMessage}
                        </label>
                      ) : null}
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="d-flex justify-content-end mb-5">
              <button
                type="button"
                className="btn text border-0 font-14 font-semibold me-3 shadow-none text-btn"
                onClick={() => navigate("/customer")}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn primary-btn border-0 font-14 font-semibold "
                onClick={() => {
                  save();
                }}
                disabled={disableSave}
              >
                Save
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default CustomerLegalDetails;
