import React from "react";

export default function Norecords() {
  return (
    <>
      <div className="d-flex justify-content-center color-white-bg rounded-bottom pt-100 pb-400 mb-5">
        <div>
          <img
            src="Images/no-records-found.svg"
            alt="No-Records-Found"
            className="d-block"
          />
          <div>
            <p className="d-flex font-20 font-regular tertiary-color mt-3 justify-content-center">
              No records found
            </p>
          </div>
        </div>
        {/* <div class="d-flex justify-content-center mb-5">
      <button type="button" class="btn tertiary-btn border-0 font-14 font-semibold ">
        Load More</button>
    </div> */}
      </div>
    </>
  );
}
