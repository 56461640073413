import React, { useEffect, useState, useRef } from "react";
import {
  getCustomerQuotesGridDetails,
  sendQuotesApi,
  deleteQuotesDetails,
  previewQuotes
} from "../../services/quotesService";
// import { QuotesDetails } from '../../interface';
import { useNavigate } from "react-router-dom";
//import * as fs from 'fs';
import * as filesaver from "file-saver";
import { Loader } from "../loader";
import { getQuotesLicenseDetailsById } from "../../services/quotesService";
import { customerSessionData } from "../../interface";
import { defaultLimit, encryptStorage, sessionKeys, formatAmount } from "../../configs/constant";
import moment from "moment";

//** PS_QG_1 PS_QG_2 PS_QG_3 PS_QG_4 PS_QG_5 PS_QG_6 PS_QG_7 PS_QG_8 PS_QG_9 PS_QG_10
//PS_QG_11 PS_QG_12 PS_QG_13 PS_QG_14 PS_QG_15 PS_QG_16 PS_QG_17 PS_QG_18 PS_QG_19 PS_QG_20
//PS_QG_21 PS_QG_22 PS_QG_23 PS_QG_24 PS_QG_25
// to render the quotation Module  */
function QuotesGrid() {
  //** PS_QG_3 state variable and object variables */
  let customerdetails: customerSessionData | null = JSON.parse(
    encryptStorage.getItem(sessionKeys.customerDetails) || "null"
  );
  const [customerId, setCustomerId] = useState(customerdetails?.customer_id);
  const [quotesDetails, SetQuotesDetails] = useState<any>([]);
  const [loader, setloader] = useState<boolean>(false);
  const [sortType, setSortType] = useState<string>("desc");
  const [sortColumn, setSortColumn] = useState<string>("q.created_date");
  const [search_value, setSearch_value] = useState<string>("");
  const [quoteSort, setQuoteSort] = useState<boolean>(false);
  const [customerNameSort, setCustomerNameSort] = useState<boolean>(false);
  const [microsoftPriceSort, setMicrosoftPriceSort] = useState<boolean>(false);
  const [partnerPriceSort, setPartnerPriceSort] = useState<boolean>(false);
  const [resellerPriceSort, setResellerPriceSort] = useState<boolean>(false);
  const [profitSort, setProfitSort] = useState<boolean>(false);
  const [mailsended, setmailsended] = useState(false);
  const [userDetails, setuserDetails] = useState<any>({});
  const [customerDetails, setcustomerDetails] = useState<any>([]);
  const [licenseDetails, setlicenseDetails] = useState([]);
  const [mailvalidation, setmailvalidation] = useState(false);
  const [count, setCount] = useState(defaultLimit);
  const [loadmoreCount, setloadmoreCount] = useState<any>();
  //   const [InvoicePreview, setInvoicePreview] = useState<boolean>(false);
  const [ispreview, setispreview] = useState(false);
  const navigate = useNavigate();
  const [quotesCount, setquotesCount] = useState<any>();
  const [resellerprice, setresellerprice] = useState<any>();
  const [microsoftPrice, setmicrosoftPrice] = useState<any>();
  const [showDelete, setshowDelete] = useState(false);
  const [deleteId, setdeleteIds] = useState([])
  const[created_date,setcreated_date]=useState<any>()

  const[mailsuccessContent,setmailsuccessContent]=useState("")
//** PS_QG_4, PS_QG_5 UseEffect to call the functionality during the rendering */
  useEffect(() => {
    if (!ispreview) {
      getQuotesDetails();
    }
  }, [
    //ispreview,
    quoteSort,
    customerNameSort,
    microsoftPriceSort,
    partnerPriceSort,
    sortType,
    sortColumn,
    count,
  ]);

  useEffect(() => {
    const totalMicrosoftPrice = licenseDetails.reduce(
      (total: any, item: any) => total + parseFloat(item.microsoft_price),
      0
    );

    setmicrosoftPrice(totalMicrosoftPrice);

    const totalResellerPrice = licenseDetails.reduce(
      (total: any, item: any) => total + parseFloat(item.reseller_price),
      0
    );

    setresellerprice(totalResellerPrice);
  }, [licenseDetails]);
//** PS_QG_4, PS_QG_5, PS_QG_6, PS_QG_7, PS_QG_8, PS_QG_9, PS_QG_10
//Fetch the quotes details */
  async function getQuotesDetails() {
    setloader(true);
    const obj = {
      customer_name: search_value,
      customer_id: customerId ? customerId : "",
      sortColumn: sortColumn,
      sortType: sortType,
      count: count,
    };
    const response = await getCustomerQuotesGridDetails(obj);

    if (response.data.statusCode == 200) {
      SetQuotesDetails(response.data.responseData[0]);
      setquotesCount(response.data.responseData[1]?.[0].count_quotes);
      setloadmoreCount(response.data.responseData[2]?.[0].count);
      setloader(false);
    }
  }
  //** PS_QG_26, PS_QG_27, PS_QG_28, PS_QG_29, PS_QG_30, PS_QG_31, PS_QG_32, PS_QG_33
  // Validate the mail of the Customer */
  async function validateMail(id: any) {
    setloader(true);

    let payload = {
      quote_id: id,
    };

    let editQuotes = await getQuotesLicenseDetailsById(payload);
    let ll = editQuotes.data.responseData[0]?.[0];
    if (
      ll.AdressLine &&
      ll.city &&
      ll.country &&
      ll.customerName &&
      ll.emailAddress &&
      ll.customer_id &&
      ll.from_data &&
      ll.name &&
      ll.phoneNumber &&
      ll.quoteNumber &&
      ll.state &&
      ll.zip
    ) {
      sendQuotes(id, true, false);
    } else {
      setmailvalidation(true);
      setmailsuccessContent("Fill customer details to send the quotes")
      setloader(false);
      return false;
    }
  }
  //** PS_QG_21," "PS_QG_22," "PS_QG_23 Send the quotes as a pdf */
  async function sendQuotes(quoteId: string, sendmail: boolean, ispreview: boolean) {

    try{

      setloader(true);
      let payload = {
        quote_id: quoteId,
        sendMail: sendmail,
        ispreview: ispreview, 
        isEdit:false
      };

      let send:any;

      if(sendmail){
        send = await sendQuotesApi(payload);
      }else{
        send = await previewQuotes(payload);
      }

      if(send.data.statusCode == 400){
        setmailvalidation(true);
        setloader(false)
        setmailsuccessContent(send.data.responseData)

      }
      else{
        if (!sendmail && !ispreview) {
          const arrayBuffer = new Uint8Array(send?.data?.responseData?.buffer?.data)
            .buffer;
          const blob = new Blob([arrayBuffer], {
            type: "application/octet-stream",
          });
          filesaver.saveAs(blob, `Quotation_${quoteId}.pdf`);
          // setloader(false);
        } else if (sendmail && !ispreview && send.data.statusCode == 200) {
          // setloader(false);
          setmailsended(true);
        } else if (ispreview && send.data.statusCode == 200) {
          setuserDetails(send.data.responseData.userDetails);
          setcustomerDetails(send.data.responseData.customerDetails);
          setcreated_date(send.data.responseData.created_date[0].created_date);
          setlicenseDetails(send.data.responseData.licenseDetails);
          setispreview(true);
        }

        setloader(false);
      }

  }catch(e:any){

    if(e?.response.data?.statusCode == 429)
    setmailvalidation(true);
    setloader(false)
    setmailsuccessContent("You've reached the maximum limits.Try after a minute")
  }

  }
//** PS_QG_14, PS_QG_15, PS_QG_16, PS_QG_17 delete the quotes */
  async function deleteData(quoteId: any) {

    setloader(true);
    let payload = {
      quoteId: quoteId,
    };
    let deleteQuotes = await deleteQuotesDetails(payload);
    if (deleteQuotes.data.statusCode == 200) {
      setshowDelete(false)
      getQuotesDetails();

    }

  }
  

  return (
    <>
      {/* header starts */}
      {/* header ends */}
      {/* Admin starts */}
      {/* <div class="px-5">
        <p class="font-20 font-semibold table-title-color mt-4">Users<span class="no-of-records"></span></p>
          </div> */}
      {!ispreview ? (
        <div className="container-fluid px-4 ">
          <div className="row justify-content-center">
            <div className="px-4">
              <div className="d-block d-md-flex flex-wrap justify-content-between align-items-center  mb-4 mt-3">
                {/* <div class> */}
                <h2 className="font-20 font-bold dark-blue-color d-flex mb-0">
                  Quotes
                  <span className="custom-admin-batch font-13 d-flex align-items-center justify-content-center ms-2 px-2">
                    {/* {quotesDetails[0]?.total_quotes} */}
                    {/* {quotesCount} */}
                    {loadmoreCount}
                  </span>
                  {/* </div> */}
                </h2>
                <div className="d-block d-md-flex">
                  <div className="w-md-100 d-flex mb-3 mb-md-0">
                    <span className="position-relative d-flex align-items-center me-3 w-100">
                      <input
                        type="search"
                        className="border-0 font-regular w-230 cus-z-ind  rounded-2 font-14 top-0 bottom-0 py-2 px-3 custom-input"
                        value={search_value}
                        id="search"
                        placeholder="Search"
                        onKeyDown={(e) => {
                          // else if (search.length === 2 || search.length > 2) {
                          //   getSubscription();
                          // }
                          if (e.key === "Enter") {
                            getQuotesDetails();
                          }
                        }}
                        onChange={(e: any) => {
                          setSearch_value(e.target.value);
                          if (e.key === "Enter") {
                            getQuotesDetails();
                          }
                        }}
                      />
                      <span className="position-absolute search-icon">
                        <img
                          src="Images/search.svg"
                          alt="search-icon"
                          onClick={() => {
                            getQuotesDetails();
                          }}
                        />
                      </span>
                    </span>
                    
                  </div>
                  
                  <button
                    type="button"
                    className="btn primary-btn border-0 font-14 font-semibold ms-md-3 ms-0 table-btn w-auto text-nowrap w-md-100"
                    onClick={() => {
                      navigate("/inventory", {
                        state: {
                          mode: "customer",
                          customerId: customerdetails?.customer_id,
                          customerName: customerdetails?.customer_Name,
                          customerType: customerdetails?.customer_id
                            ? "Existing customer"
                            : "",
                          NewQuote: true,
                        },
                      });
                    }}
                  >
                    Create Quote
                  </button>
                </div>
              </div>
              {/* <div class="tab-content" id="pills-tabContent"> */}
              {/* first tab starts here */}
              {/* <div class="tab-pane fade show active" id="pills-notstarted" role="tabpanel" >  <div class="row justify-content-center"> */}
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="table-responsive rounded-2">
                  <table className="table mb-0  themeTable rounded-2 ">
                    <thead className="font-bold">
                      <tr>
                        <th
                          className="ps-5 text-start">
                          Quote#
                          <span className="ms-2 cursor-pointer"
                            onClick={(e) => {
                              setQuoteSort(!quoteSort);
                              setSortColumn("quote_id");
                              setSortType(quoteSort ? "asc" : "desc");
                            }}>
                            <img
                              src={
                                quoteSort
                                  ? "Images/sort-up-arrow.svg"
                                  : "Images/sort-arrow.svg"
                              }
                              alt="sortarrow"
                              title="Sort"
                            />
                          </span>
                        </th>
                        <th
                          className="text-start"
                         
                        >
                          Customer Name
                          <span className="ms-2 cursor-pointer"
                           onClick={(e) => {
                            setCustomerNameSort(!customerNameSort);
                            setSortColumn("customer_name");
                            setSortType(customerNameSort ? "asc" : "desc");
                          }}
                          >
                            <img
                              src={
                                customerNameSort
                                  ? "Images/sort-up-arrow.svg"
                                  : "Images/sort-arrow.svg"
                              }
                              alt="sortarrow"
                              title="Sort"
                            />
                          </span>
                        </th>
                        <th
                          className="text-end"
                          
                        >
                          Microsoft Price
                          <span className="ms-2 cursor-pointer"
                          onClick={(e) => {
                            setMicrosoftPriceSort(!microsoftPriceSort);
                            setSortColumn("microsoft_price");
                            setSortType(microsoftPriceSort ? "asc" : "desc");
                          }}
                          >
                            <img
                              src={
                                microsoftPriceSort
                                  ? "Images/sort-up-arrow.svg"
                                  : "Images/sort-arrow.svg"
                              }
                              alt="sortarrow"
                              title="Sort"
                            />
                          </span>
                        </th>
                        <th
                          className="text-end"
                          
                        >
                          Partner Price
                          <span className="ms-2 cursor-pointer"
                          onClick={(e) => {
                            setPartnerPriceSort(!partnerPriceSort);
                            setSortColumn("partner_price");
                            setSortType(partnerPriceSort ? "asc" : "desc");
                          }}
                          
                          >
                            <img
                              src={
                                partnerPriceSort
                                  ? "Images/sort-up-arrow.svg"
                                  : "Images/sort-arrow.svg"
                              }
                              alt="sortarrow"
                              title="Sort"
                            />
                          </span>
                        </th>
                        <th
                          className="text-end"
                          
                        >
                          Reseller Price
                          <span className="ms-2 cursor-pointer"
                          onClick={(e) => {
                            setResellerPriceSort(!resellerPriceSort);
                            setSortColumn("reseller_price");
                            setSortType(resellerPriceSort ? "asc" : "desc");
                          }}
                          >
                            <img
                              src={
                                resellerPriceSort
                                  ? "Images/sort-up-arrow.svg"
                                  : "Images/sort-arrow.svg"
                              }
                              alt="sortarrow"
                              title="Sort"
                            />
                          </span>
                        </th>
                        <th
                          className="text-end"
                          
                        >
                          Profit
                          <span className="ms-2 cursor-pointer"
                          onClick={(e) => {
                            setProfitSort(!profitSort);
                            setSortColumn("profit");
                            setSortType(profitSort ? "asc" : "desc");
                          }}
                          >
                            <img
                              src={
                                profitSort
                                  ? "Images/sort-up-arrow.svg"
                                  : "Images/sort-arrow.svg"
                              }
                              alt="sortarrow"
                              title="Sort"
                            />
                          </span>
                        </th>
                        <th className="text-center">Actions</th>
                      </tr>
                    </thead>
                    <tbody className="font-medium">
                      {quotesDetails.map((quotes: any, index: any) => (
                        <tr key={index} className="border-bottom-0">
                          <td className="ps-5 text-start">
                            <a
                              className="link-blue text-decoration-none cursor-pointer"
                              onClick={() => {
                                sendQuotes(quotes.quote_id, false, true);
                              }}
                            //   data-bs-toggle="modal" data-bs-target="#invoicepreview"
                            >
                              {quotes.quote_id}
                            </a>
                          </td>
                          <td className="text-start font-regular">
                            {quotes.customer_name ? quotes.customer_name : "-"}
                          </td>
                          <td className="text-end font-regular">
                            $
                            {quotes.microsoft_price
                              ? (quotes.microsoft_price).toFixed(2)
                              : "0.00"}
                          </td>
                          <td className="text-end font-regular">
                            ${quotes.partner_price ? (quotes.partner_price).toFixed(2) : "0.00"}
                          </td>
                          <td className="text-end font-regular">
                            $
                            {quotes.reseller_price
                              ? (quotes.reseller_price).toFixed(2)
                              : "0.00"}
                          </td>
                          <td className="text-end font-regular">
                            ${quotes.profit ? (quotes.profit).toFixed(2) : "0.00"}
                          </td>
                          <td className="text-center font-regular">
                            <span className="cursor-pointer">
                              <img
                                src="Images/edit-icon.svg"
                                alt="editIcon"
                                title="Edit"
                                onClick={() => {
                                  navigate("/editquote", {
                                    state: { ...quotes },
                                  });
                                }}
                              />
                            </span>
                            <span className="ms-3 cursor-pointer">
                              <img
                                src="Images/delete-icon.svg"
                                alt="deleteIcon"
                                title="Delete"
                                onClick={() => {
                                  setshowDelete(true)
                                  setdeleteIds(quotes.quote_id)

                                }}
                              />
                            </span>
                            <span className="ms-3 cursor-pointer">
                              <img
                                src="Images/download-icons.svg"
                                alt="downloadIcon"
                                title="Download"
                                onClick={() => {
                                  sendQuotes(quotes.quote_id, false, false);
                                }}
                              />
                            </span>
                            <span className="ms-3 cursor-pointer">
                              <img
                                src="Images/send-icon.svg"
                                alt="sendIcon"
                                title="Send"
                                onClick={() => {
                                  validateMail(quotes.quote_id);
                                  // sendQuotes(quotes.quote_id, true, false);
                                }}
                              />
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {quotesDetails.length < loadmoreCount ? (
                  <div className="d-flex justify-content-center mb-5">
                    <button
                      type="button"
                      className="btn tertiary-btn border-0 font-14 font-semibold  mt-3"
                      onClick={() => {
                        setCount(count + defaultLimit);
                      }}
                    >
                      Load More
                    </button>
                  </div>
                ) : null}

                {quotesDetails.length === 0 ? (
                  <>
                    <div className="d-flex justify-content-center color-white-bg rounded-bottom pt-100 pb-400 mb-5">
                      <div>
                        <img
                          src="Images/no-records-found.svg"
                          alt="No-Records-Found"
                          className="d-block"
                        />
                        <div>
                          <p className="d-flex font-20 font-regular tertiary-color mt-3 justify-content-center">
                            No records found
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
          {loader ? <Loader /> : null}
          <>
            {mailsended ? (
              <div
                className="modal fade show"
                style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
                id="successpopup"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-hidden="true"
              >
                <div className="modal-dialog popup-w-400 modal-dialog-centered">
                  <div className="modal-content px-3">
                    <div className="modal-header border-0 justify-content-center">
                      <span className="popup-icon-pos">
                        <img
                          src="Images/success-icon.svg"
                          alt="success-icon"
                          className="popup-icon"
                        />
                      </span>
                    </div>
                    <div className="modal-body pt-5 px-3">
                      <p className="text-center font-16 font-medium secondary-text-color">
                        Mail sent successfully
                      </p>
                    </div>
                    <div className="modal-footer border-0 justify-content-center px-3">
                      <button
                        type="button"
                        className="btn text-btn green-btn shadow-none px-3 font-14 font-medium"
                        data-bs-dismiss="modal"
                        onClick={() => {
                          setmailsended(false);
                        }}
                      >
                        Ok
                      </button>
                      {/* <button
                  type="button"
                  className="btn primary-btn font-14 font-medium"
                >
                  Move to Details
                </button> */}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {mailvalidation ? (
              <div
                className="modal fade show"
                style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
                id="successpopup"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-hidden="true"
              >
                <div className="modal-dialog popup-w-400 modal-dialog-centered">
                  <div className="modal-content px-3">
                    <div className="modal-header border-0 justify-content-center">
                      <span className="popup-icon-pos">
                        <img
                          src="Images/exclamatory-icon.svg"
                          alt="success-icon"
                          className="popup-icon"
                        />
                      </span>
                    </div>
                    <div className="modal-body pt-5 px-3">
                      <p className="text-center font-16 font-medium secondary-text-color">
                        {mailsuccessContent}
                      </p>
                    </div>
                    <div className="modal-footer border-0 justify-content-center px-3">
                      <button
                        type="button"
                        className="btn text-btn danger-btn shadow-none px-3 font-14 font-medium"
                        data-bs-dismiss="modal"
                        onClick={() => {
                          setmailvalidation(false);
                        }}
                      >
                        Ok
                      </button>
                     
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </>
         
          {/* tabs ends here */}
        </div>
      ) : (
        <div className="container-fluid">
          <div
            className="modal fade show"
            id="invoicepreview"
            style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog modal-xl modal-dialog-scrollable ">
              <div className="modal-content px-3">
                <div className="modal-header border-0 justify-content-center">
                  <h2
                    className="modal-title tertiary-color font-40 font-bold"
                    id="exampleModalToggleLabel"
                  >
                    Quotation
                  </h2>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setispreview(false);
                    }}
                  />
                </div>
                <div className="modal-body pt-2 px-3">
                 
                  <div className="row g-3 align-items-center">
                    <div className="col-auto">
                      <label
                        htmlFor="inputPassword6"
                        className="col-form-label color-black-v2 font-16 font-semibold"
                      >
                        Quotation Number :
                      </label>
                    </div>
                    <div className="col-auto">
                      <span className="color-black-v3 font-medium invoice-preno">
                        {customerDetails[0]?.quoteNumber}
                      </span>
                    </div>
                  </div>
                  <div className="row g-3 align-items-center mb-5">
                    <div className="col-auto">
                      <label
                        htmlFor="inputPassword6"
                        className="col-form-label color-black-v2 font-16 font-semibold"
                      >
                        Created Date :
                      </label>
                    </div>
                    <div className="col-auto">
                      <span className="color-black-v3 font-medium quotes-predate">
                        {moment(created_date).utc().format('MM/DD/YYYY')}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-5">
                    <div className="col-md-4">
                      <ul className="custom-list-style p-0">
                        <li className="color-black-v2 font-20 font-bold mb-3">
                          From :
                        </li>
                        <li className="color-black-v2 font-16 font-semibold">
                          {userDetails?.name ? userDetails?.name:"-"}
                        </li>
                        <li className="color-black-v3 font-16 font-medium">
                          {userDetails?.street_address
                            ? userDetails?.street_address
                            : ""}
                          , {userDetails?.city ? userDetails?.city : ""},
                          {userDetails?.state ? userDetails?.state : ""} ,{" "}
                          {userDetails?.country ? userDetails?.country : ""}{" "}.
                        </li>
                        <li className="color-black-v3 font-16 font-medium">
                          {userDetails?.postal_code
                            ? userDetails?.postal_code
                            : ""}
                          .
                        </li>
                        <li className="color-black-v3 font-16 font-medium">
                          {userDetails?.phone ? userDetails.phone : ""}
                        </li>
                        <li className="color-black-v3 font-16 font-medium">
                          {userDetails?.email ? userDetails?.email : ""}
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-4">
                      <ul className="custom-list-style p-0">
                        <li className="color-black-v2 font-20 font-bold mb-3 ">
                          To :
                        </li>
                        <li className="color-black-v2 font-16 font-semibold">
                          {" "}
                          {/* {customerDetails[0].name} */}
                          {customerDetails[0]?.customerName ?customerDetails[0]?.customerName:"-" }
                        </li>
                        <li className="color-black-v3 font-16 font-medium">
                          {customerDetails[0]?.AdressLine},{" "}
                          {customerDetails[0]?.state},{" "}
                          {customerDetails[0]?.city},{" "}
                          {customerDetails[0]?.country},{" "}.
                        </li>
                        <li className="color-black-v3 font-16 font-medium">
                          {customerDetails[0]?.zip}
                        </li>
                        <li className="color-black-v3 font-16 font-medium">
                          {customerDetails[0]?.phoneNumber
                            ? customerDetails[0]?.phoneNumber
                            : ""}
                        </li>
                        <li className="color-black-v3 font-16 font-medium">
                          {customerDetails[0]?.emailAddress
                            ? customerDetails[0]?.emailAddress
                            : ""}
                        </li>
                      </ul>
                    </div>
                   
                  </div>
                  <h2 className="font-18 font-semibold tertiary-color d-flex mb-3">
                    License List
                    <span className="custom-admin-batch font-12 d-flex align-items-center justify-content-center ms-2 px-2">
                      {licenseDetails?.length}
                    </span>
                  </h2>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div className="table-responsive themetable-border rounded-2">
                      <table className="table  themeTable mb-0 ">
                        <thead className="font-semibold">
                          <tr>
                            <th className="ps-4 text-start">License</th>
                            <th className="text-end pe-5">Quantity</th>
                            <th className="text-start">Term Duration</th>
                            <th className="text-start">Billing Cycle </th>
                            <th className="text-end">Microsoft Price</th>
                            <th className="text-end">Avasoft Price</th>
                          </tr>
                        </thead>
                        <tbody className="font-medium">
                          {licenseDetails.map((preview: any) => {
                            return (
                              <>
                                <tr className="border-bottom-0 ">
                                  <td className="ps-4 text-start font-regular text-truncate custom-text-truncate-width ">
                                    {preview.license ? preview.license : "-"}
                                  </td>
                                  <td className="text-end font-regular pe-5">
                                    {preview.quantity ? preview.quantity : "-"}
                                  </td>
                                  <td className="text-start font-regular">
                                    {preview.term_duration
                                      ? preview.term_duration
                                      : "-"}
                                  </td>
                                  <td className="text-start font-regular">
                                    {preview.billing_cycle
                                      ? preview.billing_cycle
                                      : "-"}
                                  </td>
                                  <td className="text-end font-regular">
                                    $
                                    {preview.microsoft_price
                                      ? (preview.microsoft_price).toFixed(2)
                                      : "0.00"}
                                  </td>
                                  <td className="text-end font-regular">
                                    $
                                    {preview.reseller_price
                                      ? Number(preview.reseller_price).toFixed(2)
                                      : "0.00"}
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="row">
                      <div className="d-flex  justify-content-end">
                        <ul>
                          <li className="d-flex custom-invoice-popup-width justify-content-between p-3 border-bottom">
                            <label className="font-medium tertiary-color font-14">
                              Microsoft Price{" "}
                            </label>
                            <label className="font-semibold font-14 color-black">
                              ${microsoftPrice ? formatAmount(microsoftPrice) : "0.00"}
                            </label>
                          </li>
                          <li className="d-flex custom-invoice-popup-width justify-content-between p-3 border-bottom">
                            <label className="font-medium tertiary-color font-14">
                              Discount
                            </label>
                            <label className="font-semibold font-14 color-black">
                              ${(microsoftPrice - resellerprice) ? formatAmount((microsoftPrice - resellerprice)) : "0.00"}
                            </label>
                          </li>
                          <li className="d-flex custom-invoice-popup-width justify-content-between p-3 ">
                            <label className="font-medium tertiary-color font-16">
                              Order Total
                            </label>
                            <label className="font-semibold font-16 color-black">
                              ${resellerprice ? formatAmount(resellerprice) : "0.00"}
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer border-0 justify-content-center px-3 mb-3"></div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showDelete ?
        <div
          className="modal fade show"
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
          id="deletepopup"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-hidden="true"
        >
          <div className="modal-dialog popup-w-400 modal-dialog-centered">
            <div className="modal-content px-3 border-0">
              <div className="modal-header border-0 justify-content-center">
                <span className="popup-icon-pos">
                  <img
                    src="Images/delete-popup-icon.svg"
                    alt="delete-popup-icon"
                    className="popup-icon"
                  />
                </span>
              </div>
              <div className="modal-body pt-5 px-3">
                <p className="text-center font-14 font-medium secondary-text-color mb-0">
                  Are you sure, you want to delete the quote ?{" "}
                </p>
              </div>
              <div className="modal-footer border-0 justify-content-center px-3 mb-2">
                <button
                  type="button"
                  className="btn text-btns shadow-none px-3 font-14 font-medium text-btns"

                  onClick={() => {
                    setshowDelete(false)

                  }}

                >
                  No
                </button>
                <button
                  type="button"
                  className="btn danger-btn font-14 font-medium"
                  onClick={() => {
                    deleteData(deleteId)
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div> : null
      }

      {/* Admin Ends */}
    </>
  );
}

export default QuotesGrid;
