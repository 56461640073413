import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MsalProvider } from "@azure/msal-react";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { decrypt } from "./configs/encryption";
import { configs } from "./configs/constant";

// MSAL configuration
const clientId:any = decrypt(configs,process.env.REACT_APP_CLIENT_ID);
const tenantId:any = decrypt(configs,process.env.REACT_APP_TENANT_ID);
const configuration:any = {
  auth: {
    clientId: clientId,
    redirectUri: `${window.location.origin}/csploginSPA`,
    authority:
      `https://login.microsoftonline.com/${tenantId}`,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  },
};

const pca = new PublicClientApplication(configuration);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <>
    <MsalProvider instance={pca}>
      <App />
    </MsalProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
