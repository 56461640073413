import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  createInvoicelicenseDetails,
  deleteInvoiceDetailsById,
  getInvoiceDetailsById,
  getInvoiceFilterStatus,
  saveInvoicelicenseDetails,
  updateInvoicelicenseDetails,
  invoiceCalculatorByCustomer,
} from "../../services/invoiceService";
import { InvoiceFormDetails } from "../../interface";
import moment from "moment";
import { ErrorLog } from "../../services/errorService";
import BreadCrumbs from "../subscriptions/breadcrumbs";
import {
  OnlyNumber,
  OnlyIntegers,
  formatAmount,
  OnlyIntegersInclidesDot,
} from "../../configs/constant";
import { getAllDrodpownApi } from "../../services/subscriptionservice";
import { getcustomerApi } from "../../services/inventoryservice";
import { userSessionData } from "../../interface";
import { encryptStorage } from "../../configs/constant";
import { sessionKeys } from "../../configs/constant";
import { Loader } from "../loader";
import { Tooltip } from "@mui/material";
import { Button } from "@mui/material";
import InvoiceData from "../dashboard/invoiceData";
import { GetCustomerLegalDetailsById } from "../../services/customerService";
import ErrorPopup from "../popup/errorPopup";

function InvoiceForm() {
  const location = useLocation();
  const [enable, setenable] = useState<boolean>(false);
  const [invoiceLicenseId, setInvoiceLicenseId] = useState<string>("");
  const [datetime, setDatetime] = useState("");
  const [errorStatus, setErrorStatus] = useState<boolean>(false);
  let sessionUserDetails: userSessionData | null = JSON.parse(
    encryptStorage.getItem(sessionKeys.userDetails) || "null"
  );

  const [license_name, setLicense_name] = useState<any>({});
  const [quantity, setQuantity] = useState<any>({});
  const [start_date, setStart_date] = useState<any>({});
  const [termduration, setTermduration] = useState<any>({});
  const [end_date, setEnd_date] = useState<any>({});
  const [microsoft_price, setMicrosoft_price] = useState<any>({});
  const [reseller_price, setReseller_price] = useState<any>({});

  const [invoice, setInvoice] = useState<any>([]);
  const [license_name_error, setLicense_name_error] = useState<string>("");
  const [quantity_error, setQuantity_error] = useState<string>("");
  const [start_date_error, setStart_date_error] = useState<string>("");
  const [end_date_error, setEnd_date_error] = useState<string>("");
  const [microsoft_price_error, setMicrosoft_price_error] =
    useState<string>("");
  const [reseller_price_error, setReseller_price_error] = useState<string>("");
  const [termduration_error, setTermduration_error] = useState<string>("");
  const [loader, setloader] = useState<boolean>(false);

  const [LicenseInlineError, setLicenseInlineError] = useState<any>({});
  const [QuantitynlineError, setQuantityInlineError] = useState<any>({});
  const [TermInlineError, setTermInlineError] = useState<any>({});
  const [StartDateInlineError, setStartDateInlineError] = useState<any>({});
  const [EndDateInlineError, setEndDateInlineError] = useState<any>({});
  const [mircoSoftpriceInlineError, setmircoSoftpriceInlineError] =
    useState<any>({});
  const [resellerpriceInlineError, setresellerpriceInlineError] = useState<any>(
    {}
  );
  const [customerId, setcustomerId] = useState<any>(location.state.customer_id);

  const [checkCustomer, setcheckCustomer] = useState("");
  const [checkStatus, setcheckStatus] = useState("");

  const [licenseId, setlicenseId] = useState<any>([]);
  const [customerDrodpown, setcustomerDropdown] = useState([]);
  const [disablesave, setdisablesave] = useState(false);
  const [statusId, setStatusID] = useState<any>();

  const [errorstatus, seterrrorstatus] = useState("");
  const [customername, setcustomername] = useState("");
  const [customerData, setcustomerData] = useState();
  const [showErrorPopup, setShowErrorPopup] = useState<boolean>(false);
  useEffect(() => {
    const license_name: any = {};
    const quantity: any = {};
    const start_date: any = {};
    const end_date: any = {};
    const microsoft_price: any = {};
    const reseller_price: any = {};
    const termduration: any = {};

    const license_name_Error: any = {};
    const quantity_error: any = {};
    const start_date_error: any = {};
    const end_date_error: any = {};
    const microsoft_price_error: any = {};
    const reseller_price_error: any = {};
    const termduration_error: any = {};

    invoice.forEach((data: any, index: any) => {
      license_name_Error[index] = "";
      quantity_error[index] = "";
      start_date_error[index] = "";
      end_date_error[index] = "";
      microsoft_price_error[index] = "";
      reseller_price_error[index] = "";
      termduration_error[index] = "";

      license_name[index] = data.license_name;
      quantity[index] = data.quantity;
      start_date[index] = data.start_date;
      end_date[index] = data.end_date;
      microsoft_price[index] = data.microsoft_price;
      reseller_price[index] = data.reseller_price;
      termduration[index] = data.term_duration;
    });
    setLicense_name(license_name);
    setQuantity(quantity);
    setTermduration(termduration);
    setStart_date(start_date);
    setEnd_date(end_date);
    setMicrosoft_price(microsoft_price);
    setReseller_price(reseller_price);

    setLicenseInlineError(license_name_Error);
    setQuantityInlineError(quantity_error);
    setStartDateInlineError(start_date_error);
    setEndDateInlineError(end_date_error);
    setmircoSoftpriceInlineError(microsoft_price_error);
    setresellerpriceInlineError(reseller_price_error);
    setTermInlineError(TermInlineError);
  }, [invoice]);

  useEffect(() => {
    isEdit && getCustomerInvoiceDetailsById();
    fetchInvoiceFitlerStatus();
    !isEdit && getCustomerDropdown();
  }, []);

  async function getCustomerDropdown() {
    setloader(true);
    let getcustomer = await getcustomerApi();
    if (getcustomer.data.statusCode == 200) {
      setcustomerDropdown(getcustomer.data.responseData);
    }
    setloader(false);
  }

  const navigate = useNavigate();

  const initialInvoiceData: InvoiceFormDetails = {
    invoice_id: "",
    license_id: "",
    status_id: 0,
    company_name: "",
    license_name: "",
    quantity: "",
    term_duration: "",
    start_date: "",
    end_date: "",
    microsoft_price: "",
    reseller_price: "",
    editmode: "true",
  };

  const [updateinvoice, setUpdateInvoice] = useState<any>([
    {
      license_name: "",
      quantity: "",
      term_duration: "",
      start_date: "",
      end_date: "",
      microsoft_price: "",
      reseller_price: "",
      license_id: "",
    },
  ]);

  function handleEdit(e: any, index: number) {
    setInvoice((prev: any) => {
      const updaterecords = [...prev];
      updaterecords[index] = {
        ...updaterecords[index],
        editmode: true,
      };
      return updaterecords;
    });
  }

  const [createLicense, setCreateLicense] = useState<any>({
    invoice_id: location.state.invoice_id,
    // status_id: 0,
    license_id: "",
    license_name: "",
    quantity: "",
    term_duration: "",
    start_date: "",
    end_date: "",
    microsoft_price: "",
    reseller_price: "",
  });

  const [filterStatusDropDown, setFilterStatusDropDown] = useState<any>([]);
  const [TermDurationDropDown, setTermDurationDropDown] = useState<any>([]);
  const [isEdit, setisEdit] = useState(
    location?.state ? location?.state?.invoice_id : false
  );

  async function getCustomerInvoiceDetailsById() {
    setloader(true);
    const obj = { invoice_id: location.state.invoice_id };
    const response: any = await getInvoiceDetailsById(obj);

    if (response.data.statusCode == 200) {
      let data = response.data.responseData[1].map((data: any) => {
        return {
          ...data,
          editmode: false,
        };
      });

      setStatusID(response.data.responseData[0]?.[0]?.status_id);
      setcustomerId(response.data.responseData[0]?.[0]?.customer_id);
      setcustomername(response.data.responseData[0]?.[0]?.company_name);
      setInvoice(data);
    }
    setloader(false);
  }

  let breadcrumb = [
    { title: "Invoice", isActive: true, path: "/invoice" },
    {
      title: isEdit ? location?.state?.company_name : "Create Invoice",
      isActive: false,
      path: null,
    },
  ];

  useEffect(() => {
    getAllDrodpownDetailsApi();
  }, []);

  async function getAllDrodpownDetailsApi() {
    const request = await getAllDrodpownApi();

    setTermDurationDropDown(request?.data?.responseData[2]);
  }

  async function fetchInvoiceFitlerStatus() {
    try {
      const response: any = await getInvoiceFilterStatus();
      setFilterStatusDropDown(response.data.responseData);
    } catch (Error: any) {
      const errorObject = {
        stack_name: "frontend_react",
        module_name: "Invoice_module",
        function_name: "fetchInvoiceFitlerStatus",
        error_message: `${Error?.message}`,
      };
      if (Error) {
        const request: any = await ErrorLog(errorObject);
        return request.data.statusCode === 200
          ? setErrorStatus(errorStatus)
          : setErrorStatus(!errorStatus); //PS_AD_11
      }
    }
  }

  async function deleteCustomerInvoiceDetailsById(e: any, index: any) {
    if (e.target.id) {
      setlicenseId((data: any) => [...data, { license_id: e.target.id }]);
      const obj = { license_id: e.target.id };
      const filteredInvoice = invoice.filter((item: any) => {
        return !(item.license_id == e.target.id);
      });
      setInvoice(filteredInvoice);
    } else {
      const filteredInvoice = invoice.filter((item: any, i: number) => {
        return !(i == index);
      });
      setInvoice(filteredInvoice);
    }
  }
  //** PC_NO_IN_38.5, PC_NO_IN_38.6, PC_NO_IN_38.7, PC_NO_IN_38.8, PC_NO_IN_38.9, PC_NO_IN_38.10, PC_NO_IN_38.11, PC_NO_IN_38.12, PC_NO_IN_38.13, PC_NO_IN_38.14, PC_NO_IN_38.15, PC_NO_IN_38.16, PC_NO_IN_38.17, PC_NO_IN_38.18, PC_NO_IN_38.19
  // To perform the save operation */
  async function saveInvoiceLicenseChanges() {

    try{

    setLicense_name_error("");
    setQuantity_error("");
    setTermduration_error("");
    setStart_date_error("");
    setEnd_date_error("");
    setMicrosoft_price_error("");
    setReseller_price_error("");

    if (isEdit) {
      setloader(true);
      const request: any = {
        payload: [...invoice],
        delete: [...licenseId],
        customer_id: customerId,
      };

      const response = await saveInvoicelicenseDetails(request);

      if (response.data.statusCode === 200) {
        getCustomerInvoiceDetailsById();
      }
      setloader(false);
    } else if (!isEdit) {
      if (customerId && statusId) {
        setloader(true);
        let payload = {
          customer_id: customerId,
          statusId: statusId,
          created_by: sessionUserDetails && sessionUserDetails.user_id,
          invoice: invoice,
        };
        let data = await createInvoicelicenseDetails(payload);
        if (data.data.statusCode == 200) {
          navigate("/invoice");
        }
      } else {
        !customerId && setcheckCustomer("please select customer");
        !statusId && setcheckStatus("please select status");
      }
    }

  }catch(e:any){
    if(e?.response.data?.statusCode == 429){
      setloader(false);
      setShowErrorPopup(true);
    }
  }
  }
  //** PC_NO_IN_38.20, PC_NO_IN_38.21, PC_NO_IN_38.22, PC_NO_IN_38.23, PC_NO_IN_38.24, PC_NO_IN_38.25, PC_NO_IN_38.26, PC_NO_IN_38.27, PC_NO_IN_38.28, PC_NO_IN_38.29, PC_NO_IN_38.30, PC_NO_IN_38.31
  //TO validate the lineitem */
  function InlineValidation(index: number) {
    // Assuming this is inside a function

    // Initialize a flag
    let isValid = true;

    if (quantity[index]?.length <= 0) {
      setQuantityInlineError({
        ...QuantitynlineError,
        [index]: "enterquantity",
      });
      isValid = false;
    }

    if (license_name[index]?.length <= 0) {
      setLicenseInlineError({
        ...LicenseInlineError,
        [index]: "enterlicense",
      });
      isValid = false;
    }

    if (termduration[index]?.length <= 0) {
      setTermInlineError({
        ...TermInlineError,
        [index]: "enter term",
      });
      isValid = false;
    }

    if (start_date[index]?.length <= 0) {
      setStartDateInlineError({
        ...StartDateInlineError,
        [index]: "enter Start Date",
      });
      isValid = false;
    }

    if (end_date[index]?.length <= 0) {
      setEndDateInlineError({
        ...EndDateInlineError,
        [index]: "enter end Date",
      });
      isValid = false;
    }

    if (microsoft_price[index]?.length <= 0) {
      setmircoSoftpriceInlineError({
        ...mircoSoftpriceInlineError,
        [index]: "enter microsoftprice",
      });
      isValid = false;
    }

    if (reseller_price[index]?.length <= 0) {
      setresellerpriceInlineError({
        ...resellerpriceInlineError,
        [index]: "enter reseller",
      });
      isValid = false;
    }

    // Return the flag
    return isValid;
  }
  //** SQ_NO_INCAC_1, SQ_NO_INCAC_2, SQ_NO_INCAC_3, SQ_NO_INCAC_4, SQ_NO_INCAC_5, SQ_NO_INCAC_6, SQ_NO_INCAC_7, SQ_NO_INCAC_8, SQ_NO_INCAC_9, SQ_NO_INCAC_10, SQ_NO_INCAC_11, SQ_NO_INCAC_12
  //To Perform the Create invoice operation to prefill the license details into the invoice form fields */
  async function customerAutoCalcLicense(e: any) {
    try {
      if (e.target.value != "") {
        setcustomerId(e.target.value);
        setloader(true);
        const obj = { customer_id: e.target.value };
        let getdata = await GetCustomerLegalDetailsById(obj);        
        let sendcalcObj = {
          customer_id: e.target.value,
          tax_certificate: getdata.data.responseData.tax_certificate ? 1 : 0,
        };
        const response: any = await invoiceCalculatorByCustomer(sendcalcObj);
        if (response.status === 200) {
          
          setloader(false);
          let changekey = response?.data.map((datas: any) => {
            return {
              ...datas,
              term_duration: datas.termduration,
            };
          });
          setInvoice(changekey);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  //** SQ_NO_ING_1, SQ_NO_ING_2, SQ_NO_ING_3, SQ_NO_ING_4
  //to render the htmlvalue */
  function generateRows() {
    return invoice?.map((item: any, index: any) => {

      let formattedDate = moment(start_date[index]).format("MM/DD/YYYY");

      return (
        <tr className="border-bottom-0" key={item?.license_id}>
          {!item?.editmode ? (
            <>
              <td className="font-regular">
                {" "}
                {license_name[index]?.length > 25 ? (
                  <Tooltip title={license_name[index]} placement="top-end">
                    <Button className="text-fixer">
                      {license_name[index]?.substring(0, 25) + "..."}
                    </Button>
                  </Tooltip>
                ) : (
                  license_name[index]
                )}
              </td>
              <td className="text-end font-regular">{quantity[index]}</td>
              <td className="font-regular">{termduration[index]}</td>
              <td className="font-regular">
                {moment.utc(start_date[index]).format("MM/DD/YYYY")}
              </td>
              <td className="font-regular">
                {moment.utc(end_date[index]).format("MM/DD/YYYY")}
              </td>
              <td className="text-end font-regular">
                ${formatAmount(Number(microsoft_price[index]))}
              </td>
              <td className="text-end font-regular">
                ${formatAmount(Number(reseller_price[index]))}
              </td>
              <td className="text-center font-regular">
                <span className="cursor-pointer">
                  <img
                    src="Images/edit-icon.svg"
                    alt="editIcon"
                    title="Edit"
                    data-editmode={item.editmode} // Use a data attribute
                    onClick={(e: any) => {
                      // handleEdit(e, index);
                      setdisablesave(true);
                      setInvoice((prev: any) => {
                        const updaterecords = [...prev];
                        updaterecords[index] = {
                          ...updaterecords[index],
                          editmode: true,
                        };
                        return updaterecords;
                      });
                    }}
                  />
                </span>
                <span className="ms-3 cursor-pointer">
                  <img
                    src="Images/delete-icon.svg"
                    alt="deleteIcon"
                    title="Delete"
                    id={item?.license_id}
                    onClick={(e) => {
                      deleteCustomerInvoiceDetailsById(e, index);
                    }}
                  />
                </span>
              </td>
            </>
          ) : (
            <>
              <td className="font-regular">
                <input
                  type="text"
                  className="form-control custom-input"
                  placeholder="Enter license"
                  name="license_name"
                  value={license_name[index]}
                  onChange={(e: any) => {
                    handleOnchangeInlinegrid(e, index);
                  }}
                />
                <span className="color-red">{LicenseInlineError[index]}</span>
              </td>
              <td className="text-end font-regular">
                <input
                  type="text"
                  className="form-control custom-input"
                  placeholder="Enter quantity"
                  name="quantity"
                  value={quantity[index]}
                  onChange={(e: any) => {
                    handleOnchangeInlinegrid(e, index);
                  }}
                />
                <span className="color-red">{QuantitynlineError[index]}</span>
              </td>
              <td className="font-regular">
                <select
                  className=" form-select custom-select custom-input font-regular font-14"
                  name="term_duration"
                  value={termduration[index]}
                  onChange={(e: any) => {
                    handleOnchangeInlinegrid(e, index);
                  }}
                >
                  <option value="">Select</option>
                  {/* {TermDurationDropDown?.map((val: any) => (
                    <option value={val}>{val.term_duration}</option>
                  ))} */}
                  {TermDurationDropDown.map((data: any) => {
                    return (
                      <>
                        <option value={data.term_duration}>
                          {data.term_duration}
                        </option>
                      </>
                    );
                  })}
                </select>

                <span className="color-red">{TermInlineError[index]}</span>
              </td>
              <td className="font-regular">
                <input
                  type="date"
                  className="form-control custom-input"
                  // placeholder="Enter start date"
                  name="start_date"
                  value={moment(start_date[index]).format("YYYY-MM-DD")}
                  onChange={(e) => {
                    handleOnchangeInlinegrid(e, index);
                  }}
                />

                <span className="color-red">{StartDateInlineError[index]}</span>
              </td>
              <td className="font-regular">
                <input
                  type="date"
                  className="form-control custom-input"
                  placeholder="Enter end date"
                  name="end_date"
                  value={moment(end_date[index]).format("YYYY-MM-DD")}
                  onChange={(e: any) => {
                    handleOnchangeInlinegrid(e, index);
                  }}
                />

                <span className="color-red">{EndDateInlineError[index]}</span>
              </td>
              <td className="text-end font-regular">
                <input
                  type="text"
                  className="form-control custom-input"
                  placeholder="Enter Microsoft price"
                  name="microsoft_price"
                  value={microsoft_price[index]}
                  onChange={(e) => {
                    handleOnchangeInlinegrid(e, index);
                  }}
                />

                <span className="color-red">
                  {mircoSoftpriceInlineError[index]}
                </span>
              </td>
              <td className="text-end font-regular">
                <input
                  type="text"
                  className="form-control custom-input"
                  placeholder="Enter reseller price"
                  name="reseller_price"
                  value={reseller_price[index]}
                  onChange={(e) => {
                    handleOnchangeInlinegrid(e, index);
                  }}
                />
                <span className="color-red">
                  {resellerpriceInlineError[index]}
                </span>
              </td>
              <td className="text-center font-regular">
                <span className="cursor-pointer">
                  <img
                    src="Images/right-icon.svg"
                    alt="editIcon"
                    title="Edit"
                    id={index}
                    key={item.license_id}
                    onClick={(e: any) => {
                      setdisablesave(false);
                      if (InlineValidation(index)) {
                        setInvoice((invoice: any) => {
                          const data = [...invoice];
                          data[index] = {
                            ...data[index],
                            license_name: license_name[index],
                            editmode: false,
                            quantity: quantity[index],
                            term_duration: termduration[index],
                            start_date: start_date[index],
                            end_date: end_date[index],
                            microsoft_price: microsoft_price[index],
                            reseller_price: reseller_price[index],
                          };
                          return data;
                        });
                      }
                    }}
                  />
                </span>
                <span className="ms-3 cursor-pointer">
                  <img
                    src="Images/Wrong-icon.svg"
                    alt="deleteIcon"
                    title="Delete"
                    id={item.license_id}
                    onClick={(e: any) => {
                      setdisablesave(false);
                      // handleClearwithClose(e, index);
                      setInvoice((invoiceData: any) => {
                        const data = [...invoiceData];
                        data[index] = {
                          ...data[index],
                          editmode: false,
                        };
                        return data;
                      });
                    }}
                  />
                </span>
              </td>
            </>
          )}
        </tr>
      );
    });
  }

  const [updateLicenseDetails, setUpdateLicenseDetails] = useState({
    license_id: "",
    license_name: "",
    quantity: "",
    term_duration: "",
    start_date: "",
    end_date: "",
    microsoft_price: "",
    reseller_price: "",
    invoice_id: "",
  });

  async function updateCustomerInvoiceLicenseDetails() {
    const request: any = {
      license_id: "",
      license_name: "",
      quantity: "",
      term_duration: "",
      start_date: "",
      end_date: "",
      microsoft_price: "",
      reseller_price: "",
      invoice_id: "",
    };

    const response = await updateInvoicelicenseDetails(updateLicenseDetails);
  }
  //** PC_NO_IN_38.48, PC_NO_IN_38.49, PC_NO_IN_38.50, PC_NO_IN_38.51
  // To perform the Create Customer invoice license detail into the form grid */
  async function createCustomerInvoicelicenseDetails() {
    if (handleInputsValidattion()) {
      setInvoice((data: any) => {
        const newData = [...data];

        newData.push({
          company_name: newData[0]?.company_name, // Use newData instead of data
          editmode: false,
          end_date: createLicense.end_date,
          invoice_id: location.state
            ? location.state.invoice_id
            : newData[0].invoice_id, // Use newData instead of data
          license_name: createLicense.license_name,
          microsoft_price: createLicense.microsoft_price,
          quantity: createLicense.quantity,
          reseller_price: createLicense.reseller_price,
          start_date: createLicense.start_date,
          status_id: statusId,
          term_duration: createLicense.term_duration,
          isNew: true,
        });

        return newData;
      });

      setCreateLicense({
        invoice_id: "",
        // status_id: 0,
        license_id: "",
        license_name: "",
        quantity: "",
        term_duration: "",
        start_date: "",
        end_date: "",
        microsoft_price: "",
        reseller_price: "",
      });
    }
  }
  //** PC_NO_IN_38.52, PC_NO_IN_38.53, PC_NO_IN_38.54, PC_NO_IN_38.55, PC_NO_IN_38.56
  ///To handle input Changes */
  const handleInputsChange = (e: any) => {
    const { name, value } = e.target;

    if (
      name == "license_name" ||
      name == "start_date" ||
      name == "end_date" ||
      name == "term_duration"
    ) {
      setCreateLicense((prevData: any) => ({
        ...prevData,
        [name]: value,
      }));
    }
    if (name == "quantity") {
      if (!OnlyNumber(value)) {
        setCreateLicense((prevData: any) => ({
          ...prevData,
          [name]: value,
        }));
      }
    }
    if (name == "microsoft_price" || name == "reseller_price") {
      if (!OnlyIntegersInclidesDot(value)) {
        setCreateLicense((prevData: any) => ({
          ...prevData,
          [name]: value,
        }));
      }
    }

    if (name == "license_name" && value.length > 0) {
      setLicense_name_error("");
    }
    if (name == "quantity" && value.length > 0) {
      setQuantity_error("");
    }
    if (name == "term_duration" && value.length > 0) {
      setTermduration_error("");
    }
    if (name == "start_date" && value.length > 0) {
      setStart_date_error("");
    }
    if (name == "end_date" && value.length > 0) {
      setEnd_date_error("");
    }
    if (name == "microsoft_price" && value.length > 0) {
      setMicrosoft_price_error("");
    }
    if (name == "reseller_price" && value.length > 0) {
      setReseller_price_error("");
    }
  };
  //**PC_NO_IN_38.57, PC_NO_IN_38.58, PC_NO_IN_38.59, PC_NO_IN_38.60, PC_NO_IN_38.61, PC_NO_IN_38.62, PC_NO_IN_38.63, PC_NO_IN_38.64, PC_NO_IN_38.65
  // handle the onchange validatation */
  const handleOnchangeInlinegrid = (e: any, index: any) => {
    const { name, value } = e.target;

    if (name == "license_name") {
      value.length > 0 &&
        setLicenseInlineError({
          ...LicenseInlineError,
          [index]: "",
        });
      setLicense_name({ ...license_name, [index]: value });
    } else if (name == "quantity") {
      if (!OnlyNumber(value)) {
        value.length > 0 &&
          setQuantityInlineError({ ...QuantitynlineError, [index]: "" });
        setQuantity({ ...quantity, [index]: value });
      }
    } else if (name == "start_date") {
      e.target.value.length > 0 &&
        setStartDateInlineError({
          ...StartDateInlineError,
          [index]: "",
        });

      setStart_date({
        ...start_date,
        [index]: value,
      });
    } else if (name == "end_date") {
      value.length > 0 &&
        setEndDateInlineError({
          ...EndDateInlineError,
          [index]: "",
        });
      setEnd_date({ ...end_date, [index]: value });
    } else if (name == "term_duration") {
      value.length > 0 &&
        setTermInlineError({
          ...TermInlineError,
          [index]: "",
        });

      setTermduration({
        ...termduration,
        [index]: value,
      });
    } else if (name == "microsoft_price") {
      if (!OnlyIntegersInclidesDot(value)) {
        value.length > 0 &&
          setmircoSoftpriceInlineError((prevData: any) => ({
            ...mircoSoftpriceInlineError,
            [index]: "",
          }));
        setMicrosoft_price({
          ...microsoft_price,
          [index]: value,
        });
      }
    } else if (name == "reseller_price") {
      if (!OnlyIntegersInclidesDot(value)) {
        setresellerpriceInlineError({
          ...resellerpriceInlineError,
          [index]: "",
        });
        setReseller_price({
          ...reseller_price,
          [index]: value,
        });
      }
    }
  };
  //*** PC_NO_IN_38.66, PC_NO_IN_38.67, PC_NO_IN_38.68, PC_NO_IN_38.69, PC_NO_IN_38.70, PC_NO_IN_38.71, PC_NO_IN_38.72, PC_NO_IN_38.73, PC_NO_IN_38.74
  //to handle the Input validdation */
  function handleInputsValidattion() {
    let isValid = true;

    if (createLicense.license_name.trim() === "") {
      setLicense_name_error("Enter License");
      isValid = false;
    }

    if (createLicense.quantity.trim() === "") {
      setQuantity_error("Enter quantity");
      isValid = false;
    } else if (OnlyNumber(createLicense.quantity)) {
      setQuantity_error("Enter valid quantity");
      isValid = false;
    }

    if (createLicense.term_duration.trim() === "") {
      setTermduration_error("Enter term duration");
      isValid = false;
    }

    if (createLicense.start_date.trim() === "") {
      setStart_date_error("Enter start date");
      isValid = false;
    }

    if (createLicense.end_date.trim() === "") {
      setEnd_date_error("Enter end date");
      isValid = false;
    }

    if (createLicense.microsoft_price.trim() === "") {
      setMicrosoft_price_error("Enter microsoft price");
      isValid = false;
    } else if (OnlyIntegersInclidesDot(createLicense.microsoft_price)) {
      setMicrosoft_price_error("Enter valid microsoft price");
      isValid = false;
    }

    if (createLicense.reseller_price.trim() === "") {
      setReseller_price_error("Enter reseller price");
      isValid = false;
    } else if (OnlyIntegersInclidesDot(createLicense.reseller_price)) {
      setReseller_price_error("Enter valid reseller price");
      isValid = false;
    }

    return isValid;
  }
  //** SQ_NO_INBC_1, SQ_NO_INBC_2, SQ_NO_INBC_3, SQ_NO_INBC_4, SQ_NO_INBC_5
  // to bind the CustomerDD into dropdown */
  function bindCustomerDD() {
    return location.state.customer_id ? (
      <option value={location?.state?.customer_id}>
        {location?.state?.customer_name}
      </option>
    ) : (
      customerDrodpown.map((val: any) => (
        <option value={val.customer_id}>{val.company_name}</option>
      ))
    );
  }

  return (
    <>
      {/* header starts */}
      {/* header ends */}
      {loader ? <Loader /> : <></>}
      {showErrorPopup? <ErrorPopup closePopup = {setShowErrorPopup} message={"You've reached the maximum limits.Try after a minute"} /> : <></>}
      <div className="container-fluid px-4">
        <div className="row justify-content-center">
          <div className="px-4">
            <BreadCrumbs data={breadcrumb} />

            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 bg-white mt-3 rounded-3 p-4 mb-5">
              <div className="row">
                <h3 className="font-18 font-semibold tertiary-color mb-4 ">
                  {/* {invoice[0]?.company_name} Invoice */}
                  {isEdit
                    ? location?.state?.company_name + " Invoice"
                    : "Invoice"}
                </h3>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="mb-3">
                    <label className="font-14 font-semibold primary-text-color mb-2">
                      Invoice Number
                    </label>
                    <p className="font-13 font-regular secondary-text-color">
                      {location.state.invoice_id}
                    </p>
                  </div>
                </div>

                <>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    {isEdit ? (
                      <div className="mb-3">
                        <label className="font-14 font-semibold primary-text-color mb-2">
                          Customer Name
                        </label>
                        <p className="font-13 font-regular secondary-text-color">
                          {customername}
                        </p>
                      </div>
                    ) : (
                      <div className="mb-3">
                        <label className="font-14 font-semibold primary-text-color mb-2">
                          Customer Name
                        </label>
                        <select
                          className="form-select custom-input custom-select font-13 font-regular secondary-text-color invoice-select"
                          aria-label="select"
                          onChange={(e: any) => {
                            customerAutoCalcLicense(e);
                            e.target.value && setcheckCustomer("");
                          }}
                          value={customerId}
                          // onChange={(e:any) => setCustomerId(e.target.value)} // Assuming you want to update the state onChange
                        >
                          <option value="select">Select</option>
                          {bindCustomerDD()}
                        </select>
                        <span className="color-red">{checkCustomer}</span>
                      </div>
                    )}
                  </div>
                </>

                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="mb-3">
                    <label className="font-14 font-semibold primary-text-color mb-2">
                      Status
                    </label>
                    <select
                      className=" form-select custom-select custom-input font-regular font-14 invoice-select"
                      value={statusId}
                      onChange={(e: any) => {
                        setStatusID(e.target.value);
                        if (e.target.value) {
                          setcheckStatus("");
                        }
                      }}
                    >
                      <option value="">select</option>
                      {filterStatusDropDown.map((val: any) => (
                        <option value={val.status_id}>{val.status_type}</option>
                      ))}
                    </select>
                    <span className="color-red">{checkStatus}</span>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <h2 className="font-20 font-bold dark-blue-color d-flex mt-2 mb-4">
                    License List{" "}
                    <span className="custom-admin-batch font-10 d-flex align-items-center justify-content-center ms-2 px-2">
                      {invoice.length}
                    </span>
                  </h2>
                  <div className="table-responsive mb-4 rounded-3">
                    <table className="table  themeTable mb-0">
                      <thead className="font-bold">
                        <tr>
                          <th>License</th>
                          <th className="text-end">Quantity</th>
                          <th>Term Duration</th>
                          <th>Start Date</th>
                          <th>End Date</th>
                          <th className="text-end">Microsoft Price</th>
                          <th className="text-end">Reseller Price</th>
                          <th className="text-center">Actions</th>
                        </tr>
                      </thead>
                      <tbody className="font-medium">
                        <tr className="border-bottom-0 ">
                          <td className="font-regular custom-inline-table">
                            <input
                              type="text"
                              className="form-control custom-input"
                              placeholder="Enter licence"
                              name="license_name"
                              value={createLicense.license_name}
                              onChange={(e) => {
                                handleInputsChange(e);
                              }}
                            />
                            {license_name_error && (
                              <span className="color-red d-flex justify-content-start mt-1">
                                {license_name_error}
                              </span>
                            )}
                          </td>
                          <td className="text-end font-regular custom-inline-table">
                            <input
                              type="text"
                              className="form-control custom-input"
                              placeholder="Enter quantity"
                              name="quantity"
                              value={createLicense.quantity}
                              onChange={(e) => {
                                handleInputsChange(e);
                              }}
                            />
                            {quantity_error && (
                              <span className="color-red d-flex justify-content-start mt-1">
                                {quantity_error}
                              </span>
                            )}
                          </td>
                          <td className="font-regular custom-inline-table">
                            {/* <select
                                                            className="form-select custom-input custom-select font-13 font-regular secondary-text-color"
                                                            aria-label="select "
                                                        >
                                                            <option >Select</option>
                                                            <option value={1}>Yearly</option>
                                                            <option value={2}>Monthly</option>
                                                        </select> */}

                            <select
                              className=" form-select custom-select custom-input font-regular font-14"
                              name="term_duration"
                              value={createLicense.term_duration}
                              onChange={(e) => {
                                handleInputsChange(e);
                              }}
                            >
                              <option value="">Select</option>
                              {/* {TermDurationDropDown.map((val: any) => (
                                <option value={val}>{val}</option>
                              ))} */}
                              {TermDurationDropDown.map((data: any) => {
                                return (
                                  <>
                                    <option value={data.term_duration}>
                                      {data.term_duration}
                                    </option>
                                    |
                                  </>
                                );
                              })}
                            </select>
                            {termduration_error && (
                              <span className="color-red d-flex justify-content-start mt-1">
                                {termduration_error}
                              </span>
                            )}
                          </td>
                          <td className="font-regular custom-inline-table">
                            <input
                              type="date"
                              className="form-control custom-input"
                              name="start_date"
                              value={createLicense.start_date}
                              max={createLicense.end_date}
                              onChange={(e) => {
                                handleInputsChange(e);
                              }}
                            />
                            {start_date_error && (
                              <span className="color-red d-flex justify-content-start mt-1">
                                {start_date_error}
                              </span>
                            )}
                          </td>
                          <td className="font-regular custom-inline-table">
                            <input
                              type="date"
                              className="form-control custom-input"
                              name="end_date"
                              value={createLicense.end_date}
                              min={createLicense.start_date}
                              onChange={(e) => {
                                handleInputsChange(e);
                              }}
                            />
                            {end_date_error && (
                              <span className="color-red d-flex justify-content-start mt-1">
                                {end_date_error}
                              </span>
                            )}
                          </td>
                          <td className="text-end font-regular custom-inline-table">
                            <input
                              type="text"
                              className="form-control custom-input"
                              placeholder="Enter Price"
                              name="microsoft_price"
                              value={createLicense.microsoft_price}
                              onChange={(e) => {
                                handleInputsChange(e);
                              }}
                            />
                            {microsoft_price_error && (
                              <span className="color-red d-flex justify-content-start mt-1">
                                {microsoft_price_error}
                              </span>
                            )}
                          </td>
                          <td className="text-end font-regular custom-inline-table">
                            <input
                              type="text"
                              className="form-control custom-input"
                              placeholder="Enter Price"
                              name="reseller_price"
                              value={createLicense.reseller_price}
                              onChange={(e) => {
                                handleInputsChange(e);
                              }}
                            />
                            {reseller_price_error && (
                              <span className="color-red d-flex justify-content-start mt-1">
                                {reseller_price_error}
                              </span>
                            )}
                          </td>
                          <td className="text-center font-regular custom-inline-table">
                            <span className="cursor-pointer">
                              <img
                                src="Images/Add-icon.svg"
                                alt="add-icon"
                                title="Add"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                data-bs-original-title="add item"
                                aria-label="add item"
                                onClick={() => {
                                  createCustomerInvoicelicenseDetails();
                                }}
                              />
                            </span>
                          </td>
                        </tr>

                        {generateRows()}
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex justify-content-end mb-3">
                    <button
                      type="button"
                      className="btn  text-btn text border-0 font-14 font-semibold me-3 shadow-none text-btn"
                      onClick={() =>
                        navigate("/invoice", { state: { mode: "view" } })
                      }
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn primary-btn border-0 font-14 font-semibold "
                      disabled={disablesave || invoice.length <= 0}
                      onClick={() => {
                        saveInvoiceLicenseChanges();
                      }}
                    >
                      {isEdit ? "Save" : "Create"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InvoiceForm;
