
import { BlobServiceClient, ContainerClient } from '@azure/storage-blob';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { retrivingToken } from '../services/adminService';
import { decrypt } from './encryption';
import { configs } from './constant';

const containerName:any =  decrypt(configs,process.env.REACT_APP_BLOB_CONTAINER_NAME);
const storageAccountName:any = decrypt(configs,process.env.REACT_APP_BLOB_STORAGE_ACCOUNTNAME);// Fill string with your Storage resource name



// <snippet_isStorageConfigured>
// Feature flag - disable storage feature to app if not configured
export const isStorageConfigured = () => {
    return (!storageAccountName) ? false : true;
}

const createBlobInContainer = async (containerClient:any, file:any, path:any) => {

try{

    let instance = moment().format("DDHHMMmmYYYYss");
    instance += uuid() + "-iiiii-";

    let defaultPath = 'AVAHUB';
    if (!path) {
        path = defaultPath;
    }

    // create blobClient for container
    const blobClient = containerClient.getBlockBlobClient(`${path}/` + instance + file.name);

    // set mimetype as determined from browser with file upload control
    const options = { blobHTTPHeaders: { blobContentType: file.type } };

    // upload file
    let res = await blobClient.uploadBrowserData(file, options);
    if (res._response.status == 201) {
        return res._response.request.url.split('?')[0]
    }
}
catch(e:any){

    console.log(e)

}
    
}
// </snippet_createBlobInContainer>

// <snippet_uploadFileToBlob>
const uploadFileToBlob = async (file:any, path:any) => {
    if (!file) return [];


    let forSASToken:any = await retrivingToken();

    const blobService = new BlobServiceClient(
        `https://${storageAccountName}.blob.core.windows.net/${forSASToken.data.token}`
    );

    let defaultPath = 'AVAHUB';
    if (!path) {
        path = defaultPath;
    }

    const containerClient = blobService.getContainerClient(containerName);
    // await containerClient.createIfNotExists({
    //     access: 'container',
    // });

    let loc = await createBlobInContainer(containerClient, file, path);

    return loc;
};
// </snippet_uploadFileToBlob>

export default uploadFileToBlob;