
import { client } from "../../src/client";
import { backendurl as url ,adminUrl } from "../configs/constant";//PS_AD_14

//** PS_AD_15, PS_AD_16, PS_AD_17, PS_AD_18.1
// Fetch the User details */
export async function getUserDetails(data: {} | undefined) {

  let Config = {
    url: url+adminUrl.userDetails,
    headers: {
      "content-type": "application/json",
    },
    method: "post",
    body: data,
  };

  let response = await client(Config);
  return response;
}
//** PS_AD_18.2, PS_AD_18.3, PS_AD_18.4, PS_AD_18.5
// To fetch the user Filter Dropdown option value details */
export async function getUserFilterDetails(data:{}={}) {
  let Config = {
    url: url+adminUrl.getUser,
    headers: {
      "content-type": "application/json",
    },
    method: "post",
    body:data
  };

  let response = await client(Config);
  return response;
}
//** PS_AD_18.6, PS_AD_18.7, PS_AD_18.8, PS_AD_18.9
// To Fetch the user Details by User ID  */
export async function getUserById(data: {} | undefined) {

  let Config = {
    url: url+adminUrl.getUserById,
    headers: {
      "content-type": "application/json",
    },
    method: "post",
    body: data,
  };
  let response = await client(Config);
  return response;
}
//** PS_AD_18.10, PS_AD_18.11, PS_AD_18.12, PS_AD_18.13
// Update the user role details */
export async function updateUserDetail(data: {} | undefined) {

  let Config = {
    url: url+adminUrl.updateUserRole,
    headers: {
      "content-type": "application/json",
    },
    method: "post",
    body: data,
  };
  let response = await client(Config);
  return response;
}
//** PS_AD_18.14, PS_AD_18.15, PS_AD_18.16, PS_AD_18.17
// to retriving the accesstoken for hte Blob container  */
export async function retrivingToken(data:any = {}) {

  let Config = {
    url: url+adminUrl.fileNameUpload,
    headers: {
      "content-type": "application/json",
    },
    method: "post",
    body: data,
  };
  let response = await client(Config);
  return response;
}


