import React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  editSubscriptionApi,
  updateSubscriptionApi,
} from "../../services/subscriptionservice";
import HistoryComponent from "./history";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import BreadCrumbs from "./breadcrumbs";
import {
  OnlyNumber,
  sessionKeys,
  encryptStorage,
  formatAmount,
} from "../../configs/constant";
import { userSessionData } from "../../interface";
import { Button, Tooltip } from "@mui/material";
//** PS_SH_29, PS_SH_30, PS_SH_31, PS_SH_32, PS_SH_33, PS_SH_34 to perform the edit operation */
export default function EditSubscripiton() {
  const navigate = useNavigate();
  let location: any = useLocation();

  const [bindData, setbindData] = useState<any>([]);
  const [terms, setterms] = useState<any>([]);
  const [customerData, setCustomerData] = useState<any>([]);
  const [selectedTerm, setSelectedTerm] = useState<string>("");
  const [selectedBillingCycle, setSelectedBillingCycle] = useState<any>("");
  const [showDetails, setShowDetails] = useState(true);
  const [showHistory, setshowHistory] = useState(false);
  const [subscriptionId, setsubscriptionId] = useState<string>();
  const [loader, setloader] = useState<boolean>(false);
  const [Quantity, setQuantity] = useState<string>("");
  const [showQuantity, setShowQuantity] = useState(false);
  const [autoRenewal, setAutoRenewal] = useState<boolean>(false);
  const [oldSubscriptionData, setoldSubscriptionData] = useState<any>([]);
  const [submitEnable, setSubmitEnable] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [minimumQuantity, setminimumQuantity] = useState<number>(1);
  const [maximumQuantity, setMaximumQuantity] = useState<number>(0);
  const [quantityErrormsg, setquantityErrormsg] = useState<string>("");
  const [reRender, setReRender] = useState<boolean>(true);
  const [viewform, setviewform] = useState(
    location?.state?.mode == "view" ? true : false
  );

  const [customerName, setcustomerName] = useState(
    customerData.length
      ? customerData[0].company_name
      : location?.state?.params?.company_name
  );

  let breadcrumb = [
    { title: "Subscription", isActive: true, path: "/subscription" },
    {
      title: location?.state?.params?.company_name,
      isActive: true,
      path: null,
    },
    {
      title: location?.state?.params?.license_name,
      isActive: false,
      path: null,
    },
  ];

  useEffect(() => {
    if (location.state.subscriptionId != "") {
      let obj = {
        subscriptionId: location.state.params.subscription_id,
        customerId: location.state.params.customer_id,
        licenseName: location.state.params.license_name,
        productId: location.state.params.product_id,
        skuId: location.state.params.sku_id,
        termDuration: location.state.params.term_duration,
        BillingCycle: location.state.params.billing_cycle,
      };
      getEditDetails(obj);
    }
  }, []);

  useEffect(() => {
    checkSubmitEnable();
  }, [reRender]);

  useEffect(() => {});
//** PS_SH_35, PS_SH_36, PS_SH_37, PS_SH_38, PS_SH_39, PS_SH_40, PS_SH_41, PS_SH_42, PS_SH_43, PS_SH_44, PS_SH_45, PS_SH_46, PS_SH_47, PS_SH_48, PS_SH_49
//Fetch the edited subscriptions details  */
  async function getEditDetails(obj: any) {
    setloader(true);
    let getBindData: any = await editSubscriptionApi(obj);

    if (getBindData.data.statusCode == 200) {
      setminimumQuantity(getBindData.data.responseData.minimumQuantity);
      setMaximumQuantity(getBindData.data.responseData.maximumQuantity);
      setsubscriptionId(getBindData.data.responseData.subscription[0].id);
      setCustomerData(getBindData.data.responseData.customerData);
      setSelectedTerm(
        getBindData.data.responseData.subscription[0].termDuration
      );
      setSelectedBillingCycle(
        getBindData.data.responseData.subscription[0].billingCycle
      );
      setbindData(getBindData.data.responseData.subscription);
      setoldSubscriptionData(getBindData.data.responseData.subscription);
      setterms(getBindData.data.responseData.termDuration);
      setQuantity(getBindData.data.responseData.subscription[0].quantity);
      setAutoRenewal(
        getBindData.data.responseData.subscription[0].autoRenewEnabled
      );
      setReRender(!reRender);
      setloader(false);
    }
  }
  //** PS_SH_50, PS_SH_51, PS_SH_52, PS_SH_53, PS_SH_54 to handle the term duration change  */
  const handleDurationChange = (event: any) => {
    const duration = event.target.value;
    setSelectedTerm(duration);
    setReRender(!reRender);
  };

  const selectedBillingCycleOptions =
    selectedTerm &&
    terms?.find((option: any) => option?.duration === selectedTerm);
//** PS_SH_55, PS_SH_56, PS_SH_57, PS_SH_58, PS_SH_59, PS_SH_60, PS_SH_61, PS_SH_62, PS_SH_63
// handle the tab action changes */
  const handleTabChange = (tab: any) => {
    if (tab === "details") {
      setShowDetails(true);
      setshowHistory(false);
    } else if (tab === "history") {
      setShowDetails(false);
      setshowHistory(true);
    }
  };
//** PS_SH_64, PS_SH_65, PS_SH_66, PS_SH_67, PS_SH_68, PS_SH_69, PS_SH_70, PS_SH_71, PS_SH_72, PS_SH_73, PS_SH_74, PS_SH_75
// udpate the subscriptions details */
  async function updateSubscription() {
    setloader(true);
    let sessionUserDetails: userSessionData | null = JSON.parse(
      encryptStorage.getItem(sessionKeys.userDetails) || "null"
    );

    let data = {
      subscriptions: {
        ...bindData[0],
        termDuration: selectedTerm,
        billingCycle: selectedBillingCycle,
        autoRenewEnabled: autoRenewal,
      },
      customerId: location?.state?.params?.customer_id,
      subscriptionHistory: oldSubscriptionData[0],
      roleId: sessionUserDetails?.role_id,
      userId: sessionUserDetails?.user_id,
      userName: sessionUserDetails?.user_name,
    };

    let update = await updateSubscriptionApi(data);
    if (update.data.statusCode == 200) {
      setErrorMessage("");
      setloader(false);
      navigate("/subscription");
    }
    if (update.data.statusCode == 400) {
      setErrorMessage(update.data.errorMessage);
      setloader(false);
    }
  }
//** PS_SH_76, PS_SH_77, PS_SH_78, PS_SH_79, PS_SH_80, PS_SH_81, PS_SH_82, PS_SH_83
// Check the submit is enable */
  const checkSubmitEnable = () => {
    const bindDataItem = bindData[0];
    if (
      autoRenewal !== bindDataItem?.autoRenewEnabled ||
      selectedBillingCycle?.toLowerCase() !==
        bindDataItem?.billingCycle?.toLowerCase() ||
      selectedTerm.toLowerCase() !== bindDataItem?.termDuration.toLowerCase() ||
      Number(Quantity) !== oldSubscriptionData[0]?.quantity
    ) {
      setSubmitEnable(true);
    } else {
      setSubmitEnable(false);
    }
  };
//** PS_SH_84, PS_SH_85, PS_SH_86, PS_SH_87
// to handle the quanity change  */
  function quantityOnChange(e: any) {
    if (!OnlyNumber(e.target.value)) {
      setQuantity(e.target.value);
    }
  }

  return (
    <>
      <div className="container-fluid mt-5 pt-4">
        <BreadCrumbs data={breadcrumb} />
        <div className="row justify-content-center">
          <div className="col-lg-11 col-md-12 col-sm-12 bg-white mt-3 rounded-3 p-4 mb-5">
            <ul className="nav nav-tabs mb-4" id="myTab" role="tablist">
              <li className="nav-item position-relative" role="presentation">
                <a
                  //   href="#"
                  //   className="nav-link custom-nav-link border-0 py-2 font-16 font-semibold active"
                  id="home-tab"
                  className={`nav-link custom-nav-link border-0 py-2 font-16 font-semibold cursor-pointer ${
                    showDetails ? "active" : ""
                  }`}
                  onClick={() => handleTabChange("details")}
                  //   data-bs-toggle="tab"
                  //   data-bs-target="#home-tab-pane"
                  //   role="tab"
                  //   aria-controls="home-tab-pane"
                  //   aria-selected="true"
                >
                  Details
                </a>
              </li>
              <li className="nav-item position-relative" role="presentation">
                <a
                  id="profile-tab"
                  className={`nav-link custom-nav-link border-0 py-2 font-16 font-semibold cursor-pointer ${
                    showHistory ? "active" : ""
                  }`}
                  onClick={() => handleTabChange("history")}
                >
                  History
                </a>
              </li>
            </ul>

            <div className="tab-content" id="myTabContent">
              {showDetails ? (
                <div
                  style={{ display: "block" }}
                  className="tab-pane fade show active"
                  id="home-tab-pane"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                  tabIndex={0}
                >
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12  res-border">
                      <div className="row">
                        {/* <h3 class="font-18 font-semibold tertiary-color mb-4 ">Details</h3> */}
                        <div className="col-md-4 col-sm-12 p-0">
                          <div className="mb-5 px-4">
                            <label className="form-label color-black-v2 font-14 font-bold">
                              Customer Name
                            </label>
                            <p className="font-13 font-regular color-black-v3">
                              {customerName ? customerName : "NA"}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-12 p-0">
                          <div className="mb-5 px-4">
                            <label className="form-label color-black-v2 font-14 font-bold">
                              License
                            </label>
                            <p className="font-13 font-regular color-black-v3">
                              {bindData[0]?.friendlyName}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-12 p-0">
                          <div className="mb-5 px-4">
                            <label className="form-label color-black-v2 font-14 font-bold">
                              Provisioned On
                            </label>
                            <p className="font-13 font-regular color-black-v3">
                              {moment(bindData[0]?.creationDate).format(
                                "MM/DD/YYYY"
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-12 p-0">
                          <div className="mb-5 px-4">
                            <label className="form-label color-black-v2 font-14 font-bold">
                              Renews On
                            </label>
                            <p className="font-13 font-regular color-black-v3">
                              {moment(bindData[0]?.commitmentEndDate)
                                .add(1, "days")
                                .format("MM/DD/YYYY")}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-12 p-0">
                          <div className="mb-5 px-4">
                            <label className="form-label color-black-v2 font-14 font-bold">
                              Status
                            </label>
                            <p className="font-13 font-regular color-black-v3">
                              {bindData[0]?.status ? bindData[0]?.status.substr(0, 1).toUpperCase() + bindData[0]?.status.substr(1):"NA"}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-4 p-0">
                          <div className="mb-5 px-4">
                            <label className="form-label color-black-v2 font-14 font-bold">
                              Margin
                            </label>
                            <p className="font-13 font-regular color-black-v3">
                              {customerData[0]?.margin
                                ? customerData[0]?.margin + "%"
                                : "NA"}
                            </p>
                          </div>
                        </div>
                        {!viewform ? (
                          <>
                            {" "}
                            <div className="col-md-4  col-sm-12 p-0">
                              <div className="mb-3 px-4">
                                <label className="font-14 font-semibold primary-text-color mb-2">
                                  Term Duration
                                </label>
                                <select
                                  className="form-select custom-select custom-input font-13 font-regular secondary-text-color"
                                  aria-label="select "
                                  value={selectedTerm}
                                  onChange={(e) => {
                                    setErrorMessage("");
                                    handleDurationChange(e);
                                    // if (selectedTerm != e.target.value) {
                                    //   setSubmitEnable(false);
                                    // } else {
                                    //   setSubmitEnable(true);
                                    // }
                                  }}
                                >
                                  {terms?.map((val: any) => {
                                    return (
                                      <>
                                        <option>{val?.duration}</option>
                                      </>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                            <div className="col-md-4  col-sm-12 p-0">
                              <div className="mb-3 px-4">
                                <label className="font-14 font-semibold primary-text-color mb-2">
                                  Billing Cycle
                                </label>
                                <select
                                  className="form-select custom-select custom-input font-13 font-regular secondary-text-color"
                                  aria-label="select "
                                  value={selectedBillingCycle}
                                  disabled={!selectedBillingCycleOptions}
                                  onChange={(e) => {
                                    setErrorMessage("");
                                    setSelectedBillingCycle(e.target.value);
                                    setReRender(!reRender);
                                  }}
                                >
                                  {selectedBillingCycleOptions &&
                                    selectedBillingCycleOptions.billingCycle.map(
                                      (cycle: any) => (
                                        <option key={cycle} value={cycle}>
                                          {cycle}
                                        </option>
                                      )
                                    )}
                                </select>
                                {/* <span className="color-red mt-1 font-14">
                              Enter Billing cycle
                            </span> */}
                              </div>
                            </div>
                            <div className="col-md-4 p-0">
                              <div className="px-4">
                                <label className="form-label color-black-v2 font-14 font-bold">
                                  Auto Renewal
                                  {/* <span className="color-red">*</span> */}
                                </label>
                                <div className="form-check form-switch pt-2 theme-switch">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckChecked"
                                    checked={autoRenewal}
                                    onChange={(e) => {
                                      setErrorMessage("");
                                      setAutoRenewal(e.target.checked);
                                      setReRender(!reRender);
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexSwitchCheckChecked"
                                  >
                                    {autoRenewal == true ? "Yes" : "No"}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            {" "}
                            <div className="col-md-4  col-sm-12 p-0">
                              <div className="mb-5 px-4">
                                <label className="form-label color-black-v2 font-14 font-bold">
                                  Term Duration
                                </label>
                                <p className="font-13 font-regular color-black-v3">
                                  {bindData[0]?.termDuration}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-12 p-0">
                              <div className="mb-5 px-4">
                                <label className="form-label color-black-v2 font-14 font-bold">
                                  Billing Cycle
                                </label>
                                <p className="font-13 font-regular color-black-v3">
                                  {bindData[0]?.billingCycle}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4  col-sm-12 p-0">
                              <div className="mb-5 px-4">
                                <label className="form-label color-black-v2 font-14 font-bold">
                                  Auto Renewal
                                </label>
                                <p className="font-13 font-regular color-black-v3">
                                  {bindData[0]?.autoRenewEnabled == true
                                    ? "Yes"
                                    : "No"}
                                </p>
                              </div>
                            </div>{" "}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ps-4">
                      <h3 className="font-18 font-semibold tertiary-color my-3">
                        Summary
                      </h3>
                      <div className="table-responsive mb-4 rounded-3  m-1">
                        <table className="table themeTable  mb-0  ">
                          <thead className="font-bold">
                            <tr>
                              <th className="text-start">License</th>
                              <th className="text-start">Term Duration</th>
                              <th className="text-start">Billing Cycle</th>
                              <th className="text-start">Quantity</th>
                              <th className="text-end">Microsoft Price</th>
                              <th className="text-end">Reseller Price</th>
                              {!viewform ? (
                                <th className="text-center">Actions</th>
                              ) : null}
                            </tr>
                          </thead>
                          <tbody className="font-medium">
                            <tr className="border-bottom-0 ">
                              <td className="text-start font-regular">
                                {bindData[0]?.friendlyName.length > 25 ? (
                                  <Tooltip
                                    title={bindData[0]?.friendlyName}
                                    placement="top-end"
                                  >
                                    <Button className="text-fixer">
                                      {bindData[0]?.friendlyName?.substring(
                                        0,
                                        25
                                      ) + "..."}
                                    </Button>
                                  </Tooltip>
                                ) : (
                                  bindData[0]?.friendlyName
                                )}
                              </td>
                              <td className="text-start font-regular">
                                {bindData[0]?.termDuration}
                              </td>
                              <td className="text-start font-regular">
                                {bindData[0]?.billingCycle}
                              </td>
                              <td
                                className="text-start font-regular"
                                style={{ width: "120px", height: "68px" }}
                              >
                                {showQuantity ? (
                                  <input
                                    style={{ height: "26px" }}
                                    type="text"
                                    className="form-control custom-input w-100"
                                    value={Quantity}
                                    placeholder=""
                                    onChange={(e) => {
                                      setErrorMessage("");
                                      quantityOnChange(e);
                                    }}
                                  />
                                ) : (
                                  <>{bindData[0]?.quantity}</>
                                )}
                                <span className="color-red">
                                  {quantityErrormsg}
                                </span>
                              </td>
                              <td className="text-end font-regular">
                                $
                                {customerData[0]?.microsoft_price
                                  ? formatAmount(
                                      Number(customerData[0]?.microsoft_price)
                                    )
                                  : 0}
                              </td>
                              <td className="text-end font-regular">
                                $
                                {customerData[0]?.reseller_price
                                  ? formatAmount(
                                      Number(customerData[0]?.reseller_price)
                                    )
                                  : 0}
                              </td>
                              {!viewform ? (
                                <td
                                  className="text-center font-regular"
                                  style={{ width: "133px" }}
                                >
                                  {/* <span className="cursor-pointer">
                                  <img
                                    src="Images/right-icon.svg"
                                    alt="RigthIcon"
                                    title="Save"
                                    className="me-2"
                                  />
                                </span>
                                <span className="cursor-pointer ">
                                  <img
                                    src="Images/Wrong-icon.svg"
                                    alt="WrongIcon"
                                    title="Delete"
                                    className="me-2"
                                  />
                                </span> */}
                                  <span className="cursor-pointer">
                                    {!showQuantity ? (
                                      <img
                                        src="Images/edit-icon.svg"
                                        alt="EditIcon"
                                        title="Edit"
                                        onClick={() => {
                                          setShowQuantity(true);
                                          setQuantity(bindData[0].quantity);
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src="Images/Wrong-icon.svg"
                                        alt="WrongIcon"
                                        title="Delete"
                                        className="me-2"
                                        onClick={() => {
                                          setQuantity("");
                                          setShowQuantity(false);
                                          setquantityErrormsg("");
                                        }}
                                      />
                                    )}
                                    {showQuantity ? (
                                      <img
                                        src="Images/right-icon.svg"
                                        alt="RightIcon"
                                        title="Save"
                                        className="me-2"
                                        onClick={() => {
                                          setReRender(!reRender);
                                          if (
                                            Number(Quantity) < minimumQuantity
                                          ) {
                                            setquantityErrormsg(
                                              "Quantity should be greater than 0"
                                            );
                                          } else if (
                                            Number(Quantity) > maximumQuantity
                                          ) {
                                            setquantityErrormsg(
                                              "Quantity should be less than entered quantity"
                                            );
                                          } else {
                                            setquantityErrormsg("");
                                            setShowQuantity(false);
                                          }
                                          if (
                                            Number(Quantity) > 0 &&
                                            Number(Quantity) < maximumQuantity
                                          ) {
                                            setbindData((prev: any) => {
                                              const updatedObject = {
                                                ...prev[0],
                                                quantity: Quantity,
                                              };
                                              const updatedArray = [
                                                updatedObject,
                                              ];
                                              return updatedArray;
                                            });
                                          }
                                        }}
                                      />
                                    ) : null}
                                  </span>
                                </td>
                              ) : null}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      {!viewform ? (
                        <>
                          {" "}
                          <div className="d-flex justify-content-end mb-2 mt-5">
                            <button
                              type="button"
                              className="btn  text-btn text border-0 font-14 font-semibold me-3 shadow-none"
                              onClick={() => {
                                navigate("/subscription");
                              }}
                            >
                              Cancel
                            </button>

                            <button
                              type="button"
                              className="btn primary-btn border-0 font-14 font-semibold "
                              onClick={() => {
                                updateSubscription();
                              }}
                              disabled={!submitEnable}
                            >
                              Submit
                            </button>
                          </div>
                          <span className="color-red font-14">
                            {errorMessage}
                          </span>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : null}
              {showHistory && (
                <HistoryComponent subscriptionId={subscriptionId} />
              )}
            </div>
          </div>
        </div>
        {loader ? (
          <div className="overlay">
            <div className="position-absolute top-50 start-50 translate-middle">
              <div
                className="spinner-border Loader text-light align-center "
                role="status"
              >
                <span className="visually-hidden" />
              </div>
              <p className="loading-text font-14 color-white mt-2 font-semibold">
                Loading..
              </p>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}
