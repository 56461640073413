import React, { useEffect, useState } from "react";
import {
  GetCustomerNotificationDetailsById,
  UpdateCustomerNotificationDetails,
} from "../../services/customerService";
import { useLocation, useNavigate } from "react-router-dom";
import { OnlyEmail, sessionKeys, encryptStorage } from "../../configs/constant";
import { notificationData, userSessionData } from "../../interface";
import { ErrorLog } from "../../services/errorService";
import { Loader } from "../loader";
//** PC_NO_1, PC_NO_2, PC_NO_3, PC_NO_4, PC_NO_5, Fetch the Customer Notification Details */
function CustomerNotificationDetails() {
  let location: any = useLocation();

  let notificaionData = {
    customer_id: "",
    email_address_1: "",
    email_address_2: "",
    email_address_3: "",
  };
  let ErrorMessage = {
    email_address_1: "",
    email_address_2: "",
    email_address_3: "",
  };
  const [notificationDetails, setNotificationDetails] =
    useState<notificationData>(notificaionData);
  const [errorMessages, setErrorMessages] = useState<any>(ErrorMessage);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [disableSave, setDisableSave] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    getCustomerNotificationById();
  }, []);
//PC_NO_34, PC_NO_35, PC_NO_36, PC_NO_37, PC_NO_38, PC_NO_39, PC_NO_40 Fetch the Notification Email details of the Customer
  async function getCustomerNotificationById() {
    try {
      const customerRequest = {
        customer_id: location.state.customer_id,
      };
      const response = await GetCustomerNotificationDetailsById(
        customerRequest
      );

      if (response.statusCode === 200) {
        setNotificationDetails(response.responseData[0]);
      }
    } catch (Error: any) {
      console.error("Error fetching data:", Error);
      const errorObject = {
        stack_name: "frontend_react",
        module_name: "customer_module",
        function_name: "getCustomerNotificationById",
        error_message: `${Error?.message}`,
      };
      if (Error) {
        const request: any = await ErrorLog(errorObject);
      }
    }
  }
//** PC_NO_130.6, PC_NO_130.7, PC_NO_130.8, PC_NO_130.9, PC_NO_130.10, PC_NO_130.11, PC_NO_130.12, PC_NO_130.13, PC_NO_130.14, PC_NO_130.15
// to update the notification email details of the Customer  */
  async function updateNotificationDetailsById() {
    if (validate()) {
      setShowLoader(true);
      let sessionUserDetails: userSessionData | null = JSON.parse(
        encryptStorage.getItem(sessionKeys.userDetails) || "null"
      );

      const customerRequest = {
        customer_id: location.state.customer_id,
        email_address_1: notificationDetails?.email_address_1,
        email_address_2: notificationDetails?.email_address_2,
        email_address_3: notificationDetails?.email_address_3,
        user_id: sessionUserDetails?.user_id,
      };
      const response = await UpdateCustomerNotificationDetails(customerRequest);
      if (response.statusCode == 200) {
        getCustomerNotificationById();
        setShowLoader(false);
        setDisableSave(true);
      }
    }
  }
//** PC_NO_130.16, PC_NO_130.17, PC_NO_130.18, PC_NO_130.19, PC_NO_130.20
// to perform the validation */
  const validate = () => {
    if (
      notificationDetails?.email_address_1?.trim() == "" ||
      notificationDetails.email_address_1 == null ||
      notificationDetails.email_address_1 == undefined
    ) {
      ErrorMessage.email_address_1 = "Enter email address 1";
      setErrorMessages({ ...ErrorMessage });
    }

    if (
      Object.values(ErrorMessage).every(
        (eachValue: any) => eachValue?.trim() == ""
      )
    ) {
      return true;
    } else {
      return false;
    }
  };
//**PC_NO_130.21, PC_NO_130.22, PC_NO_130.23, PC_NO_130.24, PC_NO_130.25, PC_NO_130.26
// to perform the validation During the onChange */
  const handleInputChange = (e: any) => {
    setDisableSave(false);
    setErrorMessages({ ...errorMessages, [e.target.name]: "" });
    setNotificationDetails({
      ...notificationDetails,
      [e.target.name]: e.target.value,
    });

    if (OnlyEmail(e?.target?.value)) {
      setErrorMessages({
        ...errorMessages,
        [e.target.name]: "Enter valid email address",
      });
    }
  };

  return (
    <>
      <div
        className="tab-pane fade"
        id="notifications"
        role="tabpanel"
        aria-labelledby="notificaions-tab"
      >
        {showLoader ? <Loader /> : <></>}

        {location.state.mode == "view" ? (
          <>
            <p className="font-18 font-semibold tertiary-color mb-lg-4 py-lg-3 mb-2 px-3">
              Notifications
            </p>
            <div className="row custom-row-height1">
              <div className="col-md-4">
                <div className="mb-5 px-3">
                  <label
                    htmlFor="email1"
                    className="form-label color-black-v2 font-14 font-bold"
                  >
                    Email Address 1 <span className="color-red"></span>
                  </label>
                  <br></br>
                  <label>
                    {notificationDetails?.email_address_1
                      ? notificationDetails?.email_address_1
                      : "-"}
                  </label>
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-5 px-3">
                  <label
                    htmlFor="email2"
                    className="form-label color-black-v2 font-14 font-bold"
                  >
                    Email Address 2
                  </label>

                  <br></br>
                  <label>
                    {notificationDetails?.email_address_2
                      ? notificationDetails?.email_address_2
                      : "-"}
                  </label>
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-5  px-3">
                  <label
                    htmlFor="email3"
                    className="form-label color-black-v2 font-14 font-bold"
                  >
                    Email Address 3
                  </label>

                  <br></br>
                  <label>
                    {notificationDetails?.email_address_3
                      ? notificationDetails?.email_address_3
                      : "-"}
                  </label>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <p className="font-18 font-semibold tertiary-color mb-lg-4 py-lg-3 mb-2 px-3">
              Notifications
            </p>
            <div className="row custom-row-height1">
              <div className="col-md-4">
                <div className="mb-5 px-3">
                  <label
                    htmlFor="email1"
                    className="form-label color-black-v2 font-14 font-bold"
                  >
                    Email Address 1 <span className="color-red">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control custom-input"
                    id="email1"
                    placeholder="Enter Email Address"
                    value={notificationDetails?.email_address_1}
                    name="email_address_1"
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                  {errorMessages.email_address_1 ? (
                    <span className="color-red font-14">
                      {errorMessages.email_address_1}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-5 px-3">
                  <label
                    htmlFor="email2"
                    className="form-label color-black-v2 font-14 font-bold"
                  >
                    Email Address 2
                  </label>
                  <input
                    type="emai2"
                    className="form-control custom-input"
                    id="email2"
                    placeholder="Enter Email Address"
                    name="email_address_2"
                    value={notificationDetails?.email_address_2}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                  {errorMessages.email_address_2 ? (
                    <span className="color-red font-14">
                      {errorMessages.email_address_2}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-5  px-3">
                  <label
                    htmlFor="email3"
                    className="form-label color-black-v2 font-14 font-bold"
                  >
                    Email Address 3
                  </label>
                  <input
                    type="email"
                    className="form-control custom-input"
                    id="email3"
                    name="email_address_3"
                    placeholder="Enter Email Address"
                    value={notificationDetails?.email_address_3}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                  {errorMessages.email_address_3 ? (
                    <span className="color-red font-14">
                      {errorMessages.email_address_3}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end mb-5">
              <button
                type="button"
                className="btn text border-0 font-14 font-semibold me-3 shadow-none text-btn"
                onClick={() => navigate("/customer")}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn primary-btn border-0 font-14 font-semibold "
                onClick={() => updateNotificationDetailsById()}
                disabled={disableSave}
              >
                Save
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default CustomerNotificationDetails;
