function Error() {
    return (
        <>
            {/* success popup starts here */}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <div className="h-100vh d-flex align-items-center justify-content-center">
                            <div className="  text-center">
                                <div className="custom-gif">
                                    <img src="Images/404-4.svg" alt="number-4" />
                                    <img src="Images/Gif.gif" alt="404-image" className="error-img" />
                                    <img src="Images/404-4.svg" alt="number-4" />
                                </div>
                                <p className="font-40 font-semibold tertiary-color mt-3">
                                    Oops! Page Not Found
                                </p>
                                <p className="font-16 font-medium primary-text-color">
                                    We can’t find the page you are looking for, you can try again
                                    later.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Error