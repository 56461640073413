import React from "react"
import axios from "axios"
import { subscriptionUrl,backendurl as url } from "../configs/constant"
import { client } from "../client"
//** ps_ss_1, ps_ss_2, ps_ss_3, ps_ss_4, ps_ss_5, ps_ss_6
// get the subcriptions details of the customer  */
export const getsubscriptionsApi = async (data: any = {}) => {

    let config = {
        method: 'POST',
        url: url + subscriptionUrl.getData,
        headers: {
            'Content-Type': 'application/json'
        },
        body: data
    }

    let response = await client(config)
    return response
}
//** ps_ss_11, ps_ss_12, ps_ss_13, ps_ss_14
// to edit the subcriptions */
export const editSubscriptionApi = async (data: any = {}) => {

    let config = {
        method: 'POST',
        url: url + subscriptionUrl.editsubscription,
        headers: {
            'Content-Type': 'application/json'
        },
        body: data
    }

    let response = await client(config)
    return response
}
//** ps_ss_15, ps_ss_16, ps_ss_17, ps_ss_18
// to get drop downl details  */
export const getAllDrodpownApi = async (data: any = {}) => {

    let config = {
        method: 'GET',
        url: url + subscriptionUrl.getdropdown,
        headers: {
            'Content-Type': 'application/json'
        },
        body: data
    }
    let response = await client(config)
    return response


}
//** ps_ss_7, ps_ss_8, ps_ss_9, ps_ss_10
// get the subcriptions deetails */
export const getsubscriptionHistoryApi = async (data: any = {}) => {

    let config = {
        method: 'POST',
        url: url + subscriptionUrl.history,
        headers: {
            'Content-Type': 'application/json'
        },
        body: data
    }
    let response = await client(config)
    return response


}
//** ps_ss_19, ps_ss_20, ps_ss_21, ps_ss_22
// to get the history dropdown details */
export const historyDropdownApi = async (data: any = {}) => {

    let config = {
        method: 'GET',
        url: url + subscriptionUrl.historyDropdown,
        headers: {
            'Content-Type': 'application/json'
        },
        body: data
    }
    let response = await client(config)
    return response


}
//** ps_ss_27, ps_ss_28, ps_ss_29, ps_ss_30
// to update the subcriptions */
export const updateSubscriptionApi = async (data: any = {}) => {

    let config = {
        method: 'Post',
        url: url + subscriptionUrl.updateSubscription,
        headers: {
            'Content-Type': 'application/json'
        },
        body: data
    }
    let response = await client(config)
    return response


}
//** ps_ss_31, ps_ss_32, ps_ss_33, ps_ss_34
// to cancel subcriptions */
export const CancelSubscriptionApi = async (data: any = {}) => {

    let config = {
        method: 'post',
        url: url + subscriptionUrl.cancelSubscription,
        headers: {
            'Content-Type': 'application/json'
        },
        body: data
    }

    let response = await client(config)
    return response
}


