import React, { useEffect, useState } from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";

export default function RpaToken() {
//** PC_LL_12 staet and obeject variables */
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const accounts = instance.getAllAccounts();
    const navigate = useNavigate();

const [token, setToken] = useState("");
const [regenerateToken, setRegenerateToken] = useState(false);


    useEffect(()=>{
        AcquireToken();
    },[regenerateToken])
//** PC_LL_23, PC_LL_24, PC_LL_25, PC_LL_26, PC_LL_27, PC_LL_28, PC_LL_29, PC_LL_30
//to Acquire token  */
    const AcquireToken = async () => {

        if (accounts.length > 0) {
          const silentRequest: any = {
            account: accounts[0],
            scopes:["https://api.partner.microsoft.com/.default"],
          };
    
          let token: any = null;
          await instance
            .acquireTokenSilent(silentRequest)
            .then((response) => {

              const account = response;

              token = account?.accessToken;
              setToken(token);

            })
            .catch((error) => {
                setToken("Error acquirimg token");
              console.log(
                "Error acquiring token ",
                error
              );
            });
        };
    }

    return(
        <>

        <p>{token}</p>
        <button
        onClick={()=>{setRegenerateToken(!regenerateToken)}}
        >
            Regenerate
        </button>
        </>
    )

}