import React, { useEffect, useState } from "react";
import {
  GetCustomerBasicDetailsByid,
  UpdateBasicDetailsByid,
} from "../../services/customerService";
import { CustomerBasicData, userSessionData } from "../../interface";
import { useLocation, useNavigate } from "react-router-dom";
import { ErrorLog } from "../../services/errorService";
import { OnlyNumber, sessionKeys, encryptStorage, OnlyIntegersInclidesDot } from "../../configs/constant";
import { Loader } from "../loader";
//** PC_NO_1, PC_NO_2, PC_NO_3, PC_NO_4, PC_NO_5, TO fetch the Customer Basic details */
const CustomerBasicDetails: React.FC<any> = () => {
  let location: any = useLocation();
  const navigate = useNavigate();

  let basicDetails: any = {
    customer_id: "",
    microsoft_id: "",
    company_name: "",
    is_active: "",
    margin: "",
    address: "",
    city: "",
    state: "",
    zip_code: "",
    country: "",
    email: "",
    domain_name: "",
  };

  let errorMessages = {
    margin: "Enter margin",
  };

  let error = {
    margin: "",
  };

  const [CustomerBasicDetails, setCustomerBaiscDetails] =
    useState<CustomerBasicData>(basicDetails);
  const [errorStatus, setErrorStatus] = useState<boolean>(false);
  const [showError, setShowError] = useState<any>(error);
  const [showLoader, setShowLoader] = useState<any>(false);
  const [disableSave, setDisableSave] = useState(true);

  useEffect(() => {
    GetCustomerBasicDetailsById();
  }, []);
//** PC_NO_6, PC_NO_7, PC_NO_8, PC_NO_9, PC_NO_10, PC_NO_11, PC_NO_12, */

  async function GetCustomerBasicDetailsById() {
    try {
      const customerRequest = {
        customer_id: location.state.customer_id,
      };

      setShowLoader(true);
      const response = await GetCustomerBasicDetailsByid(customerRequest);
      const data = response.data.responseData;
      if (response.data.statusCode === 200) {
        let resp = data[0];
        resp.is_active = resp?.is_active ? "1" : "0";
        //resp.margin = resp?.margin ? resp.margin : "0";
        setCustomerBaiscDetails(resp);
        setShowLoader(false);
      }
      // setActive(CustomerBasicDetails?.is_active === "1"?true:false);
    } catch (Error: any) {
      console.error("Error fetching data:", Error);
      const errorObject = {
        stack_name: "frontend_react",
        module_name: "customer_module",
        function_name: "GetCustomerBasicDetailsByid",
        error_message: `${Error?.message}`,
      };
      if (Error) {
        const request: any = await ErrorLog(errorObject);
        return request.data.statusCode === 200
          ? setErrorStatus(errorStatus)
          : setErrorStatus(!errorStatus);
      }
    }
  }
//** PC_NO_48, PC_NO_49, PC_NO_50, PC_NO_51, PC_NO_52, PC_NO_53, PC_NO_54, */

  function validate() {
    if (CustomerBasicDetails?.margin?.trim() == "" || CustomerBasicDetails?.margin?.trim() == null || CustomerBasicDetails?.margin?.trim() == "null" || CustomerBasicDetails?.margin?.trim() == undefined || CustomerBasicDetails?.margin?.trim() == "undefined") {
      showError.margin = errorMessages.margin;
      setShowError({ ...showError });
    }

    if (
      Object.values(showError).every(
        (eachValue: any) => eachValue?.trim() == ""
      )
    ) {
      return true;
    } else {
      return false;
    }
  }
//** PC_NO_48, PC_NO_49, PC_NO_50, PC_NO_51, PC_NO_52, PC_NO_53, PC_NO_54
// to update the basic details */
  async function UpdateCustomerBasicDetailsById() {
    try {
      if (validate()) {
        let sessionUserDetails: userSessionData | null = JSON.parse(
          encryptStorage.getItem(sessionKeys.userDetails) || "null"
        );
        const customerRequest = {
          customer_id: location.state.customer_id,
          margin: CustomerBasicDetails.margin,
          is_active: CustomerBasicDetails.is_active,
          user_id: sessionUserDetails?.user_id,
        };
        setShowLoader(true);
        const response = await UpdateBasicDetailsByid(customerRequest);
        const data = response.responseData;
        if (response.statusCode === 200) {
          setShowLoader(false);
          GetCustomerBasicDetailsById();
          setDisableSave(true);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  return (
    <>
      {/* <CustomerDetails /> */}
      <div
        className="tab-pane fade show active mb-5"
        id="basic-detail"
        role="tabpanel"
        aria-labelledby="basicdetail-tab"
      >
        <p className="font-18 font-semibold tertiary-color mb-lg-4 py-lg-3 mb-2 px-3">
          Basic Details
        </p>
        <div className="row custom-row-height">
          <div className="col-md-4">
            <div className="mb-5 px-3">
              <label className="form-label color-black-v2 font-14 font-bold">
                Customer Name
              </label>
              <p className="font-13 font-regular color-black-v3">
                {CustomerBasicDetails?.company_name
                  ? CustomerBasicDetails?.company_name
                  : "-"}
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-5 px-3">
              <label className="form-label color-black-v2 font-14 font-bold">
                Address
              </label>
              <p className="font-13 font-regular color-black-v3">
                {CustomerBasicDetails?.address
                  ? CustomerBasicDetails?.address
                  : "-"}
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-5 px-3">
              <label className="form-label color-black-v2 font-14 font-bold">
                City
              </label>
              <p className="font-13 font-regular color-black-v3">
                {CustomerBasicDetails?.city ? CustomerBasicDetails?.city : "-"}
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-5 px-3">
              <label className="form-label color-black-v2 font-14 font-bold">
                State
              </label>
              <p className="font-13 font-regular color-black-v3">
                {CustomerBasicDetails?.state
                  ? CustomerBasicDetails?.state
                  : "-"}
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-5 px-3">
              <label className="form-label color-black-v2 font-14 font-bold">
                Zip/Postal code
              </label>
              <p className="font-13 font-regular color-black-v3">
                {CustomerBasicDetails?.zip_code
                  ? CustomerBasicDetails?.zip_code
                  : "-"}
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-5 px-3">
              <label className="form-label color-black-v2 font-14 font-bold">
                Country/Region
              </label>
              <p className="font-13 font-regular color-black-v3">
                {CustomerBasicDetails?.country
                  ? CustomerBasicDetails?.country
                  : "-"}
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-5 px-3">
              <label className="form-label color-black-v2 font-14 font-bold">
                Email
              </label>
              <p className="font-13 font-regular color-black-v3">
                {CustomerBasicDetails?.email
                  ? CustomerBasicDetails?.email
                  : "-"}
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-5 px-3">
              <label className="form-label color-black-v2 font-14 font-bold">
                Microsoft ID
              </label>
              <p className="font-13 font-regular color-black-v3">
                {CustomerBasicDetails?.microsoft_id
                  ? CustomerBasicDetails?.microsoft_id
                  : "-"}
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-5 px-3">
              <label className="form-label color-black-v2 font-14 font-bold">
                Domain Name
              </label>
              <p className="font-13 font-regular color-black-v3">
                {CustomerBasicDetails?.domain_name
                  ? CustomerBasicDetails?.domain_name
                  : "-"}
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-5 px-3">
              <label
                htmlFor="basic-margin"
                className="form-label color-black-v2 font-14 font-bold"
              >
                Margin <span className="color-red">{location.state.mode == "view"?'':'*'}</span>
              </label>
              {location.state.mode == "view" ? (
                <>
                  <p className="font-13 font-regular color-black-v3">
                    {CustomerBasicDetails?.margin
                      ? CustomerBasicDetails.margin
                      : "-"}
                  </p>
                </>
              ) : (
                <>
                  <input
                    type="text"
                    className="form-control custom-input"
                    id="basic-margin"
                    placeholder="Enter margin"
                    value={CustomerBasicDetails.margin}
                    name="margin"
                    maxLength={5}
                    onChange={(e: any) => {

                      if (!OnlyIntegersInclidesDot(e.target.value)) {
  

                        const invalidMarginCOmbination = ["0.0000", "00.000", "000.00", "0000.0", "00000", "0.0", "0.00","0.000", ".00000", "000000", "00000.", "0.000", "00.00", "000.0", "00", "000", "0000", "000000", "00000.", "000.0", "0.", "00.", "00.0", "000.", "0000."];


                          const dots = e.target.value.match(/\./g);
                          let dotCount = dots ? dots.length : 0;
                          showError.margin = '';
                          setShowError({ ...showError });
                          if(dotCount > 1 || invalidMarginCOmbination.includes(e.target.value) ){
                            showError.margin = 'Enter Valid Margin';
                            setShowError({ ...showError });
                          }
                          if(e.target.value >100){
                            showError.margin = 'Enter Valid Margin';
                            setShowError({ ...showError });
                          }

                            setDisableSave(false);
                           
                            setCustomerBaiscDetails((prevDetails) => ({
                              ...prevDetails,
                              [e.target.name]: e.target.value,
                            }));


                      }
                    }}
                  />
                  {showError.margin ? (
                    <span className="color-red font-14">
                      {showError.margin}
                    </span>
                  ) : null}
                </>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-5 px-3">
              <label
                htmlFor="basic-cus-status"
                className="form-label color-black-v2 font-14 font-bold"
              >
                Customer’s Current Status
              </label>
              <div className="form-check form-switch pt-2 theme-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="basic-cus-status"
                  name="is_active"
                  value={CustomerBasicDetails.is_active}
                  disabled={location?.state?.mode == "view"}
                  checked={CustomerBasicDetails.is_active == "1" ? true : false}
                  onChange={(e: any) => {
                    setDisableSave(false);
                    const newValue = e.target.checked ? "1" : "0";
                    setCustomerBaiscDetails((prevDetails) => ({
                      ...prevDetails,
                      [e.target.name]: newValue,
                    }));
                  }}
                />
                <label className="form-check-label" htmlFor="basic-cus-status">
                  {" "}
                  {CustomerBasicDetails.is_active === "1"
                    ? "Active"
                    : "InActive"}{" "}
                </label>
              </div>
            </div>
          </div>
        </div>
        {location.state.mode != "view" ? (
          <div className="d-flex justify-content-end mb-5">
            <button
              type="button"
              className="btn text border-0 font-14 font-semibold me-3 shadow-none text-btn"
              onClick={() => navigate("/customer")}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn primary-btn border-0 font-14 font-semibold "
              onClick={UpdateCustomerBasicDetailsById}
              disabled={disableSave}
            >
              Save
            </button>
          </div>
        ) : null}
        {showLoader ? <Loader /> : null}
      </div>
    </>
  );
};

export default CustomerBasicDetails;
