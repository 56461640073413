import React from "react";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BreadCrumbs from "../subscriptions/breadcrumbs";
import {
  getQuotesLicenseDetailsById,
  getFromDetailsApi,
  updateQuotesLicenseDetailsById,
} from "../../services/quotesService";
import {
  OnlyEmail,
  validatePhoneNumber,
  validateAddress,
  OnlyNumber,
  OnlyNumberSpecialChar,
  allowOnlyNumberAndCharacter,
  NotOnlySpecialChar,
  onlytextandnumber,
  OnlyText,
} from "../../configs/constant";
import { Button, Tooltip } from "@mui/material";

//**PS_GF_1 ,PS_GF_2 */
// to render the quotes form to perform the edit operation*/
export default function EditQuotes() {
  //**PS_GF_3 state object and normal variable declaration */
  let alphabetsregex = /^[a-zA-Z\s]*$/;
  let location = useLocation();
  let navigate = useNavigate();
  let obj = {
    quoteNumber: "",
    name: "",
    from_data: "",
    customerName: "",
    emailAddress: "",
    phoneNumber: "",
    AdressLine: "",
    state: "",
    country: "",
    city: "",
    zip: "",
    customer_id: "",
  };

  let objError = {
    quoteNumberError: "",
    from_dataError: "",
    nameError: "",
    customerNameError: "",
    emailAddressError: "",
    phoneNumberError: "",
    AdressLineError: "",
    stateError: "",
    countryError: "",
    cityError: "",
    zipError: "",
  };
  const [editvalues, seteditvalues] = useState(obj);
  const [getlicenseList, setgetlicenseList] = useState<any>([]);
  const [microsoftPrice, setmicrosoftPrice] = useState<any>();
  const [resellerPrice, setresellerPrice] = useState<any>();
  const [fromDropdown, setfromDropdown] = useState([]);
  const [partnerPrice, setPartnerPrice] = useState<any>();

  const [editvaluesError, seteditvaluesError] = useState(objError);

  const [quantity, setquantity] = useState<any>({});
  const [margin, setmargin] = useState<any>({});
  const [termDuration, settermDuration] = useState<any>({});
  const [licenseId, setlicenseId] = useState<any>([]);
  const [loader, setloader] = useState<boolean>(false);
  const [quantityinlineError, setQuantityInlineError] = useState<any>({});
  const [termdurationinlineError, settermdurationinlineError] = useState<any>(
    {}
  );
  const [margininlineError, setmargininlineError] = useState<any>({});

  let breadcrumb = [
    { title: "Quotes", isActive: true, path: "/quote" },
    { title: location?.state?.quote_id, isActive: false, path: null },
  ];
  //** PS_GF_4 ,PS_GF_5 useEffect() to call the functionality during the rendering */
  useEffect(() => {
    getFromDetails();
    getEditOrder(location?.state?.quote_id);
  }, []);

  useEffect(() => {
    const totalMicrosoftPrice: number = getlicenseList.reduce(
      (total: number, item: any) => total + item.micro_price * item.quantity,
      0
    );

    setmicrosoftPrice(totalMicrosoftPrice);
    // const totalResellerPrice: number = getlicenseList.reduce(
    //   (total: number, item: any) =>
    //     total +
    //     (item.is_tax_ctrfct_present
    //       ? (item.partner_price)
    //       : (item.partner_price * 1.06) * item.quantity +
    //           item.is_tax_ctrfct_present
    //       ? (item.partner_price)
    //       : (item.partner_price * 1.06) * item.quantity * (item.margin / 100)),
    //   0
    // );
    // const totalResellerPrice = getlicenseList.reduce(
    //   (total:any, item:any) =>
    //     total +
    //     (item.is_tax_ctrfct_present
    //       ? item.partner_price
    //       : (item.partner_price * 1.06) * item.quantity) + 
    //     (item.is_tax_ctrfct_present
    //       ? item.partner_price
    //       : (item.partner_price * 1.06) * item.quantity * (item.margin / 100)),
    //   0
    // );
    const totalResellerPrice = getlicenseList.reduce(
      (total: any, item: any) =>
        total +
        ((item.partner_price * item.quantity) + (item.partner_price * (item.margin / 100))),
      0
    );
    setresellerPrice(totalResellerPrice);

    const partnerprice = getlicenseList.reduce(
      (total: any, item: any) => total + item.partner_price * item.quantity,
      0
    );

    setPartnerPrice(partnerprice);

    let quantity: any = {};
    let margin: any = {};
    let termDuration: any = {};

    let marginInlineError: any = {};
    let quantityInlineError: any = {};
    let termInlineError: any = {};

    getlicenseList?.forEach((data: any, index: any) => {
      quantity[index] = data.quantity;
      margin[index] = data.margin;
      termDuration[index] = data.term_duration;

      marginInlineError[index] = "";
      quantityInlineError[index] = "";
      termInlineError[index] = "";
    });
    setquantity(quantity);
    setmargin(margin);
    settermDuration(termDuration);
    setmargininlineError(marginInlineError);
    setQuantityInlineError(quantityInlineError);
    settermdurationinlineError(termInlineError);
  }, [getlicenseList]);
  //** PS_GF_28, PS_GF_29, PS_GF_30, PS_GF_31, PS_GF_32, PS_GF_33, PS_GF_34
  // to get the Customer quotes from details */
  async function getFromDetails() {
    setloader(true);
    let from = await getFromDetailsApi();
    if (from.data.statusCode == 200) {
      setfromDropdown(from.data.responseData);
      setloader(false);
    }
  }
  //** PS_GF_60, PS_GF_61, PS_GF_62, PS_GF_63, PS_GF_64, PS_GF_65
  //Fetch the updated license details */
  async function getEditOrder(id: number) {
    setloader(true);
    let payload = {
      quote_id: id,
      isEdit: true,
    };

    let editQuotes = await getQuotesLicenseDetailsById(payload);

    if (editQuotes.data.statusCode == 200) {
      let ll = editQuotes.data.responseData[1];
      let data = ll.map((item: any) => {
        return {
          ...item,
          isEdit: false,
        };
      });

      seteditvalues(editQuotes.data.responseData[0]?.[0]);
      setgetlicenseList(data);
      setloader(false);
    }
  }
  //** PS_GF_17 OnChange function to bind the validate data into the state */
  async function onchangeCustomerInfo(name: any, value: any) {
    seteditvalues((datum: any) => ({
      ...datum,
      [name]: value,
    }));
    validation(name, value, true);
  }
  //** PS_GF_47, PS_GF_48, PS_GF_49, PS_GF_50, PS_GF_51, PS_GF_52, PS_GF_53, PS_GF_54, PS_GF_55, PS_GF_56, PS_GF_57, PS_GF_58, PS_GF_59
  //to perform the validation */
  function validation(name: any, value: any, regex: boolean = false) {
    let result = true;
    if (regex && name == "from_data") {
      if (value != "") {
        seteditvaluesError((prevState) => ({
          ...prevState,
          from_dataError: "",
        }));
      } else {
        seteditvaluesError((prevState) => ({
          ...prevState,
          from_dataError: "choose From",
        }));
      }
    }
    if (regex && name == "customerName") {
      if (value !== "" && OnlyNumberSpecialChar(value)) {
        seteditvaluesError((editval) => ({
          ...editval,
          customerNameError: "Enter valid customer name",
        }));
        result = false; //PS_AR_11
      } else if (value == "") {
        seteditvaluesError((editval) => ({
          ...editval,
          customerNameError: "Please enter customer Name",
        }));
        result = true; //PS_AR_11
      } else {
        seteditvaluesError((editval) => ({
          ...editval,
          customerNameError: "",
        }));
        result = false; //PS_AR_11
      }
    }

    if (regex && name === "name") {
      if (value !== "" && OnlyNumberSpecialChar(value)) {
        seteditvaluesError((editval) => ({
          ...editval,
          nameError: "Enter a valid name",
        }));
        result = false; //PS_AR_11
      } else if (value == "") {
        seteditvaluesError((editval) => ({
          ...editval,
          nameError: "Enter a name",
        }));
        result = true; //PS_AR_11
      } else {
        seteditvaluesError((editval) => ({
          ...editval,
          nameError: "",
        }));
        result = false; //PS_AR_11
      }
    }
    if (regex && name == "emailAddress") {
      const isValid = OnlyEmail(value);

      if (value != "" && isValid) {
        seteditvaluesError((editval) => ({
          ...editval,
          emailAddressError: "Enter valid email address",
        }));
        result = false;
      } else if (value === "") {
        seteditvaluesError((editval) => ({
          ...editval,
          emailAddressError: "Enter email address",
        }));
        result = false;
      } else {
        seteditvaluesError((editval) => ({
          ...editval,
          emailAddressError: "",
        }));
        result = true;
      }
    }
    if (regex && name === "phoneNumber") {
      if (value != "" && !validatePhoneNumber(value)) {
        seteditvaluesError((editval) => ({
          ...editval,
          phoneNumberError: "Enter valid phone number",
        }));
        result = false; //PS_AR_11
      } else if (value !== "" && validatePhoneNumber(value)) {
        seteditvaluesError((editval) => ({
          ...editval,
          phoneNumberError: "",
        }));
        result = true; //PS_AR_11
      } else {
        seteditvaluesError((editval) => ({
          ...editval,
          phoneNumberError: "Enter phone number",
        }));
        result = false; //PS_AR_11
      }
    }
    if (regex && name == "AdressLine") {
      if (value != "" && OnlyNumberSpecialChar(value)) {
        seteditvaluesError((editval) => ({
          ...editval,
          AdressLineError: "Enter valid address",
        }));
        result = false; //PS_AR_11
      } else if (value !== "") {
        seteditvaluesError((editval) => ({
          ...editval,
          AdressLineError: "",
        }));
        result = true; //PS_AR_11
      } else {
        seteditvaluesError((editval) => ({
          ...editval,
          AdressLineError: "Enter address",
        }));
        result = false; //PS_AR_11
      }
    }
    if (regex && name == "state") {
      if (OnlyText(value)) {
        seteditvaluesError((editval) => ({
          ...editval,
          stateError: "Enter valid state",
        }));
        result = false; //PS_AR_11
      } else if (value != "" && !OnlyNumberSpecialChar(value)) {
        seteditvaluesError((editval) => ({
          ...editval,
          stateError: "",
        }));
        result = true; //PS_AR_11
      } else if (value == "") {
        seteditvaluesError((editval) => ({
          ...editval,
          stateError: "Enter state",
        }));
        result = false; //PS_AR_11
      }
    }
    if (regex && name == "city") {
      if (OnlyNumberSpecialChar(value)) {
        seteditvaluesError((editval) => ({
          ...editval,
          cityError: "Enter valid city",
        }));
        result = false; //PS_AR_11
      } else if (value != "" && !OnlyNumberSpecialChar(value)) {
        seteditvaluesError((editval) => ({
          ...editval,
          cityError: "",
        }));
        result = true; //PS_AR_11
      } else if (value == "") {
        seteditvaluesError((editval) => ({
          ...editval,
          cityError: "Enter city",
        }));
        result = false; //PS_AR_11
      }
    }

    if (regex && name == "country") {
      if (OnlyNumberSpecialChar(value)) {
        seteditvaluesError((editval) => ({
          ...editval,
          countryError: "Enter valid country",
        }));
        result = false; //PS_AR_11
      } else if (value != "" && !OnlyNumberSpecialChar(value)) {
        seteditvaluesError((editval) => ({
          ...editval,
          countryError: "",
        }));
        result = true; //PS_AR_11
      } else if (value == "") {
        seteditvaluesError((editval) => ({
          ...editval,
          countryError: "Enter country",
        }));
        result = false; //PS_AR_11
      }
    }

    if (regex && name == "zip") {
      if (value !== "" && OnlyNumber(value)) {
        seteditvaluesError((editval) => ({
          ...editval,
          zipError: "Enter valid zip code",
        }));
        result = false;
      } else if (value !== "") {
        seteditvaluesError((editval) => ({
          ...editval,
          zipError: "",
        }));
        result = true;
      } else if (value === "") {
        seteditvaluesError((editval) => ({
          ...editval,
          zipError: "Enter zip code",
        }));
        result = false;
      }
    }

    if (Object.values(editvaluesError).some((el: any) => el)) return false;

    if (!regex && name == "" && value == "") {
      if (
        editvalues?.from_data == "" ||
        editvalues.from_data == undefined ||
        editvalues.from_data == null ||
        editvalues.from_data == "undefined" ||
        editvalues.from_data == "null"
      ) {
        seteditvaluesError((storeLogisticDataError) => ({
          ...storeLogisticDataError,
          from_dataError: "Choose from",
        }));
        result = false;
      }

      if (
        editvalues.customerName == "" ||
        editvalues.customerName == undefined ||
        editvalues.customerName == null
      ) {
        seteditvaluesError((storeLogisticDataError) => ({
          ...storeLogisticDataError,
          customerNameError: "Enter customer name",
        }));
        result = false;
      }

      if (
        editvalues.name == "" ||
        editvalues.name == undefined ||
        editvalues.name == undefined ||
        editvalues.name == null
      ) {
        seteditvaluesError((storeLogisticDataError) => ({
          ...storeLogisticDataError,
          nameError: "Enter name",
        }));
        result = false;
      }
      if (
        editvalues.emailAddress == "" ||
        editvalues.emailAddress == undefined
      ) {
        seteditvaluesError((storeLogisticDataError) => ({
          ...storeLogisticDataError,
          emailAddressError: "Enter mail ",
        }));
        result = false;
      }
      if (editvalues.phoneNumber == "" || editvalues.phoneNumber == undefined) {
        seteditvaluesError((storeLogisticDataError) => ({
          ...storeLogisticDataError,
          phoneNumberError: "Enter phone number ",
        }));
        result = false;
      }
      if (editvalues.AdressLine == "" || editvalues.AdressLine == undefined) {
        seteditvaluesError((storeLogisticDataError) => ({
          ...storeLogisticDataError,
          AdressLineError: "Enter address",
        }));
        result = false;
      }

      if (editvalues.state == "" || editvalues.state == undefined) {
        seteditvaluesError((storeLogisticDataError) => ({
          ...storeLogisticDataError,
          stateError: "Enter State",
        }));
        result = false;
      }

      if (editvalues.city == "" || editvalues.city == undefined) {
        seteditvaluesError((storeLogisticDataError) => ({
          ...storeLogisticDataError,
          cityError: "Enter city",
        }));
        result = false;
      }
      if (editvalues.country == "" || editvalues.country == undefined) {
        seteditvaluesError((storeLogisticDataError) => ({
          ...storeLogisticDataError,
          countryError: "Enter country",
        }));
        result = false;
      }
      if (editvalues.zip == "" || editvalues.zip == undefined) {
        seteditvaluesError((storeLogisticDataError) => ({
          ...storeLogisticDataError,
          zipError: "Enter zip",
        }));
        result = false;
      }
    }

    return result;
  }
  //** PS_GF_24, PS_GF_25, PS_GF_26, PS_GF_27.1, PS_GF_27.2, PS_GF_27.3, PS_GF_27.4
  // handle the save the changes */
  async function handleSave() {
    if (validation("", "", false)) {
      setloader(true);
      let payload = {
        licenseId: licenseId,
        licenseData: getlicenseList,
        quotes: editvalues,
      };
      let update = await updateQuotesLicenseDetailsById(payload);

      if (update.data.statusCode == 200) {
        setloader(false);
        navigate("/quote");
      }
    }
  }
  //** PS_GF_35, PS_GF_36, PS_GF_37, PS_GF_38, PS_GF_39, PS_GF_40
  //to handle the edit operation in the quites license */
  async function handleEdit(index: number) {
    setgetlicenseList((data: any) => {
      const license = [...data];
      license[index] = {
        ...license[index],
        isEdit: true,
      };
      return license;
    });
  }
  //** PS_GF_18.1, PS_GF_18.2, PS_GF_18.3, PS_GF_18.4, PS_GF_18.5, PS_GF_18.6, PS_GF_18.7, PS_GF_18.8
  //to perform the delete operation in the quotes license */
  async function handleDelete(index: number, id: number) {
    setlicenseId((license: any) => [...license, { license_id: id }]);
    const filterRecords = getlicenseList.filter((data: any) => {
      return data.license_id != id;
    });
    setgetlicenseList(filterRecords);
  }
  //** PS_GF_41, PS_GF_42, PS_GF_43, PS_GF_44, PS_GF_45, PS_GF_46
  //to validate the term,quanity,margin of the customer quotes license */
  function InlineValidation(index: number) {
    // Assuming this is inside a function

    // Initialize a flag
    let isValid = true;

    if (quantity[index].length <= 0) {
      setQuantityInlineError({
        ...quantityinlineError,
        [index]: "enter quantity",
      });
      isValid = false;
    }

    if (termDuration[index].length <= 0) {
      settermdurationinlineError({
        ...termdurationinlineError,
        [index]: "enter term",
      });
      isValid = false;
    }

    if (margin[index].length <= 0) {
      setmargininlineError({
        ...margininlineError,
        [index]: "enter margin",
      });
      isValid = false;
    }

    // Return the flag
    return isValid;
  }

  return (
    <>
      <div className="container-fluid px-4">
        <BreadCrumbs data={breadcrumb} />
        <div className="row justify-content-center">
          <div className="col-lg-11 col-md-12 col-sm-12 bg-white mt-3 rounded-3 p-3">
            <div className="row">
              <div className="col-lg-7 col-md-12 col-sm-12 res-border">
                <div className="row">
                  <h3 className="font-18 font-semibold tertiary-color mb-4 px-4">
                    Details
                  </h3>
                  <div className="col-md-6 col-sm-12">
                    <div className="mb-3 px-3">
                      <label className="font-14 font-semibold primary-text-color mb-2">
                        Quote Number
                      </label>
                      <p className="font-13 font-regular secondary-text-color">
                        {editvalues?.quoteNumber}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6  col-sm-12">
                    <div className="mb-3 px-3">
                      <label className="font-14 font-semibold primary-text-color mb-2">
                        From<span className="color-red">*</span>
                      </label>
                      <select
                        className="form-select custom-input font-13 font-regular secondary-text-color"
                        aria-label="select"
                        name="from_data"
                        value={editvalues?.from_data}
                        onChange={(e: any) => {
                          onchangeCustomerInfo(e.target.name, e.target.value);
                        }}
                      >
                        <option value="">Select</option>
                        {fromDropdown.map((from: any) => {
                          return (
                            <>
                              <option id={from.user_id} value={from.name}>
                                {from.name}
                              </option>
                            </>
                          );
                        })}
                      </select>
                      <span className="color-red">
                        {editvaluesError.from_dataError}
                      </span>
                    </div>
                  </div>
                  <h3 className="font-18 font-semibold tertiary-color my-3 px-4">
                    Customer Information
                  </h3>
                  <div className="col-md-6 col-sm-12">
                    <div className="mb-3 px-3">
                      <label
                        htmlFor="customer-name"
                        className="font-14 font-semibold primary-text-color mb-2"
                      >
                        Customer Name <span className="color-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control custom-input"
                        id="customer-name"
                        placeholder="Enter customer name"
                        value={editvalues?.customerName}
                        name="customerName"
                        maxLength={255}
                        onChange={(e: any) => {
                          onchangeCustomerInfo(e.target.name, e.target.value);
                        }}
                      />

                      <span className="color-red">
                        {editvaluesError.customerNameError}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="mb-3 px-3">
                      <label
                        htmlFor="name"
                        className="font-14 font-semibold primary-text-color mb-2"
                      >
                        Name <span className="color-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control custom-input"
                        id="name"
                        placeholder="Enter name"
                        name="name"
                        maxLength={255}
                        value={editvalues.name}
                        onChange={(e: any) => {
                          onchangeCustomerInfo(e.target.name, e.target.value);
                        }}
                      />
                      <span className="color-red">
                        {editvaluesError.nameError}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="mb-3 px-3">
                      <label
                        htmlFor="Email-Address"
                        className="font-14 font-semibold primary-text-color mb-2"
                      >
                        Email Address <span className="color-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control custom-input"
                        id="Email-Address"
                        placeholder="Enter email address"
                        name="emailAddress"
                        value={editvalues.emailAddress}
                        maxLength={255}
                        onChange={(e: any) => {
                          onchangeCustomerInfo(e.target.name, e.target.value);
                        }}
                      />
                      <span className="color-red">
                        {editvaluesError.emailAddressError}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="mb-3 px-3">
                      <label
                        htmlFor="Phone-Number"
                        className="font-14 font-semibold primary-text-color mb-2"
                      >
                        Phone Number <span className="color-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control custom-input"
                        id="Phone-Number"
                        placeholder="Enter phone number"
                        value={editvalues.phoneNumber}
                        name="phoneNumber"
                        maxLength={10}
                        onChange={(e: any) => {
                          onchangeCustomerInfo(e.target.name, e.target.value);
                        }}
                      />
                      <span className="color-red">
                        {editvaluesError.phoneNumberError}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="mb-3 px-3">
                      <label
                        htmlFor="Address"
                        className="font-14 font-semibold primary-text-color mb-2"
                      >
                        Address <span className="color-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control custom-input"
                        id="Address"
                        placeholder="Enter address"
                        value={editvalues.AdressLine}
                        maxLength={400}
                        name="AdressLine"
                        onChange={(e: any) => {
                          onchangeCustomerInfo(e.target.name, e.target.value);
                        }}
                      />
                      <span className="color-red">
                        {editvaluesError.AdressLineError}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="mb-3 px-3">
                      <label
                        htmlFor="State"
                        className="font-14 font-semibold primary-text-color mb-2"
                      >
                        State <span className="color-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control custom-input"
                        id="State"
                        placeholder="Enter state"
                        value={editvalues.state}
                        name="state"
                        maxLength={255}
                        onChange={(e: any) => {
                          onchangeCustomerInfo(e.target.name, e.target.value);
                        }}
                      />
                      <span className="color-red">
                        {editvaluesError.stateError}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="mb-3 px-3">
                      <label
                        htmlFor="City"
                        className="font-14 font-semibold primary-text-color mb-2"
                      >
                        City <span className="color-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control custom-input"
                        id="City"
                        placeholder="Enter city"
                        value={editvalues.city}
                        name="city"
                        maxLength={255}
                        onChange={(e: any) => {
                          onchangeCustomerInfo(e.target.name, e.target.value);
                        }}
                      />
                      <span className="color-red">
                        {editvaluesError.cityError}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="mb-3 px-3">
                      <label
                        htmlFor="Country"
                        className="font-14 font-semibold primary-text-color mb-2"
                      >
                        Country <span className="color-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control custom-input"
                        id="Country"
                        placeholder="Enter country"
                        value={editvalues.country}
                        name="country"
                        maxLength={255}
                        onChange={(e: any) => {
                          onchangeCustomerInfo(e.target.name, e.target.value);
                        }}
                      />
                      <span className="color-red">
                        {editvaluesError.countryError}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="mb-3 px-3">
                      <label
                        htmlFor="Zip-Code"
                        className="font-14 font-semibold primary-text-color mb-2"
                      >
                        Zip Code <span className="color-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control custom-input"
                        id="Zip-Code"
                        placeholder="Enter Zip code"
                        value={editvalues.zip}
                        maxLength={15}
                        name="zip"
                        onChange={(e: any) => {
                          onchangeCustomerInfo(e.target.name, e.target.value);
                        }}
                      />
                      <span className="color-red">
                        {editvaluesError.zipError}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-12 col-sm-12 lg-px-3 px-5">
                <h3 className="font-18 font-semibold tertiary-color pb-4 ">
                  Summary
                </h3>
                <div className="table-responsive mb-4 rounded-2 ">
                  <table className="table  themeTable ">
                    <thead className="font-bold">
                      <tr>
                        <th className="text-start">Description</th>
                        <th className="text-end">Total Price</th>
                      </tr>
                    </thead>
                    <tbody className="font-medium">
                      {" "}
                      <tr className="border-bottom-0 ">
                        <td className="text-start font-regular">
                          Microsoft Price
                        </td>
                        <td className="text-end font-regular">
                          $
                          {microsoftPrice
                            ? Number(microsoftPrice).toFixed(2)
                            : "0.00"}
                        </td>
                      </tr>
                      <tr className="border-bottom-0 ">
                        <td className="text-start font-regular">
                          Reseller Price
                        </td>
                        <td className="text-end font-regular">
                          $
                          {resellerPrice
                            ? Number(resellerPrice).toFixed(2)
                            : "0.00"}
                        </td>
                      </tr>
                      <tr className="border-bottom-0 ">
                        <td className="text-start font-regular">Discount</td>
                        <td className="text-end font-regular">
                          $
                          {Number(microsoftPrice - resellerPrice)
                            ? Number(microsoftPrice - resellerPrice).toFixed(2)
                            : "0.00"}
                        </td>
                      </tr>
                      <tr className="border-bottom-0 ">
                        <td className="text-start font-regular">Profit</td>
                        <td className="text-end font-regular">
                          $
                          {Number(resellerPrice - partnerPrice)
                            ? Number(resellerPrice - partnerPrice).toFixed(2)
                            : "0.00"}
                        </td>
                      </tr>{" "}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-5 col-xl-12">
                <div className="d-flex justify-content-between mt-2 mb-4 align-items-center">
                  <h2 className="font-20 font-bold dark-blue-color d-flex mb-0">
                    License List
                    <span className="custom-admin-batch font-10 d-flex align-items-center justify-content-center ms-2 px-2" >
                      {getlicenseList.length}
                    </span>
                  </h2>
                  <button
                    type="button"
                    className="btn outline-btn font-14 font-semibold "
                    onClick={() => {
                      navigate("/inventory?editquote", {
                        state: {
                          mode: "customer",
                          customerId: editvalues.customer_id,
                          customerName: editvalues.customerName,
                          quote_id: editvalues.quoteNumber,
                          NewQuote: true,
                        },
                      });
                    }}
                  >
                    Add License
                  </button>
                </div>
                <div className="table-responsive mb-4 rounded-2">
                  <table className="table  themeTable ">
                    <thead className="font-bold">
                      <tr>
                        <th className="text-start">License</th>

                        <th className="text-start">Quantity</th>

                        <th className="text-start">Margin</th>

                        <th className="text-start">Term Duration</th>
                        <th className="text-end">Microsoft Price</th>
                        {/* <th className="text-end">
                          Partner Price
                          <span className="ms-2 cursor-pointer">
                            <img
                              src="Images/sort-arrow.svg"
                              alt="sortarrow"
                              title="Sort"
                            />
                          </span>
                        </th> */}
                        <th className="text-end">Reseller Price</th>
                        <th className="text-center">Profit</th>
                        <th className="text-center">Actions</th>
                      </tr>
                    </thead>
                    <tbody className="font-medium">
                      {getlicenseList.map((data: any, index: any) => {
                        let is_tax_certificate_present =
                          data.is_tax_ctrfct_present == 1 ? true : false;
                        // let partnerPrice = is_tax_certificate_present
                        //   ? Number(data.partner_price)
                        //   : Number(data.partner_price * 1.06);

                        let partnerPrice = Number(data.partner_price)

                        let customermarginDecimal = Number(margin[index]);
                        let customerQuantity = Number(quantity[index]);
                        const discountedPrice =
                          partnerPrice +
                          (partnerPrice * customermarginDecimal) / 100;

                        // Calculate the total price based on quantity
                        const totalPrice = discountedPrice * customerQuantity;

                        // Calculate the final partner price with margin
                        //  let finalPartnerPrice = partnerPrice + (partnerPrice * customermarginDecimal * customerQuantity);
                        let microsoftPrice =
                          Number(data.micro_price) * Number(quantity[index]);

                        return (
                          <>
                            <tr className="border-bottom-0 ">
                              <td className="text-start font-regular">
                                {data.license.length > 25 ? (
                                  <Tooltip
                                    title={data.license}
                                    placement="top-end"
                                  >
                                    <Button className="text-fixer">
                                      {data.license?.substring(0, 25) + "..."}
                                    </Button>
                                  </Tooltip>
                                ) : (
                                  data.license
                                )}{" "}
                              </td>

                              {data.isEdit == false ? (
                                <>
                                  <td className="text-start font-regular">
                                    {quantity[index]}
                                  </td>

                                  <td className="text-start font-regular">
                                    {margin[index]}%
                                  </td>

                                  <td className="text-start font-regular">
                                    {termDuration[index]}
                                  </td>
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <td className="text-end font-regular">
                                    <input
                                      type="text"
                                      className="form-control custom-input"
                                      placeholder="Enter quantity"
                                      name="quantity"
                                      value={quantity[index]}
                                      onChange={(e: any) => {
                                        if (!OnlyNumber(e.target.value)) {
                                          setQuantityInlineError({
                                            ...quantityinlineError,
                                            [index]: "",
                                          });
                                          setquantity((quantity: any) => ({
                                            ...quantity,
                                            [index]: e.target.value,
                                          }));
                                        }
                                      }}
                                    />
                                    <span className="color-red">
                                      {quantityinlineError[index]}
                                    </span>
                                  </td>
                                  <td className="font-regular">
                                    <input
                                      type="text"
                                      className="form-control custom-input"
                                      placeholder="Enter margin"
                                      // name="term_duration"
                                      name="margin"
                                      maxLength={5}
                                      value={margin[index]}
                                      onChange={(e: any) => {
                                        const invalidMarginCombinations = [
                                          "0.0000",
                                          "00.000",
                                          "000.00",
                                          "0000.0",
                                          "00000",
                                          "0.0",
                                          "0.00",
                                          "0.000",
                                          ".00000",
                                          "000000",
                                          "00000.",
                                          "0.000",
                                          "00.00",
                                          "000.0",
                                          "00",
                                          "000",
                                          "0000",
                                          "000000",
                                          "00000.",
                                          "000.0",
                                          "0.",
                                          "00.",
                                          "00.0",
                                          "000.",
                                          "0000.",
                                        ];

                                        const dots = e.target.value.match(/\./g);
                                        const dotCount = dots ? dots.length : 0;
                                        if(e.target.value === ""){
                                          setmargininlineError({
                                            ...margininlineError,
                                            [index]: "Enter Margin",
                                          });
                                        }
                                          else if ( invalidMarginCombinations.includes(e.target.value) || e.target.value > 100 || dotCount > 1 ) {
                                            setmargininlineError({
                                              ...margininlineError,
                                              [index]: "Enter Valid Margin",
                                            });
                                          }
                                          else{
                                            setmargininlineError({
                                              ...margininlineError,
                                              [index]: "",
                                            });
                                          }

                                          setmargin((margin: any) => ({
                                            ...margin,
                                            [index]: e.target.value,
                                          }));
                                        }
                                      }
                                    />
                                    <span className="color-red">
                                      {margininlineError[index]}
                                    </span>
                                  </td>
                                  <td className="font-regular">
                                    <input
                                      type="text"
                                      className="form-control custom-input"
                                      placeholder="Enter term duration"
                                      name="start_date"
                                      value={termDuration[index]}
                                      onChange={(e: any) => {
                                        settermdurationinlineError({
                                          ...termdurationinlineError,
                                          [index]: "",
                                        });
                                        settermDuration(
                                          (termDuartion: any) => ({
                                            ...termDuartion,
                                            [index]: e.target.value,
                                          })
                                        );
                                      }}
                                    />
                                    <span className="color-red">
                                      {termdurationinlineError[index]}
                                    </span>
                                  </td>
                                </>
                              )}
                              <td className="text-end font-regular">
                                ${microsoftPrice?.toFixed(2)}
                              </td>
                              {/* <td className="text-end font-regular">${data.partner_price}</td> */}
                              <td className="text-end font-regular">
                                ${totalPrice?.toFixed(2)}
                              </td>
                              <td className="text-center font-regular">
                                $
                                {Number(
                                  microsoftPrice - totalPrice

                                )?.toFixed(2)}
                              </td>
                              {data.isEdit == false ? (
                                <>
                                  {" "}
                                  <td className="text-center font-regular">
                                    <span className="cursor-pointer">
                                      <img
                                        src="Images/edit-icon.svg"
                                        alt="editIcon"
                                        title="Edit"
                                        onClick={() => {
                                          handleEdit(index);
                                        }}
                                      />
                                    </span>
                                    <span className="ms-3 cursor-pointer">
                                      <img
                                        src="Images/delete-icon.svg"
                                        alt="deleteIcon"
                                        title="Delete"
                                        onClick={() => {
                                          handleDelete(index, data.license_id);
                                        }}
                                      />
                                    </span>
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td className="text-center font-regular">
                                    <span className="cursor-pointer">
                                      <img
                                        src="Images/right-icon.svg"
                                        alt="righticon"
                                        title="righticon"
                                        // id={item.license_id}
                                        onClick={(e: any) => {
                                          if (InlineValidation(index)) {
                                            setgetlicenseList((data: any) => {
                                              const license = [...data];
                                              license[index] = {
                                                ...license[index],
                                                isEdit: false,
                                                quantity: quantity[index],
                                                margin: margin[index],
                                                term_duration:
                                                  termDuration[index],
                                              };
                                              return license;
                                            });
                                          }
                                        }}
                                      />
                                    </span>
                                    <span className="ms-3 cursor-pointer">
                                      <img
                                        src="Images/Wrong-icon.svg"
                                        alt="deleteIcon"
                                        title="Delete"
                                        onClick={(e: any) => {
                                          setgetlicenseList((data: any) => {
                                            const license = [...data];
                                            license[index] = {
                                              ...license[index],
                                              isEdit: false,
                                            };
                                            return license;
                                          });
                                        }}
                                      />
                                    </span>
                                  </td>
                                </>
                              )}
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-end mb-5">
                  <button
                    type="button"
                    className="btn text border-0 font-14 font-semibold me-3 shadow-none text-btn"
                    onClick={() => {
                      navigate("/quote");
                    }}
                  >
                  Cancel
                  </button>
                  <button
                    type="button"
                    className="btn primary-btn border-0 font-14 font-semibold "
                    onClick={() => {
                      handleSave();
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loader ? (
          <div className="overlay">
            <div className="position-absolute top-50 start-50 translate-middle">
              <div
                className="spinner-border Loader text-light align-center "
                role="status"
              >
                <span className="visually-hidden" />
              </div>
              <p className="loading-text font-14 color-white mt-2 font-semibold">
                Loading..
              </p>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}
