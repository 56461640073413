import { useEffect, useState } from "react";
import { getcustomerApi } from "../../services/inventoryservice";
import { v4 as uuidv4 } from "uuid";
import { useNavigate, useLocation } from "react-router-dom";
import { OnlyText, encryptStorage, sessionKeys } from "../../configs/constant";
import { customerSessionData } from "../../interface";
import { Loader } from "../loader";
//** PS_SH_88  to provisioned the quotes and invoice */
export default function Quoteprovision() {
  //** PS_SH_89 */
  const navigate = useNavigate();
  let location = useLocation();
  let customerDetails: customerSessionData | null = JSON.parse(
    encryptStorage.getItem(sessionKeys.customerDetails) || "null"
  );
  const [storeCustomer, setstorecustomer] = useState([]);
  const [customerName, setcustomerName] = useState<string>("");
  const [customerId, setcustomerId] = useState<string>("");
  const [customerType, setcustomerType] = useState<string>("Existing customer");
  const [loader, setloader] = useState<boolean>(false);
//** PS_SH_90 */
  useEffect(() => {
    getcustomer();
    if (location?.state?.fromPrev) {
      setcustomerName(location?.state?.cust_name);
      setcustomerType(location?.state?.customertype);
      setcustomerId(location?.state?.customerId)
    }
  }, []);
  //** PS_SH_91, PS_SH_92, PS_SH_93, PS_SH_94, PS_SH_95, PS_SH_96 Fetch the Customer details */
  async function getcustomer() {
    setloader(true)
    let product = await getcustomerApi();

    if (product.data.statusCode == 200) {
      setstorecustomer(product.data.responseData);
    }
    setloader(false)
  }
//** PS_SH_97, PS_SH_98, PS_SH_99, PS_SH_100, PS_SH_101, PS_SH_102, PS_SH_103, PS_SH_104 to handle the dropdown scenario */
  function handleDropdown(e: any) {
    const selectedCustomerId = e.target.options[e.target.selectedIndex].id;

    let data: any = storeCustomer.filter((margin: any) => {
      return margin.customer_id == selectedCustomerId;
    });
    if (data[0]?.margin) {
      if (customerDetails) {
        customerDetails.customer_margin = data[0]?.margin;
      }
      encryptStorage.setItem(
        sessionKeys.customerDetails,
        JSON.stringify(customerDetails)
      );
    }
    setcustomerName(e.target.value);
    setcustomerId(selectedCustomerId);
  }
//** PS_SH_105, PS_SH_106, PS_SH_107, PS_SH_108, PS_SH_109 handle the radio button scenario */
  function handleradio(e: any) {
    const value = e.target.value;
    // if (value == "Existing customer") {
    setcustomerName("");
    setcustomerType(value);
    // setcustomerId(e.target.id);
    // } else {
    //   setNewId(e.target.id);
    // setcustomerType(value);
    // }
  }
//** PS_SH_110, PS_SH_111, PS_SH_112 to handle the next  */
  function handleNext() {
    navigate("/inventory", {
      state: {
        mode: "customer",
        customerId: customerId,
        customerName: customerName,
        customerType: customerType,
        FromChoosedCustomer: true,
        NewQuote: location?.state?.NewQuote ? location?.state?.NewQuote : false,
      },
    });
  }

//** PS_SH_113 return the response value into html */
  return (
    <>
      {/* <div>
        <select
          name="customerName"
          className="form-select custom-select custom-input font-regular font-13"
          value={customerName}
          id={customerId}
          onChange={(e) => {
            handleDropdown(e);
          }}
        >
          <option defaultChecked id={""} value="new customer">
            Select
          </option>
          {storeCustomer.map((val: any) => {
            return (
              <>
                <option id={val.customer_id} value={val.company_name}>
                  {val.company_name}
                </option>
              </>
            );
          })}
        </select>
        <>
          <input
            type="radio"
            id=""
            name="customer"
            value="Existing customer"
            defaultChecked
            onClick={(e) => {
              handleradio(e);
            }}
          />
          <label htmlFor="red">Existing customer</label>
          <br />
          <input
            type="radio"
            id={uuidv4()}
            name="customer"
            value={"new customer"}
            onClick={(e) => {
              handleradio(e);
            }}
          />
          <label htmlFor="green">New customer</label>
          <br />
        </>
      </div>

      <button
        type="button"
        onClick={() => {
          handleNext();
        }}
      >
        Next
      </button>
      <button
        type="button"
        onClick={() => {
          navigate(-1);
        }}
      >
        cancel
      </button> */}
      <>
        <div className="px-5 mt-5 pt-4">
          <p className="font-20 font-semibold table-title-color mt-4">
            <img
              src="Images/back-arrow.svg"
              className="me-2"
              alt="back-arrow"
              onClick={() => {
                navigate("/inventory", { state: {} });
              }}
            />
            New Quote/Provision
          </p>
        </div>
        <div className="container-fluid px-5 mt-5 pt-4">
          <div className="row mt-2">
            {/* Wizard starts here */}
            <div className="d-flex justify-content-center w-100">
              <div className="col-12 col-sm-12 col-md-11 col-lg-9 col-xl-5">
                <div className="wizard-conatiner mt-2 mb-2 d-flex justify-content-between position-relative">
                  <div className="wiz-step wizard-after active ">
                    <div className="d-flex justify-content-center">
                      <span className="wiz-count font-regular font-14">1</span>
                    </div>
                    <span className="font-semibold d-none d-sm-inline-block wiz-text pt-2">
                      Choose Customer
                    </span>
                  </div>
                  <div className="wiz-step wizard-after">
                    <div className="d-flex justify-content-center">
                      <span className="wiz-count font-regular font-14">2</span>
                    </div>
                    <span className="font-semibold d-none d-sm-inline-block wiz-text pt-2">
                      Add License
                    </span>
                  </div>
                  <div className="wiz-step wiz-step wizard-after">
                    <div className="d-flex justify-content-center">
                      <span className="wiz-count font-regular font-14">3</span>
                    </div>
                    <span className="font-semibold d-none d-sm-inline-block wiz-text pt-2">
                      Quote/Provision
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* Wizard ends here */}
          </div>
        </div>
        <div className="container-fluid">
        <div className="row justify-content-center ">
          <div className="px-5">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 bg-white mt-3 rounded-3 container-height p-4">
              <div className="row ">
                <h3 className="font-18 font-semibold tertiary-color mb-4">
                  Choose Customer
                </h3>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="mb-3">
                    <p className="font-14 font-semibold">Customer Type</p>
                    <div className="form-check form-check-inline">
                      <input
                        className="custom-check form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="excustomer"
                        defaultValue="option1"
                        value="Existing customer"
                        checked={customerType === "Existing customer"}
                        onChange={(e) => {
                          handleradio(e);
                        }}
                      />
                      <label
                        className="form-check-label font-14 font-regular primary-text-color"
                        htmlFor="excustomer"
                      >
                        Existing Customer
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="custom-check form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        defaultValue="option1"
                        value="new customer"
                        checked={customerType === "new customer"}
                        onChange={(e) => {
                          handleradio(e);
                        }}
                      />
                      <label
                        className="form-check-label font-14 font-regular primary-text-color"
                        htmlFor="newcustomer"
                      >
                        New Customer
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="mb-3">
                    <label className="font-14 font-semibold primary-text-color mb-2">
                      {customerType == "new customer"
                        ? "New Customer "
                        : "Existing Customer"}
                    </label>
                    {customerType != "new customer" ? (
                      <select
                        className="form-select custom-select custom-input font-13 font-regular secondary-text-color"
                        aria-label="select "
                        value={customerName}
                        id={customerId}
                        onChange={(e) => {
                          handleDropdown(e);
                        }}
                      >
                        <option defaultChecked id={""} value="">
                          Select
                        </option>
                        {storeCustomer.map((val: any) => {
                          return (
                            <>
                              <option
                                id={val.customer_id}
                                value={val.company_name}
                              >
                                {val.company_name}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    ) : (
                      <input
                        type="text"
                        className="form-control custom-input"
                        placeholder="Enter New Customer"
                        name="name"
                        id={"new" + uuidv4()}
                        maxLength={30}
                        value={customerName}
                        onChange={(e: any) => {
                          setcustomerName(e.target.value);
                          setcustomerId(e.target.id);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end mb-3">
                <button
                  type="button"
                  className="btn text border-0 font-14 font-medium me-3 shadow-none text-btn"
                  onClick={() => {
                    // navigate(-1);
                    let customerSessionData: customerSessionData = {
                      customer_Name: null,
                      customer_id: null,
                      customer_theme: null,
                      view: "overall",
                      customer_margin: 0,
                      logo_url: null,
                      tax: null,
                    };
                    encryptStorage.setItem(
                      sessionKeys?.customerDetails,
                      JSON?.stringify(customerSessionData)
                    );
                    navigate("/inventory");
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn primary-btn border-0 font-14 font-medium "
                  disabled={!customerName}
                  onClick={() => {
                    handleNext();
                  }}
                >
                  Next
                  <img
                    src="Images/next-white-arrow.svg"
                    className="ms-2"
                    alt="next-arrow"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        </div>
        {loader ? <Loader />:null}
      </>
    </>
  );
}
