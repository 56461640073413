
import { client } from "../client";
import { errorUrl,backendurl as url } from "../configs/constant";


export async function ErrorLog(data: {} | undefined) {
    let Config = {
      url: url+errorUrl.createErrorLog,
      headers: {
        "content-type": "application/json",
      },
      method: "post",
    };
  
    let response = await client(Config);
    return response;
  }
  