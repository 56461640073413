import React from "react";
import { useState, useEffect } from "react";
import {
  getCardCount,
  getUpcomingRenewal,
  getTopTenCustomerProfit,
  getRevenueCostGraph,
} from "../../services/dashboardService";
import { getCardCounts, customerSessionData } from "../../interface";
import { sessionKeys, encryptStorage } from "../../configs/constant";
import moment from "moment";
import { Loader } from "../loader";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom"
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker.css'
import { formatAmount } from "../../configs/constant";
import { Button, Tooltip as Tool } from "@mui/material";

// import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


//   //PS_D_1, PS_D_2, PS_D_3, PS_D_4, PS_D_5, PS_D_6, PS_D_7, PS_D_8, PS_D_9, PS_D_10, PS_D_11, PS_D_12, PS_D_13, PS_D_14, PS_D_15, PS_D_16, PS_D_17, PS_D_18 
//   to render the graph details  */
export default function Graph() {
  let navigate = useNavigate();
  let customerDetails: customerSessionData | null = JSON.parse(
    encryptStorage.getItem(sessionKeys?.customerDetails) || "null"
  );
  const [customerId, setCustomerId] = useState<any>(customerDetails?.customer_id);
  const [loader, setloader] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [hoveredLicenseIndex, setHoveredLicenseIndex] = React.useState(null);
  const [UpcomingRenewal, setUpcomingRenewal] = useState<any>([]);
  const [top10Customer, settop10Customer] = useState([]);
  const [data, setData] = useState<any[]>([]);
  const [revenueGraph, setrevenueGraph] = useState<any>({
    mon: "",
    ms_price: '',
    non_dis_price: '',
    re_price: '',
    revenue: ''
  });
  const [year, setYear] = useState<any>(new Date().getFullYear());
  const [yearForCustomer, setYearForCustomer] = useState<any>(new Date().getFullYear());






  // const data = [
  //   {
  //     name: revenueGraph?.mon,
  //     uv: revenueGraph?.re_price,
  //     pv: revenueGraph?.revenue,
  //     amt: 2000,
  //   },
  //   {
  //     name: 'Feb',
  //     uv: 3000,
  //     pv: 1398,
  //     amt: 2000,
  //   },
  //   {
  //     name: 'Mar',
  //     uv: 2000,
  //     pv: 9800,
  //     amt: 2000,
  //   },

  //   {
  //     name: 'Apr',
  //     uv: 1890,
  //     pv: 4800,
  //     amt: 2000,
  //   },
  //   {
  //     name: 'May',
  //     uv: 2390,
  //     pv: 3800,
  //     amt: 2000,
  //   },
  //   {
  //     name: 'Jun',
  //     uv: 3490,
  //     pv: 4300,
  //     amt: 2000,
  //   },
  //   {
  //     name: 'July',
  //     uv: 1890,
  //     pv: 4800,
  //     amt: 2000,
  //   },
  //   {
  //     name: 'Aug',
  //     uv: 2390,
  //     pv: 3800,
  //     amt: 2000,
  //   },
  //   {
  //     name: 'Oct',
  //     uv: 3490,
  //     pv: 4300,
  //     amt: 2000,
  //   },
  //   {
  //     name: 'Nov',
  //     uv: 3490,
  //     pv: 43000,
  //     amt: 2000,
  //   },
  //   {
  //     name: 'Dec',
  //     uv: 34090,
  //     pv: 4300,
  //     amt: 2000,
  //   },
  // ];


  const CustomXAxis = ({ x, y, payload }: any) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={20} textAnchor="middle" fill="#666" fontSize={12} style={{ margin: '0 10px' }}>
          {payload.value}
        </text>
      </g>
    );
  };
  // const formatYAxisTick = (value: any) => `$${value}`;

  const formatYAxisTick = (value: any) => {
    if (value >= 1000) {
      return `$${value / 1000}k`;
    }
    return `$${value}`;
  };



  useEffect(() => {
    getGraphdata();
  }, [year]);


  async function getGraphdata() {
    let payload = {
      customer_id: customerId,
      search_value: "",
      column_name: "commitment_end_date",
      column_type: "asc",
      record_count: 4,
    };

    let UpcomingRenewal: any = await getUpcomingRenewal(payload);
    if (UpcomingRenewal.data.statusCode == 200) {
      setUpcomingRenewal(UpcomingRenewal.data.responseData.upcomming_renewals);
    }

    let payload3 = {
      year: yearForCustomer,
    };
    let toptencust = await getTopTenCustomerProfit(payload3);
    if (toptencust.data.statusCode == 200) {
      settop10Customer(toptencust.data.responseData);
    }


    let payload4 = {
      customer_id: customerId,
      year: year,
    };

    let graph = await getRevenueCostGraph(payload4);


    if (graph.data.statusCode == 200) {
      // setData((prevData) => [
      //   ...prevData,
      //   ...graph?.data.responseData.map((item:any) => ({
      //     name: item.mon,
      //     uv: Math.abs(item.re_price),
      //     pv: Math.abs(item.revenue),
      //     amt: 2000,
      //   })),
      // ]);


      // setrevenueGraph(graph.data.responseData);

      let graphData: any = [];
      let months: string[] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];


      months.map((month: string, index: number) => {

        let month_data: any = graph?.data.responseData.filter((val: any) => { return (val.mon == month) });

        if (month_data.length > 0) {
          //pv vendor price and revenue 
          //uv our price and cost


          if (customerId) {
            graphData.push(
              {
                name: month_data[0]?.mon.substring(0, 3),
                ourprice: month_data[0]?.re_price ? month_data[0]?.re_price : 0,
                vendorprice: month_data[0]?.ms_price ? month_data[0]?.ms_price : 0,
                amt: 0,
              }
            )

          } else {
            graphData.push(
              {
                name: month_data[0]?.mon.substring(0, 3),
                revenue: month_data[0]?.re_price ? month_data[0]?.re_price : 0,
                cost: month_data[0]?.non_dis_price ? month_data[0]?.non_dis_price : 0,
                amt: 0,
              }

            )
          }

        }
        else {


          if (customerId) {
            graphData.push(
              {
                name: month.substring(0, 3),
                ourprice: 0,
                vendorprice: 0,
                amt: 0,
              }
            )

          } else {
            graphData.push(
              {
                name: month.substring(0, 3),
                revenue: 0,
                cost: 0,
                amt: 0,
              }

            )
          }

        }


        // else {

        //   // graphData.push(
        //   //   {
        //   //     name: months.substring(0,2),
        //   //     uv: 0,
        //   //     pv: 0,
        //   //     amt: 0,
        //   //   }
        //   // )



        //   if (customerId) {
        //     graphData.push(
        //       {
        //         name: month_data[0]?.mon.substring(0, 3),
        //         ourprice: 0,
        //         vendorprice: 0,
        //         amt: 0,
        //       }
        //     )



        //   } else {
        //     graphData.push(
        //       {
        //         name: month_data[0]?.mon.substring(0, 3),
        //         revenue: 0,
        //         cost: 0,
        //         amt: 0,
        //       }
        //     )



        //   }


        // }
      })


      setData(graphData);

      // graph?.data.responseData.map((item:any, index:number)=>{



      //   if(item.mon == months[index]){

      //     graphData.push(
      //       {
      //         name: item.mon,
      //         uv: item.re_price,
      //         pv: item.revenue,
      //         amt: 0,
      //       }
      //     )

      //   }


      // })



    }
  }
  const handlePopoverOpen = (event: any, index: any) => {
    setAnchorEl(event.currentTarget);
    setHoveredLicenseIndex(index);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setHoveredLicenseIndex(null);
  };

  const YearDropdown = ({ years, onChange }: any) => {
    return (
      <select onChange={(e) => onChange(parseInt(e.target.value, 10))}>
        <option value="">Select a year</option>
        {years.map((year: any) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    );
  };
  const [selectedYear, setSelectedYear] = useState(new Date());
  const [selectedYearForCustomer, setSelectedYearForCustomer] = useState(new Date());

  const years = Array.from({ length: 10 }, (_, index) => new Date().getFullYear() - index);

  const [hidden, setHidden] = useState<boolean>(false);

  const handleYearChanger = async (e: any) => {
    setSelectedYear(e);
    const dateObject = new Date(e);
    const yearVal = dateObject.getFullYear();
    setYear(yearVal);
    let payload4 = {
      customer_id: customerId,
      year: yearVal,
    };

    let graph = await getRevenueCostGraph(payload4);
  }
  const handleYearChangerForCustomer = async (e: any) => {
    setSelectedYearForCustomer(e);
    const dateObject = new Date(e);
    const yearVal = dateObject.getFullYear();
    setYearForCustomer(yearVal);
    let payload4 = {
      year: yearVal,
    };

    let graph = await getTopTenCustomerProfit(payload4);
    if (graph.data.statusCode == 200) {
      settop10Customer(graph.data.responseData);
    }

  }
  function displayGraph() {
    return (
      <>
        {/* <div className="color-white-bg p-3 d-flex align-items-center justify-content-between border-radius-8"> */}
        <div className="d-flex align-items-center justify-content-between">
          <span className="tertiary-color font-semibold">{(customerId ? "Vendor Price Vs Our Price" : "Revenue Vs Cost")} </span>
          <span className="font-semibold font-14 color-black d-flex align-items-center ">
            <> <span className="me-3">
              <img src="Images/blue-dot.svg" className="me-2" alt="blue-dot" />
              {customerId ? "Vendor Price" : "Revenue"}
            </span>
              <span className="me-2">
                <img src="Images/green-dot.svg" className="me-2" alt="green-dot" />
                {customerId ? "Our Price" : "Cost"}
              </span>
              <span className="">
                <span className="font-semibold font-14 color-black d-flex justify-content-end align-items-center">
                  Year
                  {/* <YearDropdown years={years} onChange={setSelectedYear} />
                
                */}

                  <DatePicker
                    selected={selectedYear}
                    onChange={(e: any) => {
                      handleYearChanger(e)
                    }}
                    showYearPicker
                    name="year"
                    value={year}

                    dateFormat="yyyy"
                    placeholderText="Year"
                  //className="form-select dashboard-custom-select custom-select font-14 ms-2"


                  />

                  {/* <select
                  className="form-select dashboard-custom-select custom-select font-14 ms-2"
                  aria-label="Default select example"
                >
                  <option className="font-14 font-regular">2023</option>
                  <option value={1}>2024</option>
                  <option value={2}>2025</option>
                  <option value={3}>2026</option>
                </select> */}
                </span>{" "}
              </span></>
          </span>
        </div>
        <div className="bottom-scroll db-wid-x-scrl ">
          <>
            {customerId ? <>
              <ResponsiveContainer width={800} height={360} >
                <BarChart
                  width={500}
                  height={300}
                  data={data}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <XAxis
                    dataKey="name"
                    axisLine={false}
                    tickLine={false}
                    height={40}
                    tick={<CustomXAxis />}
                  />
                  <YAxis tickLine={false} axisLine={false} tickFormatter={formatYAxisTick} />
                  <Tooltip formatter={(value: any) => `$${(formatAmount(Number(value)))}`} />
                  <Legend />
                  <CartesianGrid strokeDasharray="3 3" vertical={false} />
                  {/* You can adjust the strokeDasharray values to achieve different dash patterns */}
                  {/* For example, "3 3" represents a pattern of 3 units filled, 3 units empty */}

                  <Bar dataKey="ourprice" stackId="a" fill="#8884d8" />
                  <Bar dataKey="vendorprice" stackId="a" fill="#82ca9d" />                
                </BarChart>
              </ResponsiveContainer></> :
              <> <ResponsiveContainer width={800} height={360} >
                <BarChart
                  width={500}
                  height={300}
                  data={data}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <XAxis
                    dataKey="name"
                    axisLine={false}
                    tickLine={false}
                    height={40}
                    tick={<CustomXAxis />}
                  />
                  <YAxis tickLine={false} axisLine={false} tickFormatter={formatYAxisTick} />
                  <Tooltip formatter={(value: any) => `$${(formatAmount(Number(value)))}`} />
                  <Legend />
                  <CartesianGrid strokeDasharray="3 3" vertical={false} />
                  {/* You can adjust the strokeDasharray values to achieve different dash patterns */}
                  {/* For example, "3 3" represents a pattern of 3 units filled, 3 units empty */}

                  <Bar dataKey="revenue" stackId="a" fill="#8884d8" />
                  <Bar dataKey="cost" stackId="a" fill="#82ca9d" />
                </BarChart>
              </ResponsiveContainer></>
            }
          </>

        </div>
      </>
    );
  }
  function displayUpcomingrenewal() {
    return (
      <>
        <div className="color-white-bg border-radius-8 height-420">
          <div className="p-3 d-flex align-items-center justify-content-between">
            <span className="tertiary-color font-semibold">
              Upcoming Renewal{" "}
            </span>
            {UpcomingRenewal.length > 0 ? <span className="font-semibold font-14 color-black">
              <a
                className="link-blue text-decoration-none cursor-pointer"
                onClick={() => {
                  navigate("/upcomingrenewal");
                }}
              >
                View all
                <img
                  src="Images/view-all.svg"
                  className="ms-2"
                  alt="next-arrow"
                />
              </a>
            </span> : <></>}
          </div>
          {UpcomingRenewal.length > 0 ?
            <div className="table-responsive mb-4 border-radius-8 " >
              <table className="table  themeTable mb-0">
                <thead className="font-bold ">
                  <tr>
                    <th className="text-start">Customer Name</th>
                    <th className="text-start">License</th>
                    <th className="text-start">Date</th>
                    <th className="text-end">Amount</th>
                  </tr>
                </thead>
                <tbody className="font-medium">
                  {UpcomingRenewal?.map((data: any, index: any) => {
                    return (
                      <>
                        <tr className="border-bottom-0 ">
                          <td className="text-start font-regular">
                            <Tool title={data?.company_name} placement="top-end" style={{ pointerEvents: data?.company_name?.length > 14 ? 'auto' : 'none' }}>
                              <Button className="text-fixer">
                                {data?.company_name?.length > 14 ? data?.company_name.substring(0, 14) + "..." : data?.company_name}
                              </Button>
                            </Tool>
                          </td>
                          <td className="text-start font-regular">
                            {/* <div key={index}>
                              <Typography
                                aria-owns={
                                  anchorEl
                                    ? `mouse-over-popover-${index}`
                                    : undefined
                                }
                                aria-haspopup="true"
                                onMouseEnter={(event) =>
                                  handlePopoverOpen(event, index)
                                }
                                onMouseLeave={handlePopoverClose}
                              >
                                {data.license_name.length >= 30
                                  ? data.license_name.substring(0, 22) + "..."
                                  : data.license_name}
                              </Typography>
                              <Popover
                                id={`mouse-over-popover-${index}`}
                                sx={{
                                  pointerEvents: "none",
                                }}
                                open={
                                  Boolean(anchorEl) &&
                                  index === hoveredLicenseIndex
                                }
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                onClose={handlePopoverClose}
                                disableRestoreFocus
                              >
                                <Typography sx={{ p: 1 }}>
                                  <p className="font-regular font-14">
                                    {data.license_name}
                                  </p>
                                </Typography>
                              </Popover>
                            </div> */}
                            {/* <Tool title={data.license_name} placement="top-end">
                              <Button className="text-fixer">{data?.license_name?.length > 21 ? data?.license_name.substring(0, 21) + "..." : data?.license_name}</Button>
                            </Tool> */}
                            <Tool title={data?.license_name} placement="top-end" style={{ pointerEvents: data?.license_name?.length > 21 ? 'auto' : 'none' }}>
                              <Button className="text-fixer">
                                {data?.license_name?.length > 21 ? data?.license_name.substring(0, 15) + "..." : data?.license_name}
                              </Button>
                            </Tool>
                          </td>
                          <td className="text-start font-regular">
                            {moment(data.commitment_end_date).format(
                              "MM/DD/YYYY"
                            )}
                          </td>
                          <td className="text-end font-regular">
                            ${data.amount ? formatAmount(data.amount)  : "0.00"}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div> : <><div className="d-flex justify-content-center align-items-center dashboard-nodata-height">
              <img src="Images/No-data-found.svg" alt="Norecords" /></div>
              <div>
                <p className="d-flex font-15 font-regular tertiary-color justify-content-center">
                  No Data Found
                </p>
              </div>
            </>}
        </div>
      </>
    );
  }
  function displayToptencust() {
    return (
      <>
        <div className="color-white-bg border-radius-8 height-420 ">
          <div className=" p-3 d-flex align-items-center justify-content-between ">
            <span className="tertiary-color font-semibold">
              Top 10 Customers
            </span>
            <span className="font-semibold font-14 color-black d-flex justify-content-end align-items-center">
              Year
              {/* <select
                className="form-select font-14 dashboard-custom-select custom-select ms-2"
                aria-label="Default select example"
              >
                <option className="font-14 font-regular">2023</option>
                <option value={1}>2024</option>
                <option value={2}>2025</option>
                <option value={3}>2026</option>
              </select> */}
              <DatePicker
                selected={selectedYearForCustomer}
                onChange={(e: any) => {
                  handleYearChangerForCustomer(e)
                }}
                value={yearForCustomer}
                showYearPicker
                name="yearForCustomer"
                dateFormat="yyyy"
                placeholderText="Year"
              />
            </span>

          </div>
          {top10Customer.length > 0 ? <div className="table-responsive mb-4 border-radius-8 split-delv-table-resp">
            <table className="table  themeTable mb-0 ">
              <thead className="font-bold">
                <tr>
                  <th className="text-start">Customer Name</th>
                  <th className="text-end">Revenue</th>
                </tr>
              </thead>
              <tbody className="font-medium">
                {top10Customer.map((cust: any, index: number) => {
                  return (
                    <>
                      <tr className="border-bottom-0 ">
                        <td className="text-start font-regular">
                          {/* {cust?.company_name?.length > 14 ? <Tool title={cust.company_name} placement="top-end">
                            <Button className="text-fixer">{cust?.company_name.substring(0, 14) + "..."}</Button>
                          </Tool> : cust?.company_name} */}

                          <Tool title={cust?.company_name} placement="top-end" style={{ pointerEvents: cust?.company_name?.length > 14 ? 'auto' : 'none' }}>
                            <Button className="text-fixer">
                              {cust?.company_name?.length > 14 ? cust?.company_name.substring(0, 14) + "..." : cust?.company_name}
                            </Button>
                          </Tool>
                        </td>
                        <td className="text-end font-regular">
                          ${cust.revenue? formatAmount(cust.revenue):"0.00"}
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div> : <><div className="d-flex justify-content-center align-items-center dashboard-top-10-nodata-height">
            <img src="Images/No-data-found.svg" alt="Norecords" /></div>
            <div>
              <p className="d-flex font-15 font-regular tertiary-color justify-content-center">
                No Data Found
              </p>
            </div>
          </>}

        </div>
      </>
    );
  }
  return (
    <>
      {customerId == null ? (
        <div className="col-lg-12 col-xl-4 col-md-12 col-12 mb-1 p-1 ">
          <div className="height-420 color-white-bg  border-radius-8">
            <div className="color-white-bg p-3 border-radius-8">
              {displayGraph()}
            </div>

          </div>
        </div>
      ) : (
        <div className="col-lg-12 col-xl-6 col-md-12 col-12 mb-1 p-1">
          <div className="height-420 color-white-bg  rounded-3">
            <div className="color-white-bg p-3  border-radius-8">  {/* d-flex align-items-center justify-content-between */}
              {displayGraph()}
            </div>
          </div>
        </div>
      )}
      {customerId == null ? (
        <div className="col-lg-12 col-xl-5 col-md-12 col-12 p-1">
          {displayUpcomingrenewal()}
        </div>
        // <div className="col-lg-12 col-xl-5 col-md-12 col-12 mb-5 p-1">
        //   <div className="color-white-bg border-radius-8 height-420">
        //     <div className="p-3 d-flex align-items-center justify-content-between">
        //       <span className="tertiary-color font-semibold">
        //         Upcoming Renewal
        //       </span>
        //       <span className="font-semibold font-14 color-black">
        //         <a className="link-blue text-decoration-none cursor-pointer">
        //           View all
        //           <img
        //             src="Images/view-all.svg"
        //             className="ms-2"
        //             alt="next-arrow"
        //           />
        //         </a>
        //       </span>
        //     </div>
        //     <div className="table-responsive mb-4 border-radius-8">
        //       <table className="table themeTable mb-0">
        //         <thead className="font-bold">
        //           <tr>
        //             <th className="text-start">Customer Name</th>
        //             <th className="text-start">License</th>
        //             <th className="text-start">Date</th>
        //             <th className="text-end">Amount</th>
        //           </tr>
        //         </thead>
        //         <tbody className="font-medium">
        //           <tr className="border-bottom-0">
        //             <td className="text-start font-regular">Lenovo</td>
        //             <td className="text-end font-regular">
        //               Microsoft Defender...
        //             </td>
        //             <td className="text-start font-regular">07/27/2023</td>
        //             <td className="text-end font-regular">$4.99</td>
        //           </tr>
        //           <tr className="border-bottom-0">
        //             <td className="text-start font-regular">Lenovo</td>
        //             <td className="text-end font-regular">
        //               Microsoft Defender...
        //             </td>
        //             <td className="text-start font-regular">07/27/2023</td>
        //             <td className="text-end font-regular">$4.99</td>
        //           </tr>
        //           <tr className="border-bottom-0">
        //             <td className="text-start font-regular">Lenovo</td>
        //             <td className="text-end font-regular">
        //               Microsoft Defender...
        //             </td>
        //             <td className="text-start font-regular">07/27/2023</td>
        //             <td className="text-end font-regular">$4.99</td>
        //           </tr>
        //           <tr className="border-bottom-0">
        //             <td className="text-start font-regular">Lenovo</td>
        //             <td className="text-end font-regular">
        //               Microsoft Defender...
        //             </td>
        //             <td className="text-start font-regular">07/27/2023</td>
        //             <td className="text-end font-regular">$4.99</td>
        //           </tr>
        //           <tr className="border-bottom-0">
        //             <td className="text-start font-regular">Lenovo</td>
        //             <td className="text-end font-regular">
        //               Microsoft Defender...
        //             </td>
        //             <td className="text-start font-regular">07/27/2023</td>
        //             <td className="text-end font-regular">$4.99</td>
        //           </tr>
        //         </tbody>
        //       </table>
        //     </div>
        //   </div>
        // </div>
      ) : (
        <div className="col-lg-12 col-xl-6 col-md-12 col-12 mb-2 p-1">
          {displayUpcomingrenewal()}
        </div>
        //     <div className="col-lg-12 col-xl-6 col-md-12 col-12 mb-2 p-1">
        //     <div className="color-white-bg border-radius-8 height-420">
        //       <div className="p-3 d-flex align-items-center justify-content-between">
        //         <span className="tertiary-color font-semibold">Upcoming Renewal</span>
        //         <span className="font-semibold font-14 color-black">
        //           <a className="link-blue text-decoration-none cursor-pointer">
        //             View all
        //             <img
        //               src="Images/view-all.svg"
        //               className="ms-2 cursor-pointer"
        //               alt="viewall-arrow"
        //             />
        //           </a>
        //         </span>
        //       </div>
        //       <div className="table-responsive mb-4 rounded-2">
        //         <table className="table themeTable mb-0">
        //           <thead className="font-bold">
        //             <tr>
        //               <th className="text-start">License</th>
        //               <th className="text-start">Date</th>
        //               <th className="text-end">Amount</th>
        //             </tr>
        //           </thead>
        //           <tbody className="font-medium">
        //             <tr className="border-bottom-0">
        //               <td className="text-start font-regular">Microsoft Defender...</td>
        //               <td className="text-start font-regular">07/27/2023</td>
        //               <td className="text-end font-regular">$4.99</td>
        //             </tr>
        //             <tr className="border-bottom-0">
        //               <td className="text-start font-regular">Microsoft Defender...</td>
        //               <td className="text-start font-regular">07/27/2023</td>
        //               <td className="text-end font-regular">$4.99</td>
        //             </tr>
        //             <tr className="border-bottom-0">
        //               <td className="text-start font-regular">Microsoft Defender...</td>
        //               <td className="text-start font-regular">07/27/2023</td>
        //               <td className="text-end font-regular">$4.99</td>
        //             </tr>
        //             <tr className="border-bottom-0">
        //               <td className="text-start font-regular">Microsoft Defender...</td>
        //               <td className="text-start font-regular">07/27/2023</td>
        //               <td className="text-end font-regular">$4.99</td>
        //             </tr>
        //             <tr className="border-bottom-0">
        //               <td className="text-start font-regular">Microsoft Defender...</td>
        //               <td className="text-start font-regular">07/27/2023</td>
        //               <td className="text-end font-regular">$4.99</td>
        //             </tr>
        //           </tbody>
        //         </table>
        //       </div>
        //     </div>
        //   </div>
      )}
      {customerId == null ? (
        <div className="col-lg-12 col-xl-3 col-md-12 col-12 mb-2 p-1 ">
          {displayToptencust()}
        </div>
      ) : null}


    </>
  );
}
