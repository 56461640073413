
export default function Footer() {
  return (
    <>
      {" "}
      <footer className="fixed-bottom">
        <div className="tertiary-color-bg font-12 sticky-footer py-1">
          <span className="color-white font-12 font-semibold d-flex justify-content-center">
            © Avasoft Inc {new Date().getFullYear()}
          </span>
        </div>
      </footer>
    </>
  );
}
