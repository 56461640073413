import React from "react";

import { useState, useEffect } from "react";
import { subscription } from "../../interface";
import { defaultLimit } from "../../configs/constant";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import {
  getsubscriptionsApi,
  getAllDrodpownApi,
} from "../../services/subscriptionservice";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Footer from "../footer/footer";
import { sessionKeys, encryptStorage } from "../../configs/constant";
import { customerSessionData } from "../../interface";
import { CancelSubscriptionApi } from "../../services/subscriptionservice";
import Norecords from "../norecords";
import { Loader } from "../loader";
import { Button, Tooltip } from "@mui/material";
import { useLocation } from "react-router-dom";
import { userSessionData } from "../../interface";
//sq_sg_1, sq_sg_2, sq_sg_3, sq_sg_4, sq_sg_5, sq_sg_6, sq_sg_7, sq_sg_8, sq_sg_9, sq_sg_10, sq_sg_11, sq_sg_12, sq_sg_13, sq_sg_14, sq_sg_15, sq_sg_16, sq_sg_17
// to render the subcriptions details of the customer into the grid component
export default function Subscription() {
  //sq_sg_1 sq_sg_2 sq_sg_3 sq_sg_4 sq_sg_5

  let navigate = useNavigate();
  let location = useLocation();
  let customerdetails: customerSessionData | null = JSON.parse(
    encryptStorage.getItem(sessionKeys.customerDetails) || "null"
  );
  //sq_sg_3
  let subscription: subscription = {
    company_id: customerdetails?.customer_id || "",
    status: location?.state?.status ? location?.state?.status : "",
    billingCycle: "",
    termDuration: "",
    startDate: "",
    endDate: "",
  };
  //sq_sg_4
  const [getsubscription, setgetsubscription] =
    useState<subscription>(subscription);
  /** sq_sg_5 **/
  const [sortType, setsortType] = useState<any>("desc");
  const [sortColumn, setSortColumn] = useState<string>("created_date");
  const [count, setcount] = useState<number>(defaultLimit);
  const [search, setsearch] = useState<string>("");
  const [customerSort, setCustomerSort] = useState<boolean>(false);
  const [licenseSort, setlicenseSort] = useState<boolean>(false);
  const [quantitySort, setquantitySort] = useState<boolean>(false);
  const [statusSort, setstatusSort] = useState<boolean>(false);
  const [expiresSort, setexpiresSort] = useState<boolean>(false);
  const [termDurationSort, settermDurationSort] = useState<boolean>(false);
  const [billingCycleSort, setbillingCycleSort] = useState<boolean>(false);
  const [bindsubscription, setbindsubscription] = useState<any>([]);
  const [custNameDropdown, setcustNameDropdown] = useState([]);
  const [statusDropdown, setstatusDropdown] = useState([]);
  const [billingCycleDropdown, setbillingCycleDropdown] = useState([]);
  const [termDurationDropdown, settermDurationDropdown] = useState([]);
  const [filterpopup, setfilterpopup] = useState<Boolean>(false);
  const [loader, setloader] = useState(false);
  const [isOpenAccordions, setIsOpenAccordions] = useState(() => {
    const initialState = Array(bindsubscription.length).fill(false);
    initialState[0] = true; // Set the first item to true
    return initialState;
  });
  const [popupopen, setpopupopen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState<null | number>(null);
  const [subscriptionCount, setsubscriptionCount] = useState<any>();
  const [customerCount, setcustomerCount] = useState<any>();
  const [license_name, setlicense_name] = useState<any>("");

  const [deleteSubscriptionSuccess, setdeleteSubscriptionSuccess] =
    useState(false);
    const[subscriptionDeleteMessage,setssubscriptionDeleteMessage]=useState("")
    const[showSuccessSymbol,setshowSuccessSymbol]=useState(true)

  // const [rerenderComponent, setrerenderComponent] = useState(false);
  // const [isOpen, setIsOpen] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [deleteSubscription, setdeleteSubscription] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const [subscriptionId, setsubscriptionId] = useState("");
  const open = Boolean(anchorEl);

  let sessionUserDetails: userSessionData | null = JSON.parse(
    encryptStorage.getItem(sessionKeys.userDetails) || "null"
  );

  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    getSubscription();
  }, [
    customerSort,
    licenseSort,
    quantitySort,
    statusSort,
    expiresSort,
    termDurationSort,
    billingCycleSort,
    sortType,
    sortColumn,
    count,
  ]);

  useEffect(() => {
    getAllDropdowns();
  }, []);

  // useEffect(() => {
  //   getSubscription(subscription);
  // }, [rerenderComponent]);
  // sq_sg_6 sq_sg_7 sq_sg_8 sq_sg_9 sq_sg_10 Fetch the drop option values
  async function getAllDropdowns() {
    setloader(true);
    let dropdown = await getAllDrodpownApi();

    if (dropdown.data.statusCode == 200) {
      setcustNameDropdown(dropdown.data.responseData[0]);
      setstatusDropdown(dropdown.data.responseData[1]);
      settermDurationDropdown(dropdown.data.responseData[2]);
      setbillingCycleDropdown(dropdown.data.responseData[3]);
    }
  }
  //** sq_sg_7, sq_sg_8, sq_sg_9, sq_sg_10, sq_sg_11, sq_sg_12 Fetch the Subcriptions */
  async function getSubscription(filter = getsubscription) {
    setloader(true);
    let data = {
      isCustomer: customerdetails?.customer_id ? true : false,
      count: count,
      sortColumn: sortColumn,
      sortType: sortType,
      search: search,
      ...filter,
    };

    let subscription = await getsubscriptionsApi(data);

    if (subscription.data.statusCode == 200) {
      setbindsubscription(subscription.data.responseData);
      setsubscriptionCount(
        subscription.data.subscriptionCount[0].subscriptioncount
      );
      setcustomerCount(subscription.data.count[0].count);
      setloader(false);
    }
  }
  //sq_sg_15 sq_sg_16 sq_sg_17 onchange functionality set the value into state
  async function onchangeSubcriptions(e: any) {
    setgetsubscription((Applyfilterdata) => ({
      ...Applyfilterdata,
      [e.target.name]: e.target.value,
    }));
  }

  //sq_sg_39 sq_sg_40 sq_sg_41 handle accordions onclick
  const handleAccordionClick = (index: any) => {
    const updatedAccordions = [...isOpenAccordions];
    updatedAccordions[index] = !updatedAccordions[index];
    setIsOpenAccordions(updatedAccordions);
  };

  //sq_sg_10  sq_sg_11 sq_sg_12 cancel the subscriptions
  async function cancelSubscription() {
    setloader(true);
    let payload = {
      userid: sessionUserDetails?.user_id,
      user_name: sessionUserDetails?.user_name,
      created_by: sessionUserDetails?.user_id,
      customerId: customerId,
      subscriptionId: subscriptionId,
      licenseName: license_name,
      roleId: sessionUserDetails?.role_id,
    };

    let cancel = await CancelSubscriptionApi(payload);
    if (cancel.data.statusCode == 200) {
      setssubscriptionDeleteMessage("subscription Deleted Successfully")
      setshowSuccessSymbol(true)
      setdeleteSubscriptionSuccess(true);
      setloader(false);
      setCustomerId("");
      setsubscriptionId("");
    }
    if(cancel.data.statusCode == 400){
      setssubscriptionDeleteMessage(cancel.data.responseData)
      setshowSuccessSymbol(false)
      setdeleteSubscriptionSuccess(true);
      setloader(false);
      setCustomerId("");
      setsubscriptionId("");
    }
  }

  return (
    <>
      <>
        {/* header starts */}
        {/* header ends */}
        {/* Admin starts */}
        {/* <div class="px-5">
  <p class="font-20 font-semibold table-title-color mt-4">Users<span class="no-of-records"></span></p>
    </div> */}
        <div className="container-fluid px-4 mt-5 pt-4">
          <div className="row">
            {/* <div class="col-lg-12 col-xl-12 col-md-12 col-12 mb-1 p-1"> */}
            <div className="d-block d-md-flex flex-wrap justify-content-between align-items-center  mb-4 mt-4">
              {/* <div class> */}
              <h2 className="font-20 font-bold dark-blue-color d-flex mb-0">
                Subscription
                <span className="custom-admin-batch font-11 d-flex align-items-center justify-content-center ms-2 px-2">
                  {subscriptionCount}
                </span>
                {/* </div> */}
              </h2>
              <div className="d-block d-md-flex justify-content-end">
                <div className="w-md-100 d-flex mb-3 mb-md-0">
                  <span className="position-relative d-flex align-items-center me-3 w-100">
                    <input
                      type="search"
                      className="border-0 font-regular w-230 cus-z-ind  rounded-2 font-14 top-0 bottom-0 py-2 px-3 custom-input"
                      id="search"
                      value={search}
                      placeholder="Search"
                      onKeyDown={(e) => {
                        // else if (search.length === 2 || search.length > 2) {
                        //   getSubscription();
                        // }
                        if (e.key === "Enter") {
                          getSubscription();
                        }
                      }}
                      onChange={(e: any) => {
                        setsearch(e.target.value);
                        if (e.key === "Enter") {
                          getSubscription();
                        }
                      }}
                    />
                    <span className="position-absolute search-icon">
                      <img
                        src="Images/search.svg"
                        alt="search-icon"
                        onClick={() => {
                          getSubscription();
                        }}
                      />
                    </span>
                  </span>
                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn secondary-btn custom-filter rounded-3 height-37"
                      onClick={() => {
                        {
                          setfilterpopup(true);
                        }
                      }}
                    >
                      <img
                        src="Images/filter-active-icon.svg"
                        alt="Filter"
                        className="custom-filter-active-img"
                      />
                      <img
                        src="Images/Filter.svg"
                        alt="Filter"
                        className="custom-filter-img"
                      />
                    </button>
                    {filterpopup ? (
                      <div
                        style={{
                          position: "absolute",
                          inset: "0px auto auto 0px",
                          margin: "0px",
                          transform: " translate(-340px, 42px)",
                        }}
                        className="dropdown-menu dropdown-menu-end adv-dropdown p-3 mt-1 border-0 show"
                      >
                        <div className="row">
                          <div className="d-flex justify-content-between align-items-center mb-3">
                            <h6 className="font-16 font-semibold tertiary-color mb-0 ">
                              Filter
                            </h6>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={() => {
                                {
                                  setfilterpopup(false);
                                }
                              }}
                            />
                          </div>
                          {!customerdetails?.customer_id ? (
                            <div className="col-sm-12 col-md-6 mb-4">
                              <label className="form-label font-14 font-semibold color-black">
                                Customer Name
                              </label>
                              <select
                                name="company_id"
                                className="form-select custom-select custom-input font-regular font-13"
                                value={getsubscription.company_id}
                                onChange={(e) => {
                                  onchangeSubcriptions(e);
                                }}
                              >
                                <option selected value="">
                                  Select
                                </option>
                                {custNameDropdown.map((val: any) => {
                                  return (
                                    <>
                                      <option value={val.customer_id}>
                                        {val.company_name}
                                      </option>
                                    </>
                                  );
                                })}
                              </select>
                            </div>
                          ) : null}
                          <div className="col-sm-12 col-md-6 mb-4">
                            <label className="form-label font-14 font-semibold color-black">
                              Status
                            </label>
                            <select
                              name="status"
                              className=" form-select custom-select custom-input font-regular font-13"
                              value={getsubscription.status}
                              onChange={(e) => {
                                onchangeSubcriptions(e);
                              }}
                            >
                              <option selected value="">
                                Select
                              </option>
                              {statusDropdown.map((val: any) => {
                                return (
                                  <>
                                    <option value={val.status}>
                                      {val.status}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                          <div className="col-sm-12 col-md-6 mb-4">
                            <label className="form-label font-14 font-semibold color-black">
                              Term Duration
                            </label>
                            <select
                              name="termDuration"
                              className=" form-select custom-select custom-input font-regular font-13"
                              value={getsubscription.termDuration}
                              onChange={(e) => {
                                onchangeSubcriptions(e);
                              }}
                            >
                              <option selected value="">
                                Select
                              </option>
                              {termDurationDropdown.map((vals: any) => {
                                return (
                                  <>
                                    <option>{vals.term_duration}</option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                          <div className="col-sm-12 col-md-6 mb-4">
                            <label className="form-label font-14 font-semibold color-black">
                              Billing Cycle
                            </label>
                            <select
                              name="billingCycle"
                              className=" form-select custom-select custom-input font-regular font-13"
                              value={getsubscription.billingCycle}
                              onChange={(e) => {
                                onchangeSubcriptions(e);
                              }}
                            >
                              <option selected value="">
                                Select
                              </option>
                              {billingCycleDropdown.map((val: any) => {
                                return (
                                  <>
                                    <option>{val.billing_cycle}</option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                          <h6 className="font-16 font-semibold tertiary-color mb-3 ">
                            Expires On
                          </h6>
                          <div className="col-sm-12 col-md-6 mb-5">
                            <label
                              htmlFor="from1"
                              className="form-label font-14 font-semibold color-black"
                            >
                              From
                            </label>
                            <input
                              type="date"
                              className="form-control custom-input font-regular font-13"
                              id="from1"
                              name="startDate"
                              value={getsubscription.startDate}
                              onChange={(e) => {
                                onchangeSubcriptions(e);
                              }}
                              max={getsubscription.endDate}
                            />
                          </div>
                          <div className="col-sm-12 col-md-6 mb-5">
                            <label
                              htmlFor="to1"
                              className="form-label font-14 font-semibold color-black"
                            >
                              To
                            </label>
                            <input
                              type="date"
                              className="form-control custom-input font-regular font-13"
                              id="to1"
                              name="endDate"
                              value={getsubscription.endDate}
                              onChange={(e) => {
                                onchangeSubcriptions(e);
                              }}
                              min={getsubscription.startDate}
                            />
                          </div>
                          <div className="d-flex justify-content-end">
                            <button
                              type="button"
                              className="btn text border-0 font-14 font-medium me-3 shadow-none"
                              onClick={() => {
                                setgetsubscription(subscription);
                                // setrerenderComponent((prev) => !prev);
                                getSubscription(subscription);
                              }}
                            >
                              Clear
                            </button>
                            <button
                              type="button"
                              className="btn primary-btn border-0 font-14 font-medium "
                              onClick={() => {
                                // applyfilter()
                                setfilterpopup(false);
                                getSubscription();
                              }}
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div class="tab-content" id="pills-orders"> */}
          {/* first tab starts here */}
          <div className="w-100 table-container child-table-container rounded-3 padding-1 container-bg-color border-radius-5">
            <table className="table mb-0 themeTable rounded-3 ">
              <thead className="fixed-table-head bg-white font-semibold">
                <tr className="th-shadow">
                  <th className="custom-th-width" />
                  <th>
                    Customer Name
                    <a
                      className="ms-2 cursor-pointer"
                      onClick={() => {
                        setCustomerSort(!customerSort);
                        setSortColumn("company_name");
                        setsortType(customerSort ? "asc" : "desc");
                      }}
                    >
                      <img
                        src={
                          customerSort
                            ? "Images/sort-up-arrow.svg"
                            : "Images/sort-arrow.svg"
                        }
                        alt="sortarrow"
                        title="Sort"
                      />
                    </a>
                  </th>
                  <th>
                    License
                    <a
                      className="ms-2 cursor-pointer"
                      onClick={() => {
                        setlicenseSort(!licenseSort);
                        setSortColumn("license_name");
                        setsortType(licenseSort ? "asc" : "desc");
                      }}
                    >
                      <img
                        src={
                          licenseSort
                            ? "Images/sort-up-arrow.svg"
                            : "Images/sort-arrow.svg"
                        }
                        alt="sortarrow"
                        title="Sort"
                      />
                    </a>
                  </th>
                  <th className="text-end">
                    Quantity
                    <a
                      className="ms-2 cursor-pointer"
                      onClick={() => {
                        setquantitySort(!quantitySort);
                        setSortColumn("quantity");
                        setsortType(quantitySort ? "asc" : "desc");
                      }}
                    >
                      <img
                        src={
                          quantitySort
                            ? "Images/sort-up-arrow.svg"
                            : "Images/sort-arrow.svg"
                        }
                        alt="sortarrow"
                        title="Sort"
                      />
                    </a>
                  </th>
                  <th className="text-start ">
                    Status
                    <a
                      className="ms-2 cursor-pointer"
                      onClick={() => {
                        setstatusSort(!statusSort);
                        setSortColumn("status");
                        setsortType(statusSort ? "asc" : "desc");
                      }}
                    >
                      <img
                        src={
                          statusSort
                            ? "Images/sort-up-arrow.svg"
                            : "Images/sort-arrow.svg"
                        }
                        alt="sortarrow"
                        title="Sort"
                      />
                    </a>
                  </th>
                  <th className="text-start ">
                    Expires On
                    <a
                      className="ms-2 cursor-pointer"
                      onClick={() => {
                        setexpiresSort(!expiresSort);
                        setSortColumn("expires_on");
                        setsortType(expiresSort ? "asc" : "desc");
                      }}
                    >
                      <img
                        src={
                          expiresSort
                            ? "Images/sort-up-arrow.svg"
                            : "Images/sort-arrow.svg"
                        }
                        alt="sortarrow"
                        title="Sort"
                      />
                    </a>
                  </th>
                  <th className="">
                    Term Duration
                    <a
                      className="ms-2 cursor-pointer"
                      onClick={() => {
                        settermDurationSort(!termDurationSort);
                        setSortColumn("term_duration");
                        setsortType(termDurationSort ? "asc" : "desc");
                      }}
                    >
                      <img
                        src={
                          termDurationSort
                            ? "Images/sort-up-arrow.svg"
                            : "Images/sort-arrow.svg"
                        }
                        alt="sortarrow"
                        title="Sort"
                      />
                    </a>
                  </th>
                  <th className="">
                    Billing Cycle
                    <a
                      className="ms-2 cursor-pointer"
                      onClick={() => {
                        setbillingCycleSort(!billingCycleSort);
                        setSortColumn("billing_cycle");
                        setsortType(billingCycleSort ? "asc" : "desc");
                      }}
                    >
                      <img
                        src={
                          billingCycleSort
                            ? "Images/sort-up-arrow.svg"
                            : "Images/sort-arrow.svg"
                        }
                        alt="sortarrow"
                        title="Sort"
                      />
                    </a>
                  </th>
                  <th className="text-center">Actions</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {bindsubscription.map((value: any, index: any) => {
                  const isOpen = isOpenAccordions[index];
                  bindsubscription.length == index && setloader(false);
                  return (
                    <>
                      <tr>
                        <td className="text-center">
                          <span>
                            <img
                              src="Images/accordion-arrow.svg"
                              alt="accordion-arrow-icon"
                              className={`cursor-pointer accordion-arrow ${
                                isOpen ? "" : "collapsed"
                              }`}
                              onClick={() => handleAccordionClick(index)}
                              data-bs-toggle="collapse"
                              data-bs-target={`#customer${index + 1}`}
                              aria-expanded={isOpen ? "true" : "false"}
                            />
                          </span>
                        </td>
                        <td>{value.companyName}</td>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                      </tr>
                      {isOpen &&
                        value.CustomerData.map((val: any, subIndex: any) => {
                          const targetDate = new Date(val.cancel_allow_until);
                          const isButtonDisabled = !(targetDate >= currentDate);
                          return (
                            <>
                              <tr
                                id={`customer${index + 1}`}
                                className={`accordion-collapse collapse ${
                                  isOpen ? "show" : ""
                                } themeTable`}
                              >
                                <td className="color-white-bg" />
                                <td></td>
                                <td className="link-blue cursor-pointer font-medium">
                                  {/* {  val.license_name > 9? */}
                                  {/* <p
                                  className="mb-0 text-truncate custom-text-truncate-width custom-popover"
                                  data-bs-toggle="popover"
                                  data-bs-trigger="hover focus"
                                  data-bs-placement="top"
                                  title={val.license_name}
                                  data-bs-content="Disabled popover"

                                > <p
                                   
                                onClick={() => {
                                  navigate("/editsubscription", {
                                    state: {
                                      mode: "view",
                                      params: val,
                                      ...value.company_name,
                                    },
                                  });
                                }}
                                   
                                 
                                  >
                                    {val.license_name.substring(0, 8) + "..."}
                                  </p></p>  */}
                                  {/* <div>
                                    <Typography
                                      aria-owns={
                                        open ? "mouse-over-popover" : undefined
                                      }
                                      aria-haspopup="true"
                                      onMouseEnter={(event: any) => {
                                        setAnchorEl(event.currentTarget);
                                      }}
                                      onMouseLeave={() => {
                                        setAnchorEl(null);
                                      }}
                                    >
                                     {val.license_name.substring(0, 8) + "..."}
                                    </Typography>
                                    <Popover
                                      id="mouse-over-popover"
                                      sx={{
                                        pointerEvents: "none",
                                      }}
                                      open={open}
                                      anchorEl={anchorEl}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                      }}
                                      onClose={() => {
                                        setAnchorEl(null);
                                      }}
                                      disableRestoreFocus
                                    >
                                      <Typography sx={{ p: 1 }}>
                                      <p className="font-regular font-14" >{val.license_name}</p> 
                                      {/* <p className="font-regular font-14">ID: <span className="fw-bold">CFQ7TTC0LF8S:0003</span></p>  */}
                                  {/* </Typography>
                                    </Popover>
                                  </div> */}
                                  {val.license_name.length > 25 ? (
                                    <Tooltip
                                      title={val.license_name}
                                      placement="top-end"
                                    >
                                      <Button
                                        className="text-fixer text-font p-0"
                                        onClick={() => {
                                          navigate("/editsubscription", {
                                            state: {
                                              mode: "view",
                                              params: val,
                                              ...value.company_name,
                                            },
                                          });
                                        }}
                                      >
                                        {val.license_name.substring(0, 25) +
                                          "..."}
                                      </Button>
                                    </Tooltip>
                                  ) : (
                                    <span
                                      onClick={() => {
                                        navigate("/editsubscription", {
                                          state: {
                                            mode: "view",
                                            params: val,
                                            ...value.company_name,
                                          },
                                        });
                                      }}
                                    >
                                      {val.license_name}
                                    </span>
                                  )}

                                  {/* // :val.license_name} */}
                                </td>
                                <td className="text-end">{val.quantity} </td>
                                <td
                                  className={`${
                                    val.status === "active"
                                      ? "color-light-green"
                                      : val.status == "deleted"
                                      ? "color-red"
                                      : val.status == "expired"
                                      ? "custom-expired-color"
                                      : val.status == "suspended"
                                      ? "custom-suspended-color"
                                      : val.status == "disabled"
                                      ? "color-grey"
                                      : "color-light-green"
                                  } text-start`}
                                >
                                  <span
                                    className={
                                      val.status === "active"
                                        ? "custom-green-dot"
                                        : val.status === "suspended"
                                        ? "custom-suspended-dot"
                                        : val.status === "expired"
                                        ? "custom-expired-dot"
                                        : val.status == "disabled"
                                        ? "custom-grey-dot"
                                        : val.status == "deleted"
                                        ? "custom-deleted-dot"
                                        : "custom-green-dot"
                                    }
                                  />
                                  {val.status.charAt(0).toUpperCase() +
                                    val.status.slice(1)}
                                </td>
                                <td className="">
                                  {moment(val.expires_on).format("MM/DD/YYYY")}
                                </td>
                                <td>{val.term_duration}</td>
                                <td>{val.billing_cycle}</td>
                                <td className="text-center">
                                  {val.status.toLowerCase() === "active" ? (
                                    <>
                                      <img
                                        src="Images/edit-icon.svg"
                                        alt="edit-icon"
                                        className="cursor-pointer"
                                        onClick={() => {
                                          navigate("/editsubscription", {
                                            state: {
                                              mode: "edit",
                                              params: val,
                                              ...value.company_name,
                                            },
                                          });
                                        }}
                                      />
                                      {!isButtonDisabled && (
                                        <img
                                          src="Images/delete-icon.svg"
                                          alt="delete-icon"
                                          className="ms-2 cursor-pointer"
                                          onClick={() => {
                                            setCustomerId(val.customer_id);
                                            setsubscriptionId(
                                              val.subscription_id
                                            );
                                            setlicense_name(val.license_name);

                                            setdeleteSubscription(true);
                                            // cancelSubscription(val);
                                          }}
                                        />
                                      )}
                                      {isButtonDisabled && (
                                        <img
                                          src="Images/delete-dis-icon.svg"
                                          alt="delete-icon"
                                          className="ms-2"
                                        />
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        src="Images/edit-dis-icon.svg"
                                        alt="edit-icon"
                                        className="cursor-not-allowed"
                                      />

                                      <img
                                        src="Images/delete-dis-icon.svg"
                                        alt="delete-icon"
                                        className="ms-2" // cancelSubscription(val);
                                      />
                                    </>
                                  )}
                                </td>

                                <td className="color-white-bg" />
                              </tr>
                            </>
                          );
                        })}
                    </>
                  );
                })}
              </tbody>
            </table>

            {loader ? (
              // <div className="overlay">
              //   <div className="position-absolute top-50 start-50 translate-middle">
              //     <div
              //       className="spinner-border Loader text-light align-center "
              //       role="status"
              //     >
              //       <span className="visually-hidden" />
              //     </div>
              //     <p className="loading-text font-14 color-white mt-2 font-semibold">
              //       Loading..
              //     </p>
              //   </div>
              // </div>
              <Loader />
            ) : null}
            {/* tabs ends here */}
            {/* </div> */}
          </div>
          {/* </div> */}

          {bindsubscription.length < customerCount ? (
            <div className="d-flex justify-content-center my-3">
              <button
                type="button"
                className="btn tertiary-btn border-0 font-14 font-semibold"
                onClick={() => {
                  setcount(count + defaultLimit);
                }}
              >
                load More
              </button>
            </div>
          ) : null}
          {deleteSubscriptionSuccess ? (
            <div
              className="modal fade show"
              style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex={-1}
              aria-hidden="true"
            >
              <div className="modal-dialog popup-w-400 modal-dialog-centered">
                <div className="modal-content px-3">
                  <div className="modal-header border-0 justify-content-center">
                    <span className="popup-icon-pos">
                      <img
                        src={`${showSuccessSymbol? "Images/success-icon.svg":"Images/exclamatory-icon.svg"}`}
                        alt="success-icon"
                        className="popup-icon"
                      />
                    </span>
                  </div>
                  <div className="modal-body pt-5 px-3">
                    <p className="text-center font-14 font-medium secondary-text-color">
                      {subscriptionDeleteMessage}{" "}
                    </p>
                  </div>
                  <div className="modal-footer border-0 justify-content-center px-3">
                    <button
                      type="button"
                      className={`btn text-btn ${showSuccessSymbol ? "green-btn":"danger-btn"} shadow-none px-3 font-14 font-medium`}
                      data-bs-dismiss="modal"
                      onClick={() => {
                        setdeleteSubscriptionSuccess(false);
                      }}
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {deleteSubscription ? (
            <div
              className="modal fade show"
              id="deletepopup"
              style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex={-1}
              aria-hidden="true"
            >
              <div className="modal-dialog popup-w-400 modal-dialog-centered">
                <div className="modal-content px-3">
                  <div className="modal-header border-0 justify-content-center">
                    <span className="popup-icon-pos">
                      <img
                        src="Images/delete-popup-icon.svg"
                        alt="delete-popup-icon"
                        className="popup-icon"
                      />
                    </span>
                  </div>
                  <div className="modal-body pt-5 px-3">
                    <p className="text-center font-14 font-medium secondary-text-color">
                      Are you sure, you want to delete the subscription?{" "}
                    </p>
                  </div>
                  <div className="modal-footer border-0 justify-content-center px-3">
                    <button
                      type="button"
                      className="btn text-btns shadow-none px-3 font-14 font-medium text-btns"
                      onClick={() => {
                        setdeleteSubscription(false);
                        setCustomerId("");
                        setsubscriptionId("");
                      }}
                    >
                      No
                    </button>
                    <button
                      type="button"
                      className="btn danger-btn font-14 font-medium"
                      onClick={() => {
                        cancelSubscription();
                        setdeleteSubscription(false);
                      }}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {bindsubscription.length <= 0 ? <Norecords /> : null}
        </div>

        <Footer />
        {/* Admin Ends */}
      </>
    </>
  );
}
