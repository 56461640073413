import React, { useEffect, useState } from "react";
import { getprofile } from "../../client";
import { useLocation, useNavigate } from "react-router-dom";
import {
  sessionKeys,
  encryptStorage,
  formatAmount,
} from "../../configs/constant";
import { customerSessionData } from "../../interface";
import { Loader } from "../loader";
import { getInvoiceDetails } from "../../services/invoiceService";
import moment from "moment";

export default function InvoiceData() {
  let navigate = useNavigate();
  let customerDetails: customerSessionData | null = JSON.parse(
    encryptStorage.getItem(sessionKeys.customerDetails) || "null"
  );
  const [customerId, setCustomerId] = useState(customerDetails?.customer_id);
  const [GetInvoiceDetails, setgetInvoiceDetails] = useState<any[]>([]);
  const [loader, setloader] = useState(false);

  useEffect(() => {
    getinvoiceData();
  }, []);

  async function getinvoiceData() {
    setloader(true);

    let payload5 = {
      start_date: "",
      end_date: "",
      status_type: "",
      customer_id: customerId,
      search_value: "",
      sortColumn: "modified_date",
      sortType: "DESC",
      count: "4",
    };

    let getinvoice = await getInvoiceDetails(payload5);

    if (getinvoice.data.statusCode === 200) {
      setgetInvoiceDetails(getinvoice.data.responseData.invoiceData);
    }

    setloader(false);
  }

  function invoiceData() {
    return (
      <>
        {" "}
        <div className="col-lg-12 col-xl-12 col-md-12 col-12 mb-2 p-0">
          <div className="color-white-bg border-radius-8">
            <div className=" p-3 d-flex align-items-center justify-content-between">
              <span className="tertiary-color font-bold">Invoice</span>
              <span className="font-semibold font-14 color-black">
                <a
                  className="link-blue text-decoration-none cursor-pointer"
                  onClick={() => {
                    navigate("/invoice");
                  }}
                >
                  View all
                  <img
                    src="Images/view-all.svg"
                    className="ms-2 cursor-pointer"
                    alt="next-arrow"
                  />
                </a>
              </span>
            </div>
            <div className="table-responsive mb-4 border-radius-8">
              {GetInvoiceDetails.length > 0 ? <table className="table  themeTable mb-0">
                <thead className="font-bold">
                  <tr>
                    <th className="text-start">Customer Name</th>
                    <th className="text-start">Invoice #</th>
                    <th className="text-start">Status</th>
                    <th className="text-start">Invoice Date</th>
                    <th className="text-end">Invoice Amount</th>
                  </tr>
                </thead>
                <tbody className="font-medium">
                  {GetInvoiceDetails.map((data: any) => {
                    return (
                      <>
                        <tr className="border-bottom-0 ">
                          <td className="text-start font-regular">
                            {data.company_name}
                          </td>
                    
                          <td className="text-start font-regular text-decoration-none">
                          {data.invoice_id}
                              {/* <a
                                onClick={() =>
                                  navigate("/invoiceform", {
                                    state: {
                                      mode: "edit",
                                      invoice_id: data?.invoice_id,
                                      company_name: data?.company_name,
                                    },
                                  })
                                }
                              >
                              {data.invoice_id}
                              </a> */}
                        </td>
                          <td className="text-start font-regular">
                            {data.status_type}
                          </td>
                          <td className="text-start font-regular">
                            {moment(data.created_date).format("MM/DD/YYYY")}
                          </td>
                          <td className="text-end font-regular">
                            ${formatAmount(data.reseller_price)}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table> : <>
                <div className="record-align">
                  <div className="d-flex justify-content-center align-items-center dashboard-nodata-height">
                    <img src="Images/No-data-found.svg" alt="Norecords" />
                  </div>
                  <div>
                    <p className="d-flex font-15 font-regular tertiary-color justify-content-center">
                      No Data Found
                    </p></div>
                </div>
              </>}
            </div>
          </div>
        </div>
      </>
    );
  }

  return <>{invoiceData()}</>;
}
